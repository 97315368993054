$card-sm-toolbar-btn-font-size: 1rem;
$card-lg-toolbar-btn-font-size: 1.125rem;

//some simple styling around a card or any element
.bcard {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    border-width: 0;
    background-color: #fff;

    position: relative;

    .card-header {
        background-color: transparent;
        border-bottom-color: rgba(0, 0, 0, 0.09);
    }
}


.ccard {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 0.5rem;
    border: 1px solid bgc-color('secondary', 'l2');
    background-color: #fff;

    position: relative;

    > .card-header {
        background-color: transparent;
        border-color: bgc-color('dark', 'l3');

        > .card-title {
            color: text-color('secondary', 'd4');
        }
    }
}


.dcard {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    border: 1px solid bgc-color('secondary', 'l2');
    background-color: #fff;

    position: relative;

    > .card-header {
        background-color: transparent;
        border-color: bgc-color('dark', 'l3');

        > .card-title {
            color: text-color('secondary', 'd4');
        }
    }
}

.acard {
    box-shadow: none;
    border-radius: 0;
    border: none;
    background-color: #fff;

    position: relative;

    > .card-header {
        background-color: transparent;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-bottom: 0.5rem;

        align-items: center;

        border-color: mix(bgc-color('secondary', 'l3'), bgc-color('secondary', 'l2'));
    }

    > .card-header .card-title {
        color: text-color('secondary', 'd4');
        font-size: 1.25rem;
    }

    > .card-body {
        padding: 0.75rem 0.25rem;
    }
}


.card-header {
    display: flex;
    justify-content: space-between;
    
    padding-left: 1rem;
    padding-right: 1rem;

    &.card-header-sm {
        padding: 0.375rem 0.625rem;
    }

    &.card-header-lg {
        padding: 1.125rem 1.25rem;
    }
}

.card-title {
    color: $card-header-color;
    flex-grow: 1;
    line-height: normal;
    margin-bottom: 0;
}


@keyframes cardFadeOutIn {
    0% {
      opacity: 0.5;
    }
    70% {
        opacity: 0.7;
    }
    100% {
        opacity: 0.9;
    }
}


.card-expanding {
    position: fixed;
    z-index: $zindex-fixed;
    @include transition(all $card-expanding-transition ease-in-out);

    &.card-expand {
        animation-name: cardFadeOutIn;
        animation-duration: $card-expanding-transition;
    }
}
@if $enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
        .card-expanding.card-expand {
           animation-duration: 0s;
       }
    }
}

.card-expand {
    z-index: $zindex-fixed;
    transition-delay: .1s;

    border-radius: 0;

    position: fixed;
    top: 0;
    /*rtl:ignore*/
    left: 0;
    width: 100%;
    height: 100%;

    > .card-body {
        max-height: none !important;
    }
}




.card-toolbar {
    align-self: flex-end;
    position: relative;

    display: inline-flex;
    align-items: center;

    padding-left: 0.5rem;

    &:not(.no-border)::before {
        content: "";
        display: block;
        position: absolute;

        left: 0;
        top: 1px;//-0.125rem;
        bottom: 1px;//-0.125rem;

        width: 0;
        overflow: hidden;

        border-left: 1px solid rgba(0,0,0,0.1);
    }
    &[class*="brc-"]::before {
        border-left-color: inherit;
    }

    & + .card-toolbar {
        margin-left: 0.5rem;
    }
}



.card-toolbar-btn {
    opacity: 0.85;
    @include transition(all $toolbar-btn-transition);
    

    margin-left: 0.625rem;
    font-size: $card-toolbar-btn-font-size;

    display: inline-block;
    
    &:hover {
        opacity: 1;
    }
    &:not(.btn):hover {
        transform: scale(1.1);
    }
}

.card-header {
    .collapsed[data-action="toggle"] [class*="-up"] {
        transform-origin: center 55%;
        transform: rotate(180deg);
    }
    .collapsed[data-action="toggle"] [class*="-down"] {
        transform-origin: center;
        transform: rotate(180deg);
    }
}




.card-header-sm .card-toolbar-btn {
    font-size: $card-sm-toolbar-btn-font-size;
}
.card-header-lg .card-toolbar-btn {
    font-size: $card-lg-toolbar-btn-font-size;
}


.card-body:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.bs-card-loading-overlay {
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
    
    z-index: 999;

    background-color: rgba(0,0,0,0.27);
    text-align: center;

    border-radius: inherit;

    .bs-card-loading-icon {
        position: relative;
        top: 25%;

        .card-body.collapse:not(.show) ~ & {
            top: 10%;
        }
    }
}


