/*!
 * Ace Admin Template v3.1.0
 * Copyright 2013-2020
 * You need a commercial license to use this product
 * https://bit.ly/35ciMLb
 */
html {
  --navbar-height: 4.5rem;
  --navbar-sm-height: 4rem;
  --navbar-mobile-height: 4rem;
  --sidebar-width: 16rem;
  --sidebar-collapsed-width: 5rem;
  --sidebar-horizontal-height: 4.25rem;
  --sidebar-horizontal-collapsed-height: 4rem;
  direction: ltr;
  text-align: left;
}

html, body {
  height: 100%;
  font-family: "Open Sans", Arial, sans-serif;
}

:focus {
  outline: none;
}

body {
  overflow-x: hidden;
  background-color: #e4e6e9;
  color: #41464d;
}

body:not(.is-document-loaded) * {
  transition: none !important;
}

.body-container {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-container.container {
  padding: 0;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
}

.body-container:not(.container) {
  max-width: 100vw;
}

.main-container {
  flex: 1 0 auto;
  display: flex;
  position: relative;
  max-width: 100vw;
  background-color: #f2f5f8;
}

.main-content {
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-content {
    min-height: 100vh;
  }
}

@media (min-width: 1220px) {
  .container {
    max-width: calc(100vw - 4rem);
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1408px;
  }
}

@media (min-width: 1600px) {
  .container.container-plus {
    max-width: 1536px;
  }
}

@media (max-width: 1199.98px) {
  body {
    background-color: #f2f5f8;
  }
  .container {
    max-width: none;
  }
  .body-container {
    max-width: 100vw;
    overflow: hidden;
  }
}

.page-content {
  flex: 1 0 auto;
  padding: 1rem 1rem 0.625rem;
}

@media (min-width: 768px) {
  .page-content {
    padding: 1.25rem 1.25rem 0.625rem;
  }
}

@media (min-width: 1200px) {
  .page-content {
    padding: 1.25rem 1.5rem 0.625rem;
  }
}

@media (min-width: 1400px) {
  .page-content {
    padding: 1.25rem 1.75rem 0.625rem;
  }
}

@media (min-width: 1600px) {
  .page-content {
    padding: 1.25rem 2.25rem 0.625rem;
  }
}

@media (min-width: 1640px) {
  .page-content.container:not(.container.container-plus) {
    padding: 1.25rem 1.5rem 0.625rem;
  }
}

@media (min-width: 1840px) {
  .page-content.container-plus {
    padding: 1.25rem 1.5rem 0.625rem;
  }
}

.page-header {
  margin: 0 0;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header .page-tools {
  align-self: flex-end;
}

.page-title {
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
}

.page-title .page-info {
  margin: 0 0.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  display: block;
}

@media (min-width: 768px) {
  .page-header .page-info {
    display: inline-block;
  }
}

@media print {
  .body-container, .main-container, .main-content {
    display: block;
  }
  .sidebar, .navbar {
    display: none;
  }
  .page-content {
    max-width: none !important;
    width: 100% !important;
    min-width: auto !important;
  }
}

a {
  color: #2971bf;
}

a:hover {
  color: #2565aa;
}

a:not([href]) {
  text-decoration: none;
}

.text-40 {
  font-size: 0.4em !important;
}

.text-50 {
  font-size: 0.5em !important;
}

.text-60 {
  font-size: 0.6em !important;
}

.text-70 {
  font-size: 0.7em !important;
}

.text-80 {
  font-size: 0.8em !important;
}

.text-90 {
  font-size: 0.9em !important;
}

.text-100 {
  font-size: 1em !important;
}

.text-110 {
  font-size: 1.1em !important;
}

.text-120 {
  font-size: 1.2em !important;
}

.text-130 {
  font-size: 1.3em !important;
}

.text-140 {
  font-size: 1.4em !important;
}

.text-150 {
  font-size: 1.5em !important;
}

.text-160 {
  font-size: 1.6em !important;
}

.text-170 {
  font-size: 1.7em !important;
}

.text-180 {
  font-size: 1.8em !important;
}

.text-190 {
  font-size: 1.9em !important;
}

.text-200 {
  font-size: 2em !important;
}

.text-95 {
  font-size: .95em !important;
}

.text-85 {
  font-size: .85em !important;
}

.text-75 {
  font-size: .75em !important;
}

.text-105 {
  font-size: 1.05em !important;
}

.text-115 {
  font-size: 1.15em !important;
}

.text-125 {
  font-size: 1.25em !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text-700, .font-bold {
  font-weight: 700 !important;
}

.text-600, .font-bolder {
  font-weight: 600 !important;
}

.text-500, .font-medium {
  font-weight: 500 !important;
}

.text-400, .font-normal {
  font-weight: 400 !important;
}

.text-300, .font-light, .font-lighter {
  font-weight: 300 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.no-underline {
  text-decoration: none !important;
}

.line-through {
  text-decoration: line-through !important;
}

.line-height-n {
  line-height: normal !important;
}

.letter-spacing {
  letter-spacing: 0.5px !important;
}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

.letter-spacing-2 {
  letter-spacing: 1.5px !important;
}

.letter-spacing-3 {
  letter-spacing: 2px !important;
}

.letter-spacing-4 {
  letter-spacing: 2.5px !important;
}

.text-dark {
  color: #28292c !important;
}

a.text-dark[href]:hover, a.text-dark[href]:focus {
  color: #1c1d1f !important;
}

.text-dark-m1 {
  color: #323337 !important;
}

a.text-dark-m1:hover, a.text-dark-m1:focus {
  color: #262629 !important;
}

.text-dark-m2 {
  color: #3b3c41 !important;
}

.text-dark-m3 {
  color: #45464b !important;
}

.text-dark-d1 {
  color: #222225 !important;
}

a.text-dark-d1:hover, a.text-dark-d1:focus {
  color: #161618 !important;
}

.text-dark-d2 {
  color: #1b1c1e !important;
}

.text-dark-d3 {
  color: #151517 !important;
}

.text-dark-d4 {
  color: #0e0f10 !important;
}

.text-dark-l1 {
  color: #56585e !important;
}

.text-dark-l2 {
  color: #60626a !important;
}

.text-dark-l3 {
  color: #6a6d75 !important;
}

.text-dark-l4 {
  color: #757780 !important;
}

.text-dark-l5 {
  color: #80828b !important;
}

.text-grey {
  color: #64666a !important;
}

a.text-grey[href]:hover, a.text-grey[href]:focus {
  color: #58595c !important;
}

.text-grey-m1 {
  color: #6e7074 !important;
}

a.text-grey-m1:hover, a.text-grey-m1:focus {
  color: #616367 !important;
}

.text-grey-m2 {
  color: #77797e !important;
}

.text-grey-m3 {
  color: #818388 !important;
}

.text-grey-d1 {
  color: #5d5f63 !important;
}

a.text-grey-d1:hover, a.text-grey-d1:focus {
  color: #515255 !important;
}

.text-grey-d2 {
  color: #57585c !important;
}

.text-grey-d3 {
  color: #505255 !important;
}

.text-grey-d4 {
  color: #4a4b4e !important;
}

.text-grey-l1 {
  color: #949699 !important;
}

.text-grey-l2 {
  color: #9fa1a4 !important;
}

.text-grey-l3 {
  color: #aaacae !important;
}

.text-grey-l4 {
  color: #b5b6b9 !important;
}

.text-grey-l5 {
  color: #c0c1c4 !important;
}

.text-pink {
  color: #cf3074 !important;
}

a.text-pink[href]:hover, a.text-pink[href]:focus {
  color: #ba2b68 !important;
}

.text-pink-m1 {
  color: #d33f7f !important;
}

a.text-pink-m1:hover, a.text-pink-m1:focus {
  color: #ca2f71 !important;
}

.text-pink-m2 {
  color: #d75089 !important;
}

.text-pink-m3 {
  color: #da6094 !important;
}

.text-pink-d1 {
  color: #c42d6e !important;
}

a.text-pink-d1:hover, a.text-pink-d1:focus {
  color: #af2862 !important;
}

.text-pink-d2 {
  color: #b92b68 !important;
}

.text-pink-d3 {
  color: #ae2861 !important;
}

.text-pink-d4 {
  color: #a3255b !important;
}

.text-pink-l1 {
  color: #e17da8 !important;
}

.text-pink-l2 {
  color: #e58fb4 !important;
}

.text-pink-l3 {
  color: #e9a0c0 !important;
}

.text-pink-l4 {
  color: #edb2cb !important;
}

.text-pink-l5 {
  color: #f1c4d7 !important;
}

.text-purple {
  color: #766cba !important;
}

a.text-purple[href]:hover, a.text-purple[href]:focus {
  color: #665ab2 !important;
}

.text-purple-m1 {
  color: #7e74be !important;
}

a.text-purple-m1:hover, a.text-purple-m1:focus {
  color: #6d63b6 !important;
}

.text-purple-m2 {
  color: #877ec2 !important;
}

.text-purple-m3 {
  color: #9088c7 !important;
}

.text-purple-d1 {
  color: #6d62b5 !important;
}

a.text-purple-d1:hover, a.text-purple-d1:focus {
  color: #5d51ad !important;
}

.text-purple-d2 {
  color: #6559b1 !important;
}

.text-purple-d3 {
  color: #5d51ac !important;
}

.text-purple-d4 {
  color: #584ca3 !important;
}

.text-purple-l1 {
  color: #b2add8 !important;
}

.text-purple-l2 {
  color: #c0bbdf !important;
}

.text-purple-l3 {
  color: #cecae6 !important;
}

.text-purple-l4 {
  color: #dcd9ed !important;
}

.text-purple-l5 {
  color: #e9e8f4 !important;
}

.text-yellow {
  color: #f1d20d !important;
}

a.text-yellow[href]:hover, a.text-yellow[href]:focus {
  color: #d9bc0c !important;
}

.text-yellow-m1 {
  color: #f3d61e !important;
}

a.text-yellow-m1:hover, a.text-yellow-m1:focus {
  color: #ebcd0d !important;
}

.text-yellow-m2 {
  color: #f4d931 !important;
}

.text-yellow-m3 {
  color: #f5dd44 !important;
}

.text-yellow-d1 {
  color: #e4c60c !important;
}

a.text-yellow-d1:hover, a.text-yellow-d1:focus {
  color: #ccb10b !important;
}

.text-yellow-d2 {
  color: #d7bb0c !important;
}

.text-yellow-d3 {
  color: #cab00b !important;
}

.text-yellow-d4 {
  color: #bda40a !important;
}

.text-yellow-l1 {
  color: #f7e366 !important;
}

.text-yellow-l2 {
  color: #f8e77b !important;
}

.text-yellow-l3 {
  color: #f9eb90 !important;
}

.text-yellow-l4 {
  color: #faeea4 !important;
}

.text-yellow-l5 {
  color: #fbf2b9 !important;
}

.text-brown {
  color: #b95a3c !important;
}

a.text-brown[href]:hover, a.text-brown[href]:focus {
  color: #a55036 !important;
}

.text-brown-m1 {
  color: #c26244 !important;
}

a.text-brown-m1:hover, a.text-brown-m1:focus {
  color: #b2573a !important;
}

.text-brown-m2 {
  color: #c66d51 !important;
}

.text-brown-m3 {
  color: #cc7b61 !important;
}

.text-brown-d1 {
  color: #ae5539 !important;
}

a.text-brown-d1:hover, a.text-brown-d1:focus {
  color: #9b4b32 !important;
}

.text-brown-d2 {
  color: #a45035 !important;
}

.text-brown-d3 {
  color: #984a31 !important;
}

.text-brown-d4 {
  color: #8e452e !important;
}

.text-brown-l1 {
  color: #d5947f !important;
}

.text-brown-l2 {
  color: #dba18f !important;
}

.text-brown-l3 {
  color: #e0afa0 !important;
}

.text-brown-l4 {
  color: #e5bdb0 !important;
}

.text-brown-l5 {
  color: #ebcac0 !important;
}

.text-orange {
  color: #ff8e02 !important;
}

a.text-orange[href]:hover, a.text-orange[href]:focus {
  color: #e78000 !important;
}

.text-orange-m1 {
  color: #ff9715 !important;
}

a.text-orange-m1:hover, a.text-orange-m1:focus {
  color: #fb8b00 !important;
}

.text-orange-m2 {
  color: #ffa029 !important;
}

.text-orange-m3 {
  color: #ffa83d !important;
}

.text-orange-d1 {
  color: #f38700 !important;
}

a.text-orange-d1:hover, a.text-orange-d1:focus {
  color: #d97800 !important;
}

.text-orange-d2 {
  color: #e57f00 !important;
}

.text-orange-d3 {
  color: #d87700 !important;
}

.text-orange-d4 {
  color: #ca7000 !important;
}

.text-orange-l1 {
  color: #ffb961 !important;
}

.text-orange-l2 {
  color: #ffc277 !important;
}

.text-orange-l3 {
  color: #ffcc8d !important;
}

.text-orange-l4 {
  color: #ffd6a2 !important;
}

.text-orange-l5 {
  color: #ffdfb8 !important;
}

.text-warning {
  color: #ebab01 !important;
}

a.text-warning[href]:hover, a.text-warning[href]:focus {
  color: #d19801 !important;
}

.text-warning-m1 {
  color: #feb901 !important;
}

a.text-warning-m1:hover, a.text-warning-m1:focus {
  color: #e5a701 !important;
}

.text-warning-m2 {
  color: #febe15 !important;
}

.text-warning-m3 {
  color: #fec429 !important;
}

.text-warning-d1 {
  color: #dda101 !important;
}

a.text-warning-d1:hover, a.text-warning-d1:focus {
  color: #c48e01 !important;
}

.text-warning-d2 {
  color: #cf9701 !important;
}

.text-warning-d3 {
  color: #c28d01 !important;
}

.text-warning-d4 {
  color: #b48301 !important;
}

.text-warning-l1 {
  color: #fece4d !important;
}

.text-warning-l2 {
  color: #fed463 !important;
}

.text-warning-l3 {
  color: #feda78 !important;
}

.text-warning-l4 {
  color: #ffe08e !important;
}

.text-warning-l5 {
  color: #ffe6a3 !important;
}

.text-danger {
  color: #d52727 !important;
}

a.text-danger[href]:hover, a.text-danger[href]:focus {
  color: #c02323 !important;
}

.text-danger-m1 {
  color: #da3636 !important;
}

a.text-danger-m1:hover, a.text-danger-m1:focus {
  color: #d02727 !important;
}

.text-danger-m2 {
  color: #dd4747 !important;
}

.text-danger-m3 {
  color: #e05858 !important;
}

.text-danger-d1 {
  color: #ca2525 !important;
}

a.text-danger-d1:hover, a.text-danger-d1:focus {
  color: #b42121 !important;
}

.text-danger-d2 {
  color: #be2323 !important;
}

.text-danger-d3 {
  color: #b32121 !important;
}

.text-danger-d4 {
  color: #a71f1f !important;
}

.text-danger-l1 {
  color: #e67676 !important;
}

.text-danger-l2 {
  color: #e98989 !important;
}

.text-danger-l3 {
  color: #ed9b9b !important;
}

.text-danger-l4 {
  color: #f0adad !important;
}

.text-danger-l5 {
  color: #f3c0c0 !important;
}

.text-green {
  color: #2a8564 !important;
}

a.text-green[href]:hover, a.text-green[href]:focus {
  color: #247256 !important;
}

.text-green-m1 {
  color: #2c8d6a !important;
}

a.text-green-m1:hover, a.text-green-m1:focus {
  color: #26795b !important;
}

.text-green-m2 {
  color: #2f9671 !important;
}

.text-green-m3 {
  color: #32a179 !important;
}

.text-green-d1 {
  color: #277b5d !important;
}

a.text-green-d1:hover, a.text-green-d1:focus {
  color: #20684e !important;
}

.text-green-d2 {
  color: #237155 !important;
}

.text-green-d3 {
  color: #20664d !important;
}

.text-green-d4 {
  color: #1d5c45 !important;
}

.text-green-l1 {
  color: #49c699 !important;
}

.text-green-l2 {
  color: #59cba2 !important;
}

.text-green-l3 {
  color: #6ad0ab !important;
}

.text-green-l4 {
  color: #7ad5b5 !important;
}

.text-green-l5 {
  color: #8bdbbe !important;
}

.text-success {
  color: #508335 !important;
}

a.text-success[href]:hover, a.text-success[href]:focus {
  color: #45702e !important;
}

.text-success-m1 {
  color: #548938 !important;
}

a.text-success-m1:hover, a.text-success-m1:focus {
  color: #497731 !important;
}

.text-success-m2 {
  color: #5b943c !important;
}

.text-success-m3 {
  color: #62a041 !important;
}

.text-success-d1 {
  color: #4a7931 !important;
}

a.text-success-d1:hover, a.text-success-d1:focus {
  color: #3f672a !important;
}

.text-success-d2 {
  color: #446f2d !important;
}

.text-success-d3 {
  color: #3e6529 !important;
}

.text-success-d4 {
  color: #385c25 !important;
}

.text-success-l1 {
  color: #7dbc5b !important;
}

.text-success-l2 {
  color: #89c26b !important;
}

.text-success-l3 {
  color: #96c97a !important;
}

.text-success-l4 {
  color: #a2cf89 !important;
}

.text-success-l5 {
  color: #aed599 !important;
}

.text-default {
  color: #507c94 !important;
}

a.text-default[href]:hover, a.text-default[href]:focus {
  color: #476e84 !important;
}

.text-default-m1 {
  color: #54839c !important;
}

a.text-default-m1:hover, a.text-default-m1:focus {
  color: #4b758c !important;
}

.text-default-m2 {
  color: #5b8ca7 !important;
}

.text-default-m3 {
  color: #6895ad !important;
}

.text-default-d1 {
  color: #4b758b !important;
}

a.text-default-d1:hover, a.text-default-d1:focus {
  color: #42677b !important;
}

.text-default-d2 {
  color: #476d83 !important;
}

.text-default-d3 {
  color: #416578 !important;
}

.text-default-d4 {
  color: #3c5d6f !important;
}

.text-default-l1 {
  color: #86aabe !important;
}

.text-default-l2 {
  color: #94b4c5 !important;
}

.text-default-l3 {
  color: #a2becd !important;
}

.text-default-l4 {
  color: #b1c8d5 !important;
}

.text-default-l5 {
  color: #bfd2dc !important;
}

.text-secondary {
  color: #647893 !important;
}

a.text-secondary[href]:hover, a.text-secondary[href]:focus {
  color: #5a6b84 !important;
}

.text-secondary-m1 {
  color: #6c7f9b !important;
}

a.text-secondary-m1:hover, a.text-secondary-m1:focus {
  color: #60728d !important;
}

.text-secondary-m2 {
  color: #7688a2 !important;
}

.text-secondary-m3 {
  color: #8091a9 !important;
}

.text-secondary-d1 {
  color: #5f718b !important;
}

a.text-secondary-d1:hover, a.text-secondary-d1:focus {
  color: #54657c !important;
}

.text-secondary-d2 {
  color: #596b83 !important;
}

.text-secondary-d3 {
  color: #526178 !important;
}

.text-secondary-d4 {
  color: #4c5b70 !important;
}

.text-secondary-l1 {
  color: #9ca9bb !important;
}

.text-secondary-l2 {
  color: #a9b4c4 !important;
}

.text-secondary-l3 {
  color: #b6bfcd !important;
}

.text-secondary-l4 {
  color: #c2cad6 !important;
}

.text-secondary-l5 {
  color: #cfd6df !important;
}

.text-info {
  color: #228299 !important;
}

a.text-info[href]:hover, a.text-info[href]:focus {
  color: #1d7084 !important;
}

.text-info-m1 {
  color: #2488a1 !important;
}

a.text-info-m1:hover, a.text-info-m1:focus {
  color: #1f778c !important;
}

.text-info-m2 {
  color: #2691ab !important;
}

.text-info-m3 {
  color: #299db9 !important;
}

.text-info-d1 {
  color: #1f788e !important;
}

a.text-info-d1:hover, a.text-info-d1:focus {
  color: #1b6679 !important;
}

.text-info-d2 {
  color: #1d6f83 !important;
}

.text-info-d3 {
  color: #1a6577 !important;
}

.text-info-d4 {
  color: #185c6c !important;
}

.text-info-l1 {
  color: #45b9d6 !important;
}

.text-info-l2 {
  color: #56c0da !important;
}

.text-info-l3 {
  color: #68c7de !important;
}

.text-info-l4 {
  color: #7acde2 !important;
}

.text-info-l5 {
  color: #8cd4e5 !important;
}

.text-blue {
  color: #1279cd !important;
}

a.text-blue[href]:hover, a.text-blue[href]:focus {
  color: #106bb6 !important;
}

.text-blue-m1 {
  color: #1380d8 !important;
}

a.text-blue-m1:hover, a.text-blue-m1:focus {
  color: #1172c1 !important;
}

.text-blue-m2 {
  color: #1488e6 !important;
}

.text-blue-m3 {
  color: #2995ec !important;
}

.text-blue-d1 {
  color: #1172c1 !important;
}

a.text-blue-d1:hover, a.text-blue-d1:focus {
  color: #0f64a9 !important;
}

.text-blue-d2 {
  color: #106ab4 !important;
}

.text-blue-d3 {
  color: #0f63a8 !important;
}

.text-blue-d4 {
  color: #0e5c9b !important;
}

.text-blue-l1 {
  color: #4fa8f0 !important;
}

.text-blue-l2 {
  color: #63b1f1 !important;
}

.text-blue-l3 {
  color: #77bbf3 !important;
}

.text-blue-l4 {
  color: #8bc5f5 !important;
}

.text-blue-l5 {
  color: #9fcff7 !important;
}

.text-primary {
  color: #2971bf !important;
}

a.text-primary[href]:hover, a.text-primary[href]:focus {
  color: #2565aa !important;
}

.text-primary-m1 {
  color: #2d7bcf !important;
}

a.text-primary-m1:hover, a.text-primary-m1:focus {
  color: #286ebb !important;
}

.text-primary-m2 {
  color: #3b85d5 !important;
}

.text-primary-m3 {
  color: #4c8fd8 !important;
}

.text-primary-d1 {
  color: #276ab4 !important;
}

a.text-primary-d1:hover, a.text-primary-d1:focus {
  color: #225e9f !important;
}

.text-primary-d2 {
  color: #2564a9 !important;
}

.text-primary-d3 {
  color: #225d9e !important;
}

.text-primary-d4 {
  color: #205792 !important;
}

.text-primary-l1 {
  color: #6aa2df !important;
}

.text-primary-l2 {
  color: #7bade3 !important;
}

.text-primary-l3 {
  color: #8db8e6 !important;
}

.text-primary-l4 {
  color: #9fc3ea !important;
}

.text-primary-l5 {
  color: #b1ceee !important;
}

.text-white-tp1 {
  color: rgba(255, 255, 255, 0.93) !important;
}

.text-white-tp2 {
  color: rgba(255, 255, 255, 0.87) !important;
}

.text-white-tp3 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.text-white-tp4 {
  color: rgba(255, 255, 255, 0.73) !important;
}

.text-dark-tp1 {
  color: rgba(0, 0, 0, 0.91) !important;
}

.text-dark-tp2 {
  color: rgba(0, 0, 0, 0.82) !important;
}

.text-dark-tp3 {
  color: rgba(0, 0, 0, 0.73) !important;
}

.text-dark-tp4 {
  color: rgba(0, 0, 0, 0.64) !important;
}

.text-dark-tp5 {
  color: rgba(0, 0, 0, 0.55) !important;
}

.text-body {
  color: #41464d !important;
}

.opacity-1 {
  opacity: 0.91;
}

.opacity-2 {
  opacity: 0.82;
}

.opacity-3 {
  opacity: 0.73;
}

.opacity-4 {
  opacity: 0.64;
}

.opacity-5 {
  opacity: 0.55;
}

.bgc-current {
  background-color: currentColor !important;
}

.brc-current {
  border-color: currentColor !important;
}

.bgc-inherit {
  background-color: inherit !important;
}

.brc-inherit {
  border-color: inherit !important;
}

.bgc-default {
  background-color: #619cc0 !important;
}

.brc-default {
  border-color: #619cc0 !important;
}

.bgc-default-tp1 {
  background-color: rgba(97, 156, 192, 0.925) !important;
}

.brc-default-tp1 {
  border-color: rgba(97, 156, 192, 0.925) !important;
}

.bgc-default-tp2 {
  background-color: rgba(97, 156, 192, 0.85) !important;
}

.brc-default-tp2 {
  border-color: rgba(97, 156, 192, 0.85) !important;
}

.bgc-default-tp3 {
  background-color: rgba(97, 156, 192, 0.775) !important;
}

.brc-default-tp3 {
  border-color: rgba(97, 156, 192, 0.775) !important;
}

.bgc-default-tp4 {
  background-color: rgba(97, 156, 192, 0.7) !important;
}

.brc-default-tp4 {
  border-color: rgba(97, 156, 192, 0.7) !important;
}

.bgc-default-d1 {
  background-color: #5c94b6 !important;
}

.brc-default-d1 {
  border-color: #5c94b6 !important;
}

.bgc-default-d2 {
  background-color: #578cad !important;
}

.brc-default-d2 {
  border-color: #578cad !important;
}

.bgc-default-d3 {
  background-color: #5285a3 !important;
}

.brc-default-d3 {
  border-color: #5285a3 !important;
}

.bgc-default-m1 {
  background-color: #79abc9 !important;
}

.brc-default-m1, .brc-h-default-m1:hover {
  border-color: #79abc9 !important;
}

.bgc-default-m2 {
  background-color: #8db8d2 !important;
}

.brc-default-m2, .brc-h-default-m2:hover {
  border-color: #8db8d2 !important;
}

.bgc-default-m3 {
  background-color: #a2c5da !important;
}

.brc-default-m3, .brc-h-default-m3:hover {
  border-color: #a2c5da !important;
}

.bgc-default-m4 {
  background-color: #b8d2e3 !important;
}

.brc-default-m4, .brc-h-default-m4:hover {
  border-color: #b8d2e3 !important;
}

.bgc-default-l1, .bgc-h-default-l1:hover {
  background-color: #c9dde9 !important;
}

.brc-default-l1 {
  border-color: #c9dde9 !important;
}

.bgc-default-l2, .bgc-h-default-l2:hover {
  background-color: #d8e7f0 !important;
}

.brc-default-l2 {
  border-color: #d8e7f0 !important;
}

.bgc-default-l3, .bgc-h-default-l3:hover {
  background-color: #e7f0f5 !important;
}

.brc-default-l3 {
  border-color: #e7f0f5 !important;
}

.bgc-default-l4, .bgc-h-default-l4:hover {
  background-color: #eff5f9 !important;
}

.brc-default-l4 {
  border-color: #eff5f9 !important;
}

.bgc-default-l5, .bgc-h-default-l5:hover {
  background-color: #f6f9fb !important;
}

.brc-default-l5 {
  border-color: #f6f9fb !important;
}

.bgc-primary {
  background-color: #2c87d3 !important;
}

.brc-primary {
  border-color: #2c87d3 !important;
}

.bgc-primary-tp1 {
  background-color: rgba(44, 135, 211, 0.925) !important;
}

.brc-primary-tp1 {
  border-color: rgba(44, 135, 211, 0.925) !important;
}

.bgc-primary-tp2 {
  background-color: rgba(44, 135, 211, 0.85) !important;
}

.brc-primary-tp2 {
  border-color: rgba(44, 135, 211, 0.85) !important;
}

.bgc-primary-tp3 {
  background-color: rgba(44, 135, 211, 0.775) !important;
}

.brc-primary-tp3 {
  border-color: rgba(44, 135, 211, 0.775) !important;
}

.bgc-primary-tp4 {
  background-color: rgba(44, 135, 211, 0.7) !important;
}

.brc-primary-tp4 {
  border-color: rgba(44, 135, 211, 0.7) !important;
}

.bgc-primary-d1 {
  background-color: #2a80c8 !important;
}

.brc-primary-d1 {
  border-color: #2a80c8 !important;
}

.bgc-primary-d2 {
  background-color: #287abe !important;
}

.brc-primary-d2 {
  border-color: #287abe !important;
}

.bgc-primary-d3 {
  background-color: #2573b3 !important;
}

.brc-primary-d3 {
  border-color: #2573b3 !important;
}

.bgc-primary-m1 {
  background-color: #4c99da !important;
}

.brc-primary-m1, .brc-h-primary-m1:hover {
  border-color: #4c99da !important;
}

.bgc-primary-m2 {
  background-color: #67a9df !important;
}

.brc-primary-m2, .brc-h-primary-m2:hover {
  border-color: #67a9df !important;
}

.bgc-primary-m3 {
  background-color: #83b8e5 !important;
}

.brc-primary-m3, .brc-h-primary-m3:hover {
  border-color: #83b8e5 !important;
}

.bgc-primary-m4 {
  background-color: #a0c9eb !important;
}

.brc-primary-m4, .brc-h-primary-m4:hover {
  border-color: #a0c9eb !important;
}

.bgc-primary-l1, .bgc-h-primary-l1:hover {
  background-color: #c0dbf2 !important;
}

.brc-primary-l1 {
  border-color: #c0dbf2 !important;
}

.bgc-primary-l2, .bgc-h-primary-l2:hover {
  background-color: #d2e6f6 !important;
}

.brc-primary-l2 {
  border-color: #d2e6f6 !important;
}

.bgc-primary-l3, .bgc-h-primary-l3:hover {
  background-color: #e3eff9 !important;
}

.brc-primary-l3 {
  border-color: #e3eff9 !important;
}

.bgc-primary-l4, .bgc-h-primary-l4:hover {
  background-color: #edf5fb !important;
}

.brc-primary-l4 {
  border-color: #edf5fb !important;
}

.bgc-primary-l5, .bgc-h-primary-l5:hover {
  background-color: #f4f9fd !important;
}

.brc-primary-l5 {
  border-color: #f4f9fd !important;
}

.bgc-secondary {
  background-color: #798e9c !important;
}

.brc-secondary {
  border-color: #798e9c !important;
}

.bgc-secondary-tp1 {
  background-color: rgba(121, 142, 156, 0.925) !important;
}

.brc-secondary-tp1 {
  border-color: rgba(121, 142, 156, 0.925) !important;
}

.bgc-secondary-tp2 {
  background-color: rgba(121, 142, 156, 0.85) !important;
}

.brc-secondary-tp2 {
  border-color: rgba(121, 142, 156, 0.85) !important;
}

.bgc-secondary-tp3 {
  background-color: rgba(121, 142, 156, 0.775) !important;
}

.brc-secondary-tp3 {
  border-color: rgba(121, 142, 156, 0.775) !important;
}

.bgc-secondary-tp4 {
  background-color: rgba(121, 142, 156, 0.7) !important;
}

.brc-secondary-tp4 {
  border-color: rgba(121, 142, 156, 0.7) !important;
}

.bgc-secondary-d1 {
  background-color: #738794 !important;
}

.brc-secondary-d1 {
  border-color: #738794 !important;
}

.bgc-secondary-d2 {
  background-color: #6d808c !important;
}

.brc-secondary-d2 {
  border-color: #6d808c !important;
}

.bgc-secondary-d3 {
  background-color: #677985 !important;
}

.brc-secondary-d3 {
  border-color: #677985 !important;
}

.bgc-secondary-m1 {
  background-color: #8d9fab !important;
}

.brc-secondary-m1, .brc-h-secondary-m1:hover {
  border-color: #8d9fab !important;
}

.bgc-secondary-m2 {
  background-color: #9faeb8 !important;
}

.brc-secondary-m2, .brc-h-secondary-m2:hover {
  border-color: #9faeb8 !important;
}

.bgc-secondary-m3 {
  background-color: #b0bcc5 !important;
}

.brc-secondary-m3, .brc-h-secondary-m3:hover {
  border-color: #b0bcc5 !important;
}

.bgc-secondary-m4 {
  background-color: #c3ccd2 !important;
}

.brc-secondary-m4, .brc-h-secondary-m4:hover {
  border-color: #c3ccd2 !important;
}

.bgc-secondary-l1, .bgc-h-secondary-l1:hover {
  background-color: #d3dadf !important;
}

.brc-secondary-l1 {
  border-color: #d3dadf !important;
}

.bgc-secondary-l2, .bgc-h-secondary-l2:hover {
  background-color: #e0e5e8 !important;
}

.brc-secondary-l2 {
  border-color: #e0e5e8 !important;
}

.bgc-secondary-l3, .bgc-h-secondary-l3:hover {
  background-color: #ebeff1 !important;
}

.brc-secondary-l3 {
  border-color: #ebeff1 !important;
}

.bgc-secondary-l4, .bgc-h-secondary-l4:hover {
  background-color: #f2f4f6 !important;
}

.brc-secondary-l4 {
  border-color: #f2f4f6 !important;
}

.bgc-secondary-l5, .bgc-h-secondary-l5:hover {
  background-color: #f7f9f9 !important;
}

.brc-secondary-l5 {
  border-color: #f7f9f9 !important;
}

.bgc-info {
  background-color: #00b6e9 !important;
}

.brc-info {
  border-color: #00b6e9 !important;
}

.bgc-info-tp1 {
  background-color: rgba(0, 182, 233, 0.925) !important;
}

.brc-info-tp1 {
  border-color: rgba(0, 182, 233, 0.925) !important;
}

.bgc-info-tp2 {
  background-color: rgba(0, 182, 233, 0.85) !important;
}

.brc-info-tp2 {
  border-color: rgba(0, 182, 233, 0.85) !important;
}

.bgc-info-tp3 {
  background-color: rgba(0, 182, 233, 0.775) !important;
}

.brc-info-tp3 {
  border-color: rgba(0, 182, 233, 0.775) !important;
}

.bgc-info-tp4 {
  background-color: rgba(0, 182, 233, 0.7) !important;
}

.brc-info-tp4 {
  border-color: rgba(0, 182, 233, 0.7) !important;
}

.bgc-info-d1 {
  background-color: #00addd !important;
}

.brc-info-d1 {
  border-color: #00addd !important;
}

.bgc-info-d2 {
  background-color: #00a4d2 !important;
}

.brc-info-d2 {
  border-color: #00a4d2 !important;
}

.bgc-info-d3 {
  background-color: #009bc6 !important;
}

.brc-info-d3 {
  border-color: #009bc6 !important;
}

.bgc-info-m1 {
  background-color: #26c1ec !important;
}

.brc-info-m1, .brc-h-info-m1:hover {
  border-color: #26c1ec !important;
}

.bgc-info-m2 {
  background-color: #47caef !important;
}

.brc-info-m2, .brc-h-info-m2:hover {
  border-color: #47caef !important;
}

.bgc-info-m3 {
  background-color: #69d4f2 !important;
}

.brc-info-m3, .brc-h-info-m3:hover {
  border-color: #69d4f2 !important;
}

.bgc-info-m4 {
  background-color: #8cdef5 !important;
}

.brc-info-m4, .brc-h-info-m4:hover {
  border-color: #8cdef5 !important;
}

.bgc-info-l1, .bgc-h-info-l1:hover {
  background-color: #b9ebf9 !important;
}

.brc-info-l1 {
  border-color: #b9ebf9 !important;
}

.bgc-info-l2, .bgc-h-info-l2:hover {
  background-color: #cdf1fb !important;
}

.brc-info-l2 {
  border-color: #cdf1fb !important;
}

.bgc-info-l3, .bgc-h-info-l3:hover {
  background-color: #e0f6fc !important;
}

.brc-info-l3 {
  border-color: #e0f6fc !important;
}

.bgc-info-l4, .bgc-h-info-l4:hover {
  background-color: #ebf9fd !important;
}

.brc-info-l4 {
  border-color: #ebf9fd !important;
}

.bgc-info-l5, .bgc-h-info-l5:hover {
  background-color: #f3fcfe !important;
}

.brc-info-l5 {
  border-color: #f3fcfe !important;
}

.bgc-success {
  background-color: #5bb15b !important;
}

.brc-success {
  border-color: #5bb15b !important;
}

.bgc-success-tp1 {
  background-color: rgba(91, 177, 91, 0.925) !important;
}

.brc-success-tp1 {
  border-color: rgba(91, 177, 91, 0.925) !important;
}

.bgc-success-tp2 {
  background-color: rgba(91, 177, 91, 0.85) !important;
}

.brc-success-tp2 {
  border-color: rgba(91, 177, 91, 0.85) !important;
}

.bgc-success-tp3 {
  background-color: rgba(91, 177, 91, 0.775) !important;
}

.brc-success-tp3 {
  border-color: rgba(91, 177, 91, 0.775) !important;
}

.bgc-success-tp4 {
  background-color: rgba(91, 177, 91, 0.7) !important;
}

.brc-success-tp4 {
  border-color: rgba(91, 177, 91, 0.7) !important;
}

.bgc-success-d1 {
  background-color: #56a856 !important;
}

.brc-success-d1 {
  border-color: #56a856 !important;
}

.bgc-success-d2 {
  background-color: #529f52 !important;
}

.brc-success-d2 {
  border-color: #529f52 !important;
}

.bgc-success-d3 {
  background-color: #4d964d !important;
}

.brc-success-d3 {
  border-color: #4d964d !important;
}

.bgc-success-m1 {
  background-color: #74bd74 !important;
}

.brc-success-m1, .brc-h-success-m1:hover {
  border-color: #74bd74 !important;
}

.bgc-success-m2 {
  background-color: #89c789 !important;
}

.brc-success-m2, .brc-h-success-m2:hover {
  border-color: #89c789 !important;
}

.bgc-success-m3 {
  background-color: #9ed19e !important;
}

.brc-success-m3, .brc-h-success-m3:hover {
  border-color: #9ed19e !important;
}

.bgc-success-m4 {
  background-color: #b5dcb5 !important;
}

.brc-success-m4, .brc-h-success-m4:hover {
  border-color: #b5dcb5 !important;
}

.bgc-success-l1, .bgc-h-success-l1:hover {
  background-color: #cbe6cb !important;
}

.brc-success-l1 {
  border-color: #cbe6cb !important;
}

.bgc-success-l2, .bgc-h-success-l2:hover {
  background-color: #daeeda !important;
}

.brc-success-l2 {
  border-color: #daeeda !important;
}

.bgc-success-l3, .bgc-h-success-l3:hover {
  background-color: #e8f4e8 !important;
}

.brc-success-l3 {
  border-color: #e8f4e8 !important;
}

.bgc-success-l4, .bgc-h-success-l4:hover {
  background-color: #f0f8f0 !important;
}

.brc-success-l4 {
  border-color: #f0f8f0 !important;
}

.bgc-success-l5, .bgc-h-success-l5:hover {
  background-color: #f6fbf6 !important;
}

.brc-success-l5 {
  border-color: #f6fbf6 !important;
}

.bgc-danger {
  background-color: #da4e35 !important;
}

.brc-danger {
  border-color: #da4e35 !important;
}

.bgc-danger-tp1 {
  background-color: rgba(218, 78, 53, 0.925) !important;
}

.brc-danger-tp1 {
  border-color: rgba(218, 78, 53, 0.925) !important;
}

.bgc-danger-tp2 {
  background-color: rgba(218, 78, 53, 0.85) !important;
}

.brc-danger-tp2 {
  border-color: rgba(218, 78, 53, 0.85) !important;
}

.bgc-danger-tp3 {
  background-color: rgba(218, 78, 53, 0.775) !important;
}

.brc-danger-tp3 {
  border-color: rgba(218, 78, 53, 0.775) !important;
}

.bgc-danger-tp4 {
  background-color: rgba(218, 78, 53, 0.7) !important;
}

.brc-danger-tp4 {
  border-color: rgba(218, 78, 53, 0.7) !important;
}

.bgc-danger-d1 {
  background-color: #cf4a32 !important;
}

.brc-danger-d1 {
  border-color: #cf4a32 !important;
}

.bgc-danger-d2 {
  background-color: #c44630 !important;
}

.brc-danger-d2 {
  border-color: #c44630 !important;
}

.bgc-danger-d3 {
  background-color: #b9422d !important;
}

.brc-danger-d3 {
  border-color: #b9422d !important;
}

.bgc-danger-m1 {
  background-color: #e06953 !important;
}

.brc-danger-m1, .brc-h-danger-m1:hover {
  border-color: #e06953 !important;
}

.bgc-danger-m2 {
  background-color: #e4806e !important;
}

.brc-danger-m2, .brc-h-danger-m2:hover {
  border-color: #e4806e !important;
}

.bgc-danger-m3 {
  background-color: #e99788 !important;
}

.brc-danger-m3, .brc-h-danger-m3:hover {
  border-color: #e99788 !important;
}

.bgc-danger-m4 {
  background-color: #eeafa4 !important;
}

.brc-danger-m4, .brc-h-danger-m4:hover {
  border-color: #eeafa4 !important;
}

.bgc-danger-l1, .bgc-h-danger-l1:hover {
  background-color: #f3c7bf !important;
}

.brc-danger-l1 {
  border-color: #f3c7bf !important;
}

.bgc-danger-l2, .bgc-h-danger-l2:hover {
  background-color: #f7d7d1 !important;
}

.brc-danger-l2 {
  border-color: #f7d7d1 !important;
}

.bgc-danger-l3, .bgc-h-danger-l3:hover {
  background-color: #fae6e2 !important;
}

.brc-danger-l3 {
  border-color: #fae6e2 !important;
}

.bgc-danger-l4, .bgc-h-danger-l4:hover {
  background-color: #fcefec !important;
}

.brc-danger-l4 {
  border-color: #fcefec !important;
}

.bgc-danger-l5, .bgc-h-danger-l5:hover {
  background-color: #fdf6f4 !important;
}

.brc-danger-l5 {
  border-color: #fdf6f4 !important;
}

.bgc-warning {
  background-color: #eda630 !important;
}

.brc-warning {
  border-color: #eda630 !important;
}

.bgc-warning-tp1 {
  background-color: rgba(237, 166, 48, 0.925) !important;
}

.brc-warning-tp1 {
  border-color: rgba(237, 166, 48, 0.925) !important;
}

.bgc-warning-tp2 {
  background-color: rgba(237, 166, 48, 0.85) !important;
}

.brc-warning-tp2 {
  border-color: rgba(237, 166, 48, 0.85) !important;
}

.bgc-warning-tp3 {
  background-color: rgba(237, 166, 48, 0.775) !important;
}

.brc-warning-tp3 {
  border-color: rgba(237, 166, 48, 0.775) !important;
}

.bgc-warning-tp4 {
  background-color: rgba(237, 166, 48, 0.7) !important;
}

.brc-warning-tp4 {
  border-color: rgba(237, 166, 48, 0.7) !important;
}

.bgc-warning-d1 {
  background-color: #e19e2e !important;
}

.brc-warning-d1 {
  border-color: #e19e2e !important;
}

.bgc-warning-d2 {
  background-color: #d5952b !important;
}

.brc-warning-d2 {
  border-color: #d5952b !important;
}

.bgc-warning-d3 {
  background-color: #c98d29 !important;
}

.brc-warning-d3 {
  border-color: #c98d29 !important;
}

.bgc-warning-m1 {
  background-color: #f0b34f !important;
}

.brc-warning-m1, .brc-h-warning-m1:hover {
  border-color: #f0b34f !important;
}

.bgc-warning-m2 {
  background-color: #f2bf6a !important;
}

.brc-warning-m2, .brc-h-warning-m2:hover {
  border-color: #f2bf6a !important;
}

.bgc-warning-m3 {
  background-color: #f4ca85 !important;
}

.brc-warning-m3, .brc-h-warning-m3:hover {
  border-color: #f4ca85 !important;
}

.bgc-warning-m4 {
  background-color: #f7d7a2 !important;
}

.brc-warning-m4, .brc-h-warning-m4:hover {
  border-color: #f7d7a2 !important;
}

.bgc-warning-l1, .bgc-h-warning-l1:hover {
  background-color: #f9e1b9 !important;
}

.brc-warning-l1 {
  border-color: #f9e1b9 !important;
}

.bgc-warning-l2, .bgc-h-warning-l2:hover {
  background-color: #fbeacd !important;
}

.brc-warning-l2 {
  border-color: #fbeacd !important;
}

.bgc-warning-l3, .bgc-h-warning-l3:hover {
  background-color: #fcf2e0 !important;
}

.brc-warning-l3 {
  border-color: #fcf2e0 !important;
}

.bgc-warning-l4, .bgc-h-warning-l4:hover {
  background-color: #fdf7eb !important;
}

.brc-warning-l4 {
  border-color: #fdf7eb !important;
}

.bgc-warning-l5, .bgc-h-warning-l5:hover {
  background-color: #fefaf3 !important;
}

.brc-warning-l5 {
  border-color: #fefaf3 !important;
}

.bgc-dark {
  background-color: #42474e !important;
}

.brc-dark {
  border-color: #42474e !important;
}

.bgc-dark-tp1 {
  background-color: rgba(66, 71, 78, 0.925) !important;
}

.brc-dark-tp1 {
  border-color: rgba(66, 71, 78, 0.925) !important;
}

.bgc-dark-tp2 {
  background-color: rgba(66, 71, 78, 0.85) !important;
}

.brc-dark-tp2 {
  border-color: rgba(66, 71, 78, 0.85) !important;
}

.bgc-dark-tp3 {
  background-color: rgba(66, 71, 78, 0.775) !important;
}

.brc-dark-tp3 {
  border-color: rgba(66, 71, 78, 0.775) !important;
}

.bgc-dark-tp4 {
  background-color: rgba(66, 71, 78, 0.7) !important;
}

.brc-dark-tp4 {
  border-color: rgba(66, 71, 78, 0.7) !important;
}

.bgc-dark-d1 {
  background-color: #3f434a !important;
}

.brc-dark-d1 {
  border-color: #3f434a !important;
}

.bgc-dark-d2 {
  background-color: #3b4046 !important;
}

.brc-dark-d2 {
  border-color: #3b4046 !important;
}

.bgc-dark-d3 {
  background-color: #383c42 !important;
}

.brc-dark-d3 {
  border-color: #383c42 !important;
}

.bgc-dark-m1 {
  background-color: #5e6369 !important;
}

.brc-dark-m1, .brc-h-dark-m1:hover {
  border-color: #5e6369 !important;
}

.bgc-dark-m2 {
  background-color: #777b80 !important;
}

.brc-dark-m2, .brc-h-dark-m2:hover {
  border-color: #777b80 !important;
}

.bgc-dark-m3 {
  background-color: #8f9297 !important;
}

.brc-dark-m3, .brc-h-dark-m3:hover {
  border-color: #8f9297 !important;
}

.bgc-dark-m4 {
  background-color: #aaacaf !important;
}

.brc-dark-m4, .brc-h-dark-m4:hover {
  border-color: #aaacaf !important;
}

.bgc-dark-l1, .bgc-h-dark-l1:hover {
  background-color: #cccdcf !important;
}

.brc-dark-l1 {
  border-color: #cccdcf !important;
}

.bgc-dark-l2, .bgc-h-dark-l2:hover {
  background-color: #dcddde !important;
}

.brc-dark-l2 {
  border-color: #dcddde !important;
}

.bgc-dark-l3, .bgc-h-dark-l3:hover {
  background-color: #e8e9ea !important;
}

.brc-dark-l3 {
  border-color: #e8e9ea !important;
}

.bgc-dark-l4, .bgc-h-dark-l4:hover {
  background-color: #eff0f0 !important;
}

.brc-dark-l4 {
  border-color: #eff0f0 !important;
}

.bgc-dark-l5, .bgc-h-dark-l5:hover {
  background-color: #f6f6f7 !important;
}

.brc-dark-l5 {
  border-color: #f6f6f7 !important;
}

.bgc-light {
  background-color: #f8f9fb !important;
}

.brc-light {
  border-color: #f8f9fb !important;
}

.bgc-light-tp1 {
  background-color: rgba(248, 249, 251, 0.925) !important;
}

.brc-light-tp1 {
  border-color: rgba(248, 249, 251, 0.925) !important;
}

.bgc-light-tp2 {
  background-color: rgba(248, 249, 251, 0.85) !important;
}

.brc-light-tp2 {
  border-color: rgba(248, 249, 251, 0.85) !important;
}

.bgc-light-tp3 {
  background-color: rgba(248, 249, 251, 0.775) !important;
}

.brc-light-tp3 {
  border-color: rgba(248, 249, 251, 0.775) !important;
}

.bgc-light-tp4 {
  background-color: rgba(248, 249, 251, 0.7) !important;
}

.brc-light-tp4 {
  border-color: rgba(248, 249, 251, 0.7) !important;
}

.bgc-light-d1 {
  background-color: #ecedee !important;
}

.brc-light-d1 {
  border-color: #ecedee !important;
}

.bgc-light-d2 {
  background-color: #dfe0e2 !important;
}

.brc-light-d2 {
  border-color: #dfe0e2 !important;
}

.bgc-light-d3 {
  background-color: #d3d4d5 !important;
}

.brc-light-d3 {
  border-color: #d3d4d5 !important;
}

.bgc-light-l1, .bgc-h-light-l1:hover {
  background-color: #fdfdfe !important;
}

.brc-light-l1 {
  border-color: #fdfdfe !important;
}

.bgc-light-l2, .bgc-h-light-l2:hover {
  background-color: #fefefe !important;
}

.brc-light-l2 {
  border-color: #fefefe !important;
}

.bgc-light-l3, .bgc-h-light-l3:hover {
  background-color: #fefeff !important;
}

.brc-light-l3 {
  border-color: #fefeff !important;
}

.bgc-light-l4, .bgc-h-light-l4:hover {
  background-color: #feffff !important;
}

.brc-light-l4 {
  border-color: #feffff !important;
}

.bgc-white {
  background-color: #fff !important;
}

.brc-white {
  border-color: #fff !important;
}

.bgc-black {
  background-color: #2c3034 !important;
}

.brc-black {
  border-color: #2c3034 !important;
}

.bgc-grey {
  background-color: #7f8083 !important;
}

.brc-grey {
  border-color: #7f8083 !important;
}

.bgc-grey-tp1 {
  background-color: rgba(127, 128, 131, 0.925) !important;
}

.brc-grey-tp1 {
  border-color: rgba(127, 128, 131, 0.925) !important;
}

.bgc-grey-tp2 {
  background-color: rgba(127, 128, 131, 0.85) !important;
}

.brc-grey-tp2 {
  border-color: rgba(127, 128, 131, 0.85) !important;
}

.bgc-grey-tp3 {
  background-color: rgba(127, 128, 131, 0.775) !important;
}

.brc-grey-tp3 {
  border-color: rgba(127, 128, 131, 0.775) !important;
}

.bgc-grey-tp4 {
  background-color: rgba(127, 128, 131, 0.7) !important;
}

.brc-grey-tp4 {
  border-color: rgba(127, 128, 131, 0.7) !important;
}

.bgc-grey-d1 {
  background-color: #797a7c !important;
}

.brc-grey-d1 {
  border-color: #797a7c !important;
}

.bgc-grey-d2 {
  background-color: #727376 !important;
}

.brc-grey-d2 {
  border-color: #727376 !important;
}

.bgc-grey-d3 {
  background-color: #6c6d6f !important;
}

.brc-grey-d3 {
  border-color: #6c6d6f !important;
}

.bgc-grey-m1 {
  background-color: #929396 !important;
}

.brc-grey-m1, .brc-h-grey-m1:hover {
  border-color: #929396 !important;
}

.bgc-grey-m2 {
  background-color: #a3a4a6 !important;
}

.brc-grey-m2, .brc-h-grey-m2:hover {
  border-color: #a3a4a6 !important;
}

.bgc-grey-m3 {
  background-color: #b3b4b6 !important;
}

.brc-grey-m3, .brc-h-grey-m3:hover {
  border-color: #b3b4b6 !important;
}

.bgc-grey-m4 {
  background-color: #c5c6c7 !important;
}

.brc-grey-m4, .brc-h-grey-m4:hover {
  border-color: #c5c6c7 !important;
}

.bgc-grey-l1, .bgc-h-grey-l1:hover {
  background-color: #d8d9da !important;
}

.brc-grey-l1 {
  border-color: #d8d9da !important;
}

.bgc-grey-l2, .bgc-h-grey-l2:hover {
  background-color: #e4e4e4 !important;
}

.brc-grey-l2 {
  border-color: #e4e4e4 !important;
}

.bgc-grey-l3, .bgc-h-grey-l3:hover {
  background-color: #eeeeee !important;
}

.brc-grey-l3 {
  border-color: #eeeeee !important;
}

.bgc-grey-l4, .bgc-h-grey-l4:hover {
  background-color: #f4f4f4 !important;
}

.brc-grey-l4 {
  border-color: #f4f4f4 !important;
}

.bgc-grey-l5, .bgc-h-grey-l5:hover {
  background-color: #f8f8f9 !important;
}

.brc-grey-l5 {
  border-color: #f8f8f9 !important;
}

.bgc-purple {
  background-color: #776cb8 !important;
}

.brc-purple {
  border-color: #776cb8 !important;
}

.bgc-purple-tp1 {
  background-color: rgba(119, 108, 184, 0.925) !important;
}

.brc-purple-tp1 {
  border-color: rgba(119, 108, 184, 0.925) !important;
}

.bgc-purple-tp2 {
  background-color: rgba(119, 108, 184, 0.85) !important;
}

.brc-purple-tp2 {
  border-color: rgba(119, 108, 184, 0.85) !important;
}

.bgc-purple-tp3 {
  background-color: rgba(119, 108, 184, 0.775) !important;
}

.brc-purple-tp3 {
  border-color: rgba(119, 108, 184, 0.775) !important;
}

.bgc-purple-tp4 {
  background-color: rgba(119, 108, 184, 0.7) !important;
}

.brc-purple-tp4 {
  border-color: rgba(119, 108, 184, 0.7) !important;
}

.bgc-purple-d1 {
  background-color: #7167af !important;
}

.brc-purple-d1 {
  border-color: #7167af !important;
}

.bgc-purple-d2 {
  background-color: #6b61a6 !important;
}

.brc-purple-d2 {
  border-color: #6b61a6 !important;
}

.bgc-purple-d3 {
  background-color: #655c9c !important;
}

.brc-purple-d3 {
  border-color: #655c9c !important;
}

.bgc-purple-m1 {
  background-color: #8b82c3 !important;
}

.brc-purple-m1, .brc-h-purple-m1:hover {
  border-color: #8b82c3 !important;
}

.bgc-purple-m2 {
  background-color: #9d95cc !important;
}

.brc-purple-m2, .brc-h-purple-m2:hover {
  border-color: #9d95cc !important;
}

.bgc-purple-m3 {
  background-color: #afa8d5 !important;
}

.brc-purple-m3, .brc-h-purple-m3:hover {
  border-color: #afa8d5 !important;
}

.bgc-purple-m4 {
  background-color: #c2bddf !important;
}

.brc-purple-m4, .brc-h-purple-m4:hover {
  border-color: #c2bddf !important;
}

.bgc-purple-l1, .bgc-h-purple-l1:hover {
  background-color: #d0cce6 !important;
}

.brc-purple-l1 {
  border-color: #d0cce6 !important;
}

.bgc-purple-l2, .bgc-h-purple-l2:hover {
  background-color: #dddbed !important;
}

.brc-purple-l2 {
  border-color: #dddbed !important;
}

.bgc-purple-l3, .bgc-h-purple-l3:hover {
  background-color: #eae8f4 !important;
}

.brc-purple-l3 {
  border-color: #eae8f4 !important;
}

.bgc-purple-l4, .bgc-h-purple-l4:hover {
  background-color: #f1f0f8 !important;
}

.brc-purple-l4 {
  border-color: #f1f0f8 !important;
}

.bgc-purple-l5, .bgc-h-purple-l5:hover {
  background-color: #f7f6fb !important;
}

.brc-purple-l5 {
  border-color: #f7f6fb !important;
}

.bgc-pink {
  background-color: #d53c77 !important;
}

.brc-pink {
  border-color: #d53c77 !important;
}

.bgc-pink-tp1 {
  background-color: rgba(213, 60, 119, 0.925) !important;
}

.brc-pink-tp1 {
  border-color: rgba(213, 60, 119, 0.925) !important;
}

.bgc-pink-tp2 {
  background-color: rgba(213, 60, 119, 0.85) !important;
}

.brc-pink-tp2 {
  border-color: rgba(213, 60, 119, 0.85) !important;
}

.bgc-pink-tp3 {
  background-color: rgba(213, 60, 119, 0.775) !important;
}

.brc-pink-tp3 {
  border-color: rgba(213, 60, 119, 0.775) !important;
}

.bgc-pink-tp4 {
  background-color: rgba(213, 60, 119, 0.7) !important;
}

.brc-pink-tp4 {
  border-color: rgba(213, 60, 119, 0.7) !important;
}

.bgc-pink-d1 {
  background-color: #ca3971 !important;
}

.brc-pink-d1 {
  border-color: #ca3971 !important;
}

.bgc-pink-d2 {
  background-color: #c0366b !important;
}

.brc-pink-d2 {
  border-color: #c0366b !important;
}

.bgc-pink-d3 {
  background-color: #b53365 !important;
}

.brc-pink-d3 {
  border-color: #b53365 !important;
}

.bgc-pink-m1 {
  background-color: #db598b !important;
}

.brc-pink-m1, .brc-h-pink-m1:hover {
  border-color: #db598b !important;
}

.bgc-pink-m2 {
  background-color: #e1739d !important;
}

.brc-pink-m2, .brc-h-pink-m2:hover {
  border-color: #e1739d !important;
}

.bgc-pink-m3 {
  background-color: #e68caf !important;
}

.brc-pink-m3, .brc-h-pink-m3:hover {
  border-color: #e68caf !important;
}

.bgc-pink-m4 {
  background-color: #eca7c2 !important;
}

.brc-pink-m4, .brc-h-pink-m4:hover {
  border-color: #eca7c2 !important;
}

.bgc-pink-l1, .bgc-h-pink-l1:hover {
  background-color: #f2c0d3 !important;
}

.brc-pink-l1 {
  border-color: #f2c0d3 !important;
}

.bgc-pink-l2, .bgc-h-pink-l2:hover {
  background-color: #f5d3e0 !important;
}

.brc-pink-l2 {
  border-color: #f5d3e0 !important;
}

.bgc-pink-l3, .bgc-h-pink-l3:hover {
  background-color: #f9e3ec !important;
}

.brc-pink-l3 {
  border-color: #f9e3ec !important;
}

.bgc-pink-l4, .bgc-h-pink-l4:hover {
  background-color: #fbedf3 !important;
}

.brc-pink-l4 {
  border-color: #fbedf3 !important;
}

.bgc-pink-l5, .bgc-h-pink-l5:hover {
  background-color: #fdf4f8 !important;
}

.brc-pink-l5 {
  border-color: #fdf4f8 !important;
}

.bgc-yellow {
  background-color: #f8e57d !important;
}

.brc-yellow {
  border-color: #f8e57d !important;
}

.bgc-yellow-tp1 {
  background-color: rgba(248, 229, 125, 0.925) !important;
}

.brc-yellow-tp1 {
  border-color: rgba(248, 229, 125, 0.925) !important;
}

.bgc-yellow-tp2 {
  background-color: rgba(248, 229, 125, 0.85) !important;
}

.brc-yellow-tp2 {
  border-color: rgba(248, 229, 125, 0.85) !important;
}

.bgc-yellow-tp3 {
  background-color: rgba(248, 229, 125, 0.775) !important;
}

.brc-yellow-tp3 {
  border-color: rgba(248, 229, 125, 0.775) !important;
}

.bgc-yellow-tp4 {
  background-color: rgba(248, 229, 125, 0.7) !important;
}

.brc-yellow-tp4 {
  border-color: rgba(248, 229, 125, 0.7) !important;
}

.bgc-yellow-d1 {
  background-color: #ecda77 !important;
}

.brc-yellow-d1 {
  border-color: #ecda77 !important;
}

.bgc-yellow-d2 {
  background-color: #dfce71 !important;
}

.brc-yellow-d2 {
  border-color: #dfce71 !important;
}

.bgc-yellow-d3 {
  background-color: #d3c36a !important;
}

.brc-yellow-d3 {
  border-color: #d3c36a !important;
}

.bgc-yellow-m1 {
  background-color: #f9e991 !important;
}

.brc-yellow-m1, .brc-h-yellow-m1:hover {
  border-color: #f9e991 !important;
}

.bgc-yellow-m2 {
  background-color: #faeca1 !important;
}

.brc-yellow-m2, .brc-h-yellow-m2:hover {
  border-color: #faeca1 !important;
}

.bgc-yellow-m3 {
  background-color: #fbf0b2 !important;
}

.brc-yellow-m3, .brc-h-yellow-m3:hover {
  border-color: #fbf0b2 !important;
}

.bgc-yellow-m4 {
  background-color: #fcf3c5 !important;
}

.brc-yellow-m4, .brc-h-yellow-m4:hover {
  border-color: #fcf3c5 !important;
}

.bgc-yellow-l1, .bgc-h-yellow-l1:hover {
  background-color: #fbf1b7 !important;
}

.brc-yellow-l1 {
  border-color: #fbf1b7 !important;
}

.bgc-yellow-l2, .bgc-h-yellow-l2:hover {
  background-color: #fcf5cc !important;
}

.brc-yellow-l2 {
  border-color: #fcf5cc !important;
}

.bgc-yellow-l3, .bgc-h-yellow-l3:hover {
  background-color: #fdf9df !important;
}

.brc-yellow-l3 {
  border-color: #fdf9df !important;
}

.bgc-yellow-l4, .bgc-h-yellow-l4:hover {
  background-color: #fefbea !important;
}

.brc-yellow-l4 {
  border-color: #fefbea !important;
}

.bgc-yellow-l5, .bgc-h-yellow-l5:hover {
  background-color: #fefdf3 !important;
}

.brc-yellow-l5 {
  border-color: #fefdf3 !important;
}

.bgc-lightgrey {
  background-color: #91969d !important;
}

.brc-lightgrey {
  border-color: #91969d !important;
}

.bgc-lightgrey-tp1 {
  background-color: rgba(145, 150, 157, 0.925) !important;
}

.brc-lightgrey-tp1 {
  border-color: rgba(145, 150, 157, 0.925) !important;
}

.bgc-lightgrey-tp2 {
  background-color: rgba(145, 150, 157, 0.85) !important;
}

.brc-lightgrey-tp2 {
  border-color: rgba(145, 150, 157, 0.85) !important;
}

.bgc-lightgrey-tp3 {
  background-color: rgba(145, 150, 157, 0.775) !important;
}

.brc-lightgrey-tp3 {
  border-color: rgba(145, 150, 157, 0.775) !important;
}

.bgc-lightgrey-tp4 {
  background-color: rgba(145, 150, 157, 0.7) !important;
}

.brc-lightgrey-tp4 {
  border-color: rgba(145, 150, 157, 0.7) !important;
}

.bgc-lightgrey-d1 {
  background-color: #8a8f95 !important;
}

.brc-lightgrey-d1 {
  border-color: #8a8f95 !important;
}

.bgc-lightgrey-d2 {
  background-color: #83878d !important;
}

.brc-lightgrey-d2 {
  border-color: #83878d !important;
}

.bgc-lightgrey-d3 {
  background-color: #7b8085 !important;
}

.brc-lightgrey-d3 {
  border-color: #7b8085 !important;
}

.bgc-lightgrey-m1 {
  background-color: #a2a6ac !important;
}

.brc-lightgrey-m1, .brc-h-lightgrey-m1:hover {
  border-color: #a2a6ac !important;
}

.bgc-lightgrey-m2 {
  background-color: #b0b3b8 !important;
}

.brc-lightgrey-m2, .brc-h-lightgrey-m2:hover {
  border-color: #b0b3b8 !important;
}

.bgc-lightgrey-m3 {
  background-color: #bec1c5 !important;
}

.brc-lightgrey-m3, .brc-h-lightgrey-m3:hover {
  border-color: #bec1c5 !important;
}

.bgc-lightgrey-m4 {
  background-color: #ced0d3 !important;
}

.brc-lightgrey-m4, .brc-h-lightgrey-m4:hover {
  border-color: #ced0d3 !important;
}

.bgc-lightgrey-l1, .bgc-h-lightgrey-l1:hover {
  background-color: #d7d9db !important;
}

.brc-lightgrey-l1 {
  border-color: #d7d9db !important;
}

.bgc-lightgrey-l2, .bgc-h-lightgrey-l2:hover {
  background-color: #e2e4e6 !important;
}

.brc-lightgrey-l2 {
  border-color: #e2e4e6 !important;
}

.bgc-lightgrey-l3, .bgc-h-lightgrey-l3:hover {
  background-color: #edeeef !important;
}

.brc-lightgrey-l3 {
  border-color: #edeeef !important;
}

.bgc-lightgrey-l4, .bgc-h-lightgrey-l4:hover {
  background-color: #f3f4f5 !important;
}

.brc-lightgrey-l4 {
  border-color: #f3f4f5 !important;
}

.bgc-lightgrey-l5, .bgc-h-lightgrey-l5:hover {
  background-color: #f8f9f9 !important;
}

.brc-lightgrey-l5 {
  border-color: #f8f9f9 !important;
}

.bgc-brown {
  background-color: #bc7c4f !important;
}

.brc-brown {
  border-color: #bc7c4f !important;
}

.bgc-brown-tp1 {
  background-color: rgba(188, 124, 79, 0.925) !important;
}

.brc-brown-tp1 {
  border-color: rgba(188, 124, 79, 0.925) !important;
}

.bgc-brown-tp2 {
  background-color: rgba(188, 124, 79, 0.85) !important;
}

.brc-brown-tp2 {
  border-color: rgba(188, 124, 79, 0.85) !important;
}

.bgc-brown-tp3 {
  background-color: rgba(188, 124, 79, 0.775) !important;
}

.brc-brown-tp3 {
  border-color: rgba(188, 124, 79, 0.775) !important;
}

.bgc-brown-tp4 {
  background-color: rgba(188, 124, 79, 0.7) !important;
}

.brc-brown-tp4 {
  border-color: rgba(188, 124, 79, 0.7) !important;
}

.bgc-brown-d1 {
  background-color: #b3764b !important;
}

.brc-brown-d1 {
  border-color: #b3764b !important;
}

.bgc-brown-d2 {
  background-color: #a97047 !important;
}

.brc-brown-d2 {
  border-color: #a97047 !important;
}

.bgc-brown-d3 {
  background-color: #a06943 !important;
}

.brc-brown-d3 {
  border-color: #a06943 !important;
}

.bgc-brown-m1 {
  background-color: #c69069 !important;
}

.brc-brown-m1, .brc-h-brown-m1:hover {
  border-color: #c69069 !important;
}

.bgc-brown-m2 {
  background-color: #cfa180 !important;
}

.brc-brown-m2, .brc-h-brown-m2:hover {
  border-color: #cfa180 !important;
}

.bgc-brown-m3 {
  background-color: #d7b297 !important;
}

.brc-brown-m3, .brc-h-brown-m3:hover {
  border-color: #d7b297 !important;
}

.bgc-brown-m4 {
  background-color: #e1c4b0 !important;
}

.brc-brown-m4, .brc-h-brown-m4:hover {
  border-color: #e1c4b0 !important;
}

.bgc-brown-l1, .bgc-h-brown-l1:hover {
  background-color: #ead6c8 !important;
}

.brc-brown-l1 {
  border-color: #ead6c8 !important;
}

.bgc-brown-l2, .bgc-h-brown-l2:hover {
  background-color: #f0e2d8 !important;
}

.brc-brown-l2 {
  border-color: #f0e2d8 !important;
}

.bgc-brown-l3, .bgc-h-brown-l3:hover {
  background-color: #f6ede6 !important;
}

.brc-brown-l3 {
  border-color: #f6ede6 !important;
}

.bgc-brown-l4, .bgc-h-brown-l4:hover {
  background-color: #f9f3ef !important;
}

.brc-brown-l4 {
  border-color: #f9f3ef !important;
}

.bgc-brown-l5, .bgc-h-brown-l5:hover {
  background-color: #fbf8f6 !important;
}

.brc-brown-l5 {
  border-color: #fbf8f6 !important;
}

.bgc-blue {
  background-color: #3e89e4 !important;
}

.brc-blue {
  border-color: #3e89e4 !important;
}

.bgc-blue-tp1 {
  background-color: rgba(62, 137, 228, 0.925) !important;
}

.brc-blue-tp1 {
  border-color: rgba(62, 137, 228, 0.925) !important;
}

.bgc-blue-tp2 {
  background-color: rgba(62, 137, 228, 0.85) !important;
}

.brc-blue-tp2 {
  border-color: rgba(62, 137, 228, 0.85) !important;
}

.bgc-blue-tp3 {
  background-color: rgba(62, 137, 228, 0.775) !important;
}

.brc-blue-tp3 {
  border-color: rgba(62, 137, 228, 0.775) !important;
}

.bgc-blue-tp4 {
  background-color: rgba(62, 137, 228, 0.7) !important;
}

.brc-blue-tp4 {
  border-color: rgba(62, 137, 228, 0.7) !important;
}

.bgc-blue-d1 {
  background-color: #3b82d9 !important;
}

.brc-blue-d1 {
  border-color: #3b82d9 !important;
}

.bgc-blue-d2 {
  background-color: #387bcd !important;
}

.brc-blue-d2 {
  border-color: #387bcd !important;
}

.bgc-blue-d3 {
  background-color: #3574c2 !important;
}

.brc-blue-d3 {
  border-color: #3574c2 !important;
}

.bgc-blue-m1 {
  background-color: #5b9be8 !important;
}

.brc-blue-m1, .brc-h-blue-m1:hover {
  border-color: #5b9be8 !important;
}

.bgc-blue-m2 {
  background-color: #74aaec !important;
}

.brc-blue-m2, .brc-h-blue-m2:hover {
  border-color: #74aaec !important;
}

.bgc-blue-m3 {
  background-color: #8db9ef !important;
}

.brc-blue-m3, .brc-h-blue-m3:hover {
  border-color: #8db9ef !important;
}

.bgc-blue-m4 {
  background-color: #a8caf3 !important;
}

.brc-blue-m4, .brc-h-blue-m4:hover {
  border-color: #a8caf3 !important;
}

.bgc-blue-l1, .bgc-h-blue-l1:hover {
  background-color: #bcd6f6 !important;
}

.brc-blue-l1 {
  border-color: #bcd6f6 !important;
}

.bgc-blue-l2, .bgc-h-blue-l2:hover {
  background-color: #d0e3f8 !important;
}

.brc-blue-l2 {
  border-color: #d0e3f8 !important;
}

.bgc-blue-l3, .bgc-h-blue-l3:hover {
  background-color: #e1edfb !important;
}

.brc-blue-l3 {
  border-color: #e1edfb !important;
}

.bgc-blue-l4, .bgc-h-blue-l4:hover {
  background-color: #ecf4fc !important;
}

.brc-blue-l4 {
  border-color: #ecf4fc !important;
}

.bgc-blue-l5, .bgc-h-blue-l5:hover {
  background-color: #f4f8fd !important;
}

.brc-blue-l5 {
  border-color: #f4f8fd !important;
}

.bgc-red {
  background-color: #e74b4b !important;
}

.brc-red {
  border-color: #e74b4b !important;
}

.bgc-red-tp1 {
  background-color: rgba(231, 75, 75, 0.925) !important;
}

.brc-red-tp1 {
  border-color: rgba(231, 75, 75, 0.925) !important;
}

.bgc-red-tp2 {
  background-color: rgba(231, 75, 75, 0.85) !important;
}

.brc-red-tp2 {
  border-color: rgba(231, 75, 75, 0.85) !important;
}

.bgc-red-tp3 {
  background-color: rgba(231, 75, 75, 0.775) !important;
}

.brc-red-tp3 {
  border-color: rgba(231, 75, 75, 0.775) !important;
}

.bgc-red-tp4 {
  background-color: rgba(231, 75, 75, 0.7) !important;
}

.brc-red-tp4 {
  border-color: rgba(231, 75, 75, 0.7) !important;
}

.bgc-red-d1 {
  background-color: #db4747 !important;
}

.brc-red-d1 {
  border-color: #db4747 !important;
}

.bgc-red-d2 {
  background-color: #d04444 !important;
}

.brc-red-d2 {
  border-color: #d04444 !important;
}

.bgc-red-d3 {
  background-color: #c44040 !important;
}

.brc-red-d3 {
  border-color: #c44040 !important;
}

.bgc-red-m1 {
  background-color: #eb6666 !important;
}

.brc-red-m1, .brc-h-red-m1:hover {
  border-color: #eb6666 !important;
}

.bgc-red-m2 {
  background-color: #ee7d7d !important;
}

.brc-red-m2, .brc-h-red-m2:hover {
  border-color: #ee7d7d !important;
}

.bgc-red-m3 {
  background-color: #f19595 !important;
}

.brc-red-m3, .brc-h-red-m3:hover {
  border-color: #f19595 !important;
}

.bgc-red-m4 {
  background-color: #f4aeae !important;
}

.brc-red-m4, .brc-h-red-m4:hover {
  border-color: #f4aeae !important;
}

.bgc-red-l1, .bgc-h-red-l1:hover {
  background-color: #f6bcbc !important;
}

.brc-red-l1 {
  border-color: #f6bcbc !important;
}

.bgc-red-l2, .bgc-h-red-l2:hover {
  background-color: #f9cfcf !important;
}

.brc-red-l2 {
  border-color: #f9cfcf !important;
}

.bgc-red-l3, .bgc-h-red-l3:hover {
  background-color: #fbe1e1 !important;
}

.brc-red-l3 {
  border-color: #fbe1e1 !important;
}

.bgc-red-l4, .bgc-h-red-l4:hover {
  background-color: #fcecec !important;
}

.brc-red-l4 {
  border-color: #fcecec !important;
}

.bgc-red-l5, .bgc-h-red-l5:hover {
  background-color: #fdf4f4 !important;
}

.brc-red-l5 {
  border-color: #fdf4f4 !important;
}

.bgc-green {
  background-color: #39b689 !important;
}

.brc-green {
  border-color: #39b689 !important;
}

.bgc-green-tp1 {
  background-color: rgba(57, 182, 137, 0.925) !important;
}

.brc-green-tp1 {
  border-color: rgba(57, 182, 137, 0.925) !important;
}

.bgc-green-tp2 {
  background-color: rgba(57, 182, 137, 0.85) !important;
}

.brc-green-tp2 {
  border-color: rgba(57, 182, 137, 0.85) !important;
}

.bgc-green-tp3 {
  background-color: rgba(57, 182, 137, 0.775) !important;
}

.brc-green-tp3 {
  border-color: rgba(57, 182, 137, 0.775) !important;
}

.bgc-green-tp4 {
  background-color: rgba(57, 182, 137, 0.7) !important;
}

.brc-green-tp4 {
  border-color: rgba(57, 182, 137, 0.7) !important;
}

.bgc-green-d1 {
  background-color: #36ad82 !important;
}

.brc-green-d1 {
  border-color: #36ad82 !important;
}

.bgc-green-d2 {
  background-color: #33a47b !important;
}

.brc-green-d2 {
  border-color: #33a47b !important;
}

.bgc-green-d3 {
  background-color: #309b74 !important;
}

.brc-green-d3 {
  border-color: #309b74 !important;
}

.bgc-green-m1 {
  background-color: #57c19b !important;
}

.brc-green-m1, .brc-h-green-m1:hover {
  border-color: #57c19b !important;
}

.bgc-green-m2 {
  background-color: #70caaa !important;
}

.brc-green-m2, .brc-h-green-m2:hover {
  border-color: #70caaa !important;
}

.bgc-green-m3 {
  background-color: #8ad4b9 !important;
}

.brc-green-m3, .brc-h-green-m3:hover {
  border-color: #8ad4b9 !important;
}

.bgc-green-m4 {
  background-color: #a6deca !important;
}

.brc-green-m4, .brc-h-green-m4:hover {
  border-color: #a6deca !important;
}

.bgc-green-l1, .bgc-h-green-l1:hover {
  background-color: #c7eade !important;
}

.brc-green-l1 {
  border-color: #c7eade !important;
}

.bgc-green-l2, .bgc-h-green-l2:hover {
  background-color: #d8f0e8 !important;
}

.brc-green-l2 {
  border-color: #d8f0e8 !important;
}

.bgc-green-l3, .bgc-h-green-l3:hover {
  background-color: #e6f6f0 !important;
}

.brc-green-l3 {
  border-color: #e6f6f0 !important;
}

.bgc-green-l4, .bgc-h-green-l4:hover {
  background-color: #eff9f5 !important;
}

.brc-green-l4 {
  border-color: #eff9f5 !important;
}

.bgc-green-l5, .bgc-h-green-l5:hover {
  background-color: #f5fbf9 !important;
}

.brc-green-l5 {
  border-color: #f5fbf9 !important;
}

.bgc-orange {
  background-color: #ff8d03 !important;
}

.brc-orange {
  border-color: #ff8d03 !important;
}

.bgc-orange-tp1 {
  background-color: rgba(255, 141, 3, 0.925) !important;
}

.brc-orange-tp1 {
  border-color: rgba(255, 141, 3, 0.925) !important;
}

.bgc-orange-tp2 {
  background-color: rgba(255, 141, 3, 0.85) !important;
}

.brc-orange-tp2 {
  border-color: rgba(255, 141, 3, 0.85) !important;
}

.bgc-orange-tp3 {
  background-color: rgba(255, 141, 3, 0.775) !important;
}

.brc-orange-tp3 {
  border-color: rgba(255, 141, 3, 0.775) !important;
}

.bgc-orange-tp4 {
  background-color: rgba(255, 141, 3, 0.7) !important;
}

.brc-orange-tp4 {
  border-color: rgba(255, 141, 3, 0.7) !important;
}

.bgc-orange-d1 {
  background-color: #f28603 !important;
}

.brc-orange-d1 {
  border-color: #f28603 !important;
}

.bgc-orange-d2 {
  background-color: #e67f03 !important;
}

.brc-orange-d2 {
  border-color: #e67f03 !important;
}

.bgc-orange-d3 {
  background-color: #d97803 !important;
}

.brc-orange-d3 {
  border-color: #d97803 !important;
}

.bgc-orange-m1 {
  background-color: #ff9e29 !important;
}

.brc-orange-m1, .brc-h-orange-m1:hover {
  border-color: #ff9e29 !important;
}

.bgc-orange-m2 {
  background-color: #ffad4a !important;
}

.brc-orange-m2, .brc-h-orange-m2:hover {
  border-color: #ffad4a !important;
}

.bgc-orange-m3 {
  background-color: #ffbc6a !important;
}

.brc-orange-m3, .brc-h-orange-m3:hover {
  border-color: #ffbc6a !important;
}

.bgc-orange-m4 {
  background-color: #ffcc8e !important;
}

.brc-orange-m4, .brc-h-orange-m4:hover {
  border-color: #ffcc8e !important;
}

.bgc-orange-l1, .bgc-h-orange-l1:hover {
  background-color: #ffddb3 !important;
}

.brc-orange-l1 {
  border-color: #ffddb3 !important;
}

.bgc-orange-l2, .bgc-h-orange-l2:hover {
  background-color: #ffe7c9 !important;
}

.brc-orange-l2 {
  border-color: #ffe7c9 !important;
}

.bgc-orange-l3, .bgc-h-orange-l3:hover {
  background-color: #fff0dd !important;
}

.brc-orange-l3 {
  border-color: #fff0dd !important;
}

.bgc-orange-l4, .bgc-h-orange-l4:hover {
  background-color: #fff5e9 !important;
}

.brc-orange-l4 {
  border-color: #fff5e9 !important;
}

.bgc-orange-l5, .bgc-h-orange-l5:hover {
  background-color: #fff9f2 !important;
}

.brc-orange-l5 {
  border-color: #fff9f2 !important;
}

.bgc-transparent {
  background-color: transparent !important;
}

.brc-transparent {
  border-color: transparent !important;
}

.brc-black {
  border-color: #000 !important;
}

.brc-white {
  border-color: #fff !important;
}

.bgc-black-tp1, .bgc-h-black-tp1:hover {
  background-color: rgba(0, 0, 0, 0.909) !important;
}

.brc-black-tp1 {
  border-color: rgba(0, 0, 0, 0.909) !important;
}

.bgc-black-tp2, .bgc-h-black-tp2:hover {
  background-color: rgba(0, 0, 0, 0.818) !important;
}

.brc-black-tp2 {
  border-color: rgba(0, 0, 0, 0.818) !important;
}

.bgc-black-tp3, .bgc-h-black-tp3:hover {
  background-color: rgba(0, 0, 0, 0.727) !important;
}

.brc-black-tp3 {
  border-color: rgba(0, 0, 0, 0.727) !important;
}

.bgc-black-tp4, .bgc-h-black-tp4:hover {
  background-color: rgba(0, 0, 0, 0.636) !important;
}

.brc-black-tp4 {
  border-color: rgba(0, 0, 0, 0.636) !important;
}

.bgc-black-tp5, .bgc-h-black-tp5:hover {
  background-color: rgba(0, 0, 0, 0.545) !important;
}

.brc-black-tp5 {
  border-color: rgba(0, 0, 0, 0.545) !important;
}

.bgc-black-tp6, .bgc-h-black-tp6:hover {
  background-color: rgba(0, 0, 0, 0.454) !important;
}

.brc-black-tp6 {
  border-color: rgba(0, 0, 0, 0.454) !important;
}

.bgc-black-tp7, .bgc-h-black-tp7:hover {
  background-color: rgba(0, 0, 0, 0.363) !important;
}

.brc-black-tp7 {
  border-color: rgba(0, 0, 0, 0.363) !important;
}

.bgc-black-tp8, .bgc-h-black-tp8:hover {
  background-color: rgba(0, 0, 0, 0.272) !important;
}

.brc-black-tp8 {
  border-color: rgba(0, 0, 0, 0.272) !important;
}

.bgc-black-tp9, .bgc-h-black-tp9:hover {
  background-color: rgba(0, 0, 0, 0.181) !important;
}

.brc-black-tp9 {
  border-color: rgba(0, 0, 0, 0.181) !important;
}

.bgc-black-tp10, .bgc-h-black-tp10:hover {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.brc-black-tp10 {
  border-color: rgba(0, 0, 0, 0.09) !important;
}

.bgc-white-tp1, .bgc-h-white-tp1:hover {
  background-color: rgba(255, 255, 255, 0.9075) !important;
}

.brc-white-tp1 {
  border-color: rgba(255, 255, 255, 0.9075) !important;
}

.bgc-white-tp2, .bgc-h-white-tp2:hover {
  background-color: rgba(255, 255, 255, 0.815) !important;
}

.brc-white-tp2 {
  border-color: rgba(255, 255, 255, 0.815) !important;
}

.bgc-white-tp3, .bgc-h-white-tp3:hover {
  background-color: rgba(255, 255, 255, 0.7225) !important;
}

.brc-white-tp3 {
  border-color: rgba(255, 255, 255, 0.7225) !important;
}

.bgc-white-tp4, .bgc-h-white-tp4:hover {
  background-color: rgba(255, 255, 255, 0.63) !important;
}

.brc-white-tp4 {
  border-color: rgba(255, 255, 255, 0.63) !important;
}

.bgc-white-tp5, .bgc-h-white-tp5:hover {
  background-color: rgba(255, 255, 255, 0.5375) !important;
}

.brc-white-tp5 {
  border-color: rgba(255, 255, 255, 0.5375) !important;
}

.bgc-white-tp6, .bgc-h-white-tp6:hover {
  background-color: rgba(255, 255, 255, 0.445) !important;
}

.brc-white-tp6 {
  border-color: rgba(255, 255, 255, 0.445) !important;
}

.bgc-white-tp7, .bgc-h-white-tp7:hover {
  background-color: rgba(255, 255, 255, 0.3525) !important;
}

.brc-white-tp7 {
  border-color: rgba(255, 255, 255, 0.3525) !important;
}

.bgc-white-tp8, .bgc-h-white-tp8:hover {
  background-color: rgba(255, 255, 255, 0.26) !important;
}

.brc-white-tp8 {
  border-color: rgba(255, 255, 255, 0.26) !important;
}

.bgc-white-tp9, .bgc-h-white-tp9:hover {
  background-color: rgba(255, 255, 255, 0.1675) !important;
}

.brc-white-tp9 {
  border-color: rgba(255, 255, 255, 0.1675) !important;
}

.bgc-white-tp10, .bgc-h-white-tp10:hover {
  background-color: rgba(255, 255, 255, 0.075) !important;
}

.brc-white-tp10 {
  border-color: rgba(255, 255, 255, 0.075) !important;
}

.brc-black-tp11 {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.bgc-black-tp11 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.bgc-dark-grad {
  background-image: linear-gradient(rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.33) 100%);
}

.bgc-light-grad {
  background-image: linear-gradient(rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.33) 100%);
}

.bgc-white-grad {
  background-image: linear-gradient(rgba(255, 255, 255, 0.87) 0%, rgba(255, 255, 255, 0.33) 50%, rgba(255, 255, 255, 0.87) 100%);
}

.bg-none {
  background-image: none !important;
}

[class*="bgc-h-"] {
  transition: background-color 0.15s;
}

[class*="brc-h-"] {
  transition: border-color 0.15s;
}

.border-0 {
  border-width: 0px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-none {
  border-width: 0px !important;
  border-style: solid;
  border-color: transparent;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-4 {
  border-width: 4px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-5 {
  border-width: 5px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-6 {
  border-width: 6px !important;
  border-style: solid !important;
  border-color: transparent;
}

.border-t-0, .border-y-0 {
  border-top-width: 0px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-0, .border-y-0 {
  border-bottom-width: 0px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-0, .border-x-0 {
  border-right-width: 0px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-0, .border-x-0 {
  border-left-width: 0px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-1, .border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-1, .border-y-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-1, .border-x-1 {
  border-right-width: 1px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-1, .border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-2, .border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-2, .border-y-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-2, .border-x-2 {
  border-right-width: 2px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-2, .border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-3, .border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-3, .border-y-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-3, .border-x-3 {
  border-right-width: 3px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-3, .border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-4, .border-y-4 {
  border-top-width: 4px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-4, .border-y-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-4, .border-x-4 {
  border-right-width: 4px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-4, .border-x-4 {
  border-left-width: 4px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-5, .border-y-5 {
  border-top-width: 5px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-5, .border-y-5 {
  border-bottom-width: 5px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-5, .border-x-5 {
  border-right-width: 5px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-5, .border-x-5 {
  border-left-width: 5px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-t-6, .border-y-6 {
  border-top-width: 6px !important;
  border-top-style: solid !important;
  border-top-color: transparent;
}

.border-b-6, .border-y-6 {
  border-bottom-width: 6px !important;
  border-bottom-style: solid !important;
  border-bottom-color: transparent;
}

.border-r-6, .border-x-6 {
  border-right-width: 6px !important;
  border-right-style: solid !important;
  border-right-color: transparent;
}

.border-l-6, .border-x-6 {
  border-left-width: 6px !important;
  border-left-style: solid !important;
  border-left-color: transparent;
}

.border-solid {
  border-style: solid !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-dashed {
  border-style: dashed !important;
}

.radius-0 {
  border-radius: 0rem !important;
}

.radius-1 {
  border-radius: 0.25rem !important;
}

.radius-2 {
  border-radius: 0.5rem !important;
}

.radius-3 {
  border-radius: 0.75rem !important;
}

.radius-4 {
  border-radius: 1rem !important;
}

.radius-5 {
  border-radius: 1.25rem !important;
}

.radius-1px {
  border-radius: 1px !important;
}

.radius-2px {
  border-radius: 2px !important;
}

.radius-3px {
  border-radius: 3px !important;
}

.radius-l-0 {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.radius-t-0 {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.radius-r-0 {
  border-bottom-right-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.radius-b-0 {
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.radius-l-1 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.radius-t-1 {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.radius-r-1 {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.radius-b-1 {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.radius-l-2 {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.radius-t-2 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.radius-r-2 {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.radius-b-2 {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.radius-l-3 {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.radius-t-3 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.radius-r-3 {
  border-bottom-right-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.radius-b-3 {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.radius-l-4 {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.radius-t-4 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.radius-r-4 {
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.radius-b-4 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.radius-l-5 {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
}

.radius-t-5 {
  border-top-left-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.radius-r-5 {
  border-bottom-right-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
}

.radius-b-5 {
  border-bottom-left-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
}

.radius-round {
  border-radius: 4rem !important;
}

.radius-l-round {
  border-top-left-radius: 4rem !important;
  border-bottom-left-radius: 4rem !important;
}

.radius-t-round {
  border-top-left-radius: 4rem !important;
  border-top-right-radius: 4rem !important;
}

.radius-r-round {
  border-bottom-right-radius: 4rem !important;
  border-top-right-radius: 4rem !important;
}

.radius-b-round {
  border-bottom-left-radius: 4rem !important;
  border-bottom-right-radius: 4rem !important;
}

.radius-50 {
  border-radius: 50% !important;
}

.radius-100 {
  border-radius: 100% !important;
}

.mw-auto {
  min-width: auto !important;
}

.mw-none {
  max-width: none !important;
}

.mh-auto {
  min-height: auto !important;
}

.mh-none {
  max-height: none !important;
}

.minh-100 {
  min-height: 100% !important;
}

.minw-100 {
  min-width: 100% !important;
}

.w-inherit {
  width: inherit !important;
}

.h-inherit {
  height: inherit !important;
}

.w-90 {
  width: 90% !important;
}

.h-90 {
  height: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.h-95 {
  height: 95% !important;
}

.w-98 {
  width: calc(100% - 2px) !important;
}

.h-98 {
  height: calc(100% - 2px) !important;
}

.w-101 {
  width: calc(100% + 1px) !important;
}

.h-101 {
  height: calc(100% + 1px) !important;
}

.w-102 {
  width: calc(100% + 2px) !important;
}

.h-102 {
  height: calc(100% + 2px) !important;
}

.w-1 {
  width: 0.5rem;
}

.w-2 {
  width: 1rem;
}

.w-3 {
  width: 1.5rem;
}

.w-4 {
  width: 2rem;
}

.w-5 {
  width: 2.5rem;
}

.w-6 {
  width: 3rem;
}

.h-1 {
  height: 0.5rem;
}

.h-2 {
  height: 1rem;
}

.h-3 {
  height: 1.5rem;
}

.h-4 {
  height: 2rem;
}

.h-5 {
  height: 2.5rem;
}

.h-6 {
  height: 3rem;
}

.m-1px {
  margin: 1px !important;
}

.m-n1px {
  margin: -1px !important;
}

.mx-1px, .ml-1px {
  margin-left: 1px !important;
}

.mx-1px, .mr-1px {
  margin-right: 1px !important;
}

.mx-n1px, .ml-n1px {
  margin-left: -1px !important;
}

.mx-n1px, .mr-n1px {
  margin-right: -1px !important;
}

.my-1px, .mt-1px {
  margin-top: 1px !important;
}

.my-1px, .mb-1px {
  margin-bottom: 1px !important;
}

.my-n1px, .mt-n1px {
  margin-top: -1px !important;
}

.my-n1px, .mb-n1px {
  margin-bottom: -1px !important;
}

.p-1px {
  padding: 1px !important;
}

.px-1px, .pl-1px {
  padding-left: 1px !important;
}

.px-1px, .pr-1px {
  padding-right: 1px !important;
}

.py-1px, .pt-1px {
  padding-top: 1px !important;
}

.py-1px, .pb-1px {
  padding-bottom: 1px !important;
}

.m-2px {
  margin: 2px !important;
}

.m-n2px {
  margin: -2px !important;
}

.mx-2px, .ml-2px {
  margin-left: 2px !important;
}

.mx-2px, .mr-2px {
  margin-right: 2px !important;
}

.mx-n2px, .ml-n2px {
  margin-left: -2px !important;
}

.mx-n2px, .mr-n2px {
  margin-right: -2px !important;
}

.my-2px, .mt-2px {
  margin-top: 2px !important;
}

.my-2px, .mb-2px {
  margin-bottom: 2px !important;
}

.my-n2px, .mt-n2px {
  margin-top: -2px !important;
}

.my-n2px, .mb-n2px {
  margin-bottom: -2px !important;
}

.p-2px {
  padding: 2px !important;
}

.px-2px, .pl-2px {
  padding-left: 2px !important;
}

.px-2px, .pr-2px {
  padding-right: 2px !important;
}

.py-2px, .pt-2px {
  padding-top: 2px !important;
}

.py-2px, .pb-2px {
  padding-bottom: 2px !important;
}

.m-3px {
  margin: 3px !important;
}

.m-n3px {
  margin: -3px !important;
}

.mx-3px, .ml-3px {
  margin-left: 3px !important;
}

.mx-3px, .mr-3px {
  margin-right: 3px !important;
}

.mx-n3px, .ml-n3px {
  margin-left: -3px !important;
}

.mx-n3px, .mr-n3px {
  margin-right: -3px !important;
}

.my-3px, .mt-3px {
  margin-top: 3px !important;
}

.my-3px, .mb-3px {
  margin-bottom: 3px !important;
}

.my-n3px, .mt-n3px {
  margin-top: -3px !important;
}

.my-n3px, .mb-n3px {
  margin-bottom: -3px !important;
}

.p-3px {
  padding: 3px !important;
}

.px-3px, .pl-3px {
  padding-left: 3px !important;
}

.px-3px, .pr-3px {
  padding-right: 3px !important;
}

.py-3px, .pt-3px {
  padding-top: 3px !important;
}

.py-3px, .pb-3px {
  padding-bottom: 3px !important;
}

.m-15 {
  margin: 0.375rem !important;
}

.mt-15,
.my-15 {
  margin-top: 0.375rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 0.375rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 0.375rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 0.375rem !important;
}

.m-25 {
  margin: 0.75rem !important;
}

.mt-25,
.my-25 {
  margin-top: 0.75rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 0.75rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 0.75rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 0.75rem !important;
}

.m-35 {
  margin: 1.25rem !important;
}

.mt-35,
.my-35 {
  margin-top: 1.25rem !important;
}

.mr-35,
.mx-35 {
  margin-right: 1.25rem !important;
}

.mb-35,
.my-35 {
  margin-bottom: 1.25rem !important;
}

.ml-35,
.mx-35 {
  margin-left: 1.25rem !important;
}

.m-425 {
  margin: 1.75rem !important;
}

.mt-425,
.my-425 {
  margin-top: 1.75rem !important;
}

.mr-425,
.mx-425 {
  margin-right: 1.75rem !important;
}

.mb-425,
.my-425 {
  margin-bottom: 1.75rem !important;
}

.ml-425,
.mx-425 {
  margin-left: 1.75rem !important;
}

.m-45 {
  margin: 2rem !important;
}

.mt-45,
.my-45 {
  margin-top: 2rem !important;
}

.mr-45,
.mx-45 {
  margin-right: 2rem !important;
}

.mb-45,
.my-45 {
  margin-bottom: 2rem !important;
}

.ml-45,
.mx-45 {
  margin-left: 2rem !important;
}

.m-475 {
  margin: 2.5rem !important;
}

.mt-475,
.my-475 {
  margin-top: 2.5rem !important;
}

.mr-475,
.mx-475 {
  margin-right: 2.5rem !important;
}

.mb-475,
.my-475 {
  margin-bottom: 2.5rem !important;
}

.ml-475,
.mx-475 {
  margin-left: 2.5rem !important;
}

.p-15 {
  padding: 0.375rem !important;
}

.pt-15,
.py-15 {
  padding-top: 0.375rem !important;
}

.pr-15,
.px-15 {
  padding-right: 0.375rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 0.375rem !important;
}

.pl-15,
.px-15 {
  padding-left: 0.375rem !important;
}

.p-25 {
  padding: 0.75rem !important;
}

.pt-25,
.py-25 {
  padding-top: 0.75rem !important;
}

.pr-25,
.px-25 {
  padding-right: 0.75rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 0.75rem !important;
}

.pl-25,
.px-25 {
  padding-left: 0.75rem !important;
}

.p-35 {
  padding: 1.25rem !important;
}

.pt-35,
.py-35 {
  padding-top: 1.25rem !important;
}

.pr-35,
.px-35 {
  padding-right: 1.25rem !important;
}

.pb-35,
.py-35 {
  padding-bottom: 1.25rem !important;
}

.pl-35,
.px-35 {
  padding-left: 1.25rem !important;
}

.p-425 {
  padding: 1.75rem !important;
}

.pt-425,
.py-425 {
  padding-top: 1.75rem !important;
}

.pr-425,
.px-425 {
  padding-right: 1.75rem !important;
}

.pb-425,
.py-425 {
  padding-bottom: 1.75rem !important;
}

.pl-425,
.px-425 {
  padding-left: 1.75rem !important;
}

.p-45 {
  padding: 2rem !important;
}

.pt-45,
.py-45 {
  padding-top: 2rem !important;
}

.pr-45,
.px-45 {
  padding-right: 2rem !important;
}

.pb-45,
.py-45 {
  padding-bottom: 2rem !important;
}

.pl-45,
.px-45 {
  padding-left: 2rem !important;
}

.p-475 {
  padding: 2.5rem !important;
}

.pt-475,
.py-475 {
  padding-top: 2.5rem !important;
}

.pr-475,
.px-475 {
  padding-right: 2.5rem !important;
}

.pb-475,
.py-475 {
  padding-bottom: 2.5rem !important;
}

.pl-475,
.px-475 {
  padding-left: 2.5rem !important;
}

.m-n15 {
  margin: -0.375rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -0.375rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -0.375rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -0.375rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -0.375rem !important;
}

.m-n25 {
  margin: -0.75rem !important;
}

.mt-n25,
.my-n25 {
  margin-top: -0.75rem !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -0.75rem !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -0.75rem !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -0.75rem !important;
}

.m-n35 {
  margin: -1.25rem !important;
}

.mt-n35,
.my-n35 {
  margin-top: -1.25rem !important;
}

.mr-n35,
.mx-n35 {
  margin-right: -1.25rem !important;
}

.mb-n35,
.my-n35 {
  margin-bottom: -1.25rem !important;
}

.ml-n35,
.mx-n35 {
  margin-left: -1.25rem !important;
}

.m-n425 {
  margin: -1.75rem !important;
}

.mt-n425,
.my-n425 {
  margin-top: -1.75rem !important;
}

.mr-n425,
.mx-n425 {
  margin-right: -1.75rem !important;
}

.mb-n425,
.my-n425 {
  margin-bottom: -1.75rem !important;
}

.ml-n425,
.mx-n425 {
  margin-left: -1.75rem !important;
}

.m-n45 {
  margin: -2rem !important;
}

.mt-n45,
.my-n45 {
  margin-top: -2rem !important;
}

.mr-n45,
.mx-n45 {
  margin-right: -2rem !important;
}

.mb-n45,
.my-n45 {
  margin-bottom: -2rem !important;
}

.ml-n45,
.mx-n45 {
  margin-left: -2rem !important;
}

.m-n475 {
  margin: -2.5rem !important;
}

.mt-n475,
.my-n475 {
  margin-top: -2.5rem !important;
}

.mr-n475,
.mx-n475 {
  margin-right: -2.5rem !important;
}

.mb-n475,
.my-n475 {
  margin-bottom: -2.5rem !important;
}

.ml-n475,
.mx-n475 {
  margin-left: -2.5rem !important;
}

.d-style:not(.active) .d-active,
.d-style.active .d-n-active,
.d-style:not(.collapsed) .d-collapsed,
.d-style.collapsed .d-n-collapsed,
.d-style:not(:hover) .d-hover,
.d-style:hover .d-n-hover,
.d-style:not(.show) .d-show,
.d-style.show .d-n-show {
  display: none !important;
}

.v-active, .v-n-active,
.v-show, .v-n-show,
.v-collapsed, .v-n-collapsed,
.v-hover, .v-n-hover {
  transition: opacity 120ms, visibility 0ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .v-active, .v-n-active,
  .v-show, .v-n-show,
  .v-collapsed, .v-n-collapsed,
  .v-hover, .v-n-hover {
    transition: none;
  }
}

.d-style:not(.active) .v-active,
.d-style.active .v-n-active,
.d-style:not(.collapsed) .v-collapsed,
.d-style.collapsed .v-n-collapsed,
.d-style:not(.show) .v-show,
.d-style.show .v-n-show,
.d-style:not(:hover) .v-hover,
.d-style:hover .v-n-hover {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.mb-hover, .mb-n-hover,
.mt-hover, .mt-n-hover,
.mr-hover, .mr-n-hover,
.ml-hover, .ml-n-hover {
  transition: transform 120ms, visibility 0ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .mb-hover, .mb-n-hover,
  .mt-hover, .mt-n-hover,
  .mr-hover, .mr-n-hover,
  .ml-hover, .ml-n-hover {
    transition: none;
  }
}

.d-style:not(:hover) .mb-hover,
.d-style:hover .mb-n-hover {
  transform: translateY(100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-style:not(:hover) .mt-hover,
.d-style:hover .mt-n-hover {
  transform: translateY(-100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-style:not(:hover) .mr-hover,
.d-style:hover .mr-n-hover {
  transform: translateX(100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-style:not(:hover) .ml-hover,
.d-style:hover .ml-n-hover {
  transform: translateX(-100%);
  visibility: hidden;
  transition-delay: 0ms, 120ms;
}

.d-zoom-1, .d-zoom-2, .d-zoom-3, .dh-zoom-1, .dh-zoom-2, .dh-zoom-3 {
  transition: transform 180ms;
}

@media (prefers-reduced-motion: reduce) {
  .d-zoom-1, .d-zoom-2, .d-zoom-3, .dh-zoom-1, .dh-zoom-2, .dh-zoom-3 {
    transition: none;
  }
}

.d-style:hover .d-zoom-1, .dh-zoom-1:hover {
  transform: scale(1.06);
}

.d-style:hover .d-zoom-2, .dh-zoom-2:hover {
  transform: scale(1.12);
}

.d-style:hover .d-zoom-3, .dh-zoom-3:hover {
  transform: scale(1.18);
}

.v-active, .v-n-active,
.v-show, .v-n-show,
.v-collapsed, .v-n-collapsed,
.v-hover, .v-n-hover {
  transition: opacity 120ms, visibility 0ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .v-active, .v-n-active,
  .v-show, .v-n-show,
  .v-collapsed, .v-n-collapsed,
  .v-hover, .v-n-hover {
    transition: none;
  }
}

.d-style:not(:hover) .f-n-hover,
.d-style:hover .f-hover {
  opacity: 0.86;
  transition: opacity 120ms;
}

@media (prefers-reduced-motion: reduce) {
  .d-style:not(:hover) .f-n-hover,
  .d-style:hover .f-hover {
    transition: none;
  }
}

.d-style:not(:hover) .f-n-hover-2,
.d-style:hover .f-hover-2 {
  opacity: 0.74;
  transition: opacity 120ms;
}

@media (prefers-reduced-motion: reduce) {
  .d-style:not(:hover) .f-n-hover-2,
  .d-style:hover .f-hover-2 {
    transition: none;
  }
}

.d-style:not(:hover) .f-n-hover-3,
.d-style:hover .f-hover-3 {
  opacity: 0.63;
  transition: opacity 120ms;
}

@media (prefers-reduced-motion: reduce) {
  .d-style:not(:hover) .f-n-hover-3,
  .d-style:hover .f-hover-3 {
    transition: none;
  }
}

.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

.position-tr,
.position-tl,
.position-br,
.position-bl,
.position-rc,
.position-lc,
.position-tc,
.position-bc,
.position-r,
.position-l,
.position-t,
.position-b,
.position-center {
  position: absolute !important;
  display: block;
}

.position-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.position-tr {
  right: 0;
  top: 0;
}

.position-tl {
  left: 0;
  top: 0;
}

.position-br {
  right: 0;
  bottom: 0;
}

.position-bl {
  left: 0;
  bottom: 0;
}

.position-lc {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-rc {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.position-tc {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.position-bc {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.position-r {
  right: 0;
}

.position-l {
  left: 0;
}

.position-t {
  top: 0;
}

.position-b {
  bottom: 0;
}

.pos-fixed {
  position: fixed !important;
}

.pos-sticky {
  position: sticky !important;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-n45 {
  transform: rotate(-45deg);
}

.align-sub {
  vertical-align: sub !important;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-equal > * {
  flex: 1 1 auto;
}

.flex-fill > * {
  flex-grow: 1;
  flex-basis: 0;
}

.ace-scroll-mob {
  overflow-y: auto;
}

.ace-scroll {
  scrollbar-width: thin;
  scrollbar-color: #9dbad5 #dce3ea;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ace-scroll::-webkit-scrollbar {
  width: 8px;
  -webkit-appearance: none;
  appearance: none;
}

.ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.ace-scroll.ace-scroll-grey:not(.ace-scroll-wrap) {
  scrollbar-color: #bbbdbf #e5e6e8;
}

.ace-scroll.ace-scroll-grey:not(.ace-scroll-wrap)::-webkit-scrollbar-thumb {
  background-color: #bbbdbf;
}

.ace-scroll.ace-scroll-grey:not(.ace-scroll-wrap)::-webkit-scrollbar-track {
  background-color: #e5e6e8;
}

@media (hover: hover) {
  .ace-scrollbar:not(.ace-scroll-mob) {
    scrollbar-width: thin;
    scrollbar-color: #9dbad5 #dce3ea;
  }
  .ace-scrollbar:not(.ace-scroll-mob)::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    -webkit-appearance: none;
    appearance: none;
  }
  .ace-scrollbar:not(.ace-scroll-mob)::-webkit-scrollbar-thumb {
    background-color: #9dbad5;
  }
  .ace-scrollbar:not(.ace-scroll-mob)::-webkit-scrollbar-track {
    background-color: #dce3ea;
  }
  .ace-scrollbar:not(.ace-scroll-mob).ace-scroll-grey {
    scrollbar-color: #bbbdbf #e5e6e8;
  }
  .ace-scrollbar:not(.ace-scroll-mob).ace-scroll-grey::-webkit-scrollbar-thumb {
    background-color: #bbbdbf;
  }
  .ace-scrollbar:not(.ace-scroll-mob).ace-scroll-grey::-webkit-scrollbar-track {
    background-color: #e5e6e8;
  }
}

.ace-scroll {
  overflow-y: hidden;
}

.ace-scroll:hover, .ace-scroll.is-always-on {
  overflow-y: auto;
  overflow-y: overlay;
}

.ace-scroll-lock {
  overscroll-behavior-y: none;
  -ms-scroll-chaining: none;
}

.ace-scroll-wrap {
  overflow-x: hidden;
}

@supports (-webkit-appearance: none) and (overflow: overlay) {
  .ace-scroll-wrap {
    overflow-y: overlay;
    color: transparent;
    transition: color 0.15s 0.4s;
  }
  @media (prefers-reduced-motion: reduce) {
    .ace-scroll-wrap {
      transition: none;
    }
  }
  .ace-scroll-wrap.is-always-on {
    color: #9dbad5;
    transition: none;
  }
  .ace-scroll-wrap:hover {
    color: #9dbad5;
    transition-delay: 0s;
  }
  .ace-scroll-wrap.ace-scroll-grey.is-always-on, .ace-scroll-wrap.ace-scroll-grey:hover {
    color: #bbbdbf;
  }
  .ace-scroll-wrap::-webkit-scrollbar-thumb {
    background-color: currentColor;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    background-clip: content-box;
  }
  .ace-scroll-wrap::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@supports (-moz-appearance: none) and (scrollbar-width: none) {
  :root {
    --moz-scrollbar-thin: 8px;
  }
  .ace-scroll-wrap {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.15s 0.4s;
  }
  @media (prefers-reduced-motion: reduce) {
    .ace-scroll-wrap {
      transition: none;
    }
  }
  .ace-scroll-wrap > .ace-scroll-inner {
    min-width: calc(100% + var(--moz-scrollbar-thin));
  }
  .ace-scroll-wrap.is-always-on {
    scrollbar-color: #9dbad5 transparent;
    transition: none;
  }
  .ace-scroll-wrap:hover {
    overflow-y: scroll;
    scrollbar-color: #9dbad5 transparent;
    transition-delay: 0s;
  }
  .ace-scroll-wrap.ace-scroll-grey.is-always-on, .ace-scroll-wrap.ace-scroll-grey:hover {
    scrollbar-color: #bbbdbf transparent;
  }
  .ace-scroll:not(.ace-scroll-wrap):not(.is-always-on) {
    overflow-x: hidden;
  }
  .ace-scroll:not(.ace-scroll-wrap):not(.is-always-on):hover > div, .ace-scroll:not(.ace-scroll-wrap):not(.is-always-on):hover > p {
    min-width: calc(100% + var(--moz-scrollbar-thin));
  }
  .sidebar .ace-scroll:not(.ace-scroll-wrap):hover > div, .sidebar .ace-scroll:not(.ace-scroll-wrap):hover > p {
    min-width: auto;
  }
}

@supports (-moz-appearance: none) and (not (scrollbar-width: none)) {
  :root {
    --moz-scrollbar-thin: 17px;
  }
  .ace-scroll:not(.is-always-on) {
    overflow-x: hidden;
  }
  .ace-scroll:not(.is-always-on):hover {
    overflow-y: scroll;
  }
  .ace-scroll:not(.is-always-on):hover > div, .ace-scroll:not(.is-always-on):hover > p {
    min-width: calc(100% + var(--moz-scrollbar-thin));
  }
  .sidebar .ace-scroll:hover {
    overflow-y: auto;
  }
  .sidebar .ace-scroll:hover > div, .sidebar .ace-scroll:hover > p {
    min-width: auto;
  }
}

.navbar {
  padding: 0;
  flex: 0 0 auto;
  height: 4.5rem;
  height: var(--navbar-height);
  visibility: hidden;
  z-index: 1022;
}

.main-content .navbar {
  z-index: 1018;
}

.main-content .navbar-open {
  z-index: 1019;
}

.navbar-modal {
  z-index: 1023 !important;
}

@media (max-width: 1199.98px) {
  .main-content .navbar {
    z-index: 1019;
  }
}

.navbar-sm {
  height: 4rem;
  height: var(--navbar-sm-height);
}

@media (max-width: 1199.98px) {
  .navbar {
    height: 4rem;
    height: var(--navbar-mobile-height);
  }
}

.navbar-inner {
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  visibility: visible;
}

.navbar.container > .navbar-inner {
  max-width: inherit;
}

.navbar-inner.container {
  padding: 0;
  flex-wrap: nowrap;
}

.navbar-inner > .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0;
  height: 100%;
  background-color: inherit;
  position: relative;
}

.navbar-brand {
  margin-left: 0.875rem;
  font-size: 1.5rem;
}

.navbar-intro {
  display: flex;
  align-items: center;
  height: 100%;
}

.body-container > .navbar .navbar-intro {
  width: 16rem;
  width: var(--sidebar-width);
}

.navbar-content {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 0 auto;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-input {
  outline: none;
  width: 320px;
  max-width: 100%;
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-input {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-content {
    margin-left: auto;
    background-color: inherit;
    flex: 0 0 auto;
  }
}

.btn-burger {
  cursor: pointer;
  width: 2.5rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.15s;
  color: #fff;
  padding: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-burger {
    transition: none;
  }
}

.btn-burger:hover, .btn-burger:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.btn-burger:active {
  transform: none !important;
}

.btn-burger .bars {
  display: block;
  width: 50%;
  height: 2px;
  position: relative;
  color: inherit;
  background-color: currentColor;
  transition: transform 0.15s, top 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-burger .bars {
    transition: none;
  }
}

.btn-burger .bars::before, .btn-burger .bars::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: inherit;
  background-color: inherit;
  color: inherit;
  border-radius: inherit;
  transition: inherit;
  transform-origin: inherit;
}

.btn-burger .bars::before {
  top: -0.375rem;
}

.btn-burger .bars::after {
  top: 0.375rem;
}

.btn-burger.burger-dots {
  width: 2.25rem;
}

.btn-burger.burger-dots .bars {
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.25rem;
}

.btn-burger.burger-steps .bars {
  transform-origin: left;
}

.btn-burger.burger-steps:hover .bars {
  transform: scaleX(0.75);
}

.btn-burger.burger-steps:hover .bars::before {
  transform: scaleX(1.25);
}

.btn-burger.burger-steps:hover .bars::after {
  transform: scaleX(0.75);
}

.btn-burger.burger-arrowed .bars {
  transform-origin: left;
}

.btn-burger.burger-arrowed:not(.static):hover .bars::before, .btn-burger.burger-arrowed.static:not(.collapsed) .bars::before {
  top: 0;
  transform: rotate(-45deg) scaleX(0.66);
}

.btn-burger.burger-arrowed:not(.static):hover .bars::after, .btn-burger.burger-arrowed.static:not(.collapsed) .bars::after {
  top: 0;
  transform: rotate(45deg) scaleX(0.66);
}

.btn-burger.burger-arrowed:not(.static).collapsed .bars {
  transform-origin: right;
}

.btn-burger.burger-arrowed:not(.static).collapsed:hover .bars::before {
  top: 0;
  transform: rotate(45deg) scaleX(0.66);
}

.btn-burger.burger-arrowed:not(.static).collapsed:hover .bars::after {
  top: 0;
  transform: rotate(-45deg) scaleX(0.66);
}

.btn-burger.burger-minus:not(.static):hover .bars::before, .btn-burger.burger-minus:not(.static):hover .bars::after, .btn-burger.burger-minus.static:not(.collapsed) .bars::before, .btn-burger.burger-minus.static:not(.collapsed) .bars::after {
  top: 0;
}

.btn-burger.burger-times .bars {
  transform-origin: center;
}

.btn-burger.burger-times:not(.static):hover .bars, .btn-burger.burger-times.static:not(.collapsed) .bars {
  visibility: hidden;
}

.btn-burger.burger-times:not(.static):hover .bars::before, .btn-burger.burger-times.static:not(.collapsed) .bars::before {
  top: 0;
  visibility: visible;
  transform: rotate(-45deg) scaleX(0.9);
}

.btn-burger.burger-times:not(.static):hover .bars::after, .btn-burger.burger-times.static:not(.collapsed) .bars::after {
  top: 0;
  visibility: visible;
  transform: rotate(45deg) scaleX(0.9);
}

.btn-burger.burger-compact:not(.static):hover .bars, .btn-burger.burger-compact.static:not(.collapsed) .bars {
  transform-origin: center;
  transform: scaleX(0.8);
}

.btn-burger.burger-compact:not(.static):hover .bars::before, .btn-burger.burger-compact.static:not(.collapsed) .bars::before {
  top: -0.25rem;
}

.btn-burger.burger-compact:not(.static):hover .bars::after, .btn-burger.burger-compact.static:not(.collapsed) .bars::after {
  top: 0.25rem;
}

.navbar .nav > .nav-item > .nav-link:not(.disabled) {
  border-radius: 0;
  transition: background-color 150ms, color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .navbar .nav > .nav-item > .nav-link:not(.disabled) {
    transition: none;
  }
}

.navbar .navbar-nav .nav .dropdown-toggle::after {
  display: none;
}

.navbar .nav > .nav-item > .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .navbar .navbar-menu.navbar-collapse {
    height: 100%;
    flex-grow: 0;
  }
  .navbar .navbar-nav {
    height: 100%;
    align-items: center;
  }
  .navbar .navbar-nav > .nav {
    height: 100%;
  }
  .navbar-inner.container .navbar-nav .nav > .nav-item:last-of-type, .navbar-inner.container .navbar-nav .nav > .nav-item:last-of-type::after {
    border-right-width: 0;
  }
  .navbar .navbar-nav > .nav > .nav-item {
    height: 100%;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link, .navbar .navbar-nav > .nav > .nav-item > .btn {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link > .caret, .navbar .navbar-nav > .nav > .nav-item > .btn > .caret {
    margin: 0 0.25rem 0 0.5rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link .badge, .navbar .navbar-nav > .nav > .nav-item > .btn .badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link .badge-dot, .navbar .navbar-nav > .nav > .nav-item > .btn .badge-dot {
    top: -0.125rem;
    right: -0.125rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link .badge-tr, .navbar .navbar-nav > .nav > .nav-item > .btn .badge-tr {
    top: 0;
    right: 0;
  }
  .navbar .navbar-nav > .nav > .nav-item > .btn {
    border-width: 0;
    border-radius: 0;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled), .navbar .navbar-nav > .nav.has-active-border > .nav-item > .btn:not(.disabled) {
    position: relative;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item > .btn:not(.disabled)::before {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 2px;
    right: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 0;
    transition: width 150ms;
    height: 3px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.73);
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item > .btn:not(.disabled)::before {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .nav-link:not(.disabled), .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .btn:not(.disabled) {
    position: relative;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .nav-link:not(.disabled)::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item.show > .btn:not(.disabled)::before {
    width: calc(100% - 4px);
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar .navbar-nav > .nav > .nav-item > .nav-link:focus {
    outline: none;
  }
  .navbar .navbar-nav > .nav.nav-compact {
    height: 80%;
    min-height: 3.5rem;
  }
  .navbar .navbar-nav > .nav.nav-compact-2 {
    height: 65%;
    min-height: 3rem;
  }
  .navbar .navbar-nav > .nav.nav-compact-3 {
    height: 55%;
    min-height: 2.5rem;
  }
  .navbar .navbar-nav > .nav.nav-compact > .nav-item, .navbar .navbar-nav > .nav.nav-compact-2 > .nav-item, .navbar .navbar-nav > .nav.nav-compact-3 > .nav-item {
    border-width: 0 !important;
    max-height: 100%;
  }
  .navbar .navbar-nav > .nav.nav-compact > .nav-item > .nav-link, .navbar .navbar-nav > .nav.nav-compact > .nav-item > .btn, .navbar .navbar-nav > .nav.nav-compact-2 > .nav-item > .nav-link, .navbar .navbar-nav > .nav.nav-compact-2 > .nav-item > .btn, .navbar .navbar-nav > .nav.nav-compact-3 > .nav-item > .nav-link, .navbar .navbar-nav > .nav.nav-compact-3 > .nav-item > .btn {
    border-radius: 0.25rem;
  }
  .navbar .navbar-nav > .nav.border-0 > .nav-item {
    border-width: 0 !important;
  }
  .navbar .navbar-nav > .nav.border-0 > .nav-item > .nav-link::after {
    display: none;
  }
  .navbar .navbar-nav > .nav.has-active-border > .nav-item.border-0 > .nav-link::before, .navbar .navbar-nav > .nav.has-active-border > .nav-item.border-0 > .btn::before {
    display: none !important;
  }
  .navbar .navbar-nav .dropdown-menu {
    max-height: calc(100vh - 4.5rem - 0.25rem);
    max-height: calc(100vh - var(--navbar-height) - 0.25rem);
    overflow: hidden auto;
    margin-top: 0;
  }
  .navbar .navbar-nav .dropdown-menu.dropdown-caret {
    overflow: visible;
  }
  .navbar .navbar-nav > .nav:not(.has-active-border):not([class*="nav-compact"]) .dropdown-caret:not(.dropdown-menu) {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 1001;
    left: calc(50% - 0.25rem);
    bottom: -1px;
    border-width: 0 0.5rem 0.5rem;
    border-style: solid;
    border-bottom-color: #fff;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    opacity: 0;
    background-color: transparent;
    transform-origin: bottom center;
    transform: scale(0);
    transition: all 150ms;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .navbar-nav > .nav:not(.has-active-border):not([class*="nav-compact"]) .dropdown-caret:not(.dropdown-menu) {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav > .nav:not(.has-active-border):not([class*="nav-compact"]) > .nav-item.show .dropdown-caret:not(.dropdown-menu) {
    transform: none;
    opacity: 1;
  }
  .navbar .navbar-menu .navbar-links > .nav > .nav-item > .btn {
    border-bottom-width: 3px;
    border-radius: 0;
  }
}

@media (min-width: 992px) {
  .navbar .nav-user-name {
    display: block;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.navbar .dropdown-mega > .dropdown-menu {
  width: 100%;
  left: 0;
  right: 0;
  padding: 1.5rem;
}

.navbar .dropdown-mega > .dropdown-lg {
  width: 48rem;
  max-width: calc(100vw - 1rem);
  right: 0;
  left: auto;
}

.navbar .dropdown-mega > .dropdown-md {
  width: 36rem;
  max-width: calc(100vw - 1rem);
  right: 0;
  left: auto;
}

.navbar .dropdown-mega > .dropdown-sm {
  width: 28rem;
  max-width: calc(100vw - 1rem);
  right: 0;
  left: auto;
}

.navbar .dropdown-mega > .dropdown-xs {
  width: 20rem;
  max-width: calc(100vw - 1rem);
  right: 0;
  left: auto;
}

.navbar .dropdown-mega > .dropdown-xxs {
  width: 16rem;
  max-width: calc(100vw - 1rem);
  right: 0;
  left: auto;
}

.navbar .dropdown-mega.pos-rel > .dropdown-lg.dropdown-center {
  left: calc(-24rem + 50%);
}

.navbar .dropdown-mega.pos-rel > .dropdown-md.dropdown-center {
  left: calc(-18rem + 50%);
}

.navbar .dropdown-mega.pos-rel > .dropdown-sm.dropdown-center {
  left: calc(-14rem + 50%);
}

.navbar .dropdown-mega.pos-rel > .dropdown-xs.dropdown-center {
  left: calc(-10rem + 50%);
}

.navbar .dropdown-mega.pos-rel > .dropdown-xxs.dropdown-center {
  left: calc(-8rem + 50%);
}

.navbar .dropdown-mega.pos-rel > .dropdown-menu-right {
  left: auto;
  right: -2rem;
}

.navbar .dropdown.dropdown-mega {
  position: static;
}

.navbar .navbar-input {
  font-size: 0.95rem;
}

.navbar .navbar-input::placeholder {
  font-size: 0.9025rem;
}

@media (max-width: 991.98px) {
  .navbar .dropdown-caret:not(.dropdown-menu),
  .navbar .dropdown-caret.dropdown-menu::before,
  .navbar .dropdown-caret.dropdown-menu::after {
    display: none !important;
  }
  .navbar .navbar-intro {
    width: auto;
  }
  .navbar .navbar-toggler {
    align-self: center;
  }
  .navbar .navbar-collapse {
    position: absolute;
    z-index: 1020;
    transition-duration: 250ms;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: calc(100vh - 4rem - 3rem);
    max-height: calc(100vh - var(--navbar-mobile-height) - 3rem);
    overflow: hidden auto;
    overscroll-behavior: none;
    border-radius: 0;
    background-color: inherit;
  }
  .navbar .navbar-collapse > * {
    background-color: inherit;
  }
  .navbar.navbar-fixed .navbar-collapse {
    position: fixed;
    top: 4rem;
    top: var(--navbar-mobile-height);
    max-height: calc(100% - 4rem - 0.5rem);
    max-height: calc(100% - var(--navbar-mobile-height) - 0.5rem);
  }
  .navbar .navbar-backdrop::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    top: auto;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }
  .navbar .navbar-menu.navbar-hw {
    min-width: 50%;
    max-width: 100%;
  }
  .navbar .navbar-menu .navbar-nav > .nav {
    flex-direction: column;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    border-radius: 0;
    border-width: 0;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .badge, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .badge {
    margin-left: auto;
    margin-right: 0.75rem;
    top: auto;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .caret {
    margin-left: auto;
    margin-right: 0.25rem;
    transition: transform 150ms;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .caret {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .nav-link > .badge ~ .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item > .btn > .badge ~ .caret {
    margin-left: 0;
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .nav-link > .caret, .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .btn > .caret {
    transform: rotate(-180deg);
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .nav-link > .caret[class*="-left"], .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .btn > .caret[class*="-left"] {
    transform: rotate(-90deg);
  }
  .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .nav-link > .caret[class*="-right"], .navbar .navbar-menu .navbar-nav > .nav > .nav-item.show > .btn > .caret[class*="-right"] {
    transform: rotate(90deg);
  }
  .navbar .navbar-menu .navbar-nav > .nav .dropdown-menu {
    transform: none !important;
    visibility: visible;
    opacity: 1;
    display: none;
    margin: 0.25rem 0.5rem 0.5rem !important;
    border-width: 0;
    max-width: calc(100vw - 1rem);
    width: auto !important;
    box-shadow: none !important;
  }
  .navbar .navbar-menu .navbar-nav > .nav .dropdown-menu.show {
    display: block;
  }
  .navbar .navbar-menu .navbar-nav > .nav .dropdown-divider {
    margin: 0.5rem 1rem;
  }
  .navbar .navbar-menu .navbar-links > .nav > .nav-item > .btn {
    border-width: 0 0 0 0.25rem;
    border-radius: 0;
    margin-left: 1px;
  }
  .navbar-menu.navbar-collapse .dropdown[class*="backdrop"]::before {
    display: none;
  }
  .navbar-collapse [class*="icon-animated"] {
    animation: none !important;
  }
  body.mob-navbar-body, body.mob-dropdown-body {
    overflow-y: hidden;
  }
  body.mob-navbar-body .navbar-fixed {
    z-index: 1023;
  }
}

@media (max-width: 767.98px) {
  .navbar .navbar-menu.navbar-collapse .navbar-nav > .nav .dropdown-menu {
    margin: 0.25rem !important;
    max-width: calc(100vw - 0.5rem);
  }
}

.sticky-trigger {
  position: absolute;
  top: -1px;
  width: 0;
  height: 0;
  z-index: -1;
}

@media (max-width: 575.98px) {
  thead.sticky-nav-sm > tr > th, .sticky-nav-sm {
    position: sticky !important;
    top: 0;
    z-index: 997;
  }
}

@media (max-width: 767.98px) {
  thead.sticky-nav-md > tr > th, .sticky-nav-md {
    position: sticky !important;
    top: 0;
    z-index: 997;
  }
}

@media (max-width: 991.98px) {
  thead.sticky-nav-lg > tr > th, .sticky-nav-lg {
    position: sticky !important;
    top: 0;
    z-index: 997;
  }
}

@media (max-width: 1199.98px) {
  thead.sticky-nav-xl > tr > th, .sticky-nav-xl {
    position: sticky !important;
    top: 0;
    z-index: 997;
  }
}

thead.sticky-nav > tr > th, .sticky-nav {
  position: sticky !important;
  top: 0;
  z-index: 997;
}

.navbar-fixed + div .sticky-nav {
  top: 4.5rem;
  top: var(--navbar-height);
}

.navbar-fixed + div .sticky-nav .sticky-trigger {
  top: calc( -4.5rem - 1px);
  top: calc( -1 * var(--navbar-height) - 1px);
}

.navbar-fixed + div thead.sticky-nav > tr > th {
  top: 4.5rem;
  top: var(--navbar-height);
}

.navbar-sm.navbar-fixed + div .sticky-nav {
  top: 4rem;
  top: var(--navbar-sm-height);
}

.navbar-sm.navbar-fixed + div .sticky-nav .sticky-trigger {
  top: calc( -4rem - 1px);
  top: calc( -1 * var(--navbar-sm-height) - 1px);
}

.navbar-sm.navbar-fixed + div thead.sticky-nav > tr > th {
  top: 4rem;
  top: var(--navbar-sm-height);
}

.sticky-nav .is-stuck[class*="sticky-nav"] {
  z-index: 1018;
}

@media (max-width: 1199.98px) {
  .navbar-fixed + div [class*="sticky-nav"] {
    top: 4rem;
    top: var(--navbar-mobile-height);
  }
  .navbar-fixed + div [class*="sticky-nav"] .sticky-trigger {
    top: calc( -4rem - 1px);
    top: calc( -1 * var(--navbar-mobile-height) - 1px);
  }
  .navbar-fixed + div thead[class*="sticky-nav"] > tr > th {
    top: 4rem;
    top: var(--navbar-mobile-height);
  }
}

thead[class*="sticky-nav"] {
  position: static;
}

.sidebar {
  flex: 0 0 auto;
  width: 16rem;
  width: var(--sidebar-width);
  --active-border-offset-right: 0px;
  position: relative;
  z-index: 1020;
  visibility: hidden;
}

.navbar ~ .main-container > .sidebar:not(.sidebar-fixed) {
  z-index: 999;
}

.sidebar .sidebar-inner {
  width: inherit;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: inherit;
  visibility: visible;
  display: flex;
  flex-direction: column;
}

.sidebar .ace-scroll,
.sidebar .sidebar-section {
  width: 16rem;
  width: var(--sidebar-width);
  white-space: nowrap;
}

.sidebar .fadeable {
  transition: opacity 150ms 0s, visibility 0s 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .fadeable {
    transition: none;
  }
}

.sidebar .fadeinable {
  position: absolute;
  left: 0;
  width: 5rem;
  width: var(--sidebar-collapsed-width);
  min-height: 3rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 150ms 0s, visibility 0s 0s;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .fadeinable {
    transition: none;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sidebar .fadeinable {
    top: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-right {
    direction: rtl;
    order: 10;
  }
  .sidebar-right .sidebar-inner {
    direction: ltr;
  }
  .sidebar {
    transition: width 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.toggling {
    overflow: hidden;
  }
  .sidebar.toggling.collapsed .fadeable {
    transition-delay: 0s, 150ms;
  }
  .sidebar.toggling:not(.collapsed) .fadeable {
    transition-delay: 0s;
  }
  .sidebar.collapsed {
    width: 5rem;
    width: var(--sidebar-collapsed-width);
  }
  .sidebar.collapsed .fadeable {
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.collapsed .fadeinable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 150ms;
  }
}

@keyframes hideScrollbars {
  from {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.sidebar .ace-scroll {
  overflow-x: hidden;
  overscroll-behavior: none;
  -ms-scroll-chaining: none;
  flex-grow: 1;
}

.sidebar .ace-scroll:not(:hover) {
  animation: 2s hideScrollbars;
}

.sidebar .ace-scroll:hover, .sidebar .ace-scroll:focus-within {
  overflow-y: auto;
  overflow-y: overlay;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sidebar .ace-scroll {
    animation: none !important;
    overflow-y: auto;
    height: 50%;
  }
}

.sidebar .ace-scroll-mob {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  animation: none !important;
  overscroll-behavior: none;
  -ms-scroll-chaining: none;
}

@media (hover: none) {
  .sidebar .ace-scroll {
    overflow-x: hidden;
    overflow-y: auto;
    animation: none !important;
    overscroll-behavior: none;
    -ms-scroll-chaining: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .ace-scroll {
    animation: none !important;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable .ace-scroll,
  .sidebar.expandable .sidebar-section,
  .sidebar.expandable nav, .sidebar.expandable .nav {
    width: inherit;
  }
  .sidebar.expandable:not(.sidebar-spaced) .nav > .nav-item {
    width: inherit;
  }
  .sidebar.expandable .nav > .nav-item > .nav-link,
  .sidebar.expandable .nav > .nav-item > .submenu,
  .sidebar.expandable .sidebar-section-item {
    width: 16rem;
    width: var(--sidebar-width);
    white-space: nowrap;
  }
  .sidebar:not(.collapsed).toggling .sidebar-inner.is-hover:hover {
    width: 16rem;
    width: var(--sidebar-width);
  }
  .sidebar.collapsed.toggling .sidebar-inner.is-hover:hover {
    width: 16rem;
    width: var(--sidebar-width);
  }
  .sidebar.expandable.collapsed:not(.sidebar-fixed) .sidebar-inner {
    position: absolute;
    top: 0;
  }
  .sidebar.expandable.collapsed .sidebar-inner {
    transition: width 150ms ease-out 400ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .sidebar-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner .fadeable {
    transition-delay: 400ms, 550ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner .fadeinable {
    transition-delay: 400ms, 550ms;
  }
  .sidebar.expandable.collapsed .sidebar-inner .hideable {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0ms 400ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .sidebar-inner .hideable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner .hideable.fadeable {
    visibility: visible;
    transition: max-height 0ms 400ms, opacity 0ms 400ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed .sidebar-inner .hideable.fadeable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed.let-expanded .sidebar-inner:hover, .sidebar.expandable.collapsed.let-expanded .sidebar-inner.has-focus, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner:hover, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner.has-focus {
    width: 16rem;
    width: var(--sidebar-width);
    transition-delay: 80ms;
  }
  .sidebar.expandable.collapsed.let-expanded .sidebar-inner:hover .fadeable, .sidebar.expandable.collapsed.let-expanded .sidebar-inner.has-focus .fadeable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner:hover .fadeable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner.has-focus .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 80ms, 80ms;
  }
  .sidebar.expandable.collapsed.let-expanded .sidebar-inner:hover .fadeinable, .sidebar.expandable.collapsed.let-expanded .sidebar-inner.has-focus .fadeinable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner:hover .fadeinable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner.has-focus .fadeinable {
    opacity: 0;
    visibility: hidden;
    transition-delay: 80ms;
    transition-duration: 0ms;
  }
  .sidebar.expandable.collapsed.let-expanded .sidebar-inner:hover .hideable, .sidebar.expandable.collapsed.let-expanded .sidebar-inner.has-focus .hideable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner:hover .hideable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner.has-focus .hideable {
    max-height: 2000px;
    transition-delay: 80ms;
  }
  .sidebar.expandable.collapsed.let-expanded .sidebar-inner:hover .hideable.fadeable, .sidebar.expandable.collapsed.let-expanded .sidebar-inner.has-focus .hideable.fadeable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner:hover .hideable.fadeable, .sidebar.expandable.collapsed:not(.toggling):not(.let-expanded) .sidebar-inner.has-focus .hideable.fadeable {
    transition-delay: 80ms, 80ms;
    transition-duration: 0ms, 150ms;
  }
  .sidebar.expandable.collapsed.toggling .fadeable {
    transition-delay: 0ms, 150ms;
  }
  .sidebar.expandable.collapsed.toggling:not(.collapsed) .fadeable {
    transition-delay: 0ms , 0ms;
  }
  .sidebar.expandable.collapsed.toggling .fadeinable {
    transition-delay: 0ms , 0ms;
  }
  .sidebar.expandable.collapsed.toggling .sidebar-inner {
    transition: none;
  }
}

@keyframes navItemHidden {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(0);
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable:not(.collapsed):not(.sidebar-hover) .submenu,
  .sidebar.hoverable:not(.collapsed):not(.sidebar-hover) .nav > .nav-item > .nav-link > .nav-text, .sidebar:not(.hoverable):not(.sidebar-hover) .submenu,
  .sidebar:not(.hoverable):not(.sidebar-hover) .nav > .nav-item > .nav-link > .nav-text, .sidebar.toggling:not(.collapsed) .submenu,
  .sidebar.toggling:not(.collapsed) .nav > .nav-item > .nav-link > .nav-text {
    transform: none !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar .submenu,
  .sidebar .nav > .nav-item > .nav-link > .nav-text {
    transform: none !important;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable:not(.collapsed):not(.toggling) .ace-scroll,
  .sidebar.hoverable:not(.collapsed):not(.toggling) .sidebar-section,
  .sidebar.hoverable:not(.collapsed):not(.toggling) .sidebar-section-item,
  .sidebar.hoverable:not(.collapsed):not(.toggling) nav, .sidebar.hoverable:not(.collapsed):not(.toggling) .nav {
    width: inherit;
  }
  .sidebar.hoverable.collapsed .sidebar-inner,
  .sidebar.hoverable.collapsed .ace-scroll,
  .sidebar.hoverable.collapsed .ace-scroll-mob {
    overflow: visible;
    animation: none;
  }
  .sidebar.hoverable.collapsed .submenu-inner {
    overflow: visible;
  }
  .sidebar.hoverable.collapsed.toggling .sidebar-inner,
  .sidebar.hoverable.collapsed.toggling .submenu-inner,
  .sidebar.hoverable.collapsed.toggling .ace-scroll,
  .sidebar.hoverable.collapsed.toggling .ace-scroll-mob {
    overflow: hidden;
  }
  .sidebar.hoverable.collapsed .ace-scroll,
  .sidebar.hoverable.collapsed .sidebar-section {
    width: auto;
    white-space: nowrap;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item {
    width: 5rem;
    width: var(--sidebar-collapsed-width);
  }
  .sidebar.hoverable.collapsed.toggling .submenu,
  .sidebar.hoverable.collapsed.toggling .sub-arrow,
  .sidebar.hoverable.collapsed.toggling .nav > .nav-item > .nav-link > .nav-text {
    display: none;
  }
  .sidebar.hoverable.collapsed.toggling .sidebar-section .fadeable {
    display: none;
  }
  .sidebar.hoverable.collapsed.toggling .fadeinable {
    transition: none;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar.hoverable.collapsed .nav-item-caption .fadeable {
    animation: 1s navItemHidden;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    transition: margin 150ms, opacity 150ms, visibility 0ms;
    transition-delay: 600ms, 600ms, 750ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav-item:hover > .submenu,
  .sidebar.hoverable.collapsed .nav-item:hover > .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .nav-link > .nav-text,
  .sidebar.hoverable.collapsed .sidebar-section-item:hover .fadeable,
  .sidebar.hoverable.collapsed .nav-item-caption:hover .fadeable {
    animation-duration: 0ms;
    transition-delay: 100ms, 100ms, 0ms;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .submenu,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .nav-link > .nav-text {
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-inner:hover .nav-item:not(:hover) > .submenu,
  .sidebar.hoverable.collapsed .sidebar-inner:hover .nav-item:not(:hover) > .sub-arrow,
  .sidebar.hoverable.collapsed .sidebar-inner:hover .nav > .nav-item:not(:hover) > .nav-link > .nav-text {
    transition: none;
  }
  .sidebar.hoverable.collapsed .nav-item {
    position: relative;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    position: absolute !important;
    left: 100%;
    z-index: 2;
    margin-left: -11px;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.hoverable.collapsed .submenu,
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    top: 0;
    min-width: 14rem;
    min-width: calc(var(--sidebar-width) - 2rem);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #d1d1d1;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text .badge {
    margin-left: auto !important;
    float: none;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu {
    top: calc(100% - 3px);
    border-top-color: #e1e1e1;
  }
  .sidebar.hoverable.collapsed .nav-item .nav-item > .submenu {
    top: -0.125rem;
  }
  .sidebar.hoverable.collapsed .submenu-inner {
    border-width: 0 !important;
    margin: 0 0.125rem;
  }
  .sidebar.hoverable.collapsed .nav-item:hover > .submenu,
  .sidebar.hoverable.collapsed .nav-item:hover > .sub-arrow,
  .sidebar.hoverable.collapsed .nav > .nav-item:hover > .nav-link > .nav-text {
    margin-left: -2px;
    opacity: 1;
    visibility: visible;
  }
  .sidebar.hoverable.collapsed .nav-item > .submenu {
    display: block;
    height: auto !important;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu {
    border-radius: 0 0 0.375rem 0.375rem;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link.dropdown-toggle > .nav-text {
    border-radius: 0.375rem 0.375rem 0 0;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    border-radius: 0.375rem;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu .submenu {
    border-radius: 0.375rem;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeable, .sidebar.sidebar-h .sidebar-section-item .fadeable {
    position: absolute;
    left: 0;
    z-index: 2;
    min-height: inherit;
    min-width: 16rem;
    min-width: var(--sidebar-width);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    transition: opacity 150ms, visibility 0ms, background-color 0ms, box-shadow 0ms;
    transition-delay: 600ms, 750ms, 750ms, 750ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeable, .sidebar.sidebar-h .sidebar-section-item .fadeable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .sidebar-section-item[class*="fadeable-"] .fadeable, .sidebar.sidebar-h .sidebar-section-item[class*="fadeable-"] .fadeable {
    margin-left: -11px;
    transition: opacity 150ms, margin 150ms, visibility 0ms, background-color 0ms, box-shadow 0ms;
    transition-delay: 600ms, 600ms, 750ms, 750ms, 750ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hoverable.collapsed .sidebar-section-item[class*="fadeable-"] .fadeable, .sidebar.sidebar-h .sidebar-section-item[class*="fadeable-"] .fadeable {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .sidebar-section-item:hover .fadeable, .sidebar.sidebar-h .sidebar-section-item:hover .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item:focus-within .fadeable, .sidebar.sidebar-h .sidebar-section-item:focus-within .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left:hover .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left:hover .fadeable {
    margin-left: -2px;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left:focus-within .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left:focus-within .fadeable {
    margin-left: -2px;
    transition-delay: 0ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item .fadeinable, .sidebar.sidebar-h .sidebar-section-item .fadeinable {
    position: relative;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item:not(:hover) .fadeinable, .sidebar.sidebar-h .sidebar-section-item:not(:hover) .fadeinable {
    transition-delay: 0s, 600ms;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item:hover .fadeinable, .sidebar.sidebar-h .sidebar-section-item:hover .fadeinable {
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left .fadeable {
    left: 100%;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-left .fadeinable, .sidebar.sidebar-h .sidebar-section-item.fadeable-left .fadeinable {
    opacity: 1;
    visibility: visible;
    transition: none;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-top .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-top .fadeable {
    top: 0;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-bottom .fadeable, .sidebar.sidebar-h .sidebar-section-item.fadeable-bottom .fadeable {
    bottom: 0;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item .sub-arrow, .sidebar.sidebar-h .sidebar-section-item .sub-arrow {
    left: auto;
    right: 100%;
    opacity: 1;
    visibility: visible;
    margin-left: auto !important;
    transition: none;
    animation: none;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item .sub-arrow::after, .sidebar.sidebar-h .sidebar-section-item .sub-arrow::after {
    border-right-color: #fff;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-top .sub-arrow, .sidebar.sidebar-h .sidebar-section-item.fadeable-top .sub-arrow {
    top: 1.25rem;
  }
  .sidebar.hoverable.collapsed .sidebar-section-item.fadeable-bottom .sub-arrow, .sidebar.sidebar-h .sidebar-section-item.fadeable-bottom .sub-arrow {
    top: auto;
    bottom: 2.5rem;
  }
  .sidebar.hoverable.collapsed .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeable, .sidebar.hoverable.collapsed .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeinable, .sidebar.sidebar-h .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeable, .sidebar.sidebar-h .sidebar-inner:hover .sidebar-section-item:not(:hover) .fadeinable {
    transition: none;
  }
  .sidebar.hoverable.collapsed .nav-item-caption .fadeable, .sidebar.sidebar-h .nav-item-caption .fadeable {
    width: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret, .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret {
    transform: none;
  }
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-down"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-down"] {
    transform: rotate(-90deg) !important;
  }
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-up"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-up"] {
    transform: rotate(90deg) !important;
  }
  .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-left"], .sidebar.hoverable.collapsed .nav-item > .nav-link > .caret[class*="-right"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-left"], .sidebar-hover:not(.collapsed) .nav-item > .nav-link > .caret[class*="-right"] {
    transform: scaleX(-1) !important;
  }
  .sidebar-hover.toggling .sidebar-inner {
    overflow: hidden !important;
  }
  .sidebar-hover:not(.collapsed) .sidebar-inner,
  .sidebar-hover:not(.collapsed) .ace-scroll,
  .sidebar-hover:not(.collapsed) .ace-scroll-mob {
    overflow: visible;
    animation: none !important;
    animation-duration: 0ms;
  }
  .sidebar-hover:not(.collapsed) .submenu-inner {
    overflow: visible;
  }
  .sidebar-hover:not(.collapsed) .nav-item {
    position: relative;
  }
  .sidebar-hover:not(.collapsed) .nav-item .nav-item > .nav-link {
    padding-left: 1.5rem !important;
  }
  .sidebar-hover:not(.collapsed) .nav-item .nav-item > .nav-link > .nav-text::before {
    margin-left: 2px;
  }
  .sidebar-hover:not(.collapsed) .submenu,
  .sidebar-hover:not(.collapsed) .sub-arrow {
    animation: 1s navItemHidden;
    position: absolute !important;
    left: 100%;
    z-index: 2;
    margin-left: -11px;
    opacity: 0;
    visibility: hidden;
    transition: margin 150ms, opacity 150ms, visibility 0ms;
    transition-delay: 600ms, 600ms, 750ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar-hover:not(.collapsed) .submenu,
  .sidebar-hover:not(.collapsed) .sub-arrow {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable .sub-arrow {
    transition: none;
  }
  .sidebar-hover:not(.collapsed) .submenu {
    top: 0;
    min-width: 14rem;
    min-width: calc(var(--sidebar-width) - 2rem);
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #d1d1d1;
    border-radius: 0.375rem;
  }
  .sidebar-hover:not(.collapsed) .sub-arrow::after {
    border-right-color: #fff;
  }
  .sidebar-hover:not(.collapsed) .nav > .nav-item:hover > .submenu,
  .sidebar-hover:not(.collapsed) .nav > .nav-item:hover > .sub-arrow {
    transition-delay: 0ms;
  }
  .sidebar-hover:not(.collapsed) .sidebar-inner:hover .nav-item:not(:hover) > .submenu,
  .sidebar-hover:not(.collapsed) .sidebar-inner:hover .nav-item:not(:hover) > .sub-arrow {
    transition: none;
  }
  .sidebar-hover:not(.collapsed) .nav-item:hover > .submenu,
  .sidebar-hover:not(.collapsed) .nav-item:hover > .sub-arrow {
    margin-left: -2px;
    opacity: 1;
    visibility: visible;
    animation-duration: 0ms;
    transition-delay: 100ms, 100ms, 0ms;
  }
  .sidebar-hover:not(.collapsed) .nav-item > .submenu {
    display: block;
    height: auto !important;
  }
  .sidebar-hover:not(.collapsed) .nav > .nav-item .submenu {
    top: -0.125rem;
    background-color: #fff;
  }
  .sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu {
    top: -0.5rem;
  }
  .sidebar-hover:not(.collapsed) .submenu-inner {
    border-width: 0 !important;
    margin: 0 0.125rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sidebar-hover:not(.collapsed) .nav-item .nav-link:not(.dropdown-toggle) ~ .sub-arrow {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .sidebar.hideable {
    transition-duration: 150ms;
  }
  .sidebar.hideable .sidebar-inner {
    transition: width 150ms, visibility 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.hideable .sidebar-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.hideable:not(.collapsed):not(.toggling) .ace-scroll,
  .sidebar.hideable:not(.collapsed):not(.toggling) .sidebar-section,
  .sidebar.hideable:not(.collapsed):not(.toggling) .sidebar-section-item,
  .sidebar.hideable:not(.collapsed):not(.toggling) nav, .sidebar.hideable:not(.collapsed):not(.toggling) .nav {
    width: inherit;
  }
  .sidebar.hideable.collapsed {
    width: 0;
    overflow: hidden;
  }
  .sidebar.hideable.collapsed .sidebar-inner {
    visibility: hidden;
    width: 0;
    overflow: hidden;
    transition-delay: 0ms, 150ms;
    transition-duration: 150ms, 0ms;
  }
}

.sidebar .nav {
  flex-direction: column;
}

.sidebar .nav-item, .sidebar .nav-link {
  position: relative;
}

.sidebar .nav-link:focus {
  outline: none;
}

.sidebar .nav > .nav-item > .nav-link {
  display: flex;
  align-items: center;
  min-height: 3.75em;
  padding: 0.5em 0.5em 0.5em 1.5em;
  text-shadow: none;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 150ms, color 150ms;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sidebar .nav > .nav-item > .nav-link {
    height: 3.75em;
  }
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav > .nav-item > .nav-link {
    transition: none;
  }
}

.sidebar .nav > .nav-item > .nav-link::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 1px;
  top: 0;
  bottom: 0;
  border: 0px solid transparent;
  border-width: 0 0 0 0.25rem;
  transition: border-color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav > .nav-item > .nav-link::before {
    transition: none;
  }
}

.sidebar .nav.active-on-right > .nav-item > .nav-link::before {
  left: auto !important;
  right: 1px;
}

.sidebar .nav > .nav-item > .nav-link > .nav-icon {
  width: 1.25rem;
  margin-right: 0.75rem;
  margin-left: 0.25rem;
}

.sidebar .nav > .nav-item > .nav-link > .nav-icon:not(.fadeinable) {
  transition: color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav > .nav-item > .nav-link > .nav-icon:not(.fadeinable) {
    transition: none;
  }
}

.sidebar .nav > .nav-item > .nav-link > .nav-icon.nav-icon-round {
  width: 2.125rem;
  height: 2.125rem;
  margin-left: 0;
  border-radius: 2rem;
  color: #fff !important;
  font-size: 1rem;
  text-align: center;
  line-height: 2;
  padding-left: 1px;
}

.sidebar .nav-text {
  flex-grow: 1;
  white-space: normal;
  word-break: break-all;
  margin-right: 3px;
  display: flex;
  align-items: center;
}

.sidebar .nav-text .badge {
  margin-left: auto;
}

.sidebar .nav > .nav-item > .submenu {
  position: relative;
}

.sidebar .nav > .nav-item > .submenu > .submenu-inner {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.sidebar .nav > .nav-item > .submenu > .submenu-inner > .nav-item:not(:last-child) {
  margin-bottom: 1px;
}

.sidebar .submenu-inner {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  overflow-y: hidden;
}

.sidebar .submenu.collapsing {
  transition: height 0.35s ease !important;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .submenu.collapsing {
    transition: none !important;
  }
}

.sidebar .submenu .nav-link {
  min-height: 3rem;
}

.sidebar .submenu .submenu .nav-link {
  margin-top: 1px;
  margin-bottom: 1px;
}

.sidebar .submenu .nav-link {
  padding: 0.625rem 0 0.75rem 3rem;
  display: flex;
  align-items: center;
}

.sidebar .submenu .nav-icon {
  margin-right: 0.25rem;
  vertical-align: baseline;
  opacity: 0.85;
}

.sidebar .submenu .nav-link:hover > .nav-icon,
.sidebar .submenu .nav-item.active > .nav-link > .nav-icon {
  opacity: 1;
}

.sidebar .caret {
  margin-left: auto;
  margin-right: 1rem;
}

.sidebar .nav-item > .nav-link > .caret {
  transition: transform 150ms, color 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav-item > .nav-link > .caret {
    transition: none;
  }
}

.sidebar .nav-item.open > .nav-link > .caret {
  transform: scaleX(-1);
}

.sidebar .nav-item.open > .nav-link > .caret.rt-90, .sidebar .nav-item.open > .nav-link > .caret[class*="-right"] {
  transform: rotate(90deg);
}

.sidebar .nav-item.open > .nav-link > .caret.rt-n90, .sidebar .nav-item.open > .nav-link > .caret[class*="-left"] {
  transform: rotate(-90deg);
}

.sidebar .submenu .caret {
  margin-right: 1.5rem;
}

.sidebar .nav > .nav-item.is-toggling:not(.open):not(.active):not(:hover) > .nav-link {
  transition: none;
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav-item .nav-item > .nav-link {
    padding-left: 1.5rem;
  }
  .sidebar.hoverable.collapsed .nav-item .nav-item > .nav-link > .nav-text::before {
    margin-left: 2px;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .caret {
    display: none;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .submenu::before,
  .sidebar.hoverable.collapsed .nav-item .nav-item::before {
    display: none;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    padding: 0 0.75rem;
    color: #3d78b1;
    background-color: #f8f8f8;
    font-size: 0.9125rem;
    font-weight: 600;
  }
  .sidebar.hoverable.collapsed .nav > .nav-item > .nav-link.dropdown-toggle > .nav-text {
    cursor: default;
  }
  .sidebar.hoverable.collapsed.sidebar-spaced:not(.toggling) .nav > .nav-item {
    width: auto;
    overflow: visible;
  }
  .sidebar.hoverable.collapsed.sidebar-spaced:not(.toggling) .nav > .nav-item > .nav-link {
    border-radius: inherit;
    padding: 0;
    justify-content: center;
  }
  .sidebar.hoverable.collapsed.sidebar-spaced:not(.toggling) .nav > .nav-item > .nav-link > .nav-icon {
    margin: 0;
  }
  .sidebar.hoverable.collapsed .submenu {
    background-color: #fff;
  }
  .sub-arrow {
    position: absolute;
    visibility: hidden;
    top: calc(50% - 0.625rem);
    margin-top: -1px;
    box-shadow: none;
    border: none;
  }
  .sub-arrow::after, .sub-arrow::before {
    content: "";
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    border: 0.625rem solid transparent;
  }
  .sub-arrow::after {
    border-right-color: #f8f8f8;
    right: -2px;
  }
  .sub-arrow::before {
    border-right-color: #d1d1d1;
    right: -1px;
  }
  .nav-item .nav-item .sub-arrow::after {
    border-right-color: #fff;
  }
  .submenu-pullup .sub-arrow::after {
    border-right-color: #fff;
  }
  .sidebar.expandable:not(.sidebar-hover) .sub-arrow, .sidebar.collapsed:not(.hoverable) .sub-arrow {
    display: none !important;
  }
}

.sidebar .nav-link.dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item-caption {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0.325rem;
  margin: 0.5rem 0rem;
}

.sidebar .nav-item + .nav-item-caption {
  margin-top: 2rem;
}

.sidebar .nav-item-divider {
  margin: 0.5rem;
}

.sidebar .submenu .nav-item-divider {
  margin: 0.25rem 0.75rem 0.25rem 3rem;
}

.sidebar .submenu .submenu .nav-item-divider {
  margin-left: 4rem;
}

@media (min-width: 1200px) {
  .sidebar.collapsed .sidebar-inner:not(.is-hover) .nav-item-divider.hideable {
    margin: 0;
    visibility: hidden;
  }
  .sidebar.collapsed .sidebar-inner:not(.is-hover) .nav-item + .nav-item-caption.hideable {
    margin-top: 0;
  }
  .sidebar.collapsed.hoverable .submenu .nav-item-divider {
    margin: 0.5rem 1rem;
  }
}

.sidebar-section-item {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}

.sidebar-shortcuts-mini .btn {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  padding: 0;
  margin: 0 1px;
}

.sidebar-search-input {
  width: 12rem;
  width: calc(var(--sidebar-width) - 4rem);
  height: 2rem;
  line-height: 2;
  background-color: transparent;
  border-width: 0;
  border-bottom: 1px solid transparent;
}

.sidebar-search-input:focus {
  outline: none;
}

.sidebar-search-input::placeholder {
  font-size: 0.925rem;
  line-height: 2;
}

/**
@if $sidebar-focus-expand != true and $expandable == true {
    @include media-breakpoint-up($sidebar-desktop-min-width) {
        .sidebar.expandable {
            .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
                @include transition(font-size 0ms 0ms);
            }
            &.collapsed:not(:hover) .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
                font-size: 0;//don't remove, it's important
                transition-delay: $expandable-hide-delay;
            }

            &.collapsed:hover .sidebar-inner:not(.has-focus) .sidebar-search-input:focus {
                transition-delay: $expandable-hide-delay / 2;
            }
        }
    }
}
*/
@media (max-width: 1199.98px) {
  .sidebar {
    max-width: 0;
    visibility: hidden;
  }
  .sidebar .sidebar-inner {
    visibility: visible;
    transform: translateX(-100%) translateX(-1px);
    transition: transform 300ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar .sidebar-inner {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar:not(.sidebar-fixed) .sidebar-inner {
    position: relative;
    top: 0;
    overflow-y: auto;
    height: auto;
    max-height: 0;
    transition: transform 300ms 0ms, max-height 0ms 300ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar:not(.sidebar-fixed) .sidebar-inner {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar .ace-scroll,
  .sidebar .sidebar-section-item {
    width: auto;
  }
  .sidebar-visible .sidebar-inner {
    transform: translateX(0);
  }
  .sidebar-visible:not(.sidebar-fixed) .sidebar-inner {
    transition-delay: 0s !important;
    max-height: 1000vh;
  }
  .sidebar-push:not(.sidebar-fixed) .sidebar-inner {
    height: 100%;
    max-height: none;
  }
  .sidebar:not(.sidebar-push) .sidebar-inner .nav-item:last-child::after {
    display: none;
  }
  .sidebar-push {
    transition: max-width 300ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-push {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-push.sidebar-visible {
    max-width: 16rem;
    max-width: var(--sidebar-width);
  }
  .sidebar-push.sidebar-visible ~ .main-content {
    min-width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
  .sidebar-backdrop::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    display: block;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 150ms 0ms, visibility 0ms 150ms;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-backdrop::before {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-visible.sidebar-backdrop::before,
  .sidebar-visible .sidebar-backdrop::before {
    visibility: visible;
    pointer-events: all;
    opacity: 0.4;
    transition-delay: 0s;
  }
  .sidebar.sidebar-spaced .nav > .nav-item {
    width: auto;
    margin-right: 0.875rem;
  }
  body.mob-sidebar-body, body.mob-sidebarswipe-body {
    overflow-y: hidden;
  }
  body.mob-sidebar-body .body-container, body.mob-sidebarswipe-body .body-container {
    overflow: hidden;
  }
  .sidebar-visible [data-toggle-mobile="sidebar"] {
    pointer-events: auto !important;
  }
}

@media (max-width: 991.98px) {
  .sidebar.sidebar-push {
    max-width: 0;
    min-width: 0;
    width: 0;
    transition: width 300ms, min-width 300ms, max-width 300ms;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .sidebar.sidebar-push {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .sidebar.sidebar-push.sidebar-visible {
    width: 75vw;
    max-width: 320px;
    min-width: 16rem;
    min-width: var(--sidebar-width);
  }
  .sidebar .sidebar-inner {
    width: 75vw;
    max-width: 320px;
    min-width: 16rem;
    min-width: var(--sidebar-width);
  }
  .sidebar .sidebar-inner .ace-scroll,
  .sidebar .sidebar-inner .sidebar-section,
  .sidebar .sidebar-inner .sidebar-search-input {
    width: auto;
  }
  .sidebar .sidebar-inner .sidebar-search-input {
    max-width: calc( 100% - 1rem);
  }
}

@media (min-width: 1200px) {
  .sidebar.sidebar-h {
    transition: none;
    min-height: auto;
    height: 4.25rem;
    height: var(--sidebar-horizontal-height);
    width: 100%;
  }
  .sidebar.sidebar-h .sidebar-inner {
    top: auto !important;
    bottom: auto !important;
    flex-direction: row;
    overflow: visible;
    height: inherit !important;
  }
  .sidebar.sidebar-h .ace-scroll {
    overflow: visible !important;
    width: auto;
    height: auto;
  }
  .sidebar.sidebar-h .nav {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .sidebar.sidebar-h .nav > .nav-item {
    width: auto;
    margin-bottom: 0 !important;
    margin: 0 1px;
    position: relative;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link {
    width: auto;
    height: 100%;
    padding-left: 0.75em;
    padding-right: 0.75em;
    position: relative;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link .nav-icon {
    margin-right: 0.5em;
    margin-left: 0.125em;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link .nav-text {
    flex-grow: 0;
    margin-right: 0;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link .nav-text .badge {
    float: none;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link > .caret {
    display: none;
  }
  .sidebar.sidebar-h .nav > .nav-item.active > .nav-link {
    font-weight: 600;
  }
  .sidebar.sidebar-h .nav.nav-link-rounded > .nav-item > .nav-link {
    border-radius: 0.25em 0.25em 0 0;
  }
  .sidebar.sidebar-h .nav-fill {
    width: 100%;
  }
  .sidebar.sidebar-h .nav-fill.text-center > .nav-item > .nav-link {
    justify-content: center;
  }
  .sidebar.sidebar-h .nav-fill .nav-item .nav-item {
    text-align: left;
  }
  .sidebar.sidebar-h .nav-item-caption, .sidebar.sidebar-h .nav-item-divider {
    display: none;
  }
  .sidebar.sidebar-h .nav > .nav-item > .submenu {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    min-width: 15rem;
    min-width: calc(var(--sidebar-width) - 1rem);
    margin-left: 0;
    margin-top: -11px;
  }
  .sidebar.sidebar-h .nav > .nav-item:hover > .submenu {
    margin-left: 0 !important;
    margin-top: -2px !important;
  }
  .sidebar.sidebar-h .nav > .nav-item > .nav-link::before {
    top: auto !important;
    bottom: 1px !important;
    left: 1px !important;
    right: 1px !important;
    border-width: 3px 0 0 0;
  }
  .sidebar.sidebar-h .nav.active-on-right > .nav-item > .nav-link::before {
    bottom: auto !important;
    top: 1px !important;
    left: 0 !important;
    right: 0 !important;
    border-width: 0 0 3px 0;
  }
  .sidebar.sidebar-h .nav.has-active-border > .nav-item.active::before, .sidebar.sidebar-h .nav.has-active-border > .nav-item.active > .nav-link::before {
    left: 0 !important;
    right: 0 !important;
    bottom: 1px !important;
    top: auto;
    border-width: 3px 0 0 0;
  }
  .sidebar.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item.active::before, .sidebar.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item > .nav-link::before {
    top: 0 !important;
  }
  .sidebar.sidebar-h .nav.nav-active-sm > .nav-item > .nav-link::before,
  .sidebar.sidebar-h .nav.nav-active-sm > .nav-item.active > .nav-link::before {
    left: 10% !important;
    right: 10% !important;
  }
  .sidebar.sidebar-h .nav.nav-spaced > .nav-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sidebar.sidebar-h .nav.nav-spaced > .nav-item > .nav-link {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .sidebar.sidebar-h .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    display: block !important;
    top: auto;
    bottom: -1px;
    right: auto;
    left: calc(50% - 0.625rem);
    border-right: 0.625rem solid transparent;
    border-left: 0.625rem solid transparent;
    border-top-width: 0;
    border-bottom: 0.625rem solid #fff;
    z-index: 1;
  }
  .sidebar.sidebar-h .nav.has-active-arrow .nav-item .nav-item.active > .nav-link::after {
    display: none !important;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow {
    top: calc(100% - 1.25rem + 1px);
    bottom: auto;
    left: 1rem !important;
    right: auto;
    margin-left: 0 !important;
    margin-top: -11px;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::after, .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::before, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::after, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::before {
    border: 0.625rem solid;
    border-color: transparent;
    right: auto;
    border-right-color: transparent !important;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::after, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::after {
    border-bottom-color: #d1d1d1;
    top: -1px;
    z-index: 0;
  }
  .sidebar.sidebar-h .nav > .nav-item > .sub-arrow::before, .sidebar.sidebar-h .sidebar-section-item.fadeable-below .sub-arrow::before {
    border-bottom-color: #fff;
    z-index: 1;
  }
  .sidebar.sidebar-h .nav.has-active-arrow > .nav-item > .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
  .sidebar.sidebar-h:not(.sidebar-hover) .nav > .nav-item.open > .sub-arrow {
    display: block;
  }
  .sidebar.sidebar-h.sidebar-hover .nav > .nav-item:hover > .sub-arrow {
    margin-top: -2px;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-below .fadeable .sub-arrow {
    top: calc(-1.25rem + 1px);
    margin-top: 0 !important;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-right .fadeable .sub-arrow {
    left: auto !important;
    right: 2rem;
  }
  .sidebar.sidebar-h .nav > .nav-item:last-of-type > .submenu, .sidebar.sidebar-h .nav > .nav-item.submenu-right > .submenu, .sidebar.sidebar-h .nav > .nav-item:last-of-type > .submenu, .sidebar.sidebar-h .nav > .nav-item.submenu-right > .submenu {
    left: auto !important;
    right: 0 !important;
  }
  .sidebar.sidebar-h .nav:not(.has-active-arrow) > .nav-item:last-of-type > .sub-arrow, .sidebar.sidebar-h .nav:not(.has-active-arrow) > .nav-item.submenu-right > .sub-arrow {
    left: auto !important;
    right: 2rem !important;
  }
  .sidebar.sidebar-h .sidebar-section {
    width: auto;
    display: inline-flex;
    flex-direction: row;
  }
  .sidebar.sidebar-h .sidebar-section-item .fadeable {
    animation: 1s navItemHidden;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-below .fadeable {
    top: 100% !important;
    left: 0 !important;
    margin-left: 0 !important;
    margin-top: -11px;
  }
  .sidebar.sidebar-h .sidebar-section-item.fadeable-right .fadeable {
    left: auto !important;
    right: 0;
  }
  .sidebar.sidebar-h .sidebar-section-item .fadeinable {
    opacity: 1 !important;
    visibility: visible !important;
    transition: none;
  }
  .sidebar.sidebar-h .sidebar-section-item:hover .fadeable {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
    margin-left: 0 !important;
    margin-top: -2px;
  }
  .sidebar.sidebar-h .sidebar-section-item:focus-within .fadeable {
    margin-top: -2px;
  }
  .sidebar.sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:not(:first-child) .fadeable,
  .sidebar.sidebar-h .sidebar-section:not(:first-child) .sidebar-section-item.fadeable-center .fadeable {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .sidebar.sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:not(:first-child) .fadeable .sub-arrow,
  .sidebar.sidebar-h .sidebar-section:not(:first-child) .sidebar-section-item.fadeable-center .fadeable .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
  .sidebar-h.collapsed-h {
    height: 4rem;
    height: var(--sidebar-horizontal-collapsed-height);
  }
  .sidebar-h.collapsed-h .nav > .nav-item > .nav-link > .nav-text {
    display: none;
  }
  .sidebar-h.collapsed-h .nav > .nav-item > .nav-link > .nav-icon {
    margin: 0 0.625em;
  }
  .navbar:not(.navbar-fixed) ~ .sidebar-h.sidebar-fixed, .navbar:not(.navbar-fixed) ~ .main-container .sidebar-h.sidebar-fixed {
    position: sticky;
    top: 0;
  }
  .navbar:not(.navbar-fixed) ~ .sidebar-h.sidebar-fixed .sidebar-inner, .navbar:not(.navbar-fixed) ~ .main-container .sidebar-h.sidebar-fixed .sidebar-inner {
    position: relative;
  }
  .main-container .sidebar-h.sidebar-fixed {
    position: sticky;
    top: 0;
  }
  .main-container .sidebar-h.sidebar-fixed .sidebar-inner {
    position: relative;
  }
  .navbar-fixed ~ .main-container .sidebar-h.sidebar-fixed, .main-container .navbar-fixed ~ .sidebar-h.sidebar-fixed {
    top: 4.5rem;
    top: var(--navbar-height);
  }
  .navbar-fixed.navbar-sm ~ .main-container .sidebar-h.sidebar-fixed, .main-container .navbar-fixed.navbar-sm ~ .sidebar-h.sidebar-fixed {
    top: 4rem;
    top: var(--navbar-sm-height);
  }
  .sidebar-h.sidebar-fixed ~ .main-container .sidebar-fixed .sidebar-inner {
    margin-top: 4.25rem;
    margin-top: var(--sidebar-horizontal-height);
  }
  .collapsed-h.sidebar-fixed ~ .main-container .sidebar-fixed .sidebar-inner {
    margin-top: 4rem;
    margin-top: var(--sidebar-horizontal-collapsed-height);
  }
}

@media (min-width: 1600px) {
  .container .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable, .sidebar-h .container .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .container .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow, .sidebar-h .container .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
}

@media (min-width: 1750px) {
  .container-plus .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable, .sidebar-h .container-plus .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .container-plus .sidebar-h .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow, .sidebar-h .container-plus .sidebar-section:first-child .sidebar-section-item.fadeable-center:first-child .fadeable .sub-arrow {
    left: calc(50% - 0.625rem) !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-h .nav.nav-fill .nav-item {
    flex: unset;
    text-align: left;
  }
  .sidebar-h .container {
    height: 100%;
  }
}

.nav.has-active-arrow .nav-item.active > .nav-link::after {
  content: "";
  display: none;
  position: absolute;
  z-index: 0;
  width: 0;
  height: 0;
  margin: 0;
  right: 0;
  top: calc(50% - 0.625rem);
  border-right: 0.625rem solid #f2f5f8;
  border-left: none;
  border-top: 0.625rem solid transparent;
  border-bottom: 0.625rem solid transparent;
}

.nav.has-active-arrow .nav-item.active:not(.open) > .nav-link::after {
  display: block;
}

.nav.has-active-arrow .nav-item.active.open .nav-item.active:not(.open) > .nav-link::after {
  display: block;
}

.nav.has-active-arrow .nav-item.active:not(.open) .nav-item.active:not(.open) > .nav-link::after {
  display: none;
}

@media (min-width: 1200px) {
  .sidebar.expandable.sidebar-spaced:not(.collapsed) .nav.has-active-arrow .nav-item.active > .nav-link::after, .sidebar.expandable.sidebar-spaced.toggling .nav.has-active-arrow .nav-item.active > .nav-link::after {
    display: none !important;
  }
  .sidebar.expandable.collapsed:not(.toggling) .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    right: auto !important;
    left: 4.375rem;
    left: calc( var(--sidebar-collapsed-width) - 0.625rem);
  }
  .sidebar.expandable.collapsed:not(.toggling).sidebar-spaced .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    margin-left: calc( -0.4375rem - 2px);
  }
  .sidebar.expandable.collapsed .nav.has-active-arrow .nav-item .nav-item > .nav-link::after {
    display: none !important;
  }
  .sidebar.expandable.collapsed:not(.toggling) .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    transition: visibility 0s 550ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.expandable.collapsed:not(.toggling) .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    display: block;
  }
  .sidebar.expandable.collapsed .sidebar-inner:hover .nav.has-active-arrow > .nav-item.active > .nav-link::after, .sidebar.expandable.collapsed .sidebar-inner.has-focus .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    visibility: hidden;
    transition-delay: 80ms;
  }
  .sidebar.hoverable.collapsed .nav.has-active-arrow > .nav-item.active > .nav-link::after {
    display: block;
    right: -1px;
  }
  .sidebar.hoverable.collapsed .nav.has-active-arrow .nav-item .nav-item.active > .nav-link::after {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar:not(.sidebar-push) .nav.has-active-arrow .nav-item.active > .nav-link::after,
  .sidebar.sidebar-backdrop .nav.has-active-arrow .nav-item.active > .nav-link::after {
    display: none !important;
  }
}

.main-container.bgc-white .nav.has-active-arrow .nav-item.active > .nav-link::after {
  border-right-color: #fff;
}

.nav.has-active-border > .nav-item.active::before {
  position: absolute;
  z-index: 1;
  left: 1px;
  top: 0;
  bottom: 0;
  border: 0px solid transparent;
  border-left-width: 0.25rem;
}

.nav.has-active-border > .nav-item.active[class*="brc-"]::before {
  border-left-color: inherit;
}

.nav.has-active-border .nav-item.active > .nav-link::before {
  position: absolute;
  z-index: 1;
  left: 1px;
  top: 0;
  bottom: 0;
  border: 0px solid transparent;
  border-left-width: 0.25rem;
}

.nav.has-active-border .nav-item.active.open > .nav-link::before {
  display: none;
}

.nav.has-active-border .nav-item.active:not(.open) .nav-item > .nav-link::before {
  display: none;
}

@media (min-width: 1200px) {
  .sidebar.expandable.collapsed .sidebar-inner .nav.has-active-border > .nav-item.active > .nav-link::before {
    display: block;
  }
  .sidebar.expandable.collapsed .sidebar-inner.is-hover .nav.has-active-border > .nav-item.active.open > .nav-link::before, .sidebar.expandable.collapsed .sidebar-inner.has-focus .nav.has-active-border > .nav-item.active.open > .nav-link::before {
    visibility: hidden;
  }
  .sidebar.expandable.collapsed .sidebar-inner.is-hover .nav.has-active-border > .nav-item.active:not(.open) > .nav-link::before, .sidebar.expandable.collapsed .sidebar-inner.has-focus .nav.has-active-border > .nav-item.active:not(.open) > .nav-link::before {
    visibility: visible;
  }
}

.nav.has-active-border.active-on-top .nav-item.active::before {
  display: none !important;
}

.nav.has-active-border.active-on-top .nav-item.active > .nav-link::before {
  display: block !important;
  visibility: visible !important;
}

.nav.has-active-border.active-on-top .nav-item .nav-item.active > .nav-link::before {
  display: none !important;
}

@media (min-width: 1200px) {
  .sidebar.hoverable.collapsed .nav.has-active-border > .nav-item.active > .nav-link::before {
    display: block !important;
    visibility: visible !important;
  }
  .sidebar.hoverable.collapsed .nav.has-active-border .nav-item .nav-item.active > .nav-link::before {
    display: none !important;
  }
}

.nav.has-active-border.active-on-right .nav-item.active > .nav-link::before,
.nav.has-active-border.active-on-right > .nav-item.active::before {
  left: auto !important;
}

@media (min-width: 1200px) {
  .collapsed:not(.toggling) .sidebar-inner:not(.is-hover) .nav.active-on-right .nav-item.active > .nav-link::before,
  .collapsed:not(.toggling) .sidebar-inner:not(.is-hover) .nav.active-on-right > .nav-item.active::before {
    left: 5rem !important;
    left: var(--sidebar-collapsed-width) !important;
    margin-left: calc(-0.25rem - var(--active-border-offset-right));
    right: auto;
  }
}

.sidebar-spaced .nav.has-active-arrow .nav-item.active > .nav-link::after {
  display: none !important;
}

@media (min-width: 1200px) {
  .sidebar-spaced.sidebar-color.hoverable.collapsed .nav.has-active-border > .nav-item.active > .nav-link::before {
    display: none !important;
  }
}

.sidebar-spaced .nav > .nav-item {
  width: 14.25rem;
  width: calc( var(--sidebar-width) - 1.75rem);
  margin-left: 0.875rem;
  border-radius: 0.325rem;
  overflow: hidden;
  margin-bottom: 0.25rem;
  pointer-events: none;
}

.sidebar-spaced .nav > .nav-item::before, .sidebar-spaced .nav > .nav-item::after {
  display: none !important;
}

.sidebar-spaced .nav > .nav-item > .nav-link,
.sidebar-spaced .nav > .nav-item > .submenu {
  max-width: 100%;
  pointer-events: auto;
}

.sidebar-spaced .nav > .nav-item > .nav-link {
  min-height: 3.125em;
}

.sidebar-spaced .nav > .nav-item > .nav-link > .nav-icon {
  margin-left: 0;
}

.sidebar-spaced .nav > .nav-item > .submenu {
  border-width: 0;
}

.sidebar-spaced .caret {
  margin-right: 0.25rem;
}

.sidebar-spaced .submenu .caret {
  margin-right: 0.875rem;
}

.sidebar-spaced .submenu .nav-link {
  margin-left: 2px;
  margin-right: 2px;
}

.sidebar-spaced.sidebar-color .nav > .nav-item {
  transition: none;
  border-radius: 0.5rem;
}

@media (min-width: 1200px) {
  .sidebar-spaced.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    margin-left: 0.4375rem;
  }
  .sidebar-spaced.hoverable.collapsed .nav > .nav-item {
    margin-left: 0.5775rem;
    margin-right: 0.5775rem;
  }
  .sidebar-spaced.hoverable.collapsed .nav > .nav-item > .submenu {
    border-width: 1px;
  }
}

@media (min-width: 1200px) {
  .sidebar-spaced.sidebar.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item > .nav-link > .nav-icon-round {
    margin-left: -0.625rem;
  }
  .sidebar-spaced.expandable .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
  .sidebar-spaced.hoverable:not(.collapsed) .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
  .sidebar-spaced.hideable .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-spaced .nav > .nav-item:not(.open):not(.active):not(:hover) > .submenu {
    background-color: transparent;
  }
}

/**
// If you want to use a dark sidebar like "purple", you should also include "sidebar-color".
// Except for `.sidebar-dark` and `.sidebar-dark2`
$my-sidebar-themes: (
    "color": $sidebar-color, // include before 'purple'
    "purple": $sidebar-purple
);
*/
.navbar-blue {
  background-color: #2470bd;
}

.navbar-blue .navbar-toggler:hover, .navbar-blue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-blue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-blue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.navbar-blue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-blue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-blue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-blue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-blue .navbar-intro {
    background-color: #2166ac;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item.show,
  .navbar-blue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-blue .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-blue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-blue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-blue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-blue .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-blue .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-blue .navbar-input::-moz-placeholder {
  color: #fff;
}

.sidebar-light {
  background-color: #f1f3f6;
  --active-border-offset-right: 0px;
}

.sidebar-light .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0 #e2e5e8;
}

.sidebar-light.toggling.has-open:not(.collapsed) .sidebar-inner {
  border-right: 1px solid #e2e5e8;
}

@media (min-width: 1200px) {
  .sidebar-light:not(.sidebar-h) .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-light:not(.sidebar-h) .nav > .nav-item.active:not(.open) > .nav-link,
  .sidebar-light.collapsed:not(.sidebar-h) .sidebar-inner:not(.is-hover) .nav.active-on-right > .nav-item.active > .nav-link {
    left: 1px;
  }
  .sidebar-light:not(.sidebar-h) .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-light:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav {
    left: auto;
  }
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav .nav-item > .nav-link {
    left: auto;
  }
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border > .nav-item > .nav-link::before,
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-h .nav > .nav-item.active > .nav-link {
    top: 1px;
  }
  .sidebar-light.sidebar-h .nav.active-on-right > .nav-item::before, .sidebar-light.sidebar-h .nav.active-on-right > .nav-item > .nav-link::before {
    bottom: auto !important;
    top: 0 !important;
  }
  .sidebar-light.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item::before, .sidebar-light.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item > .nav-link::before {
    top: -1px !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-light.sidebar-push .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-light.sidebar-push .nav > .nav-item.active:not(.open) > .nav-link {
    left: 1px;
  }
  .sidebar-light.sidebar-push .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-light.sidebar-push .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
}

.sidebar-light .nav.active-on-right .nav-item.active .nav-link::before {
  right: 0px;
}

@media (max-width: 1199.98px) {
  .sidebar-light:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #d7dbdf;
  }
  .sidebar-light:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #d7dbdf;
  }
}

.sidebar-light .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-light .nav > .nav-item > .nav-link {
  color: #4e4e4e;
  font-size: 0.975rem;
}

.sidebar-light .nav > .nav-item > .nav-link > .nav-icon {
  color: #5c5c5c;
  font-size: 1.2em;
}

.sidebar-light .nav > .nav-item.open > .nav-link {
  color: #313131;
}

.sidebar-light.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e2e5ea;
}

.sidebar-light .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #4a4a4a;
}

.sidebar-light .nav > .nav-item > .nav-link:hover {
  background-color: #e2e5ea;
  color: #2e2f30;
}

.sidebar-light .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #454647;
}

.sidebar-light .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-light .submenu .nav-link {
  color: #616161;
  font-size: 0.95rem;
}

.sidebar-light .submenu .nav-item.open > .nav-link {
  color: #1f74b6;
}

.sidebar-light .submenu .nav-item > .nav-link:hover {
  color: #0f62a6;
  background-color: #e3eff9;
}

.sidebar-light .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-light .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-light .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-light .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-light .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-light .open > .nav-link > .caret {
  color: #6b6b6c;
}

.sidebar-light .nav-item > .nav-link:hover > .caret {
  color: #6b6b6c;
}

.sidebar-light .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-light .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

.sidebar-light .nav > .nav-item.active {
  border: 1px solid #e1e4e7;
  border-width: 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-light .nav > .nav-item.active > .nav-link {
  color: #2f73b2;
  background-color: #fff;
  font-weight: 600;
}

.sidebar-light .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #427fb8;
}

.sidebar-light .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
}

.sidebar-light .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-light .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #e3eff9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-light.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    background-color: #e3eff9;
    font-weight: 600;
  }
  .sidebar-light.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-light .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4b95ce;
  top: -1px;
  bottom: -1px;
}

.sidebar-light .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-light .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-light .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-light .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-light .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-light .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-light .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-light .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-light.hoverable.collapsed .submenu .nav-text::before, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-light .nav-item-caption {
  color: #65707c;
  font-size: 0.875rem;
}

.sidebar-light .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-light .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-light .nav > .nav-item > .submenu > .submenu-inner {
  border: 1px solid #e1e4e7;
  border-width: 1px 0;
}

.sidebar-light .nav > .nav-item.active > .submenu > .submenu-inner {
  border-bottom-width: 0;
}

.sidebar-light .nav > .nav-item.open + .nav-item.active,
.sidebar-light .nav > .nav-item.is-toggling + .nav-item.active {
  margin-top: -2px;
}

.sidebar-light .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-light .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f1f3f6;
}

.sidebar-light .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f1f3f6;
}

.sidebar-light.sidebar-spaced .nav {
  margin-left: 0;
}

.sidebar-light.sidebar-spaced .nav > .nav-item.active:not(.open) > .nav-link {
  left: auto;
}

.sidebar-light.sidebar-spaced .nav > .nav-item.active {
  border-width: 1px;
  border-color: #d0d5d9;
}

@media (min-width: 1200px) {
  .sidebar-light.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #4e4e4e;
    background-color: transparent;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #5c5c5c;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-light.hoverable.collapsed .nav > .nav-item.active.open {
    border-width: 1px 0;
    transition: none;
  }
}

.sidebar-light .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-light .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-light .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-light .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .submenu .submenu,
  .sidebar-color.hoverable.collapsed .submenu-inner,
  .sidebar-color.hoverable.collapsed .submenu .nav-item {
    background-color: inherit !important;
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    color: rgba(255, 255, 255, 0.93) !important;
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    top: calc(100% - 2px);
  }
  .sidebar-color.hoverable.collapsed .sub-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.33) !important;
  }
  .sidebar-color.hoverable.collapsed .sub-arrow::after {
    border-right-color: inherit !important;
  }
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable {
    border: 1px solid;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
  .sidebar-color.hoverable.collapsed .submenu,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable {
    border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.hoverable.collapsed .submenu .nav-text::before {
    display: none;
  }
  .sidebar[class*="sidebar-gradient"]:not(.collapsed) .sidebar-color {
    background-color: transparent;
    background-image: none;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .submenu,
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu-inner,
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-item {
    background-color: inherit !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.33) !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow::after {
    border-right-color: inherit !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu {
    border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    display: none;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .submenu,
  .sidebar-color.sidebar-h .sidebar-section-item .fadeable {
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13);
    box-shadow: none !important;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .sub-arrow::before,
  .sidebar-color.sidebar-h .sidebar-section-item .sub-arrow::before {
    border-right-color: transparent !important;
    border-bottom-color: inherit !important;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .sub-arrow::after,
  .sidebar-color.sidebar-h .sidebar-section-item .sub-arrow::after {
    border-right-color: transparent !important;
    border-bottom-color: rgba(255, 255, 255, 0.33) !important;
  }
}

.footer {
  height: 4.75rem;
  width: 100%;
  margin-top: 1.25rem;
  direction: rtl;
}

.footer-sm {
  height: 3.625rem;
}

.footer-inner {
  text-align: center;
  height: inherit;
  direction: ltr;
  padding-right: 0;
  padding-left: 0;
}

.footer-fixed .footer-inner {
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
  z-index: 998;
  width: 100%;
}

@media (min-width: 1200px) {
  .container .main-container, .container .main-content, .container .footer-fixed {
    max-width: inherit;
  }
  .container .footer-fixed .footer-inner {
    max-width: inherit;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .footer-fixed .footer-inner {
    padding-left: 16rem;
    padding-left: var(--sidebar-width);
  }
  .sidebar.toggling ~ .main-content .footer-fixed .footer-inner {
    transition: padding-left 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar.toggling ~ .main-content .footer-fixed .footer-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.collapsed ~ .main-content .footer-fixed .footer-inner {
    padding-left: 5rem;
    padding-left: var(--sidebar-collapsed-width);
  }
  .sidebar.hideable.collapsed ~ .main-content .footer-fixed .footer-inner {
    padding-left: 0;
  }
}

.footer-tools {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 998;
}

.footer .footer-tools {
  right: auto;
  width: 0;
}

.btn-scroll-up {
  visibility: hidden;
  pointer-events: none;
  opacity: 0.75;
  transform: translateY(150%);
  transition: transform 300ms 0ms, opacity 200ms 0ms, visibility 0ms 300ms !important;
}

@media (prefers-reduced-motion: reduce) {
  .btn-scroll-up {
    transition: none;
  }
}

.btn-scroll-up:hover {
  opacity: 0.92;
}

.btn-scroll-up.scroll-btn-visible {
  transform: none;
  pointer-events: auto;
  visibility: visible;
  transition-delay: 0ms !important;
}

@media (prefers-reduced-motion: reduce) {
  .btn-scroll-up {
    transition: none !important;
  }
}

.scroll-btn-observe {
  position: absolute;
  top: 90vh;
  bottom: auto;
  right: 0;
  height: 0;
  width: 0;
  visibility: hidden;
  pointer-events: none;
}

@media (min-width: 600px) {
  .scroll-btn-observe {
    top: 70vh;
  }
}

@media (min-width: 850px) {
  .scroll-btn-observe {
    top: 40vh;
  }
}

.navbar-fixed > .navbar-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
  .main-content .navbar-fixed .navbar-inner {
    left: 0;
    right: 0;
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .navbar-fixed-sm > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 767.98px) {
  .navbar-fixed-md > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-fixed-lg > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 1199.98px) {
  .navbar-fixed-xl > .navbar-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .main-content .navbar-fixed .navbar-inner {
    left: 16rem;
    left: var(--sidebar-width);
    transition: left 150ms;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .main-content .navbar-fixed .navbar-inner {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.collapsed ~ .main-content .navbar-fixed .navbar-inner {
    left: 5rem;
    left: var(--sidebar-collapsed-width);
  }
  .sidebar.collapsed.hideable ~ .main-content .navbar-fixed .navbar-inner {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .container .main-content .navbar-fixed {
    position: relative;
    top: auto;
  }
  .container .main-content .navbar-fixed .navbar-inner {
    position: relative;
    width: 100%;
    padding-left: 0 !important;
    left: auto !important;
    right: auto !important;
  }
  @supports (position: sticky) {
    .container .main-content .navbar-fixed {
      position: sticky;
      top: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .main-content .navbar-fixed .navbar-inner,
  .container .main-content .navbar-fixed .navbar-inner {
    left: auto;
    right: auto;
    width: 100%;
  }
}

.sidebar-fixed {
  z-index: 1021;
}

.sidebar-fixed .sidebar-inner {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto;
}

.main-container .sidebar-fixed {
  z-index: 1020;
}

.main-content .sidebar-fixed {
  z-index: 1017;
}

@media (min-width: 1200px) {
  .navbar ~ .main-container .sidebar-fixed > .sidebar-inner {
    top: 4.5rem;
    top: var(--navbar-height);
  }
  .navbar-sm ~ .main-container .sidebar-fixed > .sidebar-inner {
    top: 4rem;
    top: var(--navbar-sm-height);
  }
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    z-index: 1020;
  }
  .navbar ~ .main-container .sidebar-fixed > .sidebar-inner,
  .navbar ~ .sidebar-fixed > .sidebar-inner {
    top: 4rem;
    top: var(--navbar-mobile-height);
  }
  .sidebar-fixed.sidebar-top > .sidebar-inner {
    top: 0 !important;
  }
  .sidebar-fixed.sidebar-top {
    z-index: 1024 !important;
  }
  .main-container .sidebar-fixed {
    z-index: 1024;
  }
  .navbar ~ .main-container .sidebar-fixed {
    z-index: 1020;
  }
}

.content-nav {
  position: relative;
  min-height: 2.5rem;
  margin: 0.75rem 1rem;
  padding: 0 0.5rem;
  border-radius: 0.325rem;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
}

.breadcrumb-item {
  font-size: 0.95rem;
  position: relative;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1.25em;
}

.breadcrumb-item + .breadcrumb-item::before {
  position: absolute;
  left: 0.425em;
  top: -2px;
  content: "\203A";
  font-size: 1.25em;
  opacity: 0.88;
}

.breadcrumb-item[class*="text-"]::before {
  color: inherit !important;
}

.breadcrumb-nosep .breadcrumb-item::before {
  display: none;
}

.bcard {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  border-width: 0;
  background-color: #fff;
  position: relative;
}

.bcard .card-header {
  background-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.09);
}

.ccard {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.5rem;
  border: 1px solid #e0e5e8;
  background-color: #fff;
  position: relative;
}

.ccard > .card-header {
  background-color: transparent;
  border-color: #e8e9ea;
}

.ccard > .card-header > .card-title {
  color: #4c5b70;
}

.dcard {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  border: 1px solid #e0e5e8;
  background-color: #fff;
  position: relative;
}

.dcard > .card-header {
  background-color: transparent;
  border-color: #e8e9ea;
}

.dcard > .card-header > .card-title {
  color: #4c5b70;
}

.acard {
  box-shadow: none;
  border-radius: 0;
  border: none;
  background-color: #fff;
  position: relative;
}

.acard > .card-header {
  background-color: transparent;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.5rem;
  align-items: center;
  border-color: #e6eaed;
}

.acard > .card-header .card-title {
  color: #4c5b70;
  font-size: 1.25rem;
}

.acard > .card-body {
  padding: 0.75rem 0.25rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.card-header.card-header-sm {
  padding: 0.375rem 0.625rem;
}

.card-header.card-header-lg {
  padding: 1.125rem 1.25rem;
}

.card-title {
  color: #2564a9;
  flex-grow: 1;
  line-height: normal;
  margin-bottom: 0;
}

@keyframes cardFadeOutIn {
  0% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.9;
  }
}

.card-expanding {
  position: fixed;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .card-expanding {
    transition: none;
  }
}

.card-expanding.card-expand {
  animation-name: cardFadeOutIn;
  animation-duration: 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .card-expanding.card-expand {
    animation-duration: 0s;
  }
}

.card-expand {
  z-index: 1030;
  transition-delay: .1s;
  border-radius: 0;
  position: fixed;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  width: 100%;
  height: 100%;
}

.card-expand > .card-body {
  max-height: none !important;
}

.card-toolbar {
  align-self: flex-end;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 0.5rem;
}

.card-toolbar:not(.no-border)::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 1px;
  bottom: 1px;
  width: 0;
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.card-toolbar[class*="brc-"]::before {
  border-left-color: inherit;
}

.card-toolbar + .card-toolbar {
  margin-left: 0.5rem;
}

.card-toolbar-btn {
  opacity: 0.85;
  transition: all 0.15s;
  margin-left: 0.625rem;
  font-size: 1.075rem;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .card-toolbar-btn {
    transition: none;
  }
}

.card-toolbar-btn:hover {
  opacity: 1;
}

.card-toolbar-btn:not(.btn):hover {
  transform: scale(1.1);
}

.card-header .collapsed[data-action="toggle"] [class*="-up"] {
  transform-origin: center 55%;
  transform: rotate(180deg);
}

.card-header .collapsed[data-action="toggle"] [class*="-down"] {
  transform-origin: center;
  transform: rotate(180deg);
}

.card-header-sm .card-toolbar-btn {
  font-size: 1rem;
}

.card-header-lg .card-toolbar-btn {
  font-size: 1.125rem;
}

.card-body:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.bs-card-loading-overlay {
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: -1px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.27);
  text-align: center;
  border-radius: inherit;
}

.bs-card-loading-overlay .bs-card-loading-icon {
  position: relative;
  top: 25%;
}

.card-body.collapse:not(.show) ~ .bs-card-loading-overlay .bs-card-loading-icon {
  top: 10%;
}

.page-link {
  color: #2573b3;
  background-color: #fafcfe;
  border-color: #d2e6f6;
  border-radius: 0;
}

.page-link:hover {
  color: #2573b3;
  background-color: #ecf4fc;
  border-color: #c0dbf2;
}

.page-link.active {
  z-index: 3;
}

.page-link:focus {
  box-shadow: none;
  color: #2573b3;
  background-color: #f3f8fd;
  border-color: #c0dbf2;
}

.page-link:active {
  color: #2573b3;
  background-color: #ecf4fc;
  border-color: #c0dbf2;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2a80c8;
  border-color: #287abe;
}

.page-item.disabled .page-link {
  color: #8d9fab;
  background-color: #fff;
  border-color: #d3dadf;
}

.btn {
  cursor: pointer;
  position: relative;
  z-index: auto;
  border-radius: 0.175rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s, opacity 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn.btn-xs {
  padding: 0.25rem 0.5rem 0.275rem;
  line-height: 1.25;
  font-size: 0.9rem;
}

@media (hover: hover) {
  button.btn:active:not(.disabled):not(.static) {
    transform: translateY(2px);
  }
}

@media (hover: none) {
  button.btn {
    transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s, opacity 0.15s, transform 0.14s;
  }
}

@media (hover: none) and (prefers-reduced-motion: reduce) {
  button.btn {
    transition: none;
  }
}

@media (hover: none) {
  button.btn:active:not(.disabled):not(.static) {
    transform: scale(0.95);
  }
}

.btn:focus, .btn.focus,
.show > .btn.dropdown-toggle:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn[class*="btn-h-light"].fs--shadow:hover:focus,
.btn[class*="btn-h-light"]:not(:disabled):not(.disabled):active,
.btn[class*="btn-h-light"]:not(:disabled):not(.disabled).active,
.show > .btn[class*="btn-h-light"].dropdown-toggle {
  box-shadow: none;
}

.btn-default {
  color: #fff;
  background-color: #4188b3;
  border-color: #4188b3;
}

.btn-default:hover {
  color: #fff;
  background-color: #3a7aa1;
  border-color: #39789e;
}

.btn-default:focus, .btn-default.focus {
  color: #fff;
  background-color: #3b7da3;
  border-color: #377497;
}

.btn-default:focus.fs--shadow, .btn-default.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(65, 136, 179, 0.4);
}

.show > .btn.btn-default.dropdown-toggle, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active {
  background-color: #377498;
  border-color: #336b8c;
  color: #fff;
}

.btn-default.disabled, .btn-default:disabled {
  background-color: #4188b3;
  border-color: #4188b3;
}

.btn-outline-default {
  color: #2d77a4;
  border-color: #a9bdc9;
  background-color: transparent;
}

.btn-outline-default:hover {
  color: #fff;
  background-color: #4188b3;
  border-color: #4188b3;
}

.btn-outline-default:focus.fs--shadow, .btn-outline-default.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(65, 136, 179, 0.4);
}

.show > .btn.btn-outline-default.dropdown-toggle, .btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #4188b3;
  border-color: #4188b3;
}

.show > .btn.btn-outline-default.dropdown-toggle.fs--shadow:focus, .btn-outline-default:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-default:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(65, 136, 179, 0.4);
}

.btn-outline-default.disabled, .btn-outline-default:disabled {
  border-color: #a9bdc9;
  color: #2d77a4;
}

.btn-light-default {
  color: #376988;
  background-color: #e0ecf3;
  border-color: #afccdf;
}

.btn-light-default:hover {
  color: #33627f;
  background-color: #d1e2ed;
  border-color: #a0c4d9;
}

.btn-light-default:focus, .btn-light-default.focus {
  color: #315f7b;
  background-color: #c9ddea;
  border-color: #98bfd6;
}

.show > .btn.btn-light-default.dropdown-toggle, .btn-light-default:not(:disabled):not(.disabled):active, .btn-light-default:not(:disabled):not(.disabled).active {
  color: #2f5b76;
  background-color: #c1d8e6;
  border-color: #91bad3;
}

.btn-lighter-default {
  color: #3a6f90;
  background-color: #eff5f9;
  border-color: #b8d2e3;
}

.btn-lighter-default:hover {
  color: #376989;
  background-color: #e7f0f5;
  border-color: #a2c5da;
}

.btn-lighter-default:focus, .btn-lighter-default.focus {
  color: #366785;
  background-color: #e4eef4;
  border-color: #9dc2d8;
}

.show > .btn.btn-lighter-default.dropdown-toggle, .btn-lighter-default:not(:disabled):not(.disabled):active, .btn-lighter-default:not(:disabled):not(.disabled).active {
  color: #346481;
  background-color: #e0ecf3;
  border-color: #98bfd6;
}

.btn-primary {
  color: #fff;
  background-color: #2c86d1;
  border-color: #2c86d1;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2779bc;
  border-color: #2676b8;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #287abf;
  border-color: #2571b1;
}

.btn-primary:focus.fs--shadow, .btn-primary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(44, 134, 209, 0.4);
}

.show > .btn.btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #2572b2;
  border-color: #2269a4;
  color: #fff;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #2c86d1;
  border-color: #2c86d1;
}

.btn-outline-primary {
  color: #1b74bf;
  border-color: #64a0d3;
  background-color: transparent;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2c86d1;
  border-color: #2c86d1;
}

.btn-outline-primary:focus.fs--shadow, .btn-outline-primary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(44, 134, 209, 0.4);
}

.show > .btn.btn-outline-primary.dropdown-toggle, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2c86d1;
  border-color: #2c86d1;
}

.show > .btn.btn-outline-primary.dropdown-toggle.fs--shadow:focus, .btn-outline-primary:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-primary:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(44, 134, 209, 0.4);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  border-color: #64a0d3;
  color: #1b74bf;
}

.btn-light-primary {
  color: #1766a8;
  background-color: #dbebf8;
  border-color: #a0c9eb;
}

.btn-light-primary:hover {
  color: #165f9d;
  background-color: #c9e1f4;
  border-color: #83b8e5;
}

.btn-light-primary:focus, .btn-light-primary.focus {
  color: #155b95;
  background-color: #bddaf2;
  border-color: #7cb5e4;
}

.show > .btn.btn-light-primary.dropdown-toggle, .btn-light-primary:not(:disabled):not(.disabled):active, .btn-light-primary:not(:disabled):not(.disabled).active {
  color: #14568d;
  background-color: #b0d2ef;
  border-color: #75b1e2;
}

.btn-lighter-primary {
  color: #196db3;
  background-color: #edf5fb;
  border-color: #a0c9eb;
}

.btn-lighter-primary:hover {
  color: #1867aa;
  background-color: #e3eff9;
  border-color: #83b8e5;
}

.btn-lighter-primary:focus, .btn-lighter-primary.focus {
  color: #1765a6;
  background-color: #dfedf9;
  border-color: #7cb5e4;
}

.show > .btn.btn-lighter-primary.dropdown-toggle, .btn-lighter-primary:not(:disabled):not(.disabled):active, .btn-lighter-primary:not(:disabled):not(.disabled).active {
  color: #1662a1;
  background-color: #dbebf8;
  border-color: #75b1e2;
}

.btn-secondary {
  color: #fff;
  background-color: #678497;
  border-color: #678497;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5d7788;
  border-color: #5b7485;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5e788a;
  border-color: #576f80;
}

.btn-secondary:focus.fs--shadow, .btn-secondary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(103, 132, 151, 0.4);
}

.show > .btn.btn-secondary.dropdown-toggle, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #587081;
  border-color: #516777;
  color: #fff;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #678497;
  border-color: #678497;
}

.btn-outline-secondary {
  color: #4e748d;
  border-color: #86a0b2;
  background-color: transparent;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #678497;
  border-color: #678497;
}

.btn-outline-secondary:focus.fs--shadow, .btn-outline-secondary.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(103, 132, 151, 0.4);
}

.show > .btn.btn-outline-secondary.dropdown-toggle, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #678497;
  border-color: #678497;
}

.show > .btn.btn-outline-secondary.dropdown-toggle.fs--shadow:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(103, 132, 151, 0.4);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  border-color: #86a0b2;
  color: #4e748d;
}

.btn-light-secondary {
  color: #4c6778;
  background-color: #e6eaed;
  border-color: #bfc8cf;
}

.btn-light-secondary:hover {
  color: #476071;
  background-color: #dae0e4;
  border-color: #b0bcc5;
}

.btn-light-secondary:focus, .btn-light-secondary.focus {
  color: #445c6c;
  background-color: #d3dadf;
  border-color: #abb8c1;
}

.show > .btn.btn-light-secondary.dropdown-toggle, .btn-light-secondary:not(:disabled):not(.disabled):active, .btn-light-secondary:not(:disabled):not(.disabled).active {
  color: #415867;
  background-color: #cbd3d9;
  border-color: #a7b4be;
}

.btn-lighter-secondary {
  color: #516e80;
  background-color: #f2f4f6;
  border-color: #c3ccd2;
}

.btn-lighter-secondary:hover {
  color: #4d687a;
  background-color: #ebeff1;
  border-color: #b0bcc5;
}

.btn-lighter-secondary:focus, .btn-lighter-secondary.focus {
  color: #4b6677;
  background-color: #e9edef;
  border-color: #acb9c2;
}

.show > .btn.btn-lighter-secondary.dropdown-toggle, .btn-lighter-secondary:not(:disabled):not(.disabled):active, .btn-lighter-secondary:not(:disabled):not(.disabled).active {
  color: #496373;
  background-color: #e6eaed;
  border-color: #a8b5bf;
}

.btn-info {
  color: #fff;
  background-color: #008bb2;
  border-color: #008bb2;
}

.btn-info:hover {
  color: #fff;
  background-color: #007da0;
  border-color: #007a9d;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #0082a6;
  border-color: #00799a;
}

.btn-info:focus.fs--shadow, .btn-info.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(0, 139, 178, 0.4);
}

.show > .btn.btn-info.dropdown-toggle, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active {
  background-color: #007697;
  border-color: #006d8b;
  color: #fff;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #008bb2;
  border-color: #008bb2;
}

.btn-outline-info {
  color: #00799b;
  border-color: #52a4bb;
  background-color: transparent;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #008bb2;
  border-color: #008bb2;
}

.btn-outline-info:focus.fs--shadow, .btn-outline-info.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(0, 139, 178, 0.4);
}

.show > .btn.btn-outline-info.dropdown-toggle, .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #008bb2;
  border-color: #008bb2;
}

.show > .btn.btn-outline-info.dropdown-toggle.fs--shadow:focus, .btn-outline-info:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-info:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(0, 139, 178, 0.4);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  border-color: #52a4bb;
  color: #00799b;
}

.btn-light-info {
  color: #00708f;
  background-color: #d7f4fc;
  border-color: #7cd9f4;
}

.btn-light-info:hover {
  color: #006a87;
  background-color: #c3eefa;
  border-color: #64d3f2;
}

.btn-light-info:focus, .btn-light-info.focus {
  color: #006682;
  background-color: #b3eaf9;
  border-color: #54cef0;
}

.show > .btn.btn-light-info.dropdown-toggle, .btn-light-info:not(:disabled):not(.disabled):active, .btn-light-info:not(:disabled):not(.disabled).active {
  color: #00627d;
  background-color: #a3e5f7;
  border-color: #43c9ef;
}

.btn-lighter-info {
  color: #007494;
  background-color: #ebf9fd;
  border-color: #8cdef5;
}

.btn-lighter-info:hover {
  color: #006e8c;
  background-color: #e0f6fc;
  border-color: #69d4f2;
}

.btn-lighter-info:focus, .btn-lighter-info.focus {
  color: #006b89;
  background-color: #dcf5fc;
  border-color: #61d2f2;
}

.show > .btn.btn-lighter-info.dropdown-toggle, .btn-lighter-info:not(:disabled):not(.disabled):active, .btn-lighter-info:not(:disabled):not(.disabled).active {
  color: #006885;
  background-color: #d7f4fc;
  border-color: #58cff1;
}

.btn-success {
  color: #fff;
  background-color: #3f923f;
  border-color: #3f923f;
}

.btn-success:hover {
  color: #fff;
  background-color: #398339;
  border-color: #388038;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #3b873b;
  border-color: #377d37;
}

.btn-success:focus.fs--shadow, .btn-success.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(63, 146, 63, 0.4);
}

.show > .btn.btn-success.dropdown-toggle, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {
  background-color: #367c36;
  border-color: #327232;
  color: #fff;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #3f923f;
  border-color: #3f923f;
}

.btn-outline-success {
  color: #2c812c;
  border-color: #6faa6f;
  background-color: transparent;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #3f923f;
  border-color: #3f923f;
}

.btn-outline-success:focus.fs--shadow, .btn-outline-success.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(63, 146, 63, 0.4);
}

.show > .btn.btn-outline-success.dropdown-toggle, .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #3f923f;
  border-color: #3f923f;
}

.show > .btn.btn-outline-success.dropdown-toggle.fs--shadow:focus, .btn-outline-success:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-success:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(63, 146, 63, 0.4);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  border-color: #6faa6f;
  color: #2c812c;
}

.btn-light-success {
  color: #2f732f;
  background-color: #e1f1e1;
  border-color: #a7d5a7;
}

.btn-light-success:hover {
  color: #2d6d2d;
  background-color: #d3ead3;
  border-color: #9bcf9b;
}

.btn-light-success:focus, .btn-light-success.focus {
  color: #2b692b;
  background-color: #cae6ca;
  border-color: #91cb91;
}

.show > .btn.btn-light-success.dropdown-toggle, .btn-light-success:not(:disabled):not(.disabled):active, .btn-light-success:not(:disabled):not(.disabled).active {
  color: #296429;
  background-color: #c0e1c0;
  border-color: #88c688;
}

.btn-lighter-success {
  color: #327a32;
  background-color: #f0f8f0;
  border-color: #b5dcb5;
}

.btn-lighter-success:hover {
  color: #2f742f;
  background-color: #e8f4e8;
  border-color: #9ed19e;
}

.btn-lighter-success:focus, .btn-lighter-success.focus {
  color: #2e712e;
  background-color: #e5f3e5;
  border-color: #99cf99;
}

.show > .btn.btn-lighter-success.dropdown-toggle, .btn-lighter-success:not(:disabled):not(.disabled):active, .btn-lighter-success:not(:disabled):not(.disabled).active {
  color: #2d6e2d;
  background-color: #e1f1e1;
  border-color: #94cc94;
}

.btn-danger {
  color: #fff;
  background-color: #df4b30;
  border-color: #df4b30;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9432b;
  border-color: #c4422a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #ca432c;
  border-color: #bb3e29;
}

.btn-danger:focus.fs--shadow, .btn-danger.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(223, 75, 48, 0.4);
}

.show > .btn.btn-danger.dropdown-toggle, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active {
  background-color: #bd3f29;
  border-color: #ae3a26;
  color: #fff;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #df4b30;
  border-color: #df4b30;
}

.btn-outline-danger {
  color: #d53114;
  border-color: #e2735f;
  background-color: transparent;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #df4b30;
  border-color: #df4b30;
}

.btn-outline-danger:focus.fs--shadow, .btn-outline-danger.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(223, 75, 48, 0.4);
}

.show > .btn.btn-outline-danger.dropdown-toggle, .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #df4b30;
  border-color: #df4b30;
}

.show > .btn.btn-outline-danger.dropdown-toggle.fs--shadow:focus, .btn-outline-danger:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-danger:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(223, 75, 48, 0.4);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  border-color: #e2735f;
  color: #d53114;
}

.btn-light-danger {
  color: #b0341e;
  background-color: #f9dfda;
  border-color: #eeafa4;
}

.btn-light-danger:hover {
  color: #a3301c;
  background-color: #f5cfc8;
  border-color: #e99788;
}

.btn-light-danger:focus, .btn-light-danger.focus {
  color: #9a2d1a;
  background-color: #f3c5bd;
  border-color: #e89282;
}

.show > .btn.btn-light-danger.dropdown-toggle, .btn-light-danger:not(:disabled):not(.disabled):active, .btn-light-danger:not(:disabled):not(.disabled).active {
  color: #922b19;
  background-color: #f1bbb2;
  border-color: #e78c7b;
}

.btn-lighter-danger {
  color: #b9361f;
  background-color: #fcefec;
  border-color: #eeafa4;
}

.btn-lighter-danger:hover {
  color: #b0341e;
  background-color: #fae6e2;
  border-color: #e99788;
}

.btn-lighter-danger:focus, .btn-lighter-danger.focus {
  color: #ab331d;
  background-color: #fae3de;
  border-color: #e89282;
}

.show > .btn.btn-lighter-danger.dropdown-toggle, .btn-lighter-danger:not(:disabled):not(.disabled):active, .btn-lighter-danger:not(:disabled):not(.disabled).active {
  color: #a6311c;
  background-color: #f9dfda;
  border-color: #e78c7b;
}

.btn-warning {
  color: #22190b;
  background-color: #ffbe50;
  border-color: #ffbe50;
}

.btn-warning:hover {
  color: #22190b;
  background-color: #e6ab48;
  border-color: #e0a746;
}

.btn-warning:focus, .btn-warning.focus {
  color: #22190b;
  background-color: #ffb536;
  border-color: #eca732;
}

.btn-warning:focus.fs--shadow, .btn-warning.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(255, 190, 80, 0.4);
}

.show > .btn.btn-warning.dropdown-toggle, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active {
  background-color: #ffaf27;
  border-color: #eba124;
  color: #22190b;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffbe50;
  border-color: #ffbe50;
}

.btn-outline-warning {
  color: #c86800;
  border-color: #e6b461;
  background-color: transparent;
}

.btn-outline-warning:hover {
  color: #22190b;
  background-color: #ffbe50;
  border-color: #ffbe50;
}

.btn-outline-warning:focus.fs--shadow, .btn-outline-warning.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(255, 190, 80, 0.4);
}

.show > .btn.btn-outline-warning.dropdown-toggle, .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active {
  color: #22190b;
  background-color: #ffbe50;
  border-color: #ffbe50;
}

.show > .btn.btn-outline-warning.dropdown-toggle.fs--shadow:focus, .btn-outline-warning:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-warning:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(255, 190, 80, 0.4);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  border-color: #e6b461;
  color: #c86800;
}

.btn-light-warning {
  color: #8d5f13;
  background-color: #fceed7;
  border-color: #f4c67a;
}

.btn-light-warning:hover {
  color: #865a12;
  background-color: #fae6c3;
  border-color: #f1be69;
}

.btn-light-warning:focus, .btn-light-warning.focus {
  color: #825811;
  background-color: #f9e1b9;
  border-color: #f1b95e;
}

.show > .btn.btn-light-warning.dropdown-toggle, .btn-light-warning:not(:disabled):not(.disabled):active, .btn-light-warning:not(:disabled):not(.disabled).active {
  color: #7f5611;
  background-color: #f8dcae;
  border-color: #f0b553;
}

.btn-lighter-warning {
  color: #916213;
  background-color: #fdf7eb;
  border-color: #f7d6a0;
}

.btn-lighter-warning:hover {
  color: #8a5d12;
  background-color: #fcf2e0;
  border-color: #f4ca85;
}

.btn-lighter-warning:focus, .btn-lighter-warning.focus {
  color: #875b12;
  background-color: #fcf0dc;
  border-color: #f4c87f;
}

.show > .btn.btn-lighter-warning.dropdown-toggle, .btn-lighter-warning:not(:disabled):not(.disabled):active, .btn-lighter-warning:not(:disabled):not(.disabled).active {
  color: #835811;
  background-color: #fceed7;
  border-color: #f3c578;
}

.btn-dark {
  color: #fff;
  background-color: #42474e;
  border-color: #42474e;
}

.btn-dark:hover {
  color: #fff;
  background-color: #3b4046;
  border-color: #3a3e45;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #3f444a;
  border-color: #3b3e45;
}

.btn-dark:focus.fs--shadow, .btn-dark.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(66, 71, 78, 0.4);
}

.show > .btn.btn-dark.dropdown-toggle, .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active {
  background-color: #383c42;
  border-color: #34373d;
  color: #fff;
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #42474e;
  border-color: #42474e;
}

.btn-outline-dark {
  color: #42474e;
  border-color: #7e8287;
  background-color: transparent;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #42474e;
  border-color: #42474e;
}

.btn-outline-dark:focus.fs--shadow, .btn-outline-dark.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(66, 71, 78, 0.4);
}

.show > .btn.btn-outline-dark.dropdown-toggle, .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #42474e;
  border-color: #42474e;
}

.show > .btn.btn-outline-dark.dropdown-toggle.fs--shadow:focus, .btn-outline-dark:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-dark:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(66, 71, 78, 0.4);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  border-color: #7e8287;
  color: #42474e;
}

.btn-light-dark {
  color: #35393e;
  background-color: #e2e3e4;
  border-color: #aaacaf;
}

.btn-light-dark:hover {
  color: #35393e;
  background-color: #d4d5d7;
  border-color: #8f9297;
}

.btn-light-dark:focus, .btn-light-dark.focus {
  color: #35393e;
  background-color: #c8c9cb;
  border-color: #898d92;
}

.show > .btn.btn-light-dark.dropdown-toggle, .btn-light-dark:not(:disabled):not(.disabled):active, .btn-light-dark:not(:disabled):not(.disabled).active {
  color: #35393e;
  background-color: #bbbdbf;
  border-color: #83878c;
}

.btn-lighter-dark {
  color: #35393e;
  background-color: #eff0f0;
  border-color: #aaacaf;
}

.btn-lighter-dark:hover {
  color: #32363b;
  background-color: #e8e9ea;
  border-color: #8f9297;
}

.btn-lighter-dark:focus, .btn-lighter-dark.focus {
  color: #31353a;
  background-color: #e5e6e7;
  border-color: #898d92;
}

.show > .btn.btn-lighter-dark.dropdown-toggle, .btn-lighter-dark:not(:disabled):not(.disabled):active, .btn-lighter-dark:not(:disabled):not(.disabled).active {
  color: #303338;
  background-color: #e2e3e4;
  border-color: #83878c;
}

.btn-light {
  color: #5d6069;
  background-color: #f8f9fb;
  border-color: #d4d7dd;
}

.btn-light:hover {
  color: #4f5257;
  background-color: #eaecf0;
  border-color: #cbcdd1;
}

.btn-light:focus, .btn-light.focus {
  color: #595a5e;
  background-color: #eff0f3;
  border-color: #bbbcbd;
}

.btn-light:focus.fs--shadow, .btn-light.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(212, 215, 221, 0.4);
}

.show > .btn.btn-light.dropdown-toggle, .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active {
  background-color: #e6e8ed;
  border-color: #b3b4b5;
  color: #595a5e;
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f8f9fb;
  border-color: #d4d7dd;
}

.btn-outline-light {
  color: #91969b;
  border-color: #d4d7dd;
  background-color: transparent;
}

.btn-outline-light:hover {
  color: #5d6069;
  background-color: #f8f9fb;
  border-color: #d4d7dd;
}

.btn-outline-light:focus.fs--shadow, .btn-outline-light.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(212, 215, 221, 0.4);
}

.show > .btn.btn-outline-light.dropdown-toggle, .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active {
  color: #5d6069;
  background-color: #f8f9fb;
  border-color: #d4d7dd;
}

.show > .btn.btn-outline-light.dropdown-toggle.fs--shadow:focus, .btn-outline-light:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-light:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(212, 215, 221, 0.4);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  border-color: #d4d7dd;
  color: #91969b;
}

.btn-light-light {
  color: #6e7074;
  background-color: #fefeff;
  border-color: #d9d9e6;
}

.btn-light-light:hover {
  color: #6e7074;
  background-color: #fefefe;
  border-color: #d7d7eb;
}

.btn-light-light:focus, .btn-light-light.focus {
  color: #6e7074;
  background-color: #fefefe;
  border-color: #d6d6eb;
}

.show > .btn.btn-light-light.dropdown-toggle, .btn-light-light:not(:disabled):not(.disabled):active, .btn-light-light:not(:disabled):not(.disabled).active {
  color: #6e7074;
  background-color: #fdfdfe;
  border-color: #d6d6ea;
}

.btn-lighter-light {
  color: #6f7175;
  background-color: #feffff;
  border-color: #d9d9e6;
}

.btn-lighter-light:hover {
  color: #696b6f;
  background-color: #fefeff;
  border-color: #d1d1e8;
}

.btn-lighter-light:focus, .btn-lighter-light.focus {
  color: #67696c;
  background-color: #fefeff;
  border-color: #cfcfe7;
}

.show > .btn.btn-lighter-light.dropdown-toggle, .btn-lighter-light:not(:disabled):not(.disabled):active, .btn-lighter-light:not(:disabled):not(.disabled).active {
  color: #646669;
  background-color: #fefeff;
  border-color: #cdcde6;
}

.btn-white {
  color: #606060;
  background-color: #fff;
  border-color: #ddd;
}

.btn-white:hover {
  color: #606060;
  background-color: #fff;
  border-color: #c5c5c5;
}

.btn-white:focus, .btn-white.focus {
  color: #606060;
  background-color: white;
  border-color: #bdbdbd;
}

.btn-white:focus.fs--shadow, .btn-white.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(221, 221, 221, 0.4);
}

.show > .btn.btn-white.dropdown-toggle, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active {
  background-color: #fff;
  border-color: #b5b5b5;
  color: #606060;
}

.btn-white.disabled, .btn-white:disabled {
  background-color: #fff;
  border-color: #ddd;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.btn-outline-white:hover {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus.fs--shadow, .btn-outline-white.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(221, 221, 221, 0.4);
}

.show > .btn.btn-outline-white.dropdown-toggle, .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active {
  color: #464646;
  background-color: #fff;
  border-color: #fff;
}

.show > .btn.btn-outline-white.dropdown-toggle.fs--shadow:focus, .btn-outline-white:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-white:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 221, 221, 0.4);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  border-color: #fff;
  color: #fff;
}

.btn-black {
  color: #fff;
  background-color: #2c3034;
  border-color: #2c3034;
}

.btn-black:hover {
  color: #fff;
  background-color: #282b2f;
  border-color: #272a2e;
}

.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: #2c3134;
  border-color: #292e30;
}

.btn-black:focus.fs--shadow, .btn-black.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(44, 48, 52, 0.4);
}

.show > .btn.btn-black.dropdown-toggle, .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active {
  background-color: #25292c;
  border-color: #222628;
  color: #fff;
}

.btn-black.disabled, .btn-black:disabled {
  background-color: #2c3034;
  border-color: #2c3034;
}

.btn-outline-black {
  color: #2c3034;
  border-color: #414549;
  background-color: transparent;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #2c3034;
  border-color: #2c3034;
}

.btn-outline-black:focus.fs--shadow, .btn-outline-black.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(44, 48, 52, 0.4);
}

.show > .btn.btn-outline-black.dropdown-toggle, .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2c3034;
  border-color: #2c3034;
}

.show > .btn.btn-outline-black.dropdown-toggle.fs--shadow:focus, .btn-outline-black:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-black:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(44, 48, 52, 0.4);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  border-color: #414549;
  color: #2c3034;
}

.btn-light-black {
  color: #23262a;
  background-color: #dfe0e0;
  border-color: #a0a2a4;
}

.btn-light-black:hover {
  color: #23262a;
  background-color: #cfd0d1;
  border-color: #838587;
}

.btn-light-black:focus, .btn-light-black.focus {
  color: #23262a;
  background-color: #c1c3c4;
  border-color: #7c7f81;
}

.show > .btn.btn-light-black.dropdown-toggle, .btn-light-black:not(:disabled):not(.disabled):active, .btn-light-black:not(:disabled):not(.disabled).active {
  color: #23262a;
  background-color: #b3b5b6;
  border-color: #75787a;
}

.btn-lighter-black {
  color: #23262a;
  background-color: #eeeeee;
  border-color: #a0a2a4;
}

.btn-lighter-black:hover {
  color: #212428;
  background-color: #e6e6e7;
  border-color: #838587;
}

.btn-lighter-black:focus, .btn-lighter-black.focus {
  color: #212327;
  background-color: #e3e3e4;
  border-color: #7c7f81;
}

.show > .btn.btn-lighter-black.dropdown-toggle, .btn-lighter-black:not(:disabled):not(.disabled):active, .btn-lighter-black:not(:disabled):not(.disabled).active {
  color: #202226;
  background-color: #dfe0e0;
  border-color: #75787a;
}

.btn-grey {
  color: #fff;
  background-color: #767679;
  border-color: #767679;
}

.btn-grey:hover {
  color: #fff;
  background-color: #6a6b6d;
  border-color: #67686b;
}

.btn-grey:focus, .btn-grey.focus {
  color: #fff;
  background-color: #6c6d6f;
  border-color: #646567;
}

.btn-grey:focus.fs--shadow, .btn-grey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(118, 118, 121, 0.4);
}

.show > .btn.btn-grey.dropdown-toggle, .btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active {
  background-color: #646567;
  border-color: #5c5d5f;
  color: #fff;
}

.btn-grey.disabled, .btn-grey:disabled {
  background-color: #767679;
  border-color: #767679;
}

.btn-outline-grey {
  color: #666;
  border-color: #979797;
  background-color: transparent;
}

.btn-outline-grey:hover {
  color: #fff;
  background-color: #767679;
  border-color: #767679;
}

.btn-outline-grey:focus.fs--shadow, .btn-outline-grey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(118, 118, 121, 0.4);
}

.show > .btn.btn-outline-grey.dropdown-toggle, .btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #767679;
  border-color: #767679;
}

.show > .btn.btn-outline-grey.dropdown-toggle.fs--shadow:focus, .btn-outline-grey:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-grey:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(118, 118, 121, 0.4);
}

.btn-outline-grey.disabled, .btn-outline-grey:disabled {
  border-color: #979797;
  color: #666;
}

.btn-light-grey {
  color: #636366;
  background-color: #e9e9e9;
  border-color: #c5c6c7;
}

.btn-light-grey:hover {
  color: #5d5d60;
  background-color: #dedfdf;
  border-color: #b3b4b6;
}

.btn-light-grey:focus, .btn-light-grey.focus {
  color: #58585b;
  background-color: #d7d8d8;
  border-color: #afb0b2;
}

.show > .btn.btn-light-grey.dropdown-toggle, .btn-light-grey:not(:disabled):not(.disabled):active, .btn-light-grey:not(:disabled):not(.disabled).active {
  color: #535356;
  background-color: #cfd0d1;
  border-color: #abacae;
}

.btn-lighter-grey {
  color: #666669;
  background-color: #f4f4f4;
  border-color: #c5c6c7;
}

.btn-lighter-grey:hover {
  color: #616164;
  background-color: #eeeeee;
  border-color: #b3b4b6;
}

.btn-lighter-grey:focus, .btn-lighter-grey.focus {
  color: #5f5f62;
  background-color: #ececec;
  border-color: #afb0b2;
}

.show > .btn.btn-lighter-grey.dropdown-toggle, .btn-lighter-grey:not(:disabled):not(.disabled):active, .btn-lighter-grey:not(:disabled):not(.disabled).active {
  color: #5c5c5f;
  background-color: #e9e9e9;
  border-color: #abacae;
}

.btn-purple {
  color: #fff;
  background-color: #776bbe;
  border-color: #776bbe;
}

.btn-purple:hover {
  color: #fff;
  background-color: #6b60ab;
  border-color: #695ea7;
}

.btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: #6e64a7;
  border-color: #635a9e;
}

.btn-purple:focus.fs--shadow, .btn-purple.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(119, 107, 190, 0.4);
}

.show > .btn.btn-purple.dropdown-toggle, .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active {
  background-color: #655ba1;
  border-color: #5d5494;
  color: #fff;
}

.btn-purple.disabled, .btn-purple:disabled {
  background-color: #776bbe;
  border-color: #776bbe;
}

.btn-outline-purple {
  color: #7061c9;
  border-color: #9d93da;
  background-color: transparent;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #776bbe;
  border-color: #776bbe;
}

.btn-outline-purple:focus.fs--shadow, .btn-outline-purple.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(119, 107, 190, 0.4);
}

.show > .btn.btn-outline-purple.dropdown-toggle, .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #776bbe;
  border-color: #776bbe;
}

.show > .btn.btn-outline-purple.dropdown-toggle.fs--shadow:focus, .btn-outline-purple:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-purple:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(119, 107, 190, 0.4);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  border-color: #9d93da;
  color: #7061c9;
}

.btn-light-purple {
  color: #574a9f;
  background-color: #e4e2f1;
  border-color: #c2bddf;
}

.btn-light-purple:hover {
  color: #574a9f;
  background-color: #d7d4ea;
  border-color: #afa8d5;
}

.btn-light-purple:focus, .btn-light-purple.focus {
  color: #534798;
  background-color: #d0cde7;
  border-color: #aba4d3;
}

.show > .btn.btn-light-purple.dropdown-toggle, .btn-light-purple:not(:disabled):not(.disabled):active, .btn-light-purple:not(:disabled):not(.disabled).active {
  color: #4f4491;
  background-color: #c9c5e3;
  border-color: #a69fd1;
}

.btn-lighter-purple {
  color: #574a9f;
  background-color: #f1f0f8;
  border-color: #c2bddf;
}

.btn-lighter-purple:hover {
  color: #524797;
  background-color: #eae8f4;
  border-color: #afa8d5;
}

.btn-lighter-purple:focus, .btn-lighter-purple.focus {
  color: #504593;
  background-color: #e7e5f3;
  border-color: #aba4d3;
}

.show > .btn.btn-lighter-purple.dropdown-toggle, .btn-lighter-purple:not(:disabled):not(.disabled):active, .btn-lighter-purple:not(:disabled):not(.disabled).active {
  color: #4e438f;
  background-color: #e4e2f1;
  border-color: #a69fd1;
}

.btn-pink {
  color: #fff;
  background-color: #da3776;
  border-color: #da3776;
}

.btn-pink:hover {
  color: #fff;
  background-color: #c4326a;
  border-color: #c03168;
}

.btn-pink:focus, .btn-pink.focus {
  color: #fff;
  background-color: #c5326b;
  border-color: #b62e63;
}

.btn-pink:focus.fs--shadow, .btn-pink.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(218, 55, 118, 0.4);
}

.show > .btn.btn-pink.dropdown-toggle, .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active {
  background-color: #b92f64;
  border-color: #aa2b5c;
  color: #fff;
}

.btn-pink.disabled, .btn-pink:disabled {
  background-color: #da3776;
  border-color: #da3776;
}

.btn-outline-pink {
  color: #d81a63;
  border-color: #e56395;
  background-color: transparent;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #da3776;
  border-color: #da3776;
}

.btn-outline-pink:focus.fs--shadow, .btn-outline-pink.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(218, 55, 118, 0.4);
}

.show > .btn.btn-outline-pink.dropdown-toggle, .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #da3776;
  border-color: #da3776;
}

.show > .btn.btn-outline-pink.dropdown-toggle.fs--shadow:focus, .btn-outline-pink:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-pink:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(218, 55, 118, 0.4);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  border-color: #e56395;
  color: #d81a63;
}

.btn-light-pink {
  color: #b3255b;
  background-color: #f7dbe6;
  border-color: #eca7c2;
}

.btn-light-pink:hover {
  color: #a42254;
  background-color: #f4cada;
  border-color: #e68caf;
}

.btn-light-pink:focus, .btn-light-pink.focus {
  color: #9a204f;
  background-color: #f2bfd3;
  border-color: #e586ab;
}

.show > .btn.btn-light-pink.dropdown-toggle, .btn-light-pink:not(:disabled):not(.disabled):active, .btn-light-pink:not(:disabled):not(.disabled).active {
  color: #911e4a;
  background-color: #efb4cb;
  border-color: #e480a6;
}

.btn-lighter-pink {
  color: #b5255c;
  background-color: #fbedf3;
  border-color: #eca7c2;
}

.btn-lighter-pink:hover {
  color: #ac2358;
  background-color: #f9e3ec;
  border-color: #e68caf;
}

.btn-lighter-pink:focus, .btn-lighter-pink.focus {
  color: #a82256;
  background-color: #f8dfe9;
  border-color: #e586ab;
}

.show > .btn.btn-lighter-pink.dropdown-toggle, .btn-lighter-pink:not(:disabled):not(.disabled):active, .btn-lighter-pink:not(:disabled):not(.disabled).active {
  color: #a32153;
  background-color: #f7dbe6;
  border-color: #e480a6;
}

.btn-yellow {
  color: #524b21;
  background-color: #fbe77a;
  border-color: #e1ca53;
}

.btn-yellow:hover {
  color: #524b21;
  background-color: #f0db67;
  border-color: #c6b249;
}

.btn-yellow:focus, .btn-yellow.focus {
  color: #47411d;
  background-color: #ead664;
  border-color: #d8c55c;
}

.btn-yellow:focus.fs--shadow, .btn-yellow.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(225, 202, 83, 0.4);
}

.show > .btn.btn-yellow.dropdown-toggle, .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active {
  background-color: #e8d257;
  border-color: #d5c150;
  color: #47411d;
}

.btn-yellow.disabled, .btn-yellow:disabled {
  background-color: #fbe77a;
  border-color: #e1ca53;
}

.btn-outline-yellow {
  color: #aa641f;
  border-color: #ecc735;
  background-color: transparent;
}

.btn-outline-yellow:hover {
  color: #524b21;
  background-color: #fbe77a;
  border-color: #e1ca53;
}

.btn-outline-yellow:focus.fs--shadow, .btn-outline-yellow.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(225, 202, 83, 0.4);
}

.show > .btn.btn-outline-yellow.dropdown-toggle, .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active {
  color: #524b21;
  background-color: #fbe77a;
  border-color: #e1ca53;
}

.show > .btn.btn-outline-yellow.dropdown-toggle.fs--shadow:focus, .btn-outline-yellow:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(225, 202, 83, 0.4);
}

.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
  border-color: #ecc735;
  color: #aa641f;
}

.btn-light-yellow {
  color: #796c22;
  background-color: #fdf7d6;
  border-color: #f3d120;
}

.btn-light-yellow:hover {
  color: #756821;
  background-color: #fcf3c2;
  border-color: #eecc0c;
}

.btn-light-yellow:focus, .btn-light-yellow.focus {
  color: #756821;
  background-color: #fcf3c0;
  border-color: #efcb0c;
}

.show > .btn.btn-light-yellow.dropdown-toggle, .btn-light-yellow:not(:disabled):not(.disabled):active, .btn-light-yellow:not(:disabled):not(.disabled).active {
  color: #756821;
  background-color: #fcf2be;
  border-color: #efca0b;
}

.btn-lighter-yellow {
  color: #7b6e23;
  background-color: #fefbea;
  border-color: #f6db51;
}

.btn-lighter-yellow:hover {
  color: #756821;
  background-color: #fdf9df;
  border-color: #f3d31c;
}

.btn-lighter-yellow:focus, .btn-lighter-yellow.focus {
  color: #726620;
  background-color: #fdf8db;
  border-color: #f2d014;
}

.show > .btn.btn-lighter-yellow.dropdown-toggle, .btn-lighter-yellow:not(:disabled):not(.disabled):active, .btn-lighter-yellow:not(:disabled):not(.disabled).active {
  color: #6f631f;
  background-color: #fdf7d6;
  border-color: #f1cc0b;
}

.btn-lightgrey {
  color: #fff;
  background-color: #7b818a;
  border-color: #7b818a;
}

.btn-lightgrey:hover {
  color: #fff;
  background-color: #6f747c;
  border-color: #6d7279;
}

.btn-lightgrey:focus, .btn-lightgrey.focus {
  color: #fff;
  background-color: #70767d;
  border-color: #686d74;
}

.btn-lightgrey:focus.fs--shadow, .btn-lightgrey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(123, 129, 138, 0.4);
}

.show > .btn.btn-lightgrey.dropdown-toggle, .btn-lightgrey:not(:disabled):not(.disabled):active, .btn-lightgrey:not(:disabled):not(.disabled).active {
  background-color: #696e75;
  border-color: #61656c;
  color: #fff;
}

.btn-lightgrey.disabled, .btn-lightgrey:disabled {
  background-color: #7b818a;
  border-color: #7b818a;
}

.btn-outline-lightgrey {
  color: #787d82;
  border-color: #d0d5da;
  background-color: transparent;
}

.btn-outline-lightgrey:hover {
  color: #fff;
  background-color: #7b818a;
  border-color: #7b818a;
}

.btn-outline-lightgrey:focus.fs--shadow, .btn-outline-lightgrey.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(123, 129, 138, 0.4);
}

.show > .btn.btn-outline-lightgrey.dropdown-toggle, .btn-outline-lightgrey:not(:disabled):not(.disabled):active, .btn-outline-lightgrey:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #7b818a;
  border-color: #7b818a;
}

.show > .btn.btn-outline-lightgrey.dropdown-toggle.fs--shadow:focus, .btn-outline-lightgrey:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-lightgrey:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(123, 129, 138, 0.4);
}

.btn-outline-lightgrey.disabled, .btn-outline-lightgrey:disabled {
  border-color: #d0d5da;
  color: #787d82;
}

.btn-light-lightgrey {
  color: #606469;
  background-color: #e8e9eb;
  border-color: #c5c7cb;
}

.btn-light-lightgrey:hover {
  color: #5a5d62;
  background-color: #dddfe1;
  border-color: #bbbec3;
}

.btn-light-lightgrey:focus, .btn-light-lightgrey.focus {
  color: #575a5f;
  background-color: #d8dadc;
  border-color: #b6babe;
}

.show > .btn.btn-light-lightgrey.dropdown-toggle, .btn-light-lightgrey:not(:disabled):not(.disabled):active, .btn-light-lightgrey:not(:disabled):not(.disabled).active {
  color: #54575b;
  background-color: #d3d5d7;
  border-color: #b2b5ba;
}

.btn-lighter-lightgrey {
  color: #676a6f;
  background-color: #f3f4f5;
  border-color: #ced0d3;
}

.btn-lighter-lightgrey:hover {
  color: #61656a;
  background-color: #edeeef;
  border-color: #bec1c5;
}

.btn-lighter-lightgrey:focus, .btn-lighter-lightgrey.focus {
  color: #5f6267;
  background-color: #ebeced;
  border-color: #bbbec2;
}

.show > .btn.btn-lighter-lightgrey.dropdown-toggle, .btn-lighter-lightgrey:not(:disabled):not(.disabled):active, .btn-lighter-lightgrey:not(:disabled):not(.disabled).active {
  color: #5c5f64;
  background-color: #e8e9eb;
  border-color: #b7babf;
}

.btn-brown {
  color: #fff;
  background-color: #b6703f;
  border-color: #b6703f;
}

.btn-brown:hover {
  color: #fff;
  background-color: #a46538;
  border-color: #a06237;
}

.btn-brown:focus, .btn-brown.focus {
  color: #fff;
  background-color: #a66639;
  border-color: #9a5e35;
}

.btn-brown:focus.fs--shadow, .btn-brown.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(182, 112, 63, 0.4);
}

.show > .btn.btn-brown.dropdown-toggle, .btn-brown:not(:disabled):not(.disabled):active, .btn-brown:not(:disabled):not(.disabled).active {
  background-color: #9b5f35;
  border-color: #8f5731;
  color: #fff;
}

.btn-brown.disabled, .btn-brown:disabled {
  background-color: #b6703f;
  border-color: #b6703f;
}

.btn-outline-brown {
  color: #a85f2b;
  border-color: #c4926f;
  background-color: transparent;
}

.btn-outline-brown:hover {
  color: #fff;
  background-color: #b6703f;
  border-color: #b6703f;
}

.btn-outline-brown:focus.fs--shadow, .btn-outline-brown.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(182, 112, 63, 0.4);
}

.show > .btn.btn-outline-brown.dropdown-toggle, .btn-outline-brown:not(:disabled):not(.disabled):active, .btn-outline-brown:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #b6703f;
  border-color: #b6703f;
}

.show > .btn.btn-outline-brown.dropdown-toggle.fs--shadow:focus, .btn-outline-brown:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-brown:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(182, 112, 63, 0.4);
}

.btn-outline-brown.disabled, .btn-outline-brown:disabled {
  border-color: #c4926f;
  color: #a85f2b;
}

.btn-light-brown {
  color: #8f572f;
  background-color: #f3e8df;
  border-color: #e0c1ac;
}

.btn-light-brown:hover {
  color: #86512c;
  background-color: #eddcd0;
  border-color: #d7b297;
}

.btn-light-brown:focus, .btn-light-brown.focus {
  color: #804d2a;
  background-color: #ead5c6;
  border-color: #d5ae92;
}

.show > .btn.btn-light-brown.dropdown-toggle, .btn-light-brown:not(:disabled):not(.disabled):active, .btn-light-brown:not(:disabled):not(.disabled).active {
  color: #7a4a28;
  background-color: #e6cdbc;
  border-color: #d3aa8c;
}

.btn-lighter-brown {
  color: #975b31;
  background-color: #f9f3ef;
  border-color: #e1c4b0;
}

.btn-lighter-brown:hover {
  color: #8f572f;
  background-color: #f6ede6;
  border-color: #d7b297;
}

.btn-lighter-brown:focus, .btn-lighter-brown.focus {
  color: #8c552e;
  background-color: #f5ebe3;
  border-color: #d5ae92;
}

.show > .btn.btn-lighter-brown.dropdown-toggle, .btn-lighter-brown:not(:disabled):not(.disabled):active, .btn-lighter-brown:not(:disabled):not(.disabled).active {
  color: #88522c;
  background-color: #f3e8df;
  border-color: #d3aa8c;
}

.btn-blue {
  color: #fff;
  background-color: #2d81e7;
  border-color: #2d81e7;
}

.btn-blue:hover {
  color: #fff;
  background-color: #2874d0;
  border-color: #2771cb;
}

.btn-blue:focus, .btn-blue.focus {
  color: #fff;
  background-color: #2875d1;
  border-color: #256cc1;
}

.btn-blue:focus.fs--shadow, .btn-blue.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(45, 129, 231, 0.4);
}

.show > .btn.btn-blue.dropdown-toggle, .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active {
  background-color: #266ec4;
  border-color: #2365b4;
  color: #fff;
}

.btn-blue.disabled, .btn-blue:disabled {
  background-color: #2d81e7;
  border-color: #2d81e7;
}

.btn-outline-blue {
  color: #0d6ce1;
  border-color: #5a9beb;
  background-color: transparent;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #2d81e7;
  border-color: #2d81e7;
}

.btn-outline-blue:focus.fs--shadow, .btn-outline-blue.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(45, 129, 231, 0.4);
}

.show > .btn.btn-outline-blue.dropdown-toggle, .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2d81e7;
  border-color: #2d81e7;
}

.show > .btn.btn-outline-blue.dropdown-toggle.fs--shadow:focus, .btn-outline-blue:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-blue:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(45, 129, 231, 0.4);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  border-color: #5a9beb;
  color: #0d6ce1;
}

.btn-light-blue {
  color: #2362ae;
  background-color: #d9e8fa;
  border-color: #a4c7f2;
}

.btn-light-blue:hover {
  color: #205ba2;
  background-color: #c6ddf7;
  border-color: #8db9ef;
}

.btn-light-blue:focus, .btn-light-blue.focus {
  color: #1f579a;
  background-color: #bcd7f6;
  border-color: #86b5ee;
}

.show > .btn.btn-light-blue.dropdown-toggle, .btn-light-blue:not(:disabled):not(.disabled):active, .btn-light-blue:not(:disabled):not(.disabled).active {
  color: #1d5393;
  background-color: #b2d0f5;
  border-color: #7fb1ee;
}

.btn-lighter-blue {
  color: #2569bb;
  background-color: #ecf4fc;
  border-color: #a8caf3;
}

.btn-lighter-blue:hover {
  color: #2364b2;
  background-color: #e1edfb;
  border-color: #8db9ef;
}

.btn-lighter-blue:focus, .btn-lighter-blue.focus {
  color: #2262ad;
  background-color: #ddebfb;
  border-color: #87b6ef;
}

.show > .btn.btn-lighter-blue.dropdown-toggle, .btn-lighter-blue:not(:disabled):not(.disabled):active, .btn-lighter-blue:not(:disabled):not(.disabled).active {
  color: #215fa8;
  background-color: #d9e8fa;
  border-color: #81b2ee;
}

.btn-red {
  color: #fff;
  background-color: #eb4343;
  border-color: #eb4343;
}

.btn-red:hover {
  color: #fff;
  background-color: #d33d3d;
  border-color: #cf3b3b;
}

.btn-red:focus, .btn-red.focus {
  color: #fff;
  background-color: #ca4545;
  border-color: #c33737;
}

.btn-red:focus.fs--shadow, .btn-red.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(235, 67, 67, 0.4);
}

.show > .btn.btn-red.dropdown-toggle, .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active {
  background-color: #c73939;
  border-color: #b73434;
  color: #fff;
}

.btn-red.disabled, .btn-red:disabled {
  background-color: #eb4343;
  border-color: #eb4343;
}

.btn-outline-red {
  color: #e30b0b;
  border-color: #ec5959;
  background-color: transparent;
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #eb4343;
  border-color: #eb4343;
}

.btn-outline-red:focus.fs--shadow, .btn-outline-red.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(235, 67, 67, 0.4);
}

.show > .btn.btn-outline-red.dropdown-toggle, .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #eb4343;
  border-color: #eb4343;
}

.show > .btn.btn-outline-red.dropdown-toggle.fs--shadow:focus, .btn-outline-red:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-red:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(235, 67, 67, 0.4);
}

.btn-outline-red.disabled, .btn-outline-red:disabled {
  border-color: #ec5959;
  color: #e30b0b;
}

.btn-light-red {
  color: #b12b2b;
  background-color: #fad8d8;
  border-color: #f4acac;
}

.btn-light-red:hover {
  color: #a02727;
  background-color: #f8c6c6;
  border-color: #f19595;
}

.btn-light-red:focus, .btn-light-red.focus {
  color: #992525;
  background-color: #f7bebe;
  border-color: #f18f8f;
}

.show > .btn.btn-light-red.dropdown-toggle, .btn-light-red:not(:disabled):not(.disabled):active, .btn-light-red:not(:disabled):not(.disabled).active {
  color: #922323;
  background-color: #f5b5b5;
  border-color: #f08989;
}

.btn-lighter-red {
  color: #c12f2f;
  background-color: #fcecec;
  border-color: #f4aeae;
}

.btn-lighter-red:hover {
  color: #b72c2c;
  background-color: #fbe1e1;
  border-color: #f19595;
}

.btn-lighter-red:focus, .btn-lighter-red.focus {
  color: #b32b2b;
  background-color: #fbdddd;
  border-color: #f18f8f;
}

.show > .btn.btn-lighter-red.dropdown-toggle, .btn-lighter-red:not(:disabled):not(.disabled):active, .btn-lighter-red:not(:disabled):not(.disabled).active {
  color: #ae2a2a;
  background-color: #fad8d8;
  border-color: #f08989;
}

.btn-green {
  color: #fff;
  background-color: #29916c;
  border-color: #29916c;
}

.btn-green:hover {
  color: #fff;
  background-color: #258361;
  border-color: #24805f;
}

.btn-green:focus, .btn-green.focus {
  color: #fff;
  background-color: #268864;
  border-color: #237e5d;
}

.btn-green:focus.fs--shadow, .btn-green.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(41, 145, 108, 0.4);
}

.show > .btn.btn-green.dropdown-toggle, .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active {
  background-color: #237c5b;
  border-color: #207254;
  color: #fff;
}

.btn-green.disabled, .btn-green:disabled {
  background-color: #29916c;
  border-color: #29916c;
}

.btn-outline-green {
  color: #1a7f5b;
  border-color: #63a88f;
  background-color: transparent;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #29916c;
  border-color: #29916c;
}

.btn-outline-green:focus.fs--shadow, .btn-outline-green.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(41, 145, 108, 0.4);
}

.show > .btn.btn-outline-green.dropdown-toggle, .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #29916c;
  border-color: #29916c;
}

.show > .btn.btn-outline-green.dropdown-toggle.fs--shadow:focus, .btn-outline-green:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-green:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(41, 145, 108, 0.4);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  border-color: #63a88f;
  color: #1a7f5b;
}

.btn-light-green {
  color: #1b7454;
  background-color: #dff3ec;
  border-color: #97d8c1;
}

.btn-light-green:hover {
  color: #1a6e50;
  background-color: #d0ede3;
  border-color: #88d3b8;
}

.btn-light-green:focus, .btn-light-green.focus {
  color: #196a4d;
  background-color: #c4e9dc;
  border-color: #7dcfb2;
}

.show > .btn.btn-light-green.dropdown-toggle, .btn-light-green:not(:disabled):not(.disabled):active, .btn-light-green:not(:disabled):not(.disabled).active {
  color: #18664a;
  background-color: #b7e4d4;
  border-color: #72cbab;
}

.btn-lighter-green {
  color: #1d7b59;
  background-color: #eff9f5;
  border-color: #a6deca;
}

.btn-lighter-green:hover {
  color: #1b7454;
  background-color: #e6f6f0;
  border-color: #8ad4b9;
}

.btn-lighter-green:focus, .btn-lighter-green.focus {
  color: #1b7152;
  background-color: #e3f5ee;
  border-color: #84d2b6;
}

.show > .btn.btn-lighter-green.dropdown-toggle, .btn-lighter-green:not(:disabled):not(.disabled):active, .btn-lighter-green:not(:disabled):not(.disabled).active {
  color: #1a6e50;
  background-color: #dff3ec;
  border-color: #7dcfb2;
}

.btn-orange {
  color: #fff;
  background-color: #ff8d03;
  border-color: #ff8d03;
}

.btn-orange:hover {
  color: #fff;
  background-color: #e67f03;
  border-color: #e07c03;
}

.btn-orange:focus, .btn-orange.focus {
  color: #fff;
  background-color: #e88003;
  border-color: #d77603;
}

.btn-orange:focus.fs--shadow, .btn-orange.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(255, 141, 3, 0.4);
}

.show > .btn.btn-orange.dropdown-toggle, .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active {
  background-color: #d97803;
  border-color: #c86e03;
  color: #fff;
}

.btn-orange.disabled, .btn-orange:disabled {
  background-color: #ff8d03;
  border-color: #ff8d03;
}

.btn-outline-orange {
  color: #a95c00;
  border-color: #ee9b35;
  background-color: transparent;
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: #ff8d03;
  border-color: #ff8d03;
}

.btn-outline-orange:focus.fs--shadow, .btn-outline-orange.focus.fs--shadow {
  box-shadow: 0 0 1px 2px rgba(255, 141, 3, 0.4);
}

.show > .btn.btn-outline-orange.dropdown-toggle, .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #ff8d03;
  border-color: #ff8d03;
}

.show > .btn.btn-outline-orange.dropdown-toggle.fs--shadow:focus, .btn-outline-orange:not(:disabled):not(.disabled):active.fs--shadow:focus, .btn-outline-orange:not(:disabled):not(.disabled).active.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(255, 141, 3, 0.4);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  border-color: #ee9b35;
  color: #a95c00;
}

.btn-light-orange {
  color: #9b5500;
  background-color: #ffecd3;
  border-color: #ffc075;
}

.btn-light-orange:hover {
  color: #935100;
  background-color: #ffe2be;
  border-color: #ffb65d;
}

.btn-light-orange:focus, .btn-light-orange.focus {
  color: #8e4e00;
  background-color: #ffdcb0;
  border-color: #ffaf4e;
}

.show > .btn.btn-light-orange.dropdown-toggle, .btn-light-orange:not(:disabled):not(.disabled):active, .btn-light-orange:not(:disabled):not(.disabled).active {
  color: #894b00;
  background-color: #ffd5a1;
  border-color: #ffa83e;
}

.btn-lighter-orange {
  color: #a35900;
  background-color: #fff5e9;
  border-color: #ffcc8e;
}

.btn-lighter-orange:hover {
  color: #9b5500;
  background-color: #fff0dd;
  border-color: #ffbc6a;
}

.btn-lighter-orange:focus, .btn-lighter-orange.focus {
  color: #975300;
  background-color: #ffeed8;
  border-color: #ffb962;
}

.show > .btn.btn-lighter-orange.dropdown-toggle, .btn-lighter-orange:not(:disabled):not(.disabled):active, .btn-lighter-orange:not(:disabled):not(.disabled).active {
  color: #925000;
  background-color: #ffecd3;
  border-color: #ffb55a;
}

.btn-h-default:hover {
  background-color: #4188b3;
  border-color: #4188b3;
  color: #fff;
}

.btn-h-default:hover.fs--shadow.focus, .btn-h-default:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(65, 136, 179, 0.4);
}

.btn-h-outline-default:hover {
  color: #2d77a4;
  border-color: #a9bdc9;
  background-color: transparent;
}

.btn-h-outline-default:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 189, 201, 0.4);
}

.btn-h-outline-default[class*="btn-outline-"]:hover {
  background-color: rgba(65, 136, 179, 0.0625);
  color: #2b729e;
}

.btn-h-light-default:hover {
  color: #376988;
  background-color: #e0ecf3;
  border-color: #afccdf;
}

.btn-h-light-default[class*="btn-light-"]:hover {
  color: #33627f;
  background-color: #d1e2ed;
  border-color: #a0c4d9;
}

.btn-h-lighter-default:hover {
  color: #3a6f90;
  background-color: #eff5f9;
  border-color: #b8d2e3;
}

.btn-h-lighter-default[class*="btn-lighter-"]:hover {
  color: #376989;
  background-color: #e7f0f5;
  border-color: #a2c5da;
}

.btn-h-primary:hover {
  background-color: #2c86d1;
  border-color: #2c86d1;
  color: #fff;
}

.btn-h-primary:hover.fs--shadow.focus, .btn-h-primary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(44, 134, 209, 0.4);
}

.btn-h-outline-primary:hover {
  color: #1b74bf;
  border-color: #5b9bd1;
  background-color: transparent;
}

.btn-h-outline-primary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(91, 155, 209, 0.4);
}

.btn-h-outline-primary[class*="btn-outline-"]:hover {
  background-color: rgba(44, 134, 209, 0.0625);
  color: #1a70b8;
}

.btn-h-light-primary:hover {
  color: #1766a8;
  background-color: #dbebf8;
  border-color: #a0c9eb;
}

.btn-h-light-primary[class*="btn-light-"]:hover {
  color: #165f9d;
  background-color: #c9e1f4;
  border-color: #83b8e5;
}

.btn-h-lighter-primary:hover {
  color: #196db3;
  background-color: #edf5fb;
  border-color: #a0c9eb;
}

.btn-h-lighter-primary[class*="btn-lighter-"]:hover {
  color: #1867aa;
  background-color: #e3eff9;
  border-color: #83b8e5;
}

.btn-h-secondary:hover {
  background-color: #678497;
  border-color: #678497;
  color: #fff;
}

.btn-h-secondary:hover.fs--shadow.focus, .btn-h-secondary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(103, 132, 151, 0.4);
}

.btn-h-outline-secondary:hover {
  color: #4e748d;
  border-color: #7f9bad;
  background-color: transparent;
}

.btn-h-outline-secondary:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(127, 155, 173, 0.4);
}

.btn-h-outline-secondary[class*="btn-outline-"]:hover {
  background-color: rgba(103, 132, 151, 0.0625);
  color: #4b7088;
}

.btn-h-light-secondary:hover {
  color: #4c6778;
  background-color: #e6eaed;
  border-color: #bfc8cf;
}

.btn-h-light-secondary[class*="btn-light-"]:hover {
  color: #476071;
  background-color: #dae0e4;
  border-color: #b0bcc5;
}

.btn-h-lighter-secondary:hover {
  color: #516e80;
  background-color: #f2f4f6;
  border-color: #c3ccd2;
}

.btn-h-lighter-secondary[class*="btn-lighter-"]:hover {
  color: #4d687a;
  background-color: #ebeff1;
  border-color: #b0bcc5;
}

.btn-h-info:hover {
  background-color: #008bb2;
  border-color: #008bb2;
  color: #fff;
}

.btn-h-info:hover.fs--shadow.focus, .btn-h-info:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(0, 139, 178, 0.4);
}

.btn-h-outline-info:hover {
  color: #00799b;
  border-color: #479fb7;
  background-color: transparent;
}

.btn-h-outline-info:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(71, 159, 183, 0.4);
}

.btn-h-outline-info[class*="btn-outline-"]:hover {
  background-color: rgba(0, 139, 178, 0.0625);
  color: #007393;
}

.btn-h-light-info:hover {
  color: #00708f;
  background-color: #d7f4fc;
  border-color: #7cd9f4;
}

.btn-h-light-info[class*="btn-light-"]:hover {
  color: #006a87;
  background-color: #c3eefa;
  border-color: #64d3f2;
}

.btn-h-lighter-info:hover {
  color: #007494;
  background-color: #ebf9fd;
  border-color: #8cdef5;
}

.btn-h-lighter-info[class*="btn-lighter-"]:hover {
  color: #006e8c;
  background-color: #e0f6fc;
  border-color: #69d4f2;
}

.btn-h-success:hover {
  background-color: #3f923f;
  border-color: #3f923f;
  color: #fff;
}

.btn-h-success:hover.fs--shadow.focus, .btn-h-success:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(63, 146, 63, 0.4);
}

.btn-h-outline-success:hover {
  color: #2c812c;
  border-color: #67a567;
  background-color: transparent;
}

.btn-h-outline-success:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(103, 165, 103, 0.4);
}

.btn-h-outline-success[class*="btn-outline-"]:hover {
  background-color: rgba(63, 146, 63, 0.0625);
  color: #2a7c2a;
}

.btn-h-light-success:hover {
  color: #2f732f;
  background-color: #e1f1e1;
  border-color: #a7d5a7;
}

.btn-h-light-success[class*="btn-light-"]:hover {
  color: #2d6d2d;
  background-color: #d3ead3;
  border-color: #9bcf9b;
}

.btn-h-lighter-success:hover {
  color: #327a32;
  background-color: #f0f8f0;
  border-color: #b5dcb5;
}

.btn-h-lighter-success[class*="btn-lighter-"]:hover {
  color: #2f742f;
  background-color: #e8f4e8;
  border-color: #9ed19e;
}

.btn-h-danger:hover {
  background-color: #df4b30;
  border-color: #df4b30;
  color: #fff;
}

.btn-h-danger:hover.fs--shadow.focus, .btn-h-danger:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(223, 75, 48, 0.4);
}

.btn-h-outline-danger:hover {
  color: #d53114;
  border-color: #e16b56;
  background-color: transparent;
}

.btn-h-outline-danger:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(225, 107, 86, 0.4);
}

.btn-h-outline-danger[class*="btn-outline-"]:hover {
  background-color: rgba(223, 75, 48, 0.0625);
  color: #ce2f13;
}

.btn-h-light-danger:hover {
  color: #b0341e;
  background-color: #f9dfda;
  border-color: #eeafa4;
}

.btn-h-light-danger[class*="btn-light-"]:hover {
  color: #a3301c;
  background-color: #f5cfc8;
  border-color: #e99788;
}

.btn-h-lighter-danger:hover {
  color: #b9361f;
  background-color: #fcefec;
  border-color: #eeafa4;
}

.btn-h-lighter-danger[class*="btn-lighter-"]:hover {
  color: #b0341e;
  background-color: #fae6e2;
  border-color: #e99788;
}

.btn-h-warning:hover {
  background-color: #ffbe50;
  border-color: #ffbe50;
  color: #22190b;
}

.btn-h-warning:hover.fs--shadow.focus, .btn-h-warning:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(255, 190, 80, 0.4);
}

.btn-h-outline-warning:hover {
  color: #c86800;
  border-color: #e6b461;
  background-color: transparent;
}

.btn-h-outline-warning:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(230, 180, 97, 0.4);
}

.btn-h-outline-warning[class*="btn-outline-"]:hover {
  background-color: rgba(255, 190, 80, 0.0625);
  color: #c06400;
}

.btn-h-light-warning:hover {
  color: #8d5f13;
  background-color: #fceed7;
  border-color: #f4c67a;
}

.btn-h-light-warning[class*="btn-light-"]:hover {
  color: #865a12;
  background-color: #fae6c3;
  border-color: #f1be69;
}

.btn-h-lighter-warning:hover {
  color: #916213;
  background-color: #fdf7eb;
  border-color: #f7d6a0;
}

.btn-h-lighter-warning[class*="btn-lighter-"]:hover {
  color: #8a5d12;
  background-color: #fcf2e0;
  border-color: #f4ca85;
}

.btn-h-dark:hover {
  background-color: #42474e;
  border-color: #42474e;
  color: #fff;
}

.btn-h-dark:hover.fs--shadow.focus, .btn-h-dark:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(66, 71, 78, 0.4);
}

.btn-h-outline-dark:hover {
  color: #42474e;
  border-color: #777b80;
  background-color: transparent;
}

.btn-h-outline-dark:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(119, 123, 128, 0.4);
}

.btn-h-outline-dark[class*="btn-outline-"]:hover {
  background-color: rgba(66, 71, 78, 0.0625);
  color: #3e434a;
}

.btn-h-light-dark:hover {
  color: #35393e;
  background-color: #e2e3e4;
  border-color: #aaacaf;
}

.btn-h-light-dark[class*="btn-light-"]:hover {
  color: #35393e;
  background-color: #d4d5d7;
  border-color: #8f9297;
}

.btn-h-lighter-dark:hover {
  color: #35393e;
  background-color: #eff0f0;
  border-color: #aaacaf;
}

.btn-h-lighter-dark[class*="btn-lighter-"]:hover {
  color: #32363b;
  background-color: #e8e9ea;
  border-color: #8f9297;
}

.btn-h-light:hover {
  background-color: #f8f9fb;
  border-color: #d4d7dd;
  color: #5d6069;
}

.btn-h-light:hover.fs--shadow.focus, .btn-h-light:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(212, 215, 221, 0.4);
}

.btn-h-outline-light:hover {
  color: #91969b;
  border-color: #d4d7dd;
  background-color: transparent;
}

.btn-h-outline-light:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(212, 215, 221, 0.4);
}

.btn-h-outline-light[class*="btn-outline-"]:hover {
  background-color: rgba(248, 249, 251, 0.0625);
  color: #8d9297;
}

.btn-h-light-light:hover {
  color: #6e7074;
  background-color: #fefeff;
  border-color: #d9d9e6;
}

.btn-h-light-light[class*="btn-light-"]:hover {
  color: #6e7074;
  background-color: #fefefe;
  border-color: #d7d7eb;
}

.btn-h-lighter-light:hover {
  color: #6f7175;
  background-color: #feffff;
  border-color: #d9d9e6;
}

.btn-h-lighter-light[class*="btn-lighter-"]:hover {
  color: #696b6f;
  background-color: #fefeff;
  border-color: #d1d1e8;
}

.btn-h-white:hover {
  background-color: #fff;
  border-color: #ddd;
  color: #606060;
}

.btn-h-white:hover.fs--shadow.focus, .btn-h-white:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(221, 221, 221, 0.4);
}

.btn-h-outline-white:hover {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.btn-h-outline-white:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.4);
}

.btn-h-outline-white[class*="btn-outline-"]:hover {
  background-color: rgba(255, 255, 255, 0.0625);
}

.btn-h-black:hover {
  background-color: #2c3034;
  border-color: #2c3034;
  color: #fff;
}

.btn-h-black:hover.fs--shadow.focus, .btn-h-black:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(44, 48, 52, 0.4);
}

.btn-h-outline-black:hover {
  color: #2c3034;
  border-color: #414549;
  background-color: transparent;
}

.btn-h-outline-black:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(65, 69, 73, 0.4);
}

.btn-h-outline-black[class*="btn-outline-"]:hover {
  background-color: rgba(44, 48, 52, 0.0625);
  color: #282c30;
}

.btn-h-light-black:hover {
  color: #23262a;
  background-color: #dfe0e0;
  border-color: #a0a2a4;
}

.btn-h-light-black[class*="btn-light-"]:hover {
  color: #23262a;
  background-color: #cfd0d1;
  border-color: #838587;
}

.btn-h-lighter-black:hover {
  color: #23262a;
  background-color: #eeeeee;
  border-color: #a0a2a4;
}

.btn-h-lighter-black[class*="btn-lighter-"]:hover {
  color: #212428;
  background-color: #e6e6e7;
  border-color: #838587;
}

.btn-h-grey:hover {
  background-color: #767679;
  border-color: #767679;
  color: #fff;
}

.btn-h-grey:hover.fs--shadow.focus, .btn-h-grey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(118, 118, 121, 0.4);
}

.btn-h-outline-grey:hover {
  color: #666;
  border-color: #919191;
  background-color: transparent;
}

.btn-h-outline-grey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(145, 145, 145, 0.4);
}

.btn-h-outline-grey[class*="btn-outline-"]:hover {
  background-color: rgba(118, 118, 121, 0.0625);
  color: #626262;
}

.btn-h-light-grey:hover {
  color: #636366;
  background-color: #e9e9e9;
  border-color: #c5c6c7;
}

.btn-h-light-grey[class*="btn-light-"]:hover {
  color: #5d5d60;
  background-color: #dedfdf;
  border-color: #b3b4b6;
}

.btn-h-lighter-grey:hover {
  color: #666669;
  background-color: #f4f4f4;
  border-color: #c5c6c7;
}

.btn-h-lighter-grey[class*="btn-lighter-"]:hover {
  color: #616164;
  background-color: #eeeeee;
  border-color: #b3b4b6;
}

.btn-h-purple:hover {
  background-color: #776bbe;
  border-color: #776bbe;
  color: #fff;
}

.btn-h-purple:hover.fs--shadow.focus, .btn-h-purple:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(119, 107, 190, 0.4);
}

.btn-h-outline-purple:hover {
  color: #7061c9;
  border-color: #988dd8;
  background-color: transparent;
}

.btn-h-outline-purple:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(152, 141, 216, 0.4);
}

.btn-h-outline-purple[class*="btn-outline-"]:hover {
  background-color: rgba(119, 107, 190, 0.0625);
  color: #6a5bc7;
}

.btn-h-light-purple:hover {
  color: #574a9f;
  background-color: #e4e2f1;
  border-color: #c2bddf;
}

.btn-h-light-purple[class*="btn-light-"]:hover {
  color: #574a9f;
  background-color: #d7d4ea;
  border-color: #afa8d5;
}

.btn-h-lighter-purple:hover {
  color: #574a9f;
  background-color: #f1f0f8;
  border-color: #c2bddf;
}

.btn-h-lighter-purple[class*="btn-lighter-"]:hover {
  color: #524797;
  background-color: #eae8f4;
  border-color: #afa8d5;
}

.btn-h-pink:hover {
  background-color: #da3776;
  border-color: #da3776;
  color: #fff;
}

.btn-h-pink:hover.fs--shadow.focus, .btn-h-pink:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(218, 55, 118, 0.4);
}

.btn-h-outline-pink:hover {
  color: #d81a63;
  border-color: #e35a8f;
  background-color: transparent;
}

.btn-h-outline-pink:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(227, 90, 143, 0.4);
}

.btn-h-outline-pink[class*="btn-outline-"]:hover {
  background-color: rgba(218, 55, 118, 0.0625);
  color: #d21960;
}

.btn-h-light-pink:hover {
  color: #b3255b;
  background-color: #f7dbe6;
  border-color: #eca7c2;
}

.btn-h-light-pink[class*="btn-light-"]:hover {
  color: #a42254;
  background-color: #f4cada;
  border-color: #e68caf;
}

.btn-h-lighter-pink:hover {
  color: #b5255c;
  background-color: #fbedf3;
  border-color: #eca7c2;
}

.btn-h-lighter-pink[class*="btn-lighter-"]:hover {
  color: #ac2358;
  background-color: #f9e3ec;
  border-color: #e68caf;
}

.btn-h-yellow:hover {
  background-color: #fbe77a;
  border-color: #e1ca53;
  color: #524b21;
}

.btn-h-yellow:hover.fs--shadow.focus, .btn-h-yellow:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(225, 202, 83, 0.4);
}

.btn-h-outline-yellow:hover {
  color: #aa641f;
  border-color: #ecc735;
  background-color: transparent;
}

.btn-h-outline-yellow:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(236, 199, 53, 0.4);
}

.btn-h-outline-yellow[class*="btn-outline-"]:hover {
  background-color: rgba(251, 231, 122, 0.0625);
  color: #a4601e;
}

.btn-h-light-yellow:hover {
  color: #796c22;
  background-color: #fdf7d6;
  border-color: #f3d120;
}

.btn-h-light-yellow[class*="btn-light-"]:hover {
  color: #756821;
  background-color: #fcf3c2;
  border-color: #eecc0c;
}

.btn-h-lighter-yellow:hover {
  color: #7b6e23;
  background-color: #fefbea;
  border-color: #f6db51;
}

.btn-h-lighter-yellow[class*="btn-lighter-"]:hover {
  color: #756821;
  background-color: #fdf9df;
  border-color: #f3d31c;
}

.btn-h-lightgrey:hover {
  background-color: #7b818a;
  border-color: #7b818a;
  color: #fff;
}

.btn-h-lightgrey:hover.fs--shadow.focus, .btn-h-lightgrey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(123, 129, 138, 0.4);
}

.btn-h-outline-lightgrey:hover {
  color: #787d82;
  border-color: #d0d5da;
  background-color: transparent;
}

.btn-h-outline-lightgrey:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(208, 213, 218, 0.4);
}

.btn-h-outline-lightgrey[class*="btn-outline-"]:hover {
  background-color: rgba(123, 129, 138, 0.0625);
  color: #74797e;
}

.btn-h-light-lightgrey:hover {
  color: #606469;
  background-color: #e8e9eb;
  border-color: #c5c7cb;
}

.btn-h-light-lightgrey[class*="btn-light-"]:hover {
  color: #5a5d62;
  background-color: #dddfe1;
  border-color: #bbbec3;
}

.btn-h-lighter-lightgrey:hover {
  color: #676a6f;
  background-color: #f3f4f5;
  border-color: #ced0d3;
}

.btn-h-lighter-lightgrey[class*="btn-lighter-"]:hover {
  color: #61656a;
  background-color: #edeeef;
  border-color: #bec1c5;
}

.btn-h-brown:hover {
  background-color: #b6703f;
  border-color: #b6703f;
  color: #fff;
}

.btn-h-brown:hover.fs--shadow.focus, .btn-h-brown:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(182, 112, 63, 0.4);
}

.btn-h-outline-brown:hover {
  color: #a85f2b;
  border-color: #c08c67;
  background-color: transparent;
}

.btn-h-outline-brown:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(192, 140, 103, 0.4);
}

.btn-h-outline-brown[class*="btn-outline-"]:hover {
  background-color: rgba(182, 112, 63, 0.0625);
  color: #a25b2a;
}

.btn-h-light-brown:hover {
  color: #8f572f;
  background-color: #f3e8df;
  border-color: #e0c1ac;
}

.btn-h-light-brown[class*="btn-light-"]:hover {
  color: #86512c;
  background-color: #eddcd0;
  border-color: #d7b297;
}

.btn-h-lighter-brown:hover {
  color: #975b31;
  background-color: #f9f3ef;
  border-color: #e1c4b0;
}

.btn-h-lighter-brown[class*="btn-lighter-"]:hover {
  color: #8f572f;
  background-color: #f6ede6;
  border-color: #d7b297;
}

.btn-h-blue:hover {
  background-color: #2d81e7;
  border-color: #2d81e7;
  color: #fff;
}

.btn-h-blue:hover.fs--shadow.focus, .btn-h-blue:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(45, 129, 231, 0.4);
}

.btn-h-outline-blue:hover {
  color: #0d6ce1;
  border-color: #5096e9;
  background-color: transparent;
}

.btn-h-outline-blue:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(80, 150, 233, 0.4);
}

.btn-h-outline-blue[class*="btn-outline-"]:hover {
  background-color: rgba(45, 129, 231, 0.0625);
  color: #0c69da;
}

.btn-h-light-blue:hover {
  color: #2362ae;
  background-color: #d9e8fa;
  border-color: #a4c7f2;
}

.btn-h-light-blue[class*="btn-light-"]:hover {
  color: #205ba2;
  background-color: #c6ddf7;
  border-color: #8db9ef;
}

.btn-h-lighter-blue:hover {
  color: #2569bb;
  background-color: #ecf4fc;
  border-color: #a8caf3;
}

.btn-h-lighter-blue[class*="btn-lighter-"]:hover {
  color: #2364b2;
  background-color: #e1edfb;
  border-color: #8db9ef;
}

.btn-h-red:hover {
  background-color: #eb4343;
  border-color: #eb4343;
  color: #fff;
}

.btn-h-red:hover.fs--shadow.focus, .btn-h-red:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(235, 67, 67, 0.4);
}

.btn-h-outline-red:hover {
  color: #e30b0b;
  border-color: #eb5050;
  background-color: transparent;
}

.btn-h-outline-red:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(235, 80, 80, 0.4);
}

.btn-h-outline-red[class*="btn-outline-"]:hover {
  background-color: rgba(235, 67, 67, 0.0625);
  color: #dc0b0b;
}

.btn-h-light-red:hover {
  color: #b12b2b;
  background-color: #fad8d8;
  border-color: #f4acac;
}

.btn-h-light-red[class*="btn-light-"]:hover {
  color: #a02727;
  background-color: #f8c6c6;
  border-color: #f19595;
}

.btn-h-lighter-red:hover {
  color: #c12f2f;
  background-color: #fcecec;
  border-color: #f4aeae;
}

.btn-h-lighter-red[class*="btn-lighter-"]:hover {
  color: #b72c2c;
  background-color: #fbe1e1;
  border-color: #f19595;
}

.btn-h-green:hover {
  background-color: #29916c;
  border-color: #29916c;
  color: #fff;
}

.btn-h-green:hover.fs--shadow.focus, .btn-h-green:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(41, 145, 108, 0.4);
}

.btn-h-outline-green:hover {
  color: #1a7f5b;
  border-color: #5aa389;
  background-color: transparent;
}

.btn-h-outline-green:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(90, 163, 137, 0.4);
}

.btn-h-outline-green[class*="btn-outline-"]:hover {
  background-color: rgba(41, 145, 108, 0.0625);
  color: #187956;
}

.btn-h-light-green:hover {
  color: #1b7454;
  background-color: #dff3ec;
  border-color: #97d8c1;
}

.btn-h-light-green[class*="btn-light-"]:hover {
  color: #1a6e50;
  background-color: #d0ede3;
  border-color: #88d3b8;
}

.btn-h-lighter-green:hover {
  color: #1d7b59;
  background-color: #eff9f5;
  border-color: #a6deca;
}

.btn-h-lighter-green[class*="btn-lighter-"]:hover {
  color: #1b7454;
  background-color: #e6f6f0;
  border-color: #8ad4b9;
}

.btn-h-orange:hover {
  background-color: #ff8d03;
  border-color: #ff8d03;
  color: #fff;
}

.btn-h-orange:hover.fs--shadow.focus, .btn-h-orange:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(255, 141, 3, 0.4);
}

.btn-h-outline-orange:hover {
  color: #a95c00;
  border-color: #ee9b35;
  background-color: transparent;
}

.btn-h-outline-orange:hover.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 155, 53, 0.4);
}

.btn-h-outline-orange[class*="btn-outline-"]:hover {
  background-color: rgba(255, 141, 3, 0.0625);
  color: #a15800;
}

.btn-h-light-orange:hover {
  color: #9b5500;
  background-color: #ffecd3;
  border-color: #ffc075;
}

.btn-h-light-orange[class*="btn-light-"]:hover {
  color: #935100;
  background-color: #ffe2be;
  border-color: #ffb65d;
}

.btn-h-lighter-orange:hover {
  color: #a35900;
  background-color: #fff5e9;
  border-color: #ffcc8e;
}

.btn-h-lighter-orange[class*="btn-lighter-"]:hover {
  color: #9b5500;
  background-color: #fff0dd;
  border-color: #ffbc6a;
}

.btn-a-default:not(:disabled):not(.disabled):active,
.btn-a-default:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-default.dropdown-toggle {
  color: #fff;
  background-color: #3a7aa1;
  border-color: #39789e;
}

.btn-a-default:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-default:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-default.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(57, 120, 158, 0.4);
}

.btn-a-outline-default:not(:disabled):not(.disabled):active,
.btn-a-outline-default:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-default.dropdown-toggle {
  color: #2d77a4;
  border-color: #a9bdc9;
  background-color: transparent;
}

.btn-a-outline-default:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-default:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-default.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(58, 122, 161, 0.085);
  color: #2a709b;
}

.btn-a-outline-default:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-default:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-default.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(169, 189, 201, 0.4);
}

.show > .btn.btn-a-light-default.dropdown-toggle, .btn-a-light-default:not(:disabled):not(.disabled):active, .btn-a-light-default:not(:disabled):not(.disabled).active {
  color: #33627f;
  background-color: #d1e2ed;
  border-color: #a0c4d9;
}

.show > .btn.btn-a-lighter-default.dropdown-toggle, .btn-a-lighter-default:not(:disabled):not(.disabled):active, .btn-a-lighter-default:not(:disabled):not(.disabled).active {
  color: #376989;
  background-color: #e7f0f5;
  border-color: #a2c5da;
}

.btn-a-primary:not(:disabled):not(.disabled):active,
.btn-a-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-primary.dropdown-toggle {
  color: #fff;
  background-color: #2779bc;
  border-color: #2676b8;
}

.btn-a-primary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-primary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-primary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(38, 118, 184, 0.4);
}

.btn-a-outline-primary:not(:disabled):not(.disabled):active,
.btn-a-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-primary.dropdown-toggle {
  color: #1b74bf;
  border-color: #5195ce;
  background-color: transparent;
}

.btn-a-outline-primary:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-primary:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-primary.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(39, 121, 188, 0.085);
  color: #196eb5;
}

.btn-a-outline-primary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-primary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-primary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 149, 206, 0.4);
}

.show > .btn.btn-a-light-primary.dropdown-toggle, .btn-a-light-primary:not(:disabled):not(.disabled):active, .btn-a-light-primary:not(:disabled):not(.disabled).active {
  color: #165f9d;
  background-color: #c9e1f4;
  border-color: #83b8e5;
}

.show > .btn.btn-a-lighter-primary.dropdown-toggle, .btn-a-lighter-primary:not(:disabled):not(.disabled):active, .btn-a-lighter-primary:not(:disabled):not(.disabled).active {
  color: #1867aa;
  background-color: #e3eff9;
  border-color: #83b8e5;
}

.btn-a-secondary:not(:disabled):not(.disabled):active,
.btn-a-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d7788;
  border-color: #5b7485;
}

.btn-a-secondary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-secondary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-secondary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(91, 116, 133, 0.4);
}

.btn-a-outline-secondary:not(:disabled):not(.disabled):active,
.btn-a-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-secondary.dropdown-toggle {
  color: #4e748d;
  border-color: #7895a9;
  background-color: transparent;
}

.btn-a-outline-secondary:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-secondary:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-secondary.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(93, 119, 136, 0.085);
  color: #4a6e86;
}

.btn-a-outline-secondary:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-secondary:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-secondary.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(120, 149, 169, 0.4);
}

.show > .btn.btn-a-light-secondary.dropdown-toggle, .btn-a-light-secondary:not(:disabled):not(.disabled):active, .btn-a-light-secondary:not(:disabled):not(.disabled).active {
  color: #476071;
  background-color: #dae0e4;
  border-color: #b0bcc5;
}

.show > .btn.btn-a-lighter-secondary.dropdown-toggle, .btn-a-lighter-secondary:not(:disabled):not(.disabled):active, .btn-a-lighter-secondary:not(:disabled):not(.disabled).active {
  color: #4d687a;
  background-color: #ebeff1;
  border-color: #b0bcc5;
}

.btn-a-info:not(:disabled):not(.disabled):active,
.btn-a-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-info.dropdown-toggle {
  color: #fff;
  background-color: #007da0;
  border-color: #007a9d;
}

.btn-a-info:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-info:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-info.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(0, 122, 157, 0.4);
}

.btn-a-outline-info:not(:disabled):not(.disabled):active,
.btn-a-outline-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-info.dropdown-toggle {
  color: #00799b;
  border-color: #3d99b3;
  background-color: transparent;
}

.btn-a-outline-info:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-info:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-info.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(0, 125, 160, 0.085);
  color: #00708f;
}

.btn-a-outline-info:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-info:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-info.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(61, 153, 179, 0.4);
}

.show > .btn.btn-a-light-info.dropdown-toggle, .btn-a-light-info:not(:disabled):not(.disabled):active, .btn-a-light-info:not(:disabled):not(.disabled).active {
  color: #006a87;
  background-color: #c3eefa;
  border-color: #64d3f2;
}

.show > .btn.btn-a-lighter-info.dropdown-toggle, .btn-a-lighter-info:not(:disabled):not(.disabled):active, .btn-a-lighter-info:not(:disabled):not(.disabled).active {
  color: #006e8c;
  background-color: #e0f6fc;
  border-color: #69d4f2;
}

.btn-a-success:not(:disabled):not(.disabled):active,
.btn-a-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-success.dropdown-toggle {
  color: #fff;
  background-color: #398339;
  border-color: #388038;
}

.btn-a-success:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-success:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-success.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(56, 128, 56, 0.4);
}

.btn-a-outline-success:not(:disabled):not(.disabled):active,
.btn-a-outline-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-success.dropdown-toggle {
  color: #2c812c;
  border-color: #5ea05e;
  background-color: transparent;
}

.btn-a-outline-success:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-success:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-success.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(57, 131, 57, 0.085);
  color: #297929;
}

.btn-a-outline-success:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-success:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-success.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(94, 160, 94, 0.4);
}

.show > .btn.btn-a-light-success.dropdown-toggle, .btn-a-light-success:not(:disabled):not(.disabled):active, .btn-a-light-success:not(:disabled):not(.disabled).active {
  color: #2d6d2d;
  background-color: #d3ead3;
  border-color: #9bcf9b;
}

.show > .btn.btn-a-lighter-success.dropdown-toggle, .btn-a-lighter-success:not(:disabled):not(.disabled):active, .btn-a-lighter-success:not(:disabled):not(.disabled).active {
  color: #2f742f;
  background-color: #e8f4e8;
  border-color: #9ed19e;
}

.btn-a-danger:not(:disabled):not(.disabled):active,
.btn-a-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9432b;
  border-color: #c4422a;
}

.btn-a-danger:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-danger:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-danger.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(196, 66, 42, 0.4);
}

.btn-a-outline-danger:not(:disabled):not(.disabled):active,
.btn-a-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-danger.dropdown-toggle {
  color: #d53114;
  border-color: #df624c;
  background-color: transparent;
}

.btn-a-outline-danger:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-danger:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-danger.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(201, 67, 43, 0.085);
  color: #cb2f13;
}

.btn-a-outline-danger:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-danger:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-danger.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(223, 98, 76, 0.4);
}

.show > .btn.btn-a-light-danger.dropdown-toggle, .btn-a-light-danger:not(:disabled):not(.disabled):active, .btn-a-light-danger:not(:disabled):not(.disabled).active {
  color: #a3301c;
  background-color: #f5cfc8;
  border-color: #e99788;
}

.show > .btn.btn-a-lighter-danger.dropdown-toggle, .btn-a-lighter-danger:not(:disabled):not(.disabled):active, .btn-a-lighter-danger:not(:disabled):not(.disabled).active {
  color: #b0341e;
  background-color: #fae6e2;
  border-color: #e99788;
}

.btn-a-warning:not(:disabled):not(.disabled):active,
.btn-a-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-warning.dropdown-toggle {
  color: #22190b;
  background-color: #e6ab48;
  border-color: #e0a746;
}

.btn-a-warning:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-warning:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-warning.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(224, 167, 70, 0.4);
}

.btn-a-outline-warning:not(:disabled):not(.disabled):active,
.btn-a-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-warning.dropdown-toggle {
  color: #c86800;
  border-color: #e6b461;
  background-color: transparent;
}

.btn-a-outline-warning:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-warning:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-warning.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(230, 171, 72, 0.085);
  color: #bd6200;
}

.btn-a-outline-warning:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-warning:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-warning.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(230, 180, 97, 0.4);
}

.show > .btn.btn-a-light-warning.dropdown-toggle, .btn-a-light-warning:not(:disabled):not(.disabled):active, .btn-a-light-warning:not(:disabled):not(.disabled).active {
  color: #865a12;
  background-color: #fae6c3;
  border-color: #f1be69;
}

.show > .btn.btn-a-lighter-warning.dropdown-toggle, .btn-a-lighter-warning:not(:disabled):not(.disabled):active, .btn-a-lighter-warning:not(:disabled):not(.disabled).active {
  color: #8a5d12;
  background-color: #fcf2e0;
  border-color: #f4ca85;
}

.btn-a-dark:not(:disabled):not(.disabled):active,
.btn-a-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-dark.dropdown-toggle {
  color: #fff;
  background-color: #3b4046;
  border-color: #3a3e45;
}

.btn-a-dark:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-dark:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-dark.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(58, 62, 69, 0.4);
}

.btn-a-outline-dark:not(:disabled):not(.disabled):active,
.btn-a-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-dark.dropdown-toggle {
  color: #42474e;
  border-color: #6f7378;
  background-color: transparent;
}

.btn-a-outline-dark:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-dark:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-dark.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(59, 64, 70, 0.085);
  color: #3d4148;
}

.btn-a-outline-dark:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-dark:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-dark.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(111, 115, 120, 0.4);
}

.show > .btn.btn-a-light-dark.dropdown-toggle, .btn-a-light-dark:not(:disabled):not(.disabled):active, .btn-a-light-dark:not(:disabled):not(.disabled).active {
  color: #35393e;
  background-color: #d4d5d7;
  border-color: #8f9297;
}

.show > .btn.btn-a-lighter-dark.dropdown-toggle, .btn-a-lighter-dark:not(:disabled):not(.disabled):active, .btn-a-lighter-dark:not(:disabled):not(.disabled).active {
  color: #32363b;
  background-color: #e8e9ea;
  border-color: #8f9297;
}

.btn-a-light:not(:disabled):not(.disabled):active,
.btn-a-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-light.dropdown-toggle {
  color: #5d6069;
  background-color: #eaecf0;
  border-color: #cbcdd1;
}

.btn-a-light:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-light:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-light.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(203, 205, 209, 0.4);
}

.btn-a-outline-light:not(:disabled):not(.disabled):active,
.btn-a-outline-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-light.dropdown-toggle {
  color: #91969b;
  border-color: #d4d7dd;
  background-color: transparent;
}

.btn-a-outline-light:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-light:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-light.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(234, 236, 240, 0.085);
  color: #8b9096;
}

.btn-a-outline-light:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-light:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-light.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(212, 215, 221, 0.4);
}

.show > .btn.btn-a-light-light.dropdown-toggle, .btn-a-light-light:not(:disabled):not(.disabled):active, .btn-a-light-light:not(:disabled):not(.disabled).active {
  color: #6e7074;
  background-color: #fefefe;
  border-color: #d7d7eb;
}

.show > .btn.btn-a-lighter-light.dropdown-toggle, .btn-a-lighter-light:not(:disabled):not(.disabled):active, .btn-a-lighter-light:not(:disabled):not(.disabled).active {
  color: #696b6f;
  background-color: #fefeff;
  border-color: #d1d1e8;
}

.btn-a-white:not(:disabled):not(.disabled):active,
.btn-a-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-white.dropdown-toggle {
  color: #606060;
  background-color: #fff;
  border-color: #c5c5c5;
}

.btn-a-white:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-white:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-white.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(197, 197, 197, 0.4);
}

.btn-a-outline-white:not(:disabled):not(.disabled):active,
.btn-a-outline-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-white.dropdown-toggle {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.btn-a-outline-white:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-white:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-white.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(255, 255, 255, 0.085);
}

.btn-a-outline-white:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-white:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-white.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.4);
}

.btn-a-black:not(:disabled):not(.disabled):active,
.btn-a-black:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-black.dropdown-toggle {
  color: #fff;
  background-color: #282b2f;
  border-color: #272a2e;
}

.btn-a-black:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-black:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-black.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(39, 42, 46, 0.4);
}

.btn-a-outline-black:not(:disabled):not(.disabled):active,
.btn-a-outline-black:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-black.dropdown-toggle {
  color: #2c3034;
  border-color: #414549;
  background-color: transparent;
}

.btn-a-outline-black:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-black:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-black.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(40, 43, 47, 0.085);
  color: #272a2e;
}

.btn-a-outline-black:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-black:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-black.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(65, 69, 73, 0.4);
}

.show > .btn.btn-a-light-black.dropdown-toggle, .btn-a-light-black:not(:disabled):not(.disabled):active, .btn-a-light-black:not(:disabled):not(.disabled).active {
  color: #23262a;
  background-color: #cfd0d1;
  border-color: #838587;
}

.show > .btn.btn-a-lighter-black.dropdown-toggle, .btn-a-lighter-black:not(:disabled):not(.disabled):active, .btn-a-lighter-black:not(:disabled):not(.disabled).active {
  color: #212428;
  background-color: #e6e6e7;
  border-color: #838587;
}

.btn-a-grey:not(:disabled):not(.disabled):active,
.btn-a-grey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-grey.dropdown-toggle {
  color: #fff;
  background-color: #6a6b6d;
  border-color: #67686b;
}

.btn-a-grey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-grey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-grey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(103, 104, 107, 0.4);
}

.btn-a-outline-grey:not(:disabled):not(.disabled):active,
.btn-a-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-grey.dropdown-toggle {
  color: #666;
  border-color: #8b8b8b;
  background-color: transparent;
}

.btn-a-outline-grey:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-grey:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-grey.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(106, 107, 109, 0.085);
  color: #606060;
}

.btn-a-outline-grey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-grey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-grey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(139, 139, 139, 0.4);
}

.show > .btn.btn-a-light-grey.dropdown-toggle, .btn-a-light-grey:not(:disabled):not(.disabled):active, .btn-a-light-grey:not(:disabled):not(.disabled).active {
  color: #5d5d60;
  background-color: #dedfdf;
  border-color: #b3b4b6;
}

.show > .btn.btn-a-lighter-grey.dropdown-toggle, .btn-a-lighter-grey:not(:disabled):not(.disabled):active, .btn-a-lighter-grey:not(:disabled):not(.disabled).active {
  color: #616164;
  background-color: #eeeeee;
  border-color: #b3b4b6;
}

.btn-a-purple:not(:disabled):not(.disabled):active,
.btn-a-purple:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-purple.dropdown-toggle {
  color: #fff;
  background-color: #6b60ab;
  border-color: #695ea7;
}

.btn-a-purple:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-purple:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-purple.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(105, 94, 167, 0.4);
}

.btn-a-outline-purple:not(:disabled):not(.disabled):active,
.btn-a-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-purple.dropdown-toggle {
  color: #7061c9;
  border-color: #9287d6;
  background-color: transparent;
}

.btn-a-outline-purple:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-purple:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-purple.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(107, 96, 171, 0.085);
  color: #6858c6;
}

.btn-a-outline-purple:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-purple:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-purple.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(146, 135, 214, 0.4);
}

.show > .btn.btn-a-light-purple.dropdown-toggle, .btn-a-light-purple:not(:disabled):not(.disabled):active, .btn-a-light-purple:not(:disabled):not(.disabled).active {
  color: #574a9f;
  background-color: #d7d4ea;
  border-color: #afa8d5;
}

.show > .btn.btn-a-lighter-purple.dropdown-toggle, .btn-a-lighter-purple:not(:disabled):not(.disabled):active, .btn-a-lighter-purple:not(:disabled):not(.disabled).active {
  color: #524797;
  background-color: #eae8f4;
  border-color: #afa8d5;
}

.btn-a-pink:not(:disabled):not(.disabled):active,
.btn-a-pink:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-pink.dropdown-toggle {
  color: #fff;
  background-color: #c4326a;
  border-color: #c03168;
}

.btn-a-pink:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-pink:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-pink.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(192, 49, 104, 0.4);
}

.btn-a-outline-pink:not(:disabled):not(.disabled):active,
.btn-a-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-pink.dropdown-toggle {
  color: #d81a63;
  border-color: #e25189;
  background-color: transparent;
}

.btn-a-outline-pink:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-pink:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-pink.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(196, 50, 106, 0.085);
  color: #ce195f;
}

.btn-a-outline-pink:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-pink:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-pink.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(226, 81, 137, 0.4);
}

.show > .btn.btn-a-light-pink.dropdown-toggle, .btn-a-light-pink:not(:disabled):not(.disabled):active, .btn-a-light-pink:not(:disabled):not(.disabled).active {
  color: #a42254;
  background-color: #f4cada;
  border-color: #e68caf;
}

.show > .btn.btn-a-lighter-pink.dropdown-toggle, .btn-a-lighter-pink:not(:disabled):not(.disabled):active, .btn-a-lighter-pink:not(:disabled):not(.disabled).active {
  color: #ac2358;
  background-color: #f9e3ec;
  border-color: #e68caf;
}

.btn-a-yellow:not(:disabled):not(.disabled):active,
.btn-a-yellow:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-yellow.dropdown-toggle {
  color: #524b21;
  background-color: #f0db67;
  border-color: #c6b249;
}

.btn-a-yellow:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-yellow:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-yellow.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(198, 178, 73, 0.4);
}

.btn-a-outline-yellow:not(:disabled):not(.disabled):active,
.btn-a-outline-yellow:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-yellow.dropdown-toggle {
  color: #aa641f;
  border-color: #ecc735;
  background-color: transparent;
}

.btn-a-outline-yellow:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-yellow:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-yellow.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(240, 219, 103, 0.085);
  color: #a05e1d;
}

.btn-a-outline-yellow:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-yellow:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-yellow.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(236, 199, 53, 0.4);
}

.show > .btn.btn-a-light-yellow.dropdown-toggle, .btn-a-light-yellow:not(:disabled):not(.disabled):active, .btn-a-light-yellow:not(:disabled):not(.disabled).active {
  color: #756821;
  background-color: #fcf3c2;
  border-color: #eecc0c;
}

.show > .btn.btn-a-lighter-yellow.dropdown-toggle, .btn-a-lighter-yellow:not(:disabled):not(.disabled):active, .btn-a-lighter-yellow:not(:disabled):not(.disabled).active {
  color: #756821;
  background-color: #fdf9df;
  border-color: #f3d31c;
}

.btn-a-lightgrey:not(:disabled):not(.disabled):active,
.btn-a-lightgrey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-lightgrey.dropdown-toggle {
  color: #fff;
  background-color: #6f747c;
  border-color: #6d7279;
}

.btn-a-lightgrey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-lightgrey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-lightgrey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(109, 114, 121, 0.4);
}

.btn-a-outline-lightgrey:not(:disabled):not(.disabled):active,
.btn-a-outline-lightgrey:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-lightgrey.dropdown-toggle {
  color: #787d82;
  border-color: #d0d5da;
  background-color: transparent;
}

.btn-a-outline-lightgrey:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-lightgrey:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-lightgrey.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(111, 116, 124, 0.085);
  color: #72777c;
}

.btn-a-outline-lightgrey:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-lightgrey:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-lightgrey.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(208, 213, 218, 0.4);
}

.show > .btn.btn-a-light-lightgrey.dropdown-toggle, .btn-a-light-lightgrey:not(:disabled):not(.disabled):active, .btn-a-light-lightgrey:not(:disabled):not(.disabled).active {
  color: #5a5d62;
  background-color: #dddfe1;
  border-color: #bbbec3;
}

.show > .btn.btn-a-lighter-lightgrey.dropdown-toggle, .btn-a-lighter-lightgrey:not(:disabled):not(.disabled):active, .btn-a-lighter-lightgrey:not(:disabled):not(.disabled).active {
  color: #61656a;
  background-color: #edeeef;
  border-color: #bec1c5;
}

.btn-a-brown:not(:disabled):not(.disabled):active,
.btn-a-brown:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-brown.dropdown-toggle {
  color: #fff;
  background-color: #a46538;
  border-color: #a06237;
}

.btn-a-brown:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-brown:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-brown.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(160, 98, 55, 0.4);
}

.btn-a-outline-brown:not(:disabled):not(.disabled):active,
.btn-a-outline-brown:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-brown.dropdown-toggle {
  color: #a85f2b;
  border-color: #bd855e;
  background-color: transparent;
}

.btn-a-outline-brown:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-brown:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-brown.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(164, 101, 56, 0.085);
  color: #9f5a29;
}

.btn-a-outline-brown:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-brown:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-brown.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(189, 133, 94, 0.4);
}

.show > .btn.btn-a-light-brown.dropdown-toggle, .btn-a-light-brown:not(:disabled):not(.disabled):active, .btn-a-light-brown:not(:disabled):not(.disabled).active {
  color: #86512c;
  background-color: #eddcd0;
  border-color: #d7b297;
}

.show > .btn.btn-a-lighter-brown.dropdown-toggle, .btn-a-lighter-brown:not(:disabled):not(.disabled):active, .btn-a-lighter-brown:not(:disabled):not(.disabled).active {
  color: #8f572f;
  background-color: #f6ede6;
  border-color: #d7b297;
}

.btn-a-blue:not(:disabled):not(.disabled):active,
.btn-a-blue:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-blue.dropdown-toggle {
  color: #fff;
  background-color: #2874d0;
  border-color: #2771cb;
}

.btn-a-blue:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-blue:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-blue.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(39, 113, 203, 0.4);
}

.btn-a-outline-blue:not(:disabled):not(.disabled):active,
.btn-a-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-blue.dropdown-toggle {
  color: #0d6ce1;
  border-color: #4790e8;
  background-color: transparent;
}

.btn-a-outline-blue:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-blue:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-blue.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(40, 116, 208, 0.085);
  color: #0c67d6;
}

.btn-a-outline-blue:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-blue:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-blue.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(71, 144, 232, 0.4);
}

.show > .btn.btn-a-light-blue.dropdown-toggle, .btn-a-light-blue:not(:disabled):not(.disabled):active, .btn-a-light-blue:not(:disabled):not(.disabled).active {
  color: #205ba2;
  background-color: #c6ddf7;
  border-color: #8db9ef;
}

.show > .btn.btn-a-lighter-blue.dropdown-toggle, .btn-a-lighter-blue:not(:disabled):not(.disabled):active, .btn-a-lighter-blue:not(:disabled):not(.disabled).active {
  color: #2364b2;
  background-color: #e1edfb;
  border-color: #8db9ef;
}

.btn-a-red:not(:disabled):not(.disabled):active,
.btn-a-red:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-red.dropdown-toggle {
  color: #fff;
  background-color: #d33d3d;
  border-color: #cf3b3b;
}

.btn-a-red:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-red:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-red.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(207, 59, 59, 0.4);
}

.btn-a-outline-red:not(:disabled):not(.disabled):active,
.btn-a-outline-red:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-red.dropdown-toggle {
  color: #e30b0b;
  border-color: #ea4646;
  background-color: transparent;
}

.btn-a-outline-red:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-red:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-red.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(211, 61, 61, 0.085);
  color: #d80b0b;
}

.btn-a-outline-red:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-red:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-red.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(234, 70, 70, 0.4);
}

.show > .btn.btn-a-light-red.dropdown-toggle, .btn-a-light-red:not(:disabled):not(.disabled):active, .btn-a-light-red:not(:disabled):not(.disabled).active {
  color: #a02727;
  background-color: #f8c6c6;
  border-color: #f19595;
}

.show > .btn.btn-a-lighter-red.dropdown-toggle, .btn-a-lighter-red:not(:disabled):not(.disabled):active, .btn-a-lighter-red:not(:disabled):not(.disabled).active {
  color: #b72c2c;
  background-color: #fbe1e1;
  border-color: #f19595;
}

.btn-a-green:not(:disabled):not(.disabled):active,
.btn-a-green:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-green.dropdown-toggle {
  color: #fff;
  background-color: #258361;
  border-color: #24805f;
}

.btn-a-green:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-green:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-green.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(36, 128, 95, 0.4);
}

.btn-a-outline-green:not(:disabled):not(.disabled):active,
.btn-a-outline-green:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-green.dropdown-toggle {
  color: #1a7f5b;
  border-color: #519e82;
  background-color: transparent;
}

.btn-a-outline-green:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-green:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-green.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(37, 131, 97, 0.085);
  color: #187654;
}

.btn-a-outline-green:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-green:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-green.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(81, 158, 130, 0.4);
}

.show > .btn.btn-a-light-green.dropdown-toggle, .btn-a-light-green:not(:disabled):not(.disabled):active, .btn-a-light-green:not(:disabled):not(.disabled).active {
  color: #1a6e50;
  background-color: #d0ede3;
  border-color: #88d3b8;
}

.show > .btn.btn-a-lighter-green.dropdown-toggle, .btn-a-lighter-green:not(:disabled):not(.disabled):active, .btn-a-lighter-green:not(:disabled):not(.disabled).active {
  color: #1b7454;
  background-color: #e6f6f0;
  border-color: #8ad4b9;
}

.btn-a-orange:not(:disabled):not(.disabled):active,
.btn-a-orange:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-orange.dropdown-toggle {
  color: #fff;
  background-color: #e67f03;
  border-color: #e07c03;
}

.btn-a-orange:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-orange:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-orange.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(224, 124, 3, 0.4);
}

.btn-a-outline-orange:not(:disabled):not(.disabled):active,
.btn-a-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-orange.dropdown-toggle {
  color: #a95c00;
  border-color: #ee9b35;
  background-color: transparent;
}

.btn-a-outline-orange:not(:disabled):not(.disabled):active[class*="btn-outline-"],
.btn-a-outline-orange:not(:disabled):not(.disabled).active[class*="btn-outline-"],
.show > .btn.btn-a-outline-orange.dropdown-toggle[class*="btn-outline-"] {
  background-color: rgba(230, 127, 3, 0.085);
  color: #9d5600;
}

.btn-a-outline-orange:not(:disabled):not(.disabled):active.fs--shadow:focus,
.btn-a-outline-orange:not(:disabled):not(.disabled).active.fs--shadow:focus,
.show > .btn.btn-a-outline-orange.dropdown-toggle.fs--shadow:focus {
  box-shadow: 0 0 1px 2px rgba(238, 155, 53, 0.4);
}

.show > .btn.btn-a-light-orange.dropdown-toggle, .btn-a-light-orange:not(:disabled):not(.disabled):active, .btn-a-light-orange:not(:disabled):not(.disabled).active {
  color: #935100;
  background-color: #ffe2be;
  border-color: #ffb65d;
}

.show > .btn.btn-a-lighter-orange.dropdown-toggle, .btn-a-lighter-orange:not(:disabled):not(.disabled):active, .btn-a-lighter-orange:not(:disabled):not(.disabled).active {
  color: #9b5500;
  background-color: #fff0dd;
  border-color: #ffbc6a;
}

.btn-text-default,
.btn-text-default:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-default:hover,
.btn.btn-h-text-default:hover,
.btn-a-text-default:not(.disabled):not(:disabled):active,
.btn-a-text-default:not(.disabled):not(:disabled).active {
  color: #2d77a4;
}

.btn-a-text-default[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-default[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #2b719c;
}

.btn-text-primary,
.btn-text-primary:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-primary:hover,
.btn.btn-h-text-primary:hover,
.btn-a-text-primary:not(.disabled):not(:disabled):active,
.btn-a-text-primary:not(.disabled):not(:disabled).active {
  color: #1b74bf;
}

.btn-a-text-primary[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-primary[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #196fb6;
}

.btn-text-secondary,
.btn-text-secondary:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-secondary:hover,
.btn.btn-h-text-secondary:hover,
.btn-a-text-secondary:not(.disabled):not(:disabled):active,
.btn-a-text-secondary:not(.disabled):not(:disabled).active {
  color: #4e748d;
}

.btn-a-text-secondary[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-secondary[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #4a6e87;
}

.btn-text-info,
.btn-text-info:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-info:hover,
.btn.btn-h-text-info:hover,
.btn-a-text-info:not(.disabled):not(:disabled):active,
.btn-a-text-info:not(.disabled):not(:disabled).active {
  color: #00799b;
}

.btn-a-text-info[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-info[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #007191;
}

.btn-text-success,
.btn-text-success:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-success:hover,
.btn.btn-h-text-success:hover,
.btn-a-text-success:not(.disabled):not(:disabled):active,
.btn-a-text-success:not(.disabled):not(:disabled).active {
  color: #2c812c;
}

.btn-a-text-success[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-success[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #297a29;
}

.btn-text-danger,
.btn-text-danger:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-danger:hover,
.btn.btn-h-text-danger:hover,
.btn-a-text-danger:not(.disabled):not(:disabled):active,
.btn-a-text-danger:not(.disabled):not(:disabled).active {
  color: #d53114;
}

.btn-a-text-danger[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-danger[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #cc2f13;
}

.btn-text-warning,
.btn-text-warning:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-warning:hover,
.btn.btn-h-text-warning:hover,
.btn-a-text-warning:not(.disabled):not(:disabled):active,
.btn-a-text-warning:not(.disabled):not(:disabled).active {
  color: #c86800;
}

.btn-a-text-warning[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-warning[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #be6300;
}

.btn-text-dark,
.btn-text-dark:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-dark:hover,
.btn.btn-h-text-dark:hover,
.btn-a-text-dark:not(.disabled):not(:disabled):active,
.btn-a-text-dark:not(.disabled):not(:disabled).active {
  color: #42474e;
}

.btn-a-text-dark[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-dark[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #3d4248;
}

.btn-text-light,
.btn-text-light:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-light:hover,
.btn.btn-h-text-light:hover,
.btn-a-text-light:not(.disabled):not(:disabled):active,
.btn-a-text-light:not(.disabled):not(:disabled).active {
  color: #91969b;
}

.btn-a-text-light[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-light[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #8c9196;
}

.btn-text-white,
.btn-text-white:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-white:hover,
.btn.btn-h-text-white:hover,
.btn-a-text-white:not(.disabled):not(:disabled):active,
.btn-a-text-white:not(.disabled):not(:disabled).active {
  color: #fff;
}

.btn-text-black,
.btn-text-black:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-black:hover,
.btn.btn-h-text-black:hover,
.btn-a-text-black:not(.disabled):not(:disabled):active,
.btn-a-text-black:not(.disabled):not(:disabled).active {
  color: #2c3034;
}

.btn-a-text-black[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-black[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #272b2e;
}

.btn-text-grey,
.btn-text-grey:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-grey:hover,
.btn.btn-h-text-grey:hover,
.btn-a-text-grey:not(.disabled):not(:disabled):active,
.btn-a-text-grey:not(.disabled):not(:disabled).active {
  color: #666;
}

.btn-a-text-grey[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-grey[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #616161;
}

.btn-text-purple,
.btn-text-purple:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-purple:hover,
.btn.btn-h-text-purple:hover,
.btn-a-text-purple:not(.disabled):not(:disabled):active,
.btn-a-text-purple:not(.disabled):not(:disabled).active {
  color: #7061c9;
}

.btn-a-text-purple[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-purple[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #6959c6;
}

.btn-text-pink,
.btn-text-pink:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-pink:hover,
.btn.btn-h-text-pink:hover,
.btn-a-text-pink:not(.disabled):not(:disabled):active,
.btn-a-text-pink:not(.disabled):not(:disabled).active {
  color: #d81a63;
}

.btn-a-text-pink[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-pink[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #cf195f;
}

.btn-text-yellow,
.btn-text-yellow:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-yellow:hover,
.btn.btn-h-text-yellow:hover,
.btn-a-text-yellow:not(.disabled):not(:disabled):active,
.btn-a-text-yellow:not(.disabled):not(:disabled).active {
  color: #aa641f;
}

.btn-a-text-yellow[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-yellow[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #a15f1d;
}

.btn-text-lightgrey,
.btn-text-lightgrey:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-lightgrey:hover,
.btn.btn-h-text-lightgrey:hover,
.btn-a-text-lightgrey:not(.disabled):not(:disabled):active,
.btn-a-text-lightgrey:not(.disabled):not(:disabled).active {
  color: #787d82;
}

.btn-a-text-lightgrey[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-lightgrey[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #73787d;
}

.btn-text-brown,
.btn-text-brown:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-brown:hover,
.btn.btn-h-text-brown:hover,
.btn-a-text-brown:not(.disabled):not(:disabled):active,
.btn-a-text-brown:not(.disabled):not(:disabled).active {
  color: #a85f2b;
}

.btn-a-text-brown[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-brown[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #a05a29;
}

.btn-text-blue,
.btn-text-blue:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-blue:hover,
.btn.btn-h-text-blue:hover,
.btn-a-text-blue:not(.disabled):not(:disabled):active,
.btn-a-text-blue:not(.disabled):not(:disabled).active {
  color: #0d6ce1;
}

.btn-a-text-blue[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-blue[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #0c68d7;
}

.btn-text-red,
.btn-text-red:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-red:hover,
.btn.btn-h-text-red:hover,
.btn-a-text-red:not(.disabled):not(:disabled):active,
.btn-a-text-red:not(.disabled):not(:disabled).active {
  color: #e30b0b;
}

.btn-a-text-red[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-red[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #d90b0b;
}

.btn-text-green,
.btn-text-green:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-green:hover,
.btn.btn-h-text-green:hover,
.btn-a-text-green:not(.disabled):not(:disabled):active,
.btn-a-text-green:not(.disabled):not(:disabled).active {
  color: #1a7f5b;
}

.btn-a-text-green[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-green[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #187755;
}

.btn-text-orange,
.btn-text-orange:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-orange:hover,
.btn.btn-h-text-orange:hover,
.btn-a-text-orange:not(.disabled):not(:disabled):active,
.btn-a-text-orange:not(.disabled):not(:disabled).active {
  color: #a95c00;
}

.btn-a-text-orange[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-orange[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #9f5700;
}

.btn-text-pale,
.btn-text-pale:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn-h-text-pale:hover,
.btn.btn-h-text-pale:hover,
.btn.btn-f-text-pale:focus,
.btn-a-text-pale:not(.disabled):not(:disabled):active,
.btn-a-text-pale:not(.disabled):not(:disabled).active,
.btn-a-text-pale[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-text-pale[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  color: #a9b4c4;
}

.btn-faded:not(:hover):not(:active):not(.active),
.btn-h-faded:hover,
.btn.btn-f-faded:focus,
.btn-a-faded:not(.disabled):not(:disabled):active,
.btn-a-faded:not(.disabled):not(:disabled).active {
  opacity: 0.8;
}

.btn-brc-tp,
.btn-brc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn.btn-f-brc-tp:focus,
.btn-h-brc-tp:hover,
.btn.btn-h-brc-tp:hover,
.btn-a-brc-tp:not(.disabled):not(:disabled).active {
  border-color: transparent;
}

.btn-brc-white,
.btn-brc-white:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn.btn-f-brc-white:focus,
.btn-h-brc-white:hover,
.btn.btn-h-brc-white:hover,
.btn-a-brc-white:not(.disabled):not(:disabled):active,
.btn-a-brc-white:not(.disabled):not(:disabled).active {
  border-color: #fff;
}

.btn-bgc-tp,
.btn-bgc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn.btn-f-bgc-tp:focus,
.btn-h-bgc-tp:hover,
.btn.btn-h-bgc-tp:hover,
.btn-a-bgc-tp:not(.disabled):not(:disabled):active,
.btn-a-bgc-tp:not(.disabled):not(:disabled).active,
.btn-a-bgc-tp[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-bgc-tp[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  background-color: transparent;
}

.btn-bgc-white,
.btn-bgc-white:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn.btn-f-bgc-white:focus,
.btn-h-bgc-white:hover,
.btn.btn-h-bgc-white:hover,
.btn-a-bgc-white:not(.disabled):not(:disabled):active,
.btn-a-bgc-white:not(.disabled):not(:disabled).active,
.btn-a-bgc-white[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-bgc-white[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  background-color: #fff;
}

.btn-tp,
.btn-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
.btn.btn-f-tp:focus,
.btn-h-tp:hover,
.btn.btn-h-tp:hover,
.btn-a-tp:not(.disabled):not(:disabled):active,
.btn-a-tp:not(.disabled):not(:disabled).active,
.btn-a-tp[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active,
.btn-a-tp[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active {
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-bold {
  border-width: 2px;
  font-weight: 600;
  font-size: 0.9em;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.btn-h-bold:hover,
.btn-f-bold:focus,
.btn-a-bold.active:not(.disabled):not(:disabled) {
  font-weight: 600;
}

.btn-a-b2.active:not(.disabled):not(:disabled) {
  border-width: 2px;
}

.btn-a-bb2.active:not(.disabled):not(:disabled) {
  border-bottom-width: 2px;
}

.btn-a-bt2.active:not(.disabled):not(:disabled) {
  border-top-width: 2px;
}

.btn.fs--outline:focus {
  outline-color: rgba(255, 255, 255, 0.75);
  outline-width: 1px;
  outline-style: dotted;
  outline-offset: -3px;
}

.btn.fs--outline[class*="btn-outline-"]:focus, .btn.fs--outline[class*="btn-light"]:focus {
  outline-color: rgba(0, 0, 0, 0.4);
}

.btn.fs--outline[class*="btn-h-"]:not([class*="btn-h-outline"]):not([class*="btn-h-light"]):hover:focus {
  outline-color: rgba(255, 255, 255, 0.75);
}

.btn.fs--outline[class*="btn-h-outline-"]:focus:hover, .btn.fs--outline[class*="btn-h-light"]:focus:hover {
  outline-color: rgba(0, 0, 0, 0.4);
}

.btn.fs--outline[class*="btn-a-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--outline[class*="btn-a-"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(255, 255, 255, 0.75);
}

.btn.fs--outline[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--outline[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active:focus, .btn.fs--outline[class*="btn-a-light"]:not(.disabled):not(:disabled):active:focus, .btn.fs--outline[class*="btn-a-light"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(0, 0, 0, 0.4);
}

.btn.fs--solid:focus {
  outline-color: rgba(255, 255, 255, 0.6);
  outline-width: 1px;
  outline-style: solid;
  outline-offset: -3px;
}

.btn.fs--solid[class*="btn-outline-"]:focus, .btn.fs--solid[class*="btn-light"]:focus {
  outline-color: rgba(0, 0, 0, 0.27);
}

.btn.fs--solid[class*="btn-h-"]:not([class*="btn-h-outline"]):not([class*="btn-h-light"]):hover:focus {
  outline-color: rgba(255, 255, 255, 0.6);
}

.btn.fs--solid[class*="btn-h-"]:not([class*="btn-a-outline"]):not([class*="btn-a-light"]):active:focus {
  outline-color: rgba(0, 0, 0, 0.27);
}

.btn.fs--solid[class*="btn-h-outline-"]:focus:hover, .btn.fs--solid[class*="btn-h-light"]:focus:hover {
  outline-color: rgba(0, 0, 0, 0.27);
}

.btn.fs--solid[class*="btn-a-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--solid[class*="btn-a-"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(255, 255, 255, 0.6);
}

.btn.fs--solid[class*="btn-a-outline-"]:not(.disabled):not(:disabled):active:focus, .btn.fs--solid[class*="btn-a-outline-"]:not(.disabled):not(:disabled).active:focus, .btn.fs--solid[class*="btn-a-light"]:not(.disabled):not(:disabled):active:focus, .btn.fs--solid[class*="btn-a-light"]:not(.disabled):not(:disabled).active:focus {
  outline-color: rgba(0, 0, 0, 0.27);
}

.btn.fs--dashed:focus {
  outline-width: 1px;
  outline-style: dashed;
  outline-offset: -1px;
  outline-color: rgba(255, 255, 255, 0.6);
}

.btn.fs--dashed[class*="btn-outline-"].border-0:focus, .btn.fs--dashed[class*="btn-outline-"].btn-brc-tp:focus {
  outline-color: rgba(0, 0, 0, 0.27);
}

.btn.fs--raised:focus, .btn.fs--raised.focus {
  box-shadow: inset 0px -2px 1px 0px rgba(0, 0, 0, 0.15) !important;
}

.btn.disabled, .btn:disabled {
  opacity: 0.55;
  pointer-events: none;
}

.btn.disabled:not(.btn-bgc-tp):not(.btn-bgc-white):after, .btn:disabled:not(.btn-bgc-tp):not(.btn-bgc-white):after {
  content: "";
  position: absolute;
  z-index: 2;
  border-radius: inherit;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  background-color: rgba(0, 0, 0, 0.07);
}

.btn-link {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.btn.btn-raised,
.btn.btn-h-raised:hover,
.btn.btn-a-raised:not(.disabled):not(:disabled):active,
.btn.btn-a-raised:not(.disabled):not(:disabled).active {
  box-shadow: inset 0px -2px 1px 0px rgba(0, 0, 0, 0.15);
}

.btn.btn-app {
  display: inline-block;
  min-width: 7.5rem;
  line-height: 2rem;
  font-size: 1.325rem;
  font-weight: 400;
  text-align: center;
  border-radius: 0.825rem;
  padding: 1rem 0 0.75rem;
  position: relative;
}

.btn.btn-app.btn-sm {
  line-height: 1.675rem;
  min-width: 6.25rem;
  font-size: 1.175rem;
  padding: 0.875rem 0 0.675rem;
}

.btn.btn-app.btn-xs {
  line-height: 1.25rem;
  min-width: 5.25rem;
  font-size: 1rem;
  padding: 0.75rem 0 0.625rem;
}

.btn-app.btn-default {
  background-image: linear-gradient(to bottom, #4d98c5 0%, #3478a1 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-default:hover {
  background-image: linear-gradient(to bottom, #3d8cbc 0%, #2e6a8e 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-primary {
  background-image: linear-gradient(to bottom, #3f95dc 0%, #2276bc 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-primary:hover {
  background-image: linear-gradient(to bottom, #2989d9 0%, #1e69a7 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-secondary {
  background-image: linear-gradient(to bottom, #7594a8 0%, #56758a 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-secondary:hover {
  background-image: linear-gradient(to bottom, #65889f 0%, #4c687a 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-info {
  background-image: linear-gradient(to bottom, #00a3d1 0%, #007393 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-info:hover {
  background-image: linear-gradient(to bottom, #008fb7 0%, #005f7a 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-success {
  background-image: linear-gradient(to bottom, #44ac44 0%, #328032 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-success:hover {
  background-image: linear-gradient(to bottom, #3c9a3c 0%, #2b6e2b 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-danger {
  background-image: linear-gradient(to bottom, #e75f47 0%, #d5381c 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-danger:hover {
  background-image: linear-gradient(to bottom, #e44b30 0%, #be3219 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-warning {
  background-image: linear-gradient(to bottom, #ffc96f 0%, #ffb331 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-warning:hover {
  background-image: linear-gradient(to bottom, #ffc055 0%, #ffa918 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-dark {
  background-image: linear-gradient(to bottom, #4d5662 0%, #323840 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-dark:hover {
  background-image: linear-gradient(to bottom, #414954 0%, #272b31 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-light {
  background-image: linear-gradient(to bottom, white 0%, #e4e8f1 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-light:hover {
  background-image: linear-gradient(to bottom, #fbfcfd 0%, #d3dae8 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-white {
  background-image: linear-gradient(to bottom, white 0%, #f0efef 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-white:hover {
  background-image: linear-gradient(to bottom, white 0%, #e4e2e2 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-black {
  background-image: linear-gradient(to bottom, #373f47 0%, #1d2125 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-black:hover {
  background-image: linear-gradient(to bottom, #2c3339 0%, #111416 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-grey {
  background-image: linear-gradient(to bottom, #80838d 0%, #62656e 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-grey:hover {
  background-image: linear-gradient(to bottom, #737781 0%, #565960 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-purple {
  background-image: linear-gradient(to bottom, #887dcb 0%, #6051b9 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-purple:hover {
  background-image: linear-gradient(to bottom, #776bc4 0%, #5445ac 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-pink {
  background-image: linear-gradient(to bottom, #e34d87 0%, #d12165 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-pink:hover {
  background-image: linear-gradient(to bottom, #df3778 0%, #bb1e5a 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-yellow {
  background-image: linear-gradient(to bottom, #feee96 0%, #fde459 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-yellow:hover {
  background-image: linear-gradient(to bottom, #feea7d 0%, #fde040 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-lightgrey {
  background-image: linear-gradient(to bottom, #87909d 0%, #68717f 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-lightgrey:hover {
  background-image: linear-gradient(to bottom, #798391 0%, #5d6571 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-brown {
  background-image: linear-gradient(to bottom, #c87f4c 0%, #a46132 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-brown:hover {
  background-image: linear-gradient(to bottom, #bf713b 0%, #90562c 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-blue {
  background-image: linear-gradient(to bottom, #4491ee 0%, #1471e1 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-blue:hover {
  background-image: linear-gradient(to bottom, #2d83ec 0%, #1265c9 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-red {
  background-image: linear-gradient(to bottom, #f15b5b 0%, #ec2323 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-red:hover {
  background-image: linear-gradient(to bottom, #ef4444 0%, #e31313 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-green {
  background-image: linear-gradient(to bottom, #2bae7f 0%, #1f7d5b 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-green:hover {
  background-image: linear-gradient(to bottom, #269970 0%, #1a684c 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-orange {
  background-image: linear-gradient(to bottom, #ff9b22 0%, #e37d00 100%);
  background-repeat: repeat-x;
}

.btn-app.btn-orange:hover {
  background-image: linear-gradient(to bottom, #ff8f08 0%, #ca6f00 100%);
  background-repeat: repeat-x;
}

.btn-group > .btn-app + .btn-app.dropdown-toggle {
  min-width: auto;
}

.btn-group > .btn-app + .btn-app.dropdown-toggle {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.btn-group-vertical > .btn:first-child:not(.dropdown-toggle), .btn-group-vertical > .btn-group:first-child {
  margin-left: -1px;
}

.btn-group-faded .btn:not(.active):not(:hover) {
  opacity: 0.77;
}

.action-buttons a, .action-btn {
  display: inline-block;
  opacity: 0.88;
  margin: 0 0.125rem;
  transition: all 0.12s;
}

@media (prefers-reduced-motion: reduce) {
  .action-buttons a, .action-btn {
    transition: none;
  }
}

.action-buttons a:hover, .action-btn:hover {
  text-decoration: none;
  opacity: 1;
  transform: scale(1.15);
}

.btn-text-slide {
  position: relative;
  overflow: hidden;
}

.btn-text-slide .btn-text-1, .btn-text-slide .btn-text-2 {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.12s, opacity 0.12s;
  transition-delay: .1s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-text-slide .btn-text-1, .btn-text-slide .btn-text-2 {
    transition: none;
  }
}

.btn-text-slide .btn-text-2 {
  transition-delay: 0s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: inherit;
  padding-top: inherit;
  padding-bottom: inherit;
}

.btn-text-slide:hover .btn-text-1 {
  transition-delay: 0s;
  transform: translateY(100%);
  opacity: 0;
}

.btn-text-slide:hover .btn-text-1.move-up {
  transform: translateY(-100%);
}

.btn-text-slide:hover .btn-text-1.move-down {
  transform: translateY(100%);
}

.btn-text-slide:hover .btn-text-1.move-right {
  transform: translateX(120%);
}

.btn-text-slide:hover .btn-text-1.move-left {
  transform: translateX(-120%);
}

.btn-text-slide .btn-text-2 {
  transform: translateY(-100%);
}

.btn-text-slide .btn-text-2.move-down {
  transform: translateY(-100%);
}

.btn-text-slide .btn-text-2.move-up {
  transform: translateY(100%);
}

.btn-text-slide .btn-text-2.move-left {
  transform: translateX(100%);
}

.btn-text-slide .btn-text-2.move-right {
  transform: translateX(-100%);
}

.btn-text-slide:hover .btn-text-2 {
  transform: none;
  transition-delay: 0.1s;
}

.btn-text-slide-x {
  position: relative;
  overflow: hidden;
}

.btn-text-slide-x .btn-text-2 {
  transition: all 0.225s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-text-slide-x .btn-text-2 {
    transition: none;
  }
}

.btn-text-slide-x .btn-text-2 {
  opacity: 0;
  letter-spacing: -0.5rem;
  max-width: 0%;
  white-space: nowrap;
  word-break: normal;
  display: inline-block;
}

.btn-text-slide-x .btn-text-2.move-right {
  transform: translateX(-100%);
}

.btn-text-slide-x .btn-text-2.move-left {
  transform: translateX(100%);
}

.btn-text-slide-x:not(:hover) .btn-text-2 {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.btn-text-slide-x:hover .btn-text-2 {
  opacity: 1;
  letter-spacing: 0;
  max-width: 100%;
  transform: none;
}

.btn-text-slide-y {
  position: relative;
  overflow: hidden;
}

.btn-text-slide-y .btn-text-1, .btn-text-slide-y .btn-text-2 {
  display: block;
  transition: all 0.12s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn-text-slide-y .btn-text-1, .btn-text-slide-y .btn-text-2 {
    transition: none;
  }
}

.btn-text-slide-y .btn-text-2 {
  transform: translateY(100%);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.btn-text-slide-y:not(:hover) .btn-text-2 {
  margin: 0 !important;
}

.btn-text-slide-y:hover .btn-text-1 {
  transform: translateY(-30%) scale(0.8);
}

.btn-text-slide-y:hover .btn-text-2 {
  transform: none;
}

.btn-text-slide-y.direction-reverse .btn-text-2 {
  transform: translateY(-100%);
  top: 0;
  bottom: auto;
}

.btn-text-slide-y.direction-reverse:hover .btn-text-1 {
  transform: translateY(30%) scale(0.8);
}

.btn-text-slide-y.direction-reverse:hover .btn-text-2 {
  transform: none;
}

.btn-text-slide-y .btn-text-fade {
  transform: none !important;
  opacity: 0 !important;
  transition-delay: 0s;
  transition-duration: 50ms;
}

.btn-text-slide-y:hover .btn-text-fade {
  transition-delay: 0.1s;
  transform: none !important;
  opacity: 1 !important;
}

.form-control {
  color: #51575d;
  background-color: #fff;
  border: 1px solid #d3d5d7;
  border-radius: 0.125rem;
  font-family: inherit;
  transition-duration: 120ms;
}

.form-control:hover {
  border-color: #b5b5b5;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(245, 153, 66, 0.2);
  color: #696969;
  border-color: #f59942;
  background-color: #fff;
  outline: none;
}

.form-control.brc-on-focus:not(:focus) {
  border-color: #d3d5d7 !important;
}

.form-control.brc-on-focus:not(:focus):not([disabled]):hover {
  border-color: #b5b5b5 !important;
}

.form-control[disabled] {
  color: #848484;
  background-color: #eee;
}

.form-control[disabled]:hover {
  border-color: #d3d5d7;
}

.form-control[readonly] {
  color: #939192;
  background: #f5f5f5;
  cursor: default;
}

.form-control[readonly]:hover {
  border-color: #c4c4c4;
}

.form-control[readonly]:focus {
  box-shadow: none;
  border-color: #aaa;
  background-color: #f9f9f9;
}

.form-control::placeholder {
  color: #999;
}

.form-control[class*="brc-success"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(91, 177, 91, 0.2);
}

.form-control[class*="brc-danger"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(218, 78, 53, 0.2);
}

.form-control[class*="brc-warning"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(237, 166, 48, 0.2);
}

.form-control[class*="brc-primary"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(44, 135, 211, 0.2);
}

.form-control[class*="brc-info"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(0, 182, 233, 0.2);
}

.form-control[class*="brc-blue"]:focus:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 2px rgba(62, 137, 228, 0.2);
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #74bd74;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  box-shadow: 0 0 0 2px rgba(116, 189, 116, 0.2);
}

.valid-feedback {
  color: #62a041;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #e06953;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 2px rgba(224, 105, 83, 0.2);
}

.invalid-feedback {
  color: #d52727;
}

label {
  cursor: pointer;
}

label.disabled {
  cursor: default;
}

input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
  cursor: default;
}

.input-group-fade .input-group-append > .btn {
  opacity: 0.55;
}

.input-group-fade .input-group-append > .btn:hover, .input-group-fade .input-group-append > .btn:active, .input-group-fade .input-group-append > .btn.active, .input-group-fade .input-group-append > .btn:focus, .input-group-fade .input-group-append > .btn.focus {
  opacity: 1;
}

.input-group-fade .form-control:focus ~ .input-group-append > .btn {
  opacity: 1;
}

.input-floating-label {
  position: relative;
}

.input-floating-label .floating-label {
  pointer-events: none;
  background-color: #fff;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .input-floating-label .floating-label {
    transition: none;
  }
}

.input-floating-label input:focus ~ .floating-label, .input-floating-label input.has-content ~ .floating-label, .input-floating-label .floating-label.active {
  top: 0;
  font-size: 0.8em;
  opacity: 0.85;
}

.input-floating-label[class*="text-"] input:focus ~ .floating-label {
  color: inherit !important;
  opacity: 0.95;
}

.input-floating-label[class*="brc-"] input:focus {
  border-color: inherit;
}

select.form-control {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

select.form-control option {
  padding: 0.3125rem 0.3125rem 0.375rem;
}

select.ace-select {
  position: relative;
  cursor: pointer;
  appearance: none;
  width: 100%;
  height: 2.5rem;
  line-height: 2rem;
  padding-left: 0.5rem;
  border: 2px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: calc(100% - 1.125rem - 0.5px) center, calc(100% - 0.75rem - 0.5px) center, calc(100% - 2.25rem) center;
  background-size: 0.375rem 0.375rem, 0.375rem 0.375rem, 1px 1.5rem;
  background-repeat: no-repeat;
}

select.ace-select.caret-double {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(135deg, transparent 50%, currentColor 50%), linear-gradient(45deg, currentColor 50%, transparent 50%), linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: calc(100% - 1.0625rem - 0.5px) calc(50% + 0.25rem), calc(100% - 0.75rem - 0.5px) calc(50% + 0.25rem), calc(100% - 1.0625rem - 0.5px) calc(50% - 0.25rem), calc(100% - 0.75rem - 0.5px) calc(50% - 0.25rem), calc(100% - 2.125rem) center;
  background-size: 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 0.3125rem 0.3125rem, 1px 1.5rem;
}

select.ace-select option {
  background-color: #fff;
  color: #41464d;
}

select.ace-select::-ms-expand {
  display: none;
}

select.ace-select:focus {
  outline: none;
}

select.ace-select.no-border {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
}

select.ace-select.caret-double.no-border {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%), linear-gradient(135deg, transparent 50%, currentColor 50%), linear-gradient(45deg, currentColor 50%, transparent 50%);
}

select.ace-select.angle-down {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%23647893' d='M1,3 L5,7 L9,3'/%3e%3c/svg%3e");
  background-size: 12px 12px;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

select.ace-select.angle-up {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%23647893' d='M1,7 L5,3 L9,7'/%3e%3c/svg%3e");
  background-size: 12px 12px;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

select.ace-select.angle-double {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%23647893' d='M1,7 L5,11 L9,7 M1,5 L5,1 L9,5'/%3e%3c/svg%3e");
  background-size: 14px 14px;
  background-position-x: calc(100% - 8px);
  background-position-y: center;
}

.form-text.popover {
  left: auto;
  margin-top: 0;
  z-index: auto;
}

.form-text.bs-popover-right > .arrow, .form-text.bs-popover-auto[x-placement^="right"] > .arrow {
  top: 50%;
  margin-top: -0.5rem;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none) {
  .custom-control-input {
    position: absolute !important;
  }
  input[type=checkbox], input[type=radio] {
    position: relative;
    appearance: none;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    overflow: hidden;
    border: 1px solid #adb8c0;
    background-color: #fff;
    vertical-align: text-bottom;
    margin-right: 0.25rem;
  }
  input[type=checkbox].input-lg, input[type=radio].input-lg {
    width: 1.5rem;
    height: 1.5rem;
  }
  input[type=checkbox].input-xlg, input[type=radio].input-xlg {
    width: 1.75rem;
    height: 1.75rem;
  }
  input[type=checkbox].input-sm, input[type=radio].input-sm {
    width: 1rem;
    height: 1rem;
  }
  input[type=checkbox]:hover, input[type=radio]:hover {
    border-color: #ff893c;
  }
  input[type=checkbox]:focus, input[type=radio]:focus {
    outline: none;
    border-color: #f79a5e;
  }
  input[type=checkbox]:active, input[type=radio]:active {
    border-color: #ff893c;
  }
  input[type=checkbox] {
    border-radius: 0.125rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 88%;
  }
  input[type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%233e89e4' d='M 7.5,1.75 l -3.5,4 -2,-1.5 L 1,5.5 4,8.5 9,2.5 z'/%3e%3c/svg%3e");
  }
  input[type=radio] {
    border-radius: 100%;
  }
  input[type=radio]::after {
    content: "";
    border-radius: inherit;
    position: absolute;
    right: 0.25rem;
    left: 0.25rem;
    top: 0.25rem;
    bottom: 0.25rem;
    background-color: transparent;
    transition: background-color 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=radio]::after {
      transition: none;
    }
  }
  input[type=radio]:checked::after {
    background-color: #3e89e4;
  }
  input[type=radio].border-2:not(.input-lg):not(.input-xlg)::after {
    right: 0.1875rem;
    left: 0.1875rem;
    top: 0.1875rem;
    bottom: 0.1875rem;
  }
  input[type=radio].input-lg:not(.border-2)::after, input[type=radio].input-xlg::after {
    right: 0.3125rem;
    left: 0.3125rem;
    top: 0.3125rem;
    bottom: 0.3125rem;
  }
  input[type=radio].input-sm::after {
    right: 0.1875rem;
    left: 0.1875rem;
    top: 0.1875rem;
    bottom: 0.1875rem;
  }
  input[type=checkbox][class*="bgc-"] {
    transition: background-color 80ms, border-color 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=checkbox][class*="bgc-"] {
      transition: none;
    }
  }
  input[type=checkbox][class*="bgc-"]:not(:checked) {
    background-color: #fff !important;
  }
  input[type=checkbox][class*="bgc-"]:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23fff' d='M 7.5,1.75 l -3.5,4 -2,-1.5 L 1,5.5 4,8.5 9,2.5 z'/%3e%3c/svg%3e");
    border-color: transparent !important;
  }
  input[type=checkbox][class*="bgc-"].input-lg, input[type=checkbox][class*="bgc-"].input-xlg {
    background-size: 85%;
    background-position: center;
  }
  input[type=checkbox][class*="text-"] {
    transition: background-color 80ms, border-color 80ms;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cmask id='mask'%3E%3Crect fill='white' width='100%25' height='100%25' fill-opacity='1' stroke-width='0' /%3E%3Cpath d='M 7.5,1.75 l -3.5,4 -2,-1.5 L 1,5.5 4,8.5 9,2.5 z' /%3E%3C/mask%3E%3Crect mask='url(%23mask)' fill='white' width='100%25' height='100%25' fill-opacity='1'/%3E%3C/svg%3E");
    background-size: 88%;
    background-clip: padding-box;
    box-shadow: inset 0 0 0 2.5px #fff;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=checkbox][class*="text-"] {
      transition: none;
    }
  }
  input[type=checkbox][class*="text-"]:checked {
    background-color: currentColor;
  }
  input[type=checkbox][class*="text-"].input-lg, input[type=checkbox][class*="text-"].input-xlg {
    background-size: 84.5%;
    box-shadow: inset 0 0 0 3.25px #fff;
  }
  input[type=radio][class*="text-"]:checked::after {
    color: inherit;
    background-color: currentColor;
  }
  input[type=radio][class*="bgc-"] {
    transition: background-color 80ms, border-color 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input[type=radio][class*="bgc-"] {
      transition: none;
    }
  }
  input[type=radio][class*="bgc-"]:not(:checked) {
    background-color: #fff !important;
  }
  input[type=radio][class*="bgc-"]:checked {
    border-color: transparent;
  }
  input[type=radio][class*="bgc-"]:checked::after {
    box-shadow: none;
    background-color: #fff;
  }
  .brc-on-checked:not(:checked):not(:hover):not(:focus) {
    border-color: #adb8c0 !important;
  }
  [class*="brc-"] > .brc-inherit:not(:checked):not(:hover):not(:focus) {
    border-color: inherit !important;
  }
  input[type=checkbox].text-95 {
    background-size: 82.5% !important;
  }
  input[type=checkbox].text-90 {
    background-size: 78% !important;
  }
  input[type=checkbox].text-85 {
    background-size: 75% !important;
  }
  input[type=checkbox].text-80 {
    background-size: 73% !important;
  }
  input[type=checkbox]:disabled, input[type=radio]:disabled {
    cursor: default;
    border-color: #ccc !important;
    background-color: #eee !important;
    background-image: none;
    box-shadow: none !important;
  }
  input[type=checkbox]:disabled:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23aaa' d='M 7.5,1.75 l -3.5,4 -2,-1.5 L 1,5.5 4,8.5 9,2.5 z'/%3e%3c/svg%3e");
  }
  input[type=radio]:disabled:checked::after {
    background-color: #aaa;
    box-shadow: none;
  }
  input.ace-switch[class*="text-"] {
    box-shadow: none !important;
    background-clip: border-box;
  }
}

/* excludes Edge */
@supports (overflow: overlay) or (-moz-appearance: none) {
  input.ace-switch {
    background-color: #9faeb8;
    border: none;
    margin-right: 0;
  }
  input.ace-switch:focus {
    box-shadow: none;
  }
  input.ace-switch::after {
    content: "";
    position: absolute;
    background-color: #fff;
    border-radius: inherit;
    transition: transform 80ms;
  }
  @media (prefers-reduced-motion: reduce) {
    input.ace-switch::after {
      transition: none;
    }
  }
  input.ace-switch:checked {
    background-image: none;
    background-color: #2a80c8;
  }
  input.ace-switch {
    border-radius: 1.5rem;
    width: 2.75rem;
    height: 1.5rem;
  }
  input.ace-switch::after {
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
  }
  input.ace-switch[class*="brc-"]::after {
    border-width: 0.4375rem;
  }
  input.ace-switch:checked::after {
    transform: translateX(1.25rem);
  }
  input.ace-switch.input-lg {
    border-radius: 2rem;
    width: 4.25rem;
    height: 2rem;
  }
  input.ace-switch.input-lg::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  input.ace-switch.input-lg[class*="brc-"]::after {
    border-width: 0.625rem;
  }
  input.ace-switch.input-lg:checked::after {
    transform: translateX(2.25rem);
  }
  input.ace-switch.input-sm {
    border-radius: 1.25rem;
    width: 2.25rem;
    height: 1.25rem;
  }
  input.ace-switch.input-sm::after {
    top: 0.1875rem;
    left: 0.1875rem;
    width: 0.875rem;
    height: 0.875rem;
  }
  input.ace-switch.input-sm[class*="brc-"]::after {
    border-width: 0.3125rem;
  }
  input.ace-switch.input-sm:checked::after {
    transform: translateX(1rem);
  }
  input.ace-switch[class*="bgc-"]:not(:checked):not([class*="text-"]) {
    background-color: #9faeb8 !important;
  }
  input.ace-switch[class*="bgc-"]:checked {
    background-image: none;
  }
  input.ace-switch[class*="text-"]:not(:checked) {
    background-image: none;
    background-color: currentColor !important;
  }
  input.ace-switch[class*="text-"]:checked:not([class*="bgc-"]) {
    background-color: #2a80c8;
  }
  input.ace-switch[class*="brc-"] {
    background-color: transparent !important;
    border-width: 0.125rem;
    border-style: solid;
  }
  input.ace-switch[class*="brc-"]:not(:checked):not([class*="text-"]) {
    background-color: transparent !important;
    border-color: #9faeb8 !important;
  }
  input.ace-switch[class*="brc-"][class*="text-"] {
    background-color: transparent !important;
  }
  input.ace-switch[class*="brc-"][class*="text-"]:not(:checked) {
    border-color: currentColor !important;
  }
  input.ace-switch[class*="brc-"]::after {
    border-style: solid;
    border-color: inherit;
    background-color: transparent;
    width: auto;
    height: auto;
  }
  input.ace-switch::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.3125rem;
    line-height: normal;
    font-size: 0.9rem;
    font-weight: 600;
    color: #FFF;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 0.625rem;
    text-align: right;
  }
  input.ace-switch:checked::before {
    text-align: left;
  }
  input.ace-switch.ace-switch-yesno::before {
    content: "NO";
  }
  input.ace-switch.ace-switch-yesno:checked::before {
    content: "YES";
  }
  input.ace-switch.ace-switch-onoff::before {
    padding-right: 0.5rem;
    content: "OFF";
  }
  input.ace-switch.ace-switch-onoff:checked::before {
    content: "ON";
  }
  input.ace-switch.ace-switch-times:not(:checked) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='none' stroke='%23fff' stroke-width='1.75px' d='M 2.5,2.5 L 7.5,7.5  M 7.5,2.5 L 2.5,7.5'/%3e%3c/svg%3e");
    background-size: 33%;
    background-position: 88% center;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-check:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='%23fff' d='M 7.5,1.75 l -3.5,4 -2,-1.5 L 1,5.5 4,8.5 9,2.5 z'/%3e%3c/svg%3e");
    background-size: 33%;
    background-position: 15% center;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-bars::after {
    color: #999;
    content: "";
    background-image: linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor);
    background-position: calc(50% - 3px) center, center, calc(50% + 3px) center;
    background-size: 1px 45%;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-bars:disabled::after {
    color: #bbb;
  }
  input.ace-switch.ace-switch-bars-h::after {
    color: #999;
    content: "";
    background-image: linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor);
    background-position: center calc(50% - 3px), center, center calc(50% + 3px);
    background-size: 45% 1px;
    background-repeat: no-repeat;
  }
  input.ace-switch.ace-switch-bars-h:disabled::after {
    color: #bbb;
  }
  input.ace-switch.ace-switch-thin {
    visibility: hidden;
    overflow: visible;
    width: 3.09375rem;
    height: 1.6875rem;
  }
  input.ace-switch.ace-switch-thin::before {
    visibility: visible;
    content: "";
    position: absolute;
    width: 80%;
    height: 40%;
    top: 2px;
    bottom: 0;
    margin: auto;
    border-radius: 1rem;
    background-color: inherit;
    color: inherit;
    opacity: 0.45;
  }
  input.ace-switch.ace-switch-thin::after {
    height: 1.5rem;
    width: 1.5rem;
    visibility: visible;
    background-color: inherit;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }
  input.ace-switch.ace-switch-thin:checked::after {
    transform: translateX(1.4rem);
  }
  input[type=checkbox].ace-switch:disabled:not(:checked) {
    color: #ddd !important;
    background-color: #d0d0d0 !important;
  }
  input[type=checkbox].ace-switch:disabled:checked {
    background-color: #c6c6c6 !important;
  }
  input.ace-switch:not(.ace-switch-check):not(.ace-switch-times):checked:disabled {
    background-image: none;
  }
  input[type=checkbox].ace-switch[class*="brc-"]:disabled {
    background-color: transparent !important;
  }
}

*::-ms-backdrop,
input::-ms-check {
  width: 1.125rem;
  height: 1.125rem;
  color: #3e89e4;
  border-color: #adb8c0;
}

*::-ms-backdrop,
input:not([type=radio]):checked[class*="bgc-"]::-ms-check {
  color: white;
  background-color: inherit;
}

*::-ms-backdrop,
input[type=radio][class*="bgc-"] {
  background-color: transparent !important;
}

*::-ms-backdrop,
input[class*="brc-"]::-ms-check {
  border-color: inherit;
}

*::-ms-backdrop,
input[class*="border-2"]::-ms-check {
  border-width: 2px;
}

*::-ms-backdrop,
input[class*="text-"]::-ms-check {
  color: inherit;
}

*::-ms-backdrop,
input[type=checkbox]::-ms-check {
  border-radius: 0.1875rem;
}

*::-ms-backdrop,
input[type=radio]::-ms-check {
  border-radius: 100%;
}

*::-ms-backdrop,
input:disabled::-ms-check {
  color: #aaa !important;
}

input.ace-file-input[type=file], .ace-file-input [type=file] {
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}

input.ace-file-input[type=file]:focus, .ace-file-input [type=file]:focus {
  outline: none;
}

.ace-file-input {
  display: block;
  font-size: inherit;
  position: relative;
  height: 2.5rem;
  user-select: none;
  cursor: pointer;
}

.ace-file-input .ace-file-placeholder {
  display: flex;
}

.ace-file-input .ace-file-container {
  display: block;
  height: 2.5rem;
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .ace-file-input .ace-file-container {
    transition: none;
  }
}

.ace-file-input .ace-file-container .ace-file-name {
  max-width: 100%;
  height: 2.5rem;
  line-height: 2.25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #888;
}

.ace-file-input .ace-file-container.selected {
  margin-right: 1rem;
}

.ace-file-input .ace-file-container.selected .ace-file-name {
  color: #666;
}

.ace-file-input .remove {
  display: none;
  text-decoration: none;
}

.ace-file-input .ace-file-container.selected + .remove {
  display: block;
}

.ace-file-input input[type=file].disabled + .ace-file-container, .ace-file-input input[type=file][disabled] + .ace-file-container, .ace-file-input input[type=file][readonly] + .ace-file-container {
  cursor: default;
  background-color: #eee;
  border-color: #d5d5d5 !important;
}

.ace-file-input .ace-file-overlay {
  position: absolute;
  top: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.ace-file-input .ace-file-overlay > .overlay-content {
  position: relative;
  top: calc(50% - 1rem);
}

.ace-file-multiple {
  height: auto;
}

.ace-file-multiple .ace-file-placeholder {
  flex-direction: column-reverse;
}

.ace-file-multiple .ace-file-container {
  height: auto;
  border-radius: 0.25rem;
}

.ace-file-multiple .ace-file-container .ace-file-name {
  height: auto;
  margin: 0 0.25rem;
}

.ace-file-multiple .ace-file-container.selected {
  margin-right: 0;
}

.ace-file-multiple .ace-file-placeholder .ace-file-name {
  margin: 0 1rem;
  text-align: center;
  text-overflow: unset;
  word-break: break-word;
  white-space: normal;
  line-height: normal;
}

.ace-file-multiple .ace-file-item {
  border-bottom: 1px dotted #d3dadf;
}

.ace-file-multiple .ace-file-item:last-child {
  border-bottom-width: 0;
}

.ace-file-multiple .ace-file-icon.thumbnail-img {
  width: 3.25rem;
  text-align: center;
}

.badge {
  font-size: 0.75em;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  line-height: 1.175;
  border-radius: 0.125rem;
  position: relative;
}

.badge-pill {
  border-radius: 2rem;
}

.badge-sm {
  padding: 0.1875rem 0.425rem;
  font-size: 0.7em;
  line-height: 1.125;
}

.badge-lg {
  padding: 0.275rem 0.5rem;
  font-size: 0.9em;
  line-height: 1.25;
  height: 1.75rem;
}

@supports (-moz-appearance: none) {
  .badge {
    line-height: 1.15;
  }
  .badge-sm {
    line-height: 1;
  }
}

.badge.arrowed::before, .badge.arrowed-in::before, .badge.arrowed-right::after, .badge.arrowed-in-right::after {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  border: 1px solid transparent;
  border-width: 0.675rem 0.375rem;
}

.badge.arrowed {
  margin-left: 0.325rem;
}

.badge.arrowed::before {
  left: -0.7125rem;
  border-right-color: inherit;
}

.badge.arrowed-right {
  margin-right: 0.325rem;
}

.badge.arrowed-right::after {
  right: -0.7125rem;
  border-left-color: inherit;
}

.badge.arrowed-in {
  margin-left: 0.375rem;
}

.badge.arrowed-in::before {
  left: -0.375rem;
  border-color: inherit;
  border-left-color: transparent;
}

.badge.arrowed-in-right {
  margin-right: 0.375rem;
}

.badge.arrowed-in-right::after {
  right: -0.375rem;
  border-color: inherit;
  border-right-color: transparent;
}

.badge.badge-sm.arrowed {
  margin-left: 0.3rem;
}

.badge.badge-sm.arrowed::before {
  border-width: 0.55rem 0.325rem 0.57rem;
  left: -0.6125rem;
}

.badge.badge-sm.arrowed-in {
  margin-left: 0.3rem;
}

.badge.badge-sm.arrowed-in::before {
  border-width: 0.55rem 0.325rem 0.575rem;
  left: -0.325rem;
}

.badge.badge-sm.arrowed-right {
  margin-right: 0.3rem;
}

.badge.badge-sm.arrowed-right::after {
  border-width: 0.55rem 0.325rem 0.57rem;
  right: -0.6125rem;
}

.badge.badge-sm.arrowed-in-right {
  margin-right: 0.3rem;
}

.badge.badge-sm.arrowed-in-right::after {
  border-width: 0.55rem 0.325rem 0.57rem;
  right: -0.325rem;
}

.badge.badge-lg.arrowed {
  margin-left: 0.5rem;
}

.badge.badge-lg.arrowed::before {
  border-width: 0.875rem 0.5rem;
  left: -0.975rem;
}

.badge.badge-lg.arrowed-in {
  margin-left: 0.5rem;
}

.badge.badge-lg.arrowed-in::before {
  border-width: 0.875rem 0.5rem;
  left: -0.5rem;
}

.badge.badge-lg.arrowed-right {
  margin-right: 0.5rem;
}

.badge.badge-lg.arrowed-right::after {
  border-width: 0.875rem 0.5rem;
  right: -0.975rem;
}

.badge.badge-lg.arrowed-in-right {
  margin-right: 0.5rem;
}

.badge.badge-lg.arrowed-in-right::after {
  border-width: 0.875rem 0.5rem;
  right: -0.5rem;
}

.badge-default {
  color: #fff;
  background-color: #4188b3;
  border-color: #4188b3;
}

.badge-primary {
  color: #fff;
  background-color: #2c86d1;
  border-color: #2c86d1;
}

.badge-secondary {
  color: #fff;
  background-color: #678497;
  border-color: #678497;
}

.badge-info {
  color: #fff;
  background-color: #008bb2;
  border-color: #008bb2;
}

.badge-success {
  color: #fff;
  background-color: #3f923f;
  border-color: #3f923f;
}

.badge-danger {
  color: #fff;
  background-color: #df4b30;
  border-color: #df4b30;
}

.badge-warning {
  color: #22190b;
  background-color: #ffbe50;
  border-color: #ffbe50;
}

.badge-dark {
  color: #fff;
  background-color: #42474e;
  border-color: #42474e;
}

.badge-light {
  color: #5d6069;
  background-color: #f8f9fb;
  border-color: #f8f9fb;
}

.badge-dot {
  font-size: 0px !important;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  padding: 0;
}

.badge-dot:empty {
  display: inline-block;
}

.dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropleft .dropdown-toggle::before,
.dropright .dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  width: auto;
  line-height: 2rem;
  font-size: 0.95rem;
  margin: 0.125rem;
}

.dropdown-item:not(.btn) {
  color: #35393e;
}

.dropdown-item:not(.btn):hover, .dropdown-item:not(.btn):focus {
  background-color: #e3eff9;
  color: #165f9d;
}

.dropdown-item:not(.btn):active, .dropdown-item:not(.btn).active {
  background-color: #dbebf8;
  color: #14568d;
}

.dropdown-item.btn {
  padding: 0.25rem 1.5rem;
  width: auto;
  margin: 1px;
  font-size: 0.9375rem;
}

.dropdown-menu[class*="bgc-"]:not([class*="-m"]):not([class*="-l"]):not([class*="bgc-yellow"]):not([class*="bgc-light"]) .dropdown-item:not(:hover) {
  color: #fff;
}

.dropdown-item > a {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
}

li.dropdown-item {
  padding: 0 !important;
}

.page-content .dropdown-hover {
  position: relative;
}

.page-content .dropdown-hover > .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-0.5rem);
  transition: opacity 150ms 350ms, transform 150ms 350ms, visibility 0ms 500ms;
}

@media (prefers-reduced-motion: reduce) {
  .page-content .dropdown-hover > .dropdown-menu {
    transition: none;
  }
}

.page-content .dropdown-hover > .dropdown-menu {
  top: 100%;
  left: 0;
}

.page-content .dropdown-hover.dropright > .dropdown-menu {
  left: 100%;
  top: 0;
}

.page-content .dropdown-hover.dropup > .dropdown-menu {
  bottom: 100%;
  top: auto;
}

.page-content .dropdown-hover.dropleft > .dropdown-menu {
  left: auto;
  right: 100%;
  transform: translateX(0.5rem);
}

.page-content .dropdown-hover:hover > .dropdown-menu, .page-content .dropdown-hover .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  transform: none;
  transition-delay: 0ms;
}

.page-content .dropdown-menu:hover > .dropdown-hover:not(:hover) > .dropdown-menu {
  transition-delay: 50ms, 50ms, 200ms;
}

@media (max-width: 575.98px) {
  .dropdown-item.dropdown-hover > .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    top: 100% !important;
    bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-hover {
    position: relative;
  }
  .navbar .dropdown-hover > .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-0.5rem);
    transition: opacity 150ms 350ms, transform 150ms 350ms, visibility 0ms 500ms;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar .dropdown-hover > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar .dropdown-hover.dropup > .dropdown-menu {
    bottom: 100%;
    top: auto;
  }
  .navbar .dropdown-hover:hover > .dropdown-menu, .navbar .dropdown-hover .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition-delay: 0ms;
  }
}

.dropdown-menu {
  border-radius: 0.25rem;
  padding: 0.25rem 0.125rem;
  border-radius: 0.25rem;
  border-color: #d3dadf;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.dropdown-menu.dropdown-caret::before, .dropdown-menu.dropdown-caret::after {
  display: block;
  content: "";
  position: absolute;
  top: -0.5rem;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-right: 0.5rem solid transparent;
  border-top: 0;
  border-left: 0.5rem solid transparent;
}

.dropdown-menu.dropdown-caret::after {
  border-bottom-color: #fff;
}

.dropdown-menu.dropdown-caret::before {
  margin-top: -1px;
  border-bottom-color: inherit;
}

.dropdown-menu.dropdown-caret.dropdown-menu-right::before, .dropdown-menu.dropdown-caret.dropdown-menu-right::after {
  left: auto;
  right: 12px;
}

.dropdown-menu.dropdown-caret.dropdown-caret-center::before, .dropdown-menu.dropdown-caret.dropdown-caret-center::after {
  left: calc(50% - 0.5rem);
}

.dropdown-menu[x-placement^="top"].dropdown-caret::before, .dropdown-menu[x-placement^="top"].dropdown-caret::after {
  top: auto;
  bottom: -0.5rem;
  border-top: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-bottom: 0;
}

.dropdown-menu[x-placement^="top"].dropdown-caret::after {
  border-top-color: #fff;
}

.dropdown-menu[x-placement^="top"].dropdown-caret::before {
  margin-bottom: -1px;
  margin-top: 0;
  border-top-color: inherit;
}

.dropdown-menu[x-placement^="right"].dropdown-caret::before, .dropdown-menu[x-placement^="right"].dropdown-caret::after {
  top: 12px;
  left: -0.5rem;
  border-right: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-top: 0.5rem solid transparent;
  border-left: 0;
  border-bottom: 0.5rem solid transparent;
}

.dropdown-menu[x-placement^="right"].dropdown-caret::after {
  border-right-color: #fff;
}

.dropdown-menu[x-placement^="right"].dropdown-caret::before {
  margin-left: -1px;
  margin-top: 0;
  border-right-color: inherit;
}

.dropdown-menu[x-placement^="left"].dropdown-caret::before, .dropdown-menu[x-placement^="left"].dropdown-caret::after {
  top: 12px;
  right: -0.5rem;
  left: auto;
  border-left: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-top: 0.5rem solid transparent;
  border-right: 0;
  border-bottom: 0.5rem solid transparent;
}

.dropdown-menu[x-placement^="left"].dropdown-caret::after {
  border-left-color: #fff;
}

.dropdown-menu[x-placement^="left"].dropdown-caret::before {
  margin-right: -1px;
  margin-top: 0;
  border-left-color: inherit;
}

.dropdown-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  transition: opacity 150ms, transform 150ms, visibility 0s;
  transition-delay: 0s, 0s, 150ms !important;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-animated {
    transition: none;
  }
}

.dropdown-animated[x-placement^="bottom"] {
  top: 100% !important;
  bottom: auto !important;
}

.dropdown-animated[x-placement^="top"] {
  bottom: 100% !important;
  top: auto !important;
  transform-origin: bottom center;
}

.dropdown-animated.dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
}

.dropdown-animated:not(.show) {
  transform: scaleY(0) !important;
  pointer-events: none;
}

.dropdown-animated.animated-1 {
  transform: translateY(0) scale(0.6);
}

.dropdown-animated.animated-2 {
  transition: opacity 150ms, margin-top 150ms, visibility 0s;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-animated.animated-2 {
    transition: none;
  }
}

.dropdown-animated.animated-2:not(.show) {
  margin-top: -1rem !important;
}

.dropdown-animated.show {
  opacity: 1;
  visibility: visible;
  transform: none !important;
  transition-delay: 0ms, 0ms, 0ms !important;
}

@media (min-width: 1200px) {
  .dropdown.dropdown-hover:hover > .dropdown-menu.dropdown-animated {
    opacity: 1;
    visibility: visible;
    transform: none !important;
    transition-delay: 0ms, 0ms, 0ms !important;
    pointer-events: all;
  }
}

@keyframes dropdownSlideUp {
  from {
    transform: translate(0, 100%);
    display: none;
  }
  to {
    transform: translate(0, 0);
    display: block;
  }
}

@keyframes dropdownSlideCenter {
  from {
    transform: translate(0, 200%);
    display: none;
  }
  to {
    transform: translate(0, -50%);
    display: block;
  }
}

@keyframes dropdownAppearCenter {
  from {
    transform: translate(0, -50%) scale(0.5);
    display: none;
  }
  to {
    transform: translate(0, -50%);
    display: block;
  }
}

.dropdown-inner {
  background-color: inherit;
  border-width: inherit;
  border-style: inherit;
  border-color: inherit;
  padding: inherit;
  max-height: calc(100% - 2rem);
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
}

.dropdown-menu.dd-slide-up > .dropdown-inner {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  visibility: hidden;
}

.dropdown-menu.dd-slide-up.show > .dropdown-inner {
  animation-name: dropdownSlideUp;
  animation-duration: 150ms;
  animation-fill-mode: forwards;
  visibility: visible;
}

.dropdown-menu.dd-appear-center > .dropdown-inner {
  position: fixed;
  top: 50%;
  bottom: auto;
  left: 0;
  right: 0;
  max-width: calc(100% - 1rem);
  margin: 0 auto;
  width: 240px;
  visibility: hidden;
}

.dropdown-menu.dd-appear-center.show > .dropdown-inner {
  animation-name: dropdownAppearCenter;
  animation-duration: 90ms;
  animation-fill-mode: forwards;
  visibility: visible;
}

.dropdown-menu.dd-slide-center > .dropdown-inner {
  position: fixed;
  top: 50%;
  bottom: auto;
  left: 0;
  right: 0;
  max-width: calc(100% - 1rem);
  margin: 0 auto;
  width: 240px;
  visibility: hidden;
}

.dropdown-menu.dd-slide-center.show > .dropdown-inner {
  animation-name: dropdownSlideCenter;
  animation-duration: 90ms;
  animation-fill-mode: forwards;
  visibility: visible;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-menu.dd-slide-up.show > .dropdown-inner,
  .dropdown-menu.dd-appear-center.show > .dropdown-inner,
  .dropdown-menu.dd-slide-center.show > .dropdown-inner {
    animation-duration: 0ms;
  }
}

.dd-backdrop::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1040;
  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms, visibility 0s;
  transition-delay: 0s, 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .dd-backdrop::before {
    transition: none;
  }
}

.dd-backdrop.show::before {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.dd-backdrop.show > .dropdown-toggle {
  position: relative;
}

.dd-backdrop.show .dropdown-menu {
  z-index: 1050;
}

.dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]) {
  transform: none !important;
  will-change: unset !important;
  visibility: hidden;
}

.dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]).show > .dropdown-inner {
  visibility: visible;
}

.dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]).dropdown-caret::before, .dropdown-menu[class*="dd-slide-"]:not([class*="dd-slide-none-"]).dropdown-caret::after {
  display: none !important;
}

.dropdown-menu.dd-slide-none.show {
  z-index: 1000;
}

.dropdown-menu.dd-slide-none > .dropdown-inner {
  position: static;
  animation: none !important;
  width: auto;
  max-width: none;
  max-height: none;
  margin: auto;
  overflow: visible;
  background-color: transparent;
  border: none;
  padding: 0;
  visibility: inherit;
}

.dd-backdrop-none::before {
  display: none;
}

@media (min-width: 576px) {
  .dropdown-menu.dd-slide-none-sm.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-sm > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
    visibility: inherit;
  }
  .dd-backdrop-none-sm::before {
    display: none;
  }
}

@media (min-width: 768px) {
  .dropdown-menu.dd-slide-none-md.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-md > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
    visibility: inherit;
  }
  .dd-backdrop-none-md::before {
    display: none;
  }
}

@media (min-width: 992px) {
  .dropdown-menu.dd-slide-none-lg.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-lg > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
    visibility: inherit;
  }
  .dd-backdrop-none-lg::before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu.dd-slide-none-xl.show {
    z-index: 1000;
  }
  .dropdown-menu.dd-slide-none-xl > .dropdown-inner {
    position: static;
    animation: none !important;
    width: auto;
    max-width: none;
    max-height: none;
    margin: auto;
    overflow: visible;
    background-color: transparent;
    border: none;
    padding: 0;
    visibility: inherit;
  }
  .dd-backdrop-none-xl::before {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .dropdown-menu.dd-slide-none-sm {
    transform: none !important;
    will-change: unset !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-sm.dropdown-caret::before, .dropdown-menu.dd-slide-none-sm.dropdown-caret::after {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .dropdown-menu.dd-slide-none-md {
    transform: none !important;
    will-change: unset !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-md.dropdown-caret::before, .dropdown-menu.dd-slide-none-md.dropdown-caret::after {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .dropdown-menu.dd-slide-none-lg {
    transform: none !important;
    will-change: unset !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-lg.dropdown-caret::before, .dropdown-menu.dd-slide-none-lg.dropdown-caret::after {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .dropdown-menu.dd-slide-none-xl {
    transform: none !important;
    will-change: unset !important;
    visibility: hidden !important;
  }
  .dropdown-menu.dd-slide-none-xl.dropdown-caret::before, .dropdown-menu.dd-slide-none-xl.dropdown-caret::after {
    display: none !important;
  }
}

.dropdown-menu.dd-slide-none {
  transform: none !important;
  will-change: unset !important;
  visibility: hidden !important;
}

.dropdown-menu.dd-slide-none.dropdown-caret::before, .dropdown-menu.dd-slide-none.dropdown-caret::after {
  display: none !important;
}

.tab-content {
  border: 1px solid #d7dbda;
  padding: 1rem;
}

.tab-sliding {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.tab-sliding .tab-pane {
  max-width: 100%;
  flex: 1 0 auto;
  display: block !important;
  transform: none;
  visibility: hidden;
  max-height: 0;
  transition: margin-left, margin-right, transform, max-height, visibility;
  transition-duration: 350ms, 350ms, 350ms, 350ms, 0s;
  transition-delay: 0s, 0s, 0s, 0s, 340ms;
}

@media (prefers-reduced-motion: reduce) {
  .tab-sliding .tab-pane {
    transition: none;
  }
}

.tab-sliding .tab-pane:not(.active) {
  pointer-events: none;
}

.tab-sliding .tab-pane {
  margin-left: -100%;
}

.tab-sliding .tab-pane.active {
  transform: translateX(100%);
  visibility: visible;
  max-height: 100%;
  transition-delay: 0s;
}

.tab-sliding .tab-pane.mh-none:not(.active) {
  max-height: 0 !important;
}

.tab-sliding .tab-pane.active ~ .tab-pane {
  margin-left: 100%;
  margin-right: -200%;
}

.tab-pane.tab-swiping.active {
  overflow: hidden;
  transition: none;
  touch-action: none;
}

.tab-pane.tab-swiping:not(.active) {
  transition: none;
  visibility: visible;
  max-height: none;
}

.dropdown-menu:not(.show) .tab-sliding .tab-pane.active {
  visibility: hidden;
}

.dropdown-hover:hover .tab-sliding .tab-pane.active {
  visibility: visible;
}

.nav-tabs-scroll {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
}

.nav-tabs-scroll:not([class*="sticky-nav"]) {
  position: relative;
}

@supports (overflow: overlay) or (scrollbar-width: none) {
  .nav-tabs-scroll.is-scrollable:not(.is-scrollbar-shown) {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .nav-tabs-scroll.is-scrollable:not(.is-scrollbar-shown)::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}

.nav-tabs {
  border-width: 0;
  border-color: #e0e5e8;
}

.nav-tabs .nav-item {
  display: flex;
  margin-right: -1px;
}

.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.nav-tabs .nav-link {
  width: 100%;
  height: calc(100% - 1px);
  box-shadow: none;
  border: 1px solid #d7dbda;
  border-bottom-width: 0;
  border-radius: 0;
  font-size: 0.925rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.nav-tabs .nav-link:not([class*="btn-"]) {
  color: #3b3c41;
  background-color: #f8f8f9;
}

.nav-tabs .nav-link:not([class*="btn-"]):hover {
  background-color: #fff;
  border-color: #dfe2e1;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  margin-top: -2px;
  height: calc(100% + 2px);
  border-radius: 0.25rem 0.25rem 0 0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  z-index: 1;
}

.nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .nav-tabs .nav-link.active:not([class*="btn-"]) {
  color: #28292c;
  background-color: #fff;
  border-top-width: 3px;
  border-color: #d7dbda;
  border-top-color: #4c99da;
  font-weight: 600;
}

.nav-tabs.nav-tabs-shadow .nav-item.show .nav-link, .nav-tabs.nav-tabs-shadow .nav-item .nav-link.active {
  box-shadow: 0 -0.125rem 0.325rem rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-item[class*="brc-"] .nav-link.active {
  border-top-color: inherit;
}

.nav-tabs .btn {
  width: 100%;
  height: calc(100% - 1px);
  border-bottom-width: 0;
}

.nav-tabs .btn.active {
  margin-top: -2px;
  height: calc(100% + 2px);
  border-top-width: 3px;
  font-weight: 600;
}

.nav-tabs .btn.active[class*="border-b-"] {
  margin-bottom: -2px;
  margin-top: 0;
}

.nav-tabs.nav-tabs-detached .nav-link.active, .nav-tabs.nav-tabs-detached .btn.active {
  margin-top: -3px;
}

.nav-tabs.nav-tabs-detached .nav-link.active[class*="border-b-"], .nav-tabs.nav-tabs-detached .btn.active[class*="border-b-"] {
  margin-bottom: -3px;
  margin-top: 0;
}

.nav-tabs.nav-tabs-static .nav-link, .nav-tabs.nav-tabs-static .btn {
  height: 100%;
}

.nav-tabs.nav-tabs-static .nav-link.active, .nav-tabs.nav-tabs-static .btn.active {
  height: 100%;
  margin-top: 0;
}

.nav-tabs-faded .nav-link:not(.active):not(:hover), .nav-tabs-faded .btn:not(.active):not(:hover) {
  opacity: 0.77;
}

.nav-tabs.nav-tabs-simple .nav-link {
  border-width: 0;
  padding-left: 0.25rem;
  background-color: transparent;
  color: #3b3c41;
  height: calc(100% - 1px);
}

.nav-tabs.nav-tabs-simple .nav-link:not(.active):hover {
  color: #505255;
  background-color: transparent;
}

.nav-tabs-simple .nav-item.show .nav-link, .nav-tabs.nav-tabs-simple .nav-link.active {
  margin-top: 0;
  box-shadow: none;
  border-width: 0 0 3px 0;
  color: #28292c;
  font-weight: 600;
  background-color: transparent;
}

.nav-tabs-simple .nav-item.show .nav-link:not([class*="btn-"]), .nav-tabs.nav-tabs-simple .nav-link.active:not([class*="btn-"]) {
  border-bottom-color: #4c99da;
}

.nav-tabs.nav-tabs-simple + .tab-content {
  border-width: 0;
  padding: 0.75rem;
}

.tabs-left {
  display: flex;
  flex-direction: row;
}

.tabs-left > .tab-content {
  flex-grow: 1;
}

.tabs-left > .nav-tabs {
  flex-direction: column;
}

.tabs-left > .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
}

.tabs-left > .nav-tabs .nav-item:first-child .nav-link:not(.active) {
  border-radius: 0.25rem 0 0  0;
}

.tabs-left > .nav-tabs .nav-item:last-child .nav-link:not(.active) {
  border-radius: 0 0 0 0.25rem;
}

.tabs-left > .nav-tabs .nav-link {
  border-width: 1px 0 1px 1px;
  border-radius: 0;
  margin: 0 0 -1px 0;
  width: auto;
  height: auto;
}

.tabs-left .nav-tabs .nav-item.show .nav-link, .tabs-left > .nav-tabs .nav-link.active {
  position: relative;
  font-weight: 400;
  margin: 0 -1px -1px -1px;
  border-width: 1px 1px 1px 3px;
  border-top-color: #d7dbda;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right-color: transparent;
}

.tabs-left .nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .tabs-left > .nav-tabs .nav-link.active:not([class*="btn-"]) {
  border-left-color: #4c99da;
}

.tabs-left .nav-tabs.nav-tabs-shadow .nav-item.show .nav-link, .tabs-left .nav-tabs.nav-tabs-shadow .nav-item .nav-link.active {
  box-shadow: -0.125rem 0 0.325rem rgba(0, 0, 0, 0.1);
}

.tabs-left > .nav-tabs .nav-item:last-child .nav-link, .tabs-left > .nav-tabs .nav-item:last-child .btn {
  margin-bottom: 0;
}

.tabs-left > .nav-tabs .nav-item[class*="brc-"] .nav-link.active {
  border-top-color: #d7dbda;
  border-left-color: inherit;
}

.tabs-right {
  display: flex;
  flex-direction: row-reverse;
}

.tabs-right > .tab-content {
  flex-grow: 1;
}

.tabs-right > .nav-tabs {
  flex-direction: column;
}

.tabs-right > .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
}

.tabs-right > .nav-tabs .nav-item:first-child .nav-link:not(.active) {
  border-radius: 0 0.25rem 0 0;
}

.tabs-right > .nav-tabs .nav-item:last-child .nav-link:not(.active) {
  border-radius: 0 0 0.25rem 0;
}

.tabs-right > .nav-tabs .nav-link {
  border-width: 1px 1px 1px 0;
  margin: 0 0 -1px 0;
  width: auto;
  height: auto;
}

.tabs-right .nav-tabs .nav-item.show .nav-link, .tabs-right > .nav-tabs .nav-link.active {
  font-weight: 400;
  margin: 0 -1px -1px -1px;
  border-width: 1px 3px 1px 1px;
  border-top-color: #d7dbda;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left-color: transparent;
  position: relative;
}

.tabs-right .nav-tabs .nav-item.show .nav-link:not([class*="btn-"]), .tabs-right > .nav-tabs .nav-link.active:not([class*="btn-"]) {
  border-right-color: #4c99da;
}

.tabs-right .nav-tabs.nav-tabs-shadow .nav-item.show .nav-link, .tabs-right .nav-tabs.nav-tabs-shadow .nav-item .nav-link.active {
  box-shadow: 0.125rem 0 0.325rem rgba(0, 0, 0, 0.1);
}

.tabs-right > .nav-tabs .nav-item:last-child .nav-link, .tabs-right > .nav-tabs .nav-item:last-child .btn {
  margin-bottom: 0;
}

.tabs-right > .nav-tabs .nav-item[class*="brc-"] .nav-link.active {
  border-top-color: #d7dbda;
  border-right-color: inherit;
}

.accordion .card {
  border: 1px solid transparent;
}

.accordion .card:first-of-type, .accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid transparent;
}

.accordion .card[class*="brc-"] .card-header {
  border-bottom-width: 0;
}

.accordion .card + .card {
  margin-top: 0.125rem;
}

.accordion .card-header {
  border-bottom-color: transparent;
  padding: 0;
}

.accordion .card-header .card-title {
  font-size: 1rem;
}

.accordion .card-header .accordion-toggle {
  display: block;
  width: 100%;
  text-align: left;
  border-width: 0;
  font-weight: 600;
  padding: 0.75rem 1.25rem 0.875rem;
  position: relative;
  overflow: hidden;
}

.accordion .card-header .accordion-toggle.collapsed {
  font-weight: 400;
}

.accordion .card-header .accordion-toggle:not(.btn) {
  transition: all 0.15s;
  color: #fff;
  background-color: #287abe;
}

@media (prefers-reduced-motion: reduce) {
  .accordion .card-header .accordion-toggle:not(.btn) {
    transition: none;
  }
}

.accordion .card-header .accordion-toggle:not(.btn).collapsed {
  color: #57585c;
  background-color: #f0f0f0;
}

.accordion .card-header .accordion-toggle:not(.btn):hover {
  text-decoration: none;
  color: #fff;
  background-color: #2a80c8;
}

.accordion .card-header .accordion-toggle:not(.btn).collapsed:hover {
  color: #fff;
  background-color: #2a80c8;
}

.accordion .card-header .card-title[class*="bgc-"] .accordion-toggle:not(.collapsed) {
  background-color: inherit !important;
}

.accordion .card-header .card-title[class*="brc-"] .accordion-toggle:not(.collapsed) {
  border-color: inherit !important;
}

.accordion .card-header .card-title[class*="text-"] .accordion-toggle:not(.collapsed) {
  color: inherit;
}

.accordion-faded > .card > .card-header > .card-title > .accordion-toggle.collapsed:not(:hover) {
  opacity: 0.77;
}

a[data-toggle="collapse"].collapsed .toggle-icon {
  transform: none;
}

a[data-toggle="collapse"] .toggle-icon {
  transition: transform 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  a[data-toggle="collapse"] .toggle-icon {
    transition: none;
  }
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-down"] {
  transform: rotate(-180deg);
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-up"] {
  transform: rotate(180deg);
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-left"] {
  transform: rotate(-90deg);
}

a[data-toggle="collapse"]:not(.collapsed) .toggle-icon[class*="-right"] {
  transform: rotate(90deg);
}

.tooltip {
  top: 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement="top"] .arrow {
  left: calc(50% - 0.4rem) !important;
}

.bs-tooltip-top .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="top"] .arrow[class*="brc-"]::before {
  border-top-color: inherit;
}

.bs-tooltip-top .arrow + .arrow, .bs-tooltip-auto[x-placement="top"] .arrow + .arrow {
  margin-bottom: 2px;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement="bottom"] .arrow {
  left: calc(50% - 0.4rem) !important;
}

.bs-tooltip-bottom .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="bottom"] .arrow[class*="brc-"]::before {
  border-bottom-color: inherit;
}

.bs-tooltip-bottom .arrow + .arrow, .bs-tooltip-auto[x-placement="bottom"] .arrow + .arrow {
  margin-top: 2px;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement="left"] .arrow {
  top: calc(50% - 0.4rem) !important;
}

.bs-tooltip-left .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="left"] .arrow[class*="brc-"]::before {
  border-left-color: inherit;
}

.bs-tooltip-left .arrow + .arrow, .bs-tooltip-auto[x-placement="left"] .arrow + .arrow {
  margin-right: 2px;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement="right"] .arrow {
  top: calc(50% - 0.4rem) !important;
}

.bs-tooltip-right .arrow[class*="brc-"]::before, .bs-tooltip-auto[x-placement="right"] .arrow[class*="brc-"]::before {
  border-right-color: inherit;
}

.bs-tooltip-right .arrow + .arrow, .bs-tooltip-auto[x-placement="right"] .arrow + .arrow {
  margin-left: 2px;
}

.popover .arrow2 {
  z-index: 1;
}

.popover .arrow2::after, .popover .arrow2 + .arrow::after {
  display: none;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement="top"] .arrow {
  left: calc(50% - 0.5rem) !important;
  margin-left: 0;
  margin-right: 0;
}

.bs-popover-top .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="top"] .arrow[class*="brc-"]::before {
  border-top-color: inherit;
}

.bs-popover-top .arrow2, .bs-popover-auto[x-placement="top"] .arrow2 {
  margin-bottom: 1px;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement="bottom"] .arrow {
  left: calc(50% - 0.5rem) !important;
  margin-left: 0;
  margin-right: 0;
}

.bs-popover-bottom .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="bottom"] .arrow[class*="brc-"]::before {
  border-bottom-color: inherit;
}

.bs-popover-bottom .arrow2, .bs-popover-auto[x-placement="bottom"] .arrow2 {
  margin-top: 1px;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement="left"] .arrow {
  top: calc(50% - 0.5rem) !important;
  margin-top: 0;
  margin-bottom: 0;
}

.bs-popover-left .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="left"] .arrow[class*="brc-"]::before {
  border-left-color: inherit;
}

.bs-popover-left .arrow2, .bs-popover-auto[x-placement="left"] .arrow2 {
  margin-right: 1px;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement="right"] .arrow {
  top: calc(50% - 0.5rem) !important;
  margin-top: 0;
  margin-bottom: 0;
}

.bs-popover-right .arrow[class*="brc-"]::before, .bs-popover-auto[x-placement="right"] .arrow[class*="brc-"]::before {
  border-right-color: inherit;
}

.bs-popover-right .arrow2, .bs-popover-auto[x-placement="right"] .arrow2 {
  margin-left: 1px;
}

.popover .arrow[class*="brc-"] ~ .popover-header::before {
  display: none !important;
}

.alert {
  border-radius: 0.1875rem;
  color: #3b3c41;
}

.alert-collapse.collapsing {
  transition-duration: 150ms;
}

.alert-link {
  font-weight: 600;
}

.ace-toaster-container {
  position: fixed !important;
  z-index: 1023;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ace-toaster-container.position-tr, .ace-toaster-container.position-br, .ace-toaster-container.position-rc {
  right: 1rem;
  align-items: end;
}

.ace-toaster-container.position-tr {
  top: 1rem;
}

.ace-toaster-container.position-br {
  bottom: 1rem;
}

.ace-toaster-container.position-tl, .ace-toaster-container.position-bl, .ace-toaster-container.position-lc {
  left: 1rem;
  align-items: start;
}

.ace-toaster-container.position-tl {
  top: 1rem;
}

.ace-toaster-container.position-bl {
  bottom: 1rem;
}

.ace-toaster-container.position-bc {
  top: auto;
  bottom: 1rem;
}

.ace-toaster-container.position-tc {
  bottom: auto;
  top: 1rem;
}

.ace-toaster-container.toaster-below-nav {
  margin-top: 4.5rem;
  margin-top: var(--navbar-height);
  z-index: 1019;
}

@media (max-width: 1199.98px) {
  .ace-toaster-container.toaster-below-nav {
    margin-top: 4rem;
    margin-top: var(--navbar-mobile-height);
  }
}

.ace-toaster-container .toast {
  pointer-events: all;
  position: relative;
  z-index: inherit;
  width: 24rem;
  max-width: calc(100vw - 2rem);
  flex-basis: auto;
}

.ace-toaster-container .toast .toast-header {
  position: relative;
}

.ace-toaster-container .toast .toast-header .close {
  align-self: flex-start;
}

.ace-toaster-container .toast .toast-image {
  flex-shrink: 0;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  overflow: hidden;
  align-self: flex-start;
  padding: 1px;
}

.ace-toaster-container .toast .toast-image img {
  border-radius: 0.1875rem;
}

.ace-toaster-container .toast .toast-main {
  flex-grow: 1;
  width: 100%;
}

.ace-toaster-container .toast.collapsing {
  transition-duration: 250ms;
  margin-bottom: 0;
}

.ace-toaster-container .toast .toast-title {
  margin-right: auto;
  font-weight: 600;
}

.modal-header {
  border-bottom-color: #e4e9ee;
}

.modal-footer {
  border-top-color: #e4e9ee;
  background-color: #eff3f8;
}

.ace-aside {
  transition: z-index 0s 250ms;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside {
    transition: none;
  }
}

.ace-aside.aside-hover {
  transition-delay: 750ms;
}

.ace-aside.show, .ace-aside.aside-hover:hover {
  transition-delay: 0s;
}

.ace-aside.modal-nb {
  pointer-events: none;
  z-index: 1016;
}

.ace-aside.modal-nb.show, .ace-aside.modal-nb.aside-hover:hover {
  z-index: 1018;
}

.ace-aside.aside-below-nav.show, .ace-aside.aside-below-nav.aside-hover:hover {
  z-index: 1017;
}

.ace-aside.aside-above-nav {
  z-index: 1022;
}

.ace-aside.aside-above-nav.show, .ace-aside.aside-above-nav.aside-hover:hover {
  z-index: 1023;
}

.ace-aside.aside-shown-above-nav.show, .ace-aside.aside-shown-above-nav.aside-hover:hover {
  z-index: 1023;
}

.ace-aside:not(.modal-nb) {
  z-index: 1016;
}

.ace-aside:not(.modal-nb).show, .ace-aside:not(.modal-nb).aside-hover:hover {
  z-index: 1050;
}

.ace-aside:not(.modal-nb) {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.ace-aside {
  display: block !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: auto;
  height: auto;
  visibility: hidden;
}

.ace-aside.modal-nb {
  overflow: visible !important;
}

.ace-aside.show:not(.modal-nb) {
  visibility: visible;
}

.ace-aside.aside-hover:not(.modal-nb):hover {
  visibility: visible;
}

.ace-aside.fade {
  opacity: 1;
}

.ace-aside.fade .modal-dialog {
  transition-duration: 250ms;
}

.ace-aside .modal-dialog {
  margin: 0 auto;
  display: flex;
  transition: transform 250ms;
  max-width: none;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside .modal-dialog {
    transition: none;
  }
}

.ace-aside .modal-dialog .aside-header {
  pointer-events: all;
  position: relative;
  z-index: 1;
  visibility: visible;
}

.ace-aside .modal-dialog .modal-content {
  z-index: 2;
}

.ace-aside.show .modal-dialog {
  visibility: visible;
}

.ace-aside.aside-top, .ace-aside.aside-bottom {
  left: 0;
  right: 0;
  width: 100%;
}

.ace-aside.aside-top .modal-dialog, .ace-aside.aside-bottom .modal-dialog {
  align-items: center;
  transform: translateY(0);
}

.ace-aside.aside-top .aside-header, .ace-aside.aside-bottom .aside-header {
  height: 3rem;
}

.ace-aside.aside-top {
  top: 0;
  bottom: auto;
  height: auto;
  display: flex !important;
  max-height: 100%;
}

.ace-aside.aside-top .modal-dialog {
  transform: translateY(-100%);
  flex-direction: column;
  margin-top: 0;
  max-height: 100%;
  width: 100%;
}

.ace-aside.aside-top .modal-content {
  border-width: 0 0 1px 0;
  max-height: 100%;
}

.ace-aside.aside-top .aside-header + .modal-content, .ace-aside.aside-top .aside-header + .modal-body {
  order: -1;
}

.ace-aside.aside-top.aside-offset .modal-dialog {
  transform: translateY(-100%) translateY(3rem);
}

.ace-aside.aside-top.show .modal-dialog, .ace-aside.aside-top.aside-hover:hover .modal-dialog {
  transform: translateY(0);
}

.ace-aside.aside-top.show.margin-1 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateY(0.25rem);
}

.ace-aside.aside-top.show.margin-2 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateY(0.5rem);
}

.ace-aside.aside-top.show.margin-3 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateY(1rem);
}

.ace-aside.aside-top.show.margin-4 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateY(1.5rem);
}

.ace-aside.aside-top.show.margin-5 .modal-dialog, .ace-aside.aside-top.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateY(3rem);
}

.ace-aside.aside-bottom {
  bottom: 0;
  top: auto;
  display: flex !important;
  max-height: 100%;
}

.ace-aside.aside-bottom .modal-dialog {
  transform: translateY(100%);
  flex-direction: column;
  margin-bottom: 0;
  max-height: 100%;
  width: 100%;
}

.ace-aside.aside-bottom .modal-content {
  border-width: 1px 0 0 0;
  max-height: 100%;
}

.ace-aside.aside-bottom .modal-content + .aside-header, .ace-aside.aside-bottom .modal-body + .aside-header {
  order: -1;
}

.ace-aside.aside-bottom.aside-offset .modal-dialog {
  transform: translateY(100%) translateY(-3rem);
}

.ace-aside.aside-bottom.show .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover .modal-dialog {
  transform: translateY(0);
}

.ace-aside.aside-bottom.show.margin-1 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateY(-0.25rem);
}

.ace-aside.aside-bottom.show.margin-2 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateY(-0.5rem);
}

.ace-aside.aside-bottom.show.margin-3 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateY(-1rem);
}

.ace-aside.aside-bottom.show.margin-4 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateY(-1.5rem);
}

.ace-aside.aside-bottom.show.margin-5 .modal-dialog, .ace-aside.aside-bottom.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateY(-3rem);
}

.ace-aside.aside-l .modal-dialog, .ace-aside.aside-r .modal-dialog, .ace-aside.aside-c .modal-dialog {
  width: 500px;
  max-width: calc(100vw - 1rem);
}

.ace-aside.aside-l .modal-dialog {
  margin-left: 0;
}

.ace-aside.aside-r .modal-dialog {
  margin-right: 0;
}

.ace-aside.aside-right, .ace-aside.aside-left {
  top: 0;
  bottom: 0;
  height: auto;
}

.ace-aside.aside-right .modal-dialog, .ace-aside.aside-left .modal-dialog {
  align-items: center;
  width: 500px;
  max-width: calc(100vw - 1rem);
  height: 100%;
  max-height: 100%;
}

.ace-aside.aside-right .modal-dialog .modal-content, .ace-aside.aside-left .modal-dialog .modal-content {
  height: 100%;
}

.ace-aside.aside-right .modal-dialog .aside-header, .ace-aside.aside-left .modal-dialog .aside-header {
  width: 3rem;
  flex: 0 0 auto;
  word-break: break-all;
}

.ace-aside.aside-right {
  right: 0;
  left: auto;
}

.ace-aside.aside-right .modal-dialog {
  transform: translateX(100%);
  flex-direction: row;
  margin-right: 0;
}

.ace-aside.aside-right .modal-content {
  border-width: 0 0 0 1px;
}

.ace-aside.aside-right .modal-content + .aside-header, .ace-aside.aside-right .modal-body + .aside-header {
  order: -1;
}

.ace-aside.aside-right.aside-offset .modal-dialog {
  transform: translateX(100%) translateX(-3rem);
}

.ace-aside.aside-right.show .modal-dialog, .ace-aside.aside-right.aside-hover:hover .modal-dialog {
  transform: translateX(0);
}

.ace-aside.aside-right.show.margin-1 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateX(-0.25rem);
}

.ace-aside.aside-right.show.margin-2 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateX(-0.5rem);
}

.ace-aside.aside-right.show.margin-3 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateX(-1rem);
}

.ace-aside.aside-right.show.margin-4 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateX(-1.5rem);
}

.ace-aside.aside-right.show.margin-5 .modal-dialog, .ace-aside.aside-right.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateX(-3rem);
}

.ace-aside.aside-left {
  left: 0;
  right: auto;
}

.ace-aside.aside-left .modal-dialog {
  transform: translateX(-100%);
  flex-direction: row;
  margin-left: 0;
}

.ace-aside.aside-left .modal-content {
  border-width: 0 1px 0 0;
}

.ace-aside.aside-left .aside-header + .modal-content, .ace-aside.aside-left .aside-header + .modal-body {
  order: -1;
}

.ace-aside.aside-left.aside-offset .modal-dialog {
  transform: translateX(-100%) translateX(3rem);
}

.ace-aside.aside-left.show .modal-dialog, .ace-aside.aside-left.aside-hover:hover .modal-dialog {
  transform: translateX(0);
}

.ace-aside.aside-left.show.margin-1 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-1 .modal-dialog {
  transform: translateX(0.25rem);
}

.ace-aside.aside-left.show.margin-2 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-2 .modal-dialog {
  transform: translateX(0.5rem);
}

.ace-aside.aside-left.show.margin-3 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-3 .modal-dialog {
  transform: translateX(1rem);
}

.ace-aside.aside-left.show.margin-4 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-4 .modal-dialog {
  transform: translateX(1.5rem);
}

.ace-aside.aside-left.show.margin-5 .modal-dialog, .ace-aside.aside-left.aside-hover:hover.margin-5 .modal-dialog {
  transform: translateX(3rem);
}

.ace-aside.aside-m {
  margin-top: auto;
  margin-bottom: auto;
}

.ace-aside.aside-m .modal-dialog {
  max-height: calc(100% - 2rem);
  height: 400px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.ace-aside.aside-center {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}

.ace-aside.aside-center .modal-dialog {
  width: 500px;
  max-width: calc(100vw - 1rem);
  max-height: calc(100% - );
  max-height: calc(100% - var(--navbar-height));
}

.ace-aside.aside-below-nav {
  padding-top: 4.5rem;
  padding-top: var(--navbar-height);
}

@media (min-width: 1200px) {
  .navbar-sm ~ div .ace-aside.aside-below-nav {
    padding-top: 4rem;
    padding-top: var(--navbar-sm-height);
  }
}

@media (max-width: 1199.98px) {
  .ace-aside.aside-below-nav {
    padding-top: 4rem;
    padding-top: var(--navbar-mobile-height);
  }
}

.ace-aside .modal-content, .ace-aside:not(.aside-offset) .aside-header {
  visibility: hidden;
  transition: visibility 0s 250ms;
}

.ace-aside.show .modal-content, .ace-aside.show:not(.aside-offset) .aside-header, .ace-aside.aside-hover:hover .modal-content, .ace-aside.aside-hover:hover:not(.aside-offset) .aside-header {
  visibility: visible;
  transition-delay: 0s;
}

.ace-aside {
  /**
    .modal-content.scroll-body {
        max-height: 100%;
        overflow: hidden;

        .modal-body {
            overflow: hidden auto;
            flex: 2 1 auto;
        }
    }
    */
}

.ace-aside .modal-content.scroll-content {
  max-height: 100%;
  overflow: hidden auto;
}

@media (min-width: 1200px) {
  .container .aside-right.container, .container .aside-left.container {
    transform: none;
    right: 0;
    left: 0;
    width: 100%;
    clip-path: inset(-30px 0px -30px 0px);
  }
  .container .aside-right.container .modal-dialog {
    margin-right: 0;
  }
  .container .aside-left.container .modal-dialog {
    margin-left: 0;
  }
  .container .container:not(.modal-nb).aside-left, .container .container:not(.modal-nb).aside-right {
    min-width: 100%;
    max-height: none;
  }
  .container .container:not(.modal-nb).aside-left .modal-dialog, .container .container:not(.modal-nb).aside-right .modal-dialog {
    max-width: inherit;
    width: auto;
    margin: auto;
    justify-content: flex-end;
    align-items: center;
  }
  .container .container:not(.modal-nb).aside-left .modal-dialog .modal-content, .container .container:not(.modal-nb).aside-right .modal-dialog .modal-content {
    width: 500px;
    max-width: calc(100vw - 1rem);
  }
  .container .container:not(.modal-nb).aside-top, .container .container:not(.modal-nb).aside-bottom {
    min-width: 100%;
  }
  .container .container:not(.modal-nb).aside-top .modal-dialog, .container .container:not(.modal-nb).aside-bottom .modal-dialog {
    max-width: inherit;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .container .container:not(.modal-nb).aside-r .modal-content, .container .container:not(.modal-nb).aside-l .modal-content, .container .container:not(.modal-nb).aside-c .modal-content {
    width: 500px;
    max-width: calc(100vw - 1rem);
  }
  .container .container:not(.modal-nb).aside-l .modal-dialog {
    align-items: flex-start;
  }
  .container .container:not(.modal-nb).aside-r .modal-dialog {
    align-items: flex-end;
  }
  .container .aside-m.container:not(.modal-nb) .modal-dialog {
    max-height: calc(100% - 2rem);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.ace-aside.aside-fade.aside-offset {
  opacity: 1;
}

.ace-aside.aside-fade .modal-dialog {
  transform: none !important;
  visibility: hidden;
}

.ace-aside.aside-fade .modal-content {
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  transition: transform 250ms 0s, opacity 250ms 0s, visibility 0s 250ms;
  z-index: 2;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside.aside-fade .modal-content {
    transition: none;
  }
}

.ace-aside.aside-fade .aside-header {
  z-index: 1;
  visibility: visible;
}

.ace-aside.aside-fade .aside-header.fade {
  opacity: 1;
  transition: all 250ms;
}

@media (prefers-reduced-motion: reduce) {
  .ace-aside.aside-fade .aside-header.fade {
    transition: none;
  }
}

.ace-aside.aside-fade.show .modal-content, .ace-aside.aside-fade.aside-hover:hover .modal-content {
  transform: none;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.ace-aside.aside-fade.show .aside-header.fade, .ace-aside.aside-fade.aside-hover:hover .aside-header.fade {
  transform: scale(0.75);
  opacity: 0;
}

.ace-aside.aside-fade.aside-right .modal-content, .ace-aside.aside-fade.aside-right .aside-header {
  transform-origin: right center;
}

.ace-aside.aside-fade.aside-left .modal-content, .ace-aside.aside-fade.aside-left .aside-header {
  transform-origin: left center;
}

.ace-aside.aside-fade.aside-top .modal-content, .ace-aside.aside-fade.aside-top .aside-header {
  transform-origin: top center;
}

.ace-aside.aside-fade.aside-top.aside-r .modal-content, .ace-aside.aside-fade.aside-top.aside-r .aside-header {
  transform-origin: top right;
}

.ace-aside.aside-fade.aside-top.aside-l .modal-content, .ace-aside.aside-fade.aside-top.aside-l .aside-header {
  transform-origin: top left;
}

.ace-aside.aside-fade.aside-bottom .modal-content, .ace-aside.aside-fade.aside-bottom .aside-header {
  transform-origin: bottom center;
}

.ace-aside.aside-fade.aside-bottom.aside-r .modal-content, .ace-aside.aside-fade.aside-bottom.aside-r .aside-header {
  transform-origin: bottom right;
}

.ace-aside.aside-fade.aside-bottom.aside-l .modal-content, .ace-aside.aside-fade.aside-bottom.aside-l .aside-header {
  transform-origin: bottom left;
}

.aside-hover .modal-dialog {
  transition-delay: 500ms;
}

.aside-hover:hover .modal-dialog {
  transition-delay: 100ms;
}

body.modal-open:not(.scrollbar-w0):not(.modal-blur).modal-nb {
  overflow-y: auto;
  padding-right: 0 !important;
}

@media (min-width: 1200px) {
  body.modal-open:not(.scrollbar-w0):not(.modal-blur):not(.modal-nb) .navbar-fixed:not(.container) .navbar-inner {
    padding-right: var(--modal-padding);
  }
  body.modal-open:not(.scrollbar-w0):not(.modal-blur):not(.modal-nb) .container .footer-fixed .footer-inner {
    left: calc(-1 * var(--modal-padding));
  }
}

body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .aside-top, body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .aside-bottom {
  padding-right: var(--modal-padding) !important;
}

body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .aside-center .modal-dialog {
  right: var(--modal-padding);
}

body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .aside-right {
  padding-right: var(--modal-padding) !important;
}

body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .aside-right.aside-m {
  right: var(--modal-padding) !important;
}

@media (max-width: 1199.98px) {
  body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .navbar-fixed .navbar-inner {
    padding-right: var(--modal-padding);
  }
}

@media (min-width: 1200px) {
  body.modal-open.modal-scroll:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .navbar-fixed .navbar-inner.container {
    right: var(--modal-padding);
    padding-right: 0;
  }
  body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-top, body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-bottom {
    left: calc(-1 * var(--modal-padding));
    padding-right: 0 !important;
  }
  body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-right {
    padding-right: calc( var(--modal-padding) / 2) !important;
    clip-path: inset(-30px calc( var(--modal-padding) / 2) -30px 0px);
  }
  body.modal-open:not(.modal-nb):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-left {
    left: calc(-1 * var(--modal-padding));
  }
}

@supports ((overscroll-behavior: none) and (-webkit-appearance: none) and (-webkit-box-reflect: above)) or ((overscroll-behavior: none) and (scrollbar-width: none)) {
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .aside-top, body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .aside-bottom {
    padding-right: 0 !important;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .aside-center .modal-dialog {
    right: 0;
  }
  @media (min-width: 1200px) {
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .navbar-fixed:not(.container) .navbar-inner {
      padding-right: 0;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .footer-fixed .footer-inner {
      left: 0;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-top, body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-bottom {
      left: 0;
      padding-right: 0 !important;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-right {
      padding-right: 0 !important;
    }
    body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-left {
      left: 0;
    }
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-y: auto;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .modal[aria-modal=true] {
    overscroll-behavior: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-y: auto;
    scrollbar-width: none;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .modal[aria-modal=true]::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .modal[aria-modal=true]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: calc(100% + 0.5rem);
    pointer-events: none;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .ace-aside[aria-modal=true]::before {
    height: calc(100% + 1px);
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .modal-dialog-scrollable .modal-body {
    overscroll-behavior: none;
  }
  body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-top, body.modal-open:not(.modal-nb):not(.modal-scroll):not(.scrollbar-w0):not(.modal-blur) .container .ace-aside.container.aside-bottom {
    left: 0;
    margin-left: auto;
  }
}

.aside-fs, .aside-fs .modal-dialog, .aside-fs .modal-content {
  height: 100% !important;
  width: 100% !important;
}

.aside-fs.fade, .aside-fs.fade .modal-dialog {
  transition-duration: 375ms;
}

.aside-fs.fade:not(.show) .modal-content {
  transition-delay: 375ms;
}

.aside-fs .modal-dialog, .aside-fs .modal-content {
  max-width: none !important;
  max-height: none !important;
}

.modal-fs {
  padding-right: 0 !important;
}

.modal-fs .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 1rem);
  max-height: calc(100vh - 1rem);
}

.modal-fs .modal-dialog .modal-content {
  min-height: 100%;
}

@media (min-width: 576px) {
  .modal-fs .modal-dialog {
    max-width: calc(100vw - 3.5rem);
    max-height: calc(100vh - 3.5rem);
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: none;
  }
  .modal-lg .modal-dialog,
  .modal-xl .modal-dialog {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: none;
  }
  .modal-xl .modal-dialog {
    max-width: 1140px;
  }
}

.modal-dialog-static {
  transform: none !important;
}

.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
  transform: none !important;
  height: calc(100% - 0.5rem);
}

@media (min-width: 576px) {
  .modal-dialog-bottom {
    height: calc(100% - 1.75rem);
  }
}

@supports (filter: none) {
  body.modal-blur .body-container {
    filter: blur(2.5px);
    overflow: hidden;
  }
  @media (max-width: 575.98px) {
    .modal[data-blur] .modal-dialog {
      max-width: calc(100vw - 1rem);
    }
  }
}

@supports (backdrop-filter: none) {
  .modal-backdrop.modal-blur {
    backdrop-filter: blur(10px);
  }
}

.modal-off {
  position: relative;
  overflow: visible;
  opacity: 1 !important;
  display: block !important;
  display: contents !important;
  z-index: auto !important;
}

.modal-off:not(.modal-nb) {
  overflow: visible !important;
}

.modal-off.ace-aside.aside-below-nav {
  padding-top: 0;
}

.modal-off .modal-dialog-scrollable .modal-content, .modal-off .modal-dialog-scrollable .modal-body {
  overflow: visible;
  max-height: none;
}

.modal-off::before {
  display: none;
}

.modal-off .modal-dialog {
  visibility: visible;
  transform: none !important;
  transition: none;
  max-width: none !important;
  width: auto !important;
  height: auto !important;
  display: unset;
}

.modal-off .modal-content {
  visibility: visible;
  transition: none;
}

@media (min-width: 576px) {
  .modal-off-sm {
    position: relative;
    overflow: visible;
    opacity: 1 !important;
    display: block !important;
    display: contents !important;
    z-index: auto !important;
  }
  .modal-off-sm:not(.modal-nb) {
    overflow: visible !important;
  }
  .modal-off-sm.ace-aside.aside-below-nav {
    padding-top: 0;
  }
  .modal-off-sm .modal-dialog-scrollable .modal-content, .modal-off-sm .modal-dialog-scrollable .modal-body {
    overflow: visible;
    max-height: none;
  }
  .modal-off-sm::before {
    display: none;
  }
  .modal-off-sm .modal-dialog {
    visibility: visible;
    transform: none !important;
    transition: none;
    max-width: none !important;
    width: auto !important;
    height: auto !important;
    display: unset;
  }
  .modal-off-sm .modal-content {
    visibility: visible;
    transition: none;
  }
}

@media (min-width: 768px) {
  .modal-off-md {
    position: relative;
    overflow: visible;
    opacity: 1 !important;
    display: block !important;
    display: contents !important;
    z-index: auto !important;
  }
  .modal-off-md:not(.modal-nb) {
    overflow: visible !important;
  }
  .modal-off-md.ace-aside.aside-below-nav {
    padding-top: 0;
  }
  .modal-off-md .modal-dialog-scrollable .modal-content, .modal-off-md .modal-dialog-scrollable .modal-body {
    overflow: visible;
    max-height: none;
  }
  .modal-off-md::before {
    display: none;
  }
  .modal-off-md .modal-dialog {
    visibility: visible;
    transform: none !important;
    transition: none;
    max-width: none !important;
    width: auto !important;
    height: auto !important;
    display: unset;
  }
  .modal-off-md .modal-content {
    visibility: visible;
    transition: none;
  }
}

@media (min-width: 992px) {
  .modal-off-lg {
    position: relative;
    overflow: visible;
    opacity: 1 !important;
    display: block !important;
    display: contents !important;
    z-index: auto !important;
  }
  .modal-off-lg:not(.modal-nb) {
    overflow: visible !important;
  }
  .modal-off-lg.ace-aside.aside-below-nav {
    padding-top: 0;
  }
  .modal-off-lg .modal-dialog-scrollable .modal-content, .modal-off-lg .modal-dialog-scrollable .modal-body {
    overflow: visible;
    max-height: none;
  }
  .modal-off-lg::before {
    display: none;
  }
  .modal-off-lg .modal-dialog {
    visibility: visible;
    transform: none !important;
    transition: none;
    max-width: none !important;
    width: auto !important;
    height: auto !important;
    display: unset;
  }
  .modal-off-lg .modal-content {
    visibility: visible;
    transition: none;
  }
}

@media (min-width: 1200px) {
  .modal-off-xl {
    position: relative;
    overflow: visible;
    opacity: 1 !important;
    display: block !important;
    display: contents !important;
    z-index: auto !important;
  }
  .modal-off-xl:not(.modal-nb) {
    overflow: visible !important;
  }
  .modal-off-xl.ace-aside.aside-below-nav {
    padding-top: 0;
  }
  .modal-off-xl .modal-dialog-scrollable .modal-content, .modal-off-xl .modal-dialog-scrollable .modal-body {
    overflow: visible;
    max-height: none;
  }
  .modal-off-xl::before {
    display: none;
  }
  .modal-off-xl .modal-dialog {
    visibility: visible;
    transform: none !important;
    transition: none;
    max-width: none !important;
    width: auto !important;
    height: auto !important;
    display: unset;
  }
  .modal-off-xl .modal-content {
    visibility: visible;
    transition: none;
  }
}

th {
  font-weight: 600;
}

.table td, .table th {
  border-color: rgba(0, 0, 0, 0.075);
}

.table thead {
  color: #5d5f63;
}

.table thead th {
  border-bottom-width: 1px;
}

.table-hover tbody tr:hover {
  color: #323337;
  background-color: rgba(0, 0, 0, 0.05);
}

.table-bordered th {
  border-bottom-width: 1px;
}

.table-bordered-x th, .table-bordered-x td {
  border-width: 1px 0;
  border-style: solid;
}

.table-bordered-y th, .table-bordered-y td {
  border-width: 0 1px;
  border-style: solid;
}

.table[class*="brc-"] > thead, .table[class*="brc-"] > tbody, .table[class*="brc-"] > tr,
.table[class*="brc-"] > thead > tr, .table[class*="brc-"] > tbody > tr,
.table[class*="brc-"] > thead > tr > th, .table[class*="brc-"] > tr > th,
.table[class*="brc-"] > tbody > tr > td, .table[class*="brc-"] > tr > td {
  border-color: inherit;
}

.table thead[class*="brc-"] > tr, .table thead[class*="brc-"] > tr > th, .table thead[class*="brc-"] > tr > td, .table tbody[class*="brc-"] > tr, .table tbody[class*="brc-"] > tr > th, .table tbody[class*="brc-"] > tr > td {
  border-color: inherit;
}

.table tr[class*="brc-"] > th, .table tr[class*="brc-"] > td {
  border-color: inherit;
}

.table-spaced {
  border-spacing: 0 0.75rem;
  border-collapse: separate;
}

.table-spaced td, .table-spaced th {
  border-width: 0;
}

.table-lg td, .table-lg th {
  padding: 0.875rem;
}

table.bt tbody tr td {
  padding: 0;
}

table.bt tbody tr td .bt-content {
  padding: 0.375rem;
  padding-left: 0.875rem;
}

table.bt tbody tr td:first-child::before, table.bt tbody tr td:first-child .bt-content {
  padding-top: 0.875rem;
}

table.bt tbody tr td:last-child .bt-content {
  padding-bottom: 0.875rem;
}

table.bt tbody tr {
  border-bottom: 1px solid #fff;
}

table.bt tbody td::before {
  background-color: #67a9df;
  color: #fff;
  margin-right: 0.625rem;
  padding: 0.125rem 0.625rem;
  padding-top: 0.125rem;
  font-weight: 600;
}

table.bt tbody[class*="bgc-"] td::before {
  background-color: inherit;
}

table.bt tbody[class*="bgc-"] td .bt-content {
  flex: 2 1 0;
  background-color: #fff;
}

.table[class*="table-striped"]:not(.bt) tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f6f7;
}

.table-striped.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #f6f6f7;
}

.table-striped-primary tbody tr:nth-of-type(odd) {
  background-color: #f1f7fc;
}

.table-striped-primary.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-primary.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #f1f7fc;
}

.table-striped-secondary tbody tr:nth-of-type(odd) {
  background-color: #f5f7f8;
}

.table-striped-secondary.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-secondary.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #f5f7f8;
}

.table-striped-blue tbody tr:nth-of-type(odd) {
  background-color: #f0f6fd;
}

.table-striped-blue.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-blue.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #f0f6fd;
}

.table-striped-danger tbody tr:nth-of-type(odd) {
  background-color: #fdf3f0;
}

.table-striped-danger.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-danger.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #fdf3f0;
}

.table-striped-success tbody tr:nth-of-type(odd) {
  background-color: #f3faf3;
}

.table-striped-success.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-success.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #f3faf3;
}

.table-striped-warning tbody tr:nth-of-type(odd) {
  background-color: #fef9ef;
}

.table-striped-warning.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-warning.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #fef9ef;
}

.table-striped-info tbody tr:nth-of-type(odd) {
  background-color: #effbfe;
}

.table-striped-info.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-info.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #effbfe;
}

.table-striped-default tbody tr:nth-of-type(odd) {
  background-color: #f3f7fa;
}

.table-striped-default.bt tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped-default.bt tbody tr:nth-of-type(odd) > td .bt-content {
  background-color: #f3f7fa;
}

.progress {
  border-radius: 0.125rem;
}

.shadow-md {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.icon-animated-bell {
  display: inline-block;
  animation: ringing 2.0s 5 ease 1.0s;
  transform-origin: 50% 0%;
}

@keyframes ringing {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}

.icon-animated-vertical {
  display: inline-block;
  animation: vertical 2.0s 5 ease 2.0s;
}

@keyframes vertical {
  0% {
    transform: translate(0, -3px);
  }
  4% {
    transform: translate(0, 3px);
  }
  8% {
    transform: translate(0, -3px);
  }
  12% {
    transform: translate(0, 3px);
  }
  16% {
    transform: translate(0, -3px);
  }
  20% {
    transform: translate(0, 3px);
  }
  22% {
    transform: translate(0, 0);
  }
}

.icon-animated-hand-pointer {
  display: inline-block;
  animation: hand-pointer 2.0s 4 ease 2.0s;
}

@keyframes hand-pointer {
  0% {
    -moz-transform: translate(0, 0);
  }
  6% {
    -moz-transform: translate(5px, 0);
  }
  12% {
    -moz-transform: translate(0, 0);
  }
  18% {
    -moz-transform: translate(5px, 0);
  }
  24% {
    -moz-transform: translate(0, 0);
  }
  30% {
    -moz-transform: translate(5px, 0);
  }
  36% {
    -moz-transform: translate(0, 0);
  }
}

.icon-animated-wrench {
  display: inline-block;
  animation: wrenching 2.5s 4 ease;
  transform-origin: 90% 35%;
}

@keyframes wrenching {
  0% {
    transform: rotate(-12deg);
  }
  8% {
    transform: rotate(12deg);
  }
  10% {
    transform: rotate(24deg);
  }
  18% {
    transform: rotate(-24deg);
  }
  20% {
    transform: rotate(-24deg);
  }
  28% {
    transform: rotate(24deg);
  }
  30% {
    transform: rotate(24deg);
  }
  38% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(-24deg);
  }
  48% {
    transform: rotate(24deg);
  }
  50% {
    transform: rotate(24deg);
  }
  58% {
    transform: rotate(-24deg);
  }
  60% {
    transform: rotate(-24deg);
  }
  68% {
    transform: rotate(24deg);
  }
  75% {
    transform: rotate(0deg);
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes pulsating {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(0.75);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.25);
  }
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.25);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes blinkAndpulse {
  50% {
    opacity: 1;
    transform: scale(1.25);
  }
}

.typing-dots {
  color: #aaa;
  visibility: hidden;
  display: inline-block;
  line-height: 0;
  letter-spacing: -0.1875rem;
  white-space: nowrap;
}

.typing-dots .typing-dot {
  visibility: visible;
  position: relative;
  display: inline-block;
  color: inherit;
  opacity: 0.4;
  min-height: 0.625rem;
  vertical-align: middle;
  animation: 1.5s blinkAndpulse infinite 0.5s;
}

.typing-dots .typing-dot:nth-child(2) {
  animation-delay: 1s;
}

.typing-dots .typing-dot:nth-child(3) {
  animation-delay: 1.5s;
}

@media (prefers-reduced-motion: reduce) {
  [class*="icon-animated"], .typing-dot {
    animation: none !important;
  }
}

.jqtree-toggler + .jqtree-title .opened-icon {
  display: inline-block;
}

.jqtree-toggler + .jqtree-title .closed-icon {
  display: none;
}

.jqtree-closed + .jqtree-title .opened-icon {
  display: none;
}

.jqtree-closed + .jqtree-title .closed-icon {
  display: inline-block;
}

ul.jqtree-tree {
  margin: auto;
  padding: 0 0 0 1rem;
  position: relative;
}

ul.jqtree_common ul.jqtree_common {
  margin-left: 0.5rem;
  padding-left: 1rem;
}

ul.jqtree-tree li.jqtree_common {
  margin: 0.5rem 0;
}

.jqtree_common {
  position: relative;
}

ul.jqtree_common::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: -1.125rem;
  bottom: 0;
  left: 0;
  width: 0;
  border-left: 1px dotted #67b2dd;
  z-index: auto;
}

ul.jqtree-tree::before {
  left: 0;
  bottom: 0.5rem;
}

ul.jqtree-tree > .jqtree_common:last-child::after,
li.jqtree_common:last-child::after {
  display: inline-block;
  content: "";
  position: absolute;
  top: calc(1rem - 1px);
  bottom: -0.5rem;
  left: -1rem;
  width: 0;
  border-left: 1px solid #fff;
  z-index: 1;
}

li.jqtree_common:last-child::after {
  left: -1rem;
}

li.jqtree_common {
  position: relative;
}

li.jqtree_common::before {
  display: block;
  content: "";
  position: absolute;
  top: 0.925rem;
  left: calc(-1rem + 2px);
  border-top: 1px dotted #67b2dd;
  height: 1px;
  width: 1.125rem;
  z-index: auto;
}

ul.jqtree-tree .jqtree-title {
  margin-left: 0.325rem;
}

.tree-dotted ul.jqtree-tree .jqtree-title:not(.jqtree-title-folder) {
  margin-left: 0.925rem;
}

ul.jqtree-tree li.jqtree-selected > .jqtree-element, ul.jqtree-tree li.jqtree-selected > .jqtree-element:hover {
  background: #e6f1f8;
}

.jqtree-tree .jqtree-element {
  padding: 0.125rem 0.125rem 0.25rem;
  align-items: center;
}

.jqtree-folder > .jqtree-element {
  margin-left: 1rem;
}

.jqtree-folder > .jqtree-element > .jqtree-toggler {
  margin-left: -1.125rem;
}

.node-icon {
  margin-right: 0.5rem;
  margin-left: 0.125rem;
}

ul.jqtree-tree .jqtree-moving > .jqtree-element .jqtree-title {
  outline: none;
}

ul.jqtree-tree span.jqtree-border {
  border-color: #00b6e9;
}

ul.jqtree-tree li.jqtree-ghost span.jqtree-circle {
  border-color: #00b6e9;
}

ul.jqtree-tree li.jqtree-ghost span.jqtree-line {
  background-color: #00b6e9;
}

span.jqtree-dragging {
  z-index: 4;
}

.jqtree.tree-dotted .jqtree_common:not(.jqtree-folder) > .jqtree-element {
  position: relative;
}

.jqtree.tree-dotted .jqtree_common:not(.jqtree-folder) > .jqtree-element::before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100%;
  background-color: #798e9c;
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0.25rem;
  margin-top: auto;
  margin-bottom: auto;
}

.fixed-table-pagination, .fixed-table-toolbar {
  border-style: solid;
  border-width: 1px 0;
  padding: 0.25rem 1.25rem;
}

.fixed-table-toolbar {
  background-color: #edf5fb;
  margin-bottom: -1px;
  border-color: #e4e4e4;
}

.fixed-table-pagination {
  background-color: #edf5fb;
  margin-top: -1px;
  border-color: #e4e4e4;
}

.bootstrap-table .fixed-table-container.fixed-height:not(.has-footer) {
  border-bottom-width: 0;
}

.bootstrap-table .fixed-table-container {
  position: static;
}

.bootstrap-table .fixed-table-container .table tbody tr.selected td {
  background-color: #e8f4e8;
}

.bootstrap-table .fixed-table-container .table tbody tr .card-view .card-view-title {
  width: 7.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0.5rem;
  font-weight: 600;
}

.bootstrap-table .fixed-table-container .table tbody tr .card-view {
  margin-bottom: 0.625rem;
}

.bootstrap-table .fixed-table-container .table tbody tr .card-view > label {
  margin-bottom: 0;
}

.bootstrap-table th .sortable {
  background-image: none !important;
  position: relative;
}

.bootstrap-table th .sortable::before, .bootstrap-table th .sortable::after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 0.46875rem;
  top: 50%;
  bottom: auto;
  opacity: 0.3;
  color: inherit;
  border-right: 0.3125rem solid transparent;
  border-left: 0.3125rem solid transparent;
}

.bootstrap-table th .sortable::before {
  border-bottom: 0.3125rem solid currentColor;
  border-top: none;
  margin-top: -0.3125rem;
}

.bootstrap-table th .sortable::after {
  border-top: 0.3125rem solid currentColor;
  border-bottom: none;
  margin-top: 0.125rem;
}

.bootstrap-table th .sortable.asc::before, .bootstrap-table th .sortable.desc::after {
  opacity: 1;
}

.table.dataTable {
  margin: auto !important;
}

.dataTables_wrapper > .row:first-of-type {
  padding: 1.25rem 0.5rem 0.75rem;
  margin: auto;
  background-color: #f6f9fb;
}

.dataTables_wrapper > .row:last-of-type {
  padding: 0.75rem 0.5rem 1.25rem;
  margin: auto;
  background-color: #f6f9fb;
}

.dataTables_wrapper div.dataTables_length select.form-control {
  width: 4rem;
  margin: 0 0.125rem;
}

.dataTable tr.selected {
  background-color: #f0f8f0;
}

.dataTables_info .select-item {
  font-size: 0.9em;
  opacity: 0.9;
  margin-left: 0.5rem;
}

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: transparent;
  z-index: 202;
}

div.DTCR_pointer {
  width: 0.125rem;
  background-color: #2c87d3;
  z-index: 201;
}

.buttons-colvis ~ .dt-button-collection {
  top: 100% !important;
  left: auto !important;
  right: 0;
}

.buttons-colvis ~ .dt-button-collection .dropdown-menu {
  left: auto;
  right: 0;
}

.dataTable th::after, .dataTable th::before {
  content: "" !important;
  right: 0.46875rem !important;
}

.dataTable th[class*=sorting]:not(.sorting_disabled)::before, .dataTable th[class*=sorting]:not(.sorting_disabled)::after {
  width: 0;
  height: 0;
  color: inherit;
  border-right: 0.3125rem solid transparent;
  border-left: 0.3125rem solid transparent;
  top: 50% !important;
  bottom: auto !important;
}

.dataTable th[class*=sorting]:not(.sorting_disabled)::before {
  border-bottom: 0.3125rem solid currentColor;
  border-top: none;
  margin-top: -0.3125rem;
}

.dataTable th[class*=sorting]:not(.sorting_disabled)::after {
  border-top: 0.3125rem solid currentColor;
  border-bottom: none;
  margin-top: 0.125rem;
}

.dataTable:not(.collapsed) td.dtr-control, .dataTable:not(.collapsed) thead > tr > th:first-child {
  display: none;
}

.dtr-inline.collapsed > tbody > tr > .dtr-control {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%231279cd' d='M2,3 L6,7 L10,3'/%3e%3c/svg%3e") center no-repeat;
  background-size: 1.075rem;
  background-position-x: calc(50% + 4px);
}

.dtr-inline.collapsed > tbody > tr.parent > .dtr-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%231279cd' d='M2,7 L6,3 L10,7'/%3e%3c/svg%3e");
}

.dtr-plus.dtr-inline.collapsed > tbody > tr > .dtr-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%231279cd' d='M1,6 L11,6 M6,1 L6,11'/%3e%3c/svg%3e");
}

.dtr-plus.dtr-inline.collapsed > tbody > tr.parent > .dtr-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3cpath fill='none' stroke-width='1.5px' stroke='%231279cd' d='M1,6 L11,6'/%3e%3c/svg%3e");
}

.dataTable tr.child {
  background-color: #fff;
}

.dataTable tr.child td.child {
  padding: 0.25rem 0;
  border-bottom: 1px solid #e0e5e8;
}

.dataTable tr.child + tr > td {
  border-top-width: 0;
}

.dataTable tr.child ul.dtr-details {
  list-style: none;
  padding-left: 1rem;
}

.dataTable tr.child ul.dtr-details > li {
  margin: 1rem 0;
  color: #505255;
}

.dataTable tr.child ul.dtr-details > li > .dtr-title {
  font-weight: 600;
  display: inline-block;
  min-width: 90px;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.dataTable tr.child ul.dtr-details > li > .dtr-data {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 991.98px) {
  .dataTables_info {
    margin-bottom: 1rem;
  }
  .dataTables_info .select-info {
    display: block;
  }
}

div.dt-button-info {
  z-index: 1023;
}

.ui-jqgrid table {
  border-collapse: collapse;
}

.ui-jqgrid .ui-jqgrid-view,
.ui-jqgrid .ui-paging-info,
.ui-jqgrid .ui-pg-table,
.ui-jqgrid .ui-pg-selbox {
  font-size: 1rem;
}

.ui-jqgrid .ui-jqgrid-view .btn,
.ui-jqgrid .ui-jqgrid-pager .btn {
  font-size: 1rem;
}

.ui-jqgrid .ui-jqgrid-title, .ui-jqgrid .ui-jqgrid-title-rtl {
  margin: 0 0.75rem;
}

.ui-jqgrid .disabled {
  opacity: 0.35;
}

.ui-jqgrid .jqgfirstrow td {
  padding: 0 !important;
}

.ui-jqgrid .ui-jqgrid-bdiv tr > td:last-child {
  border-right-width: 0;
}

@media (max-width: 575.98px) {
  .ui-jqgrid .ui-pg-selbox {
    display: block;
  }
}

.ui-jqgrid .ui-jqgrid-titlebar,
.ui-jqgrid .ui-jqgrid-errorbar {
  font-size: 1.25rem;
}

.ui-jqgrid-btable input:not([type=checkbox]), .ui-jqgrid-btable textarea, .ui-jqgrid-btable select {
  width: auto;
  max-width: 100%;
  border: 1px solid #d3d5d7;
  border-radius: 0.125rem;
}

.ui-jqgrid-btable input:not([type=checkbox]):hover, .ui-jqgrid-btable textarea:hover, .ui-jqgrid-btable select:hover {
  border-color: #b5b5b5;
}

.ui-jqgrid-btable input:not([type=checkbox]):focus, .ui-jqgrid-btable textarea:focus, .ui-jqgrid-btable select:focus {
  box-shadow: 0 0 0 2px rgba(245, 153, 66, 0.2);
  color: #696969;
  border-color: #f59942;
  background-color: #fff;
}

.ui-jqgrid tr.ui-row-ltr td,
.ui-jqgrid tr.ui-row-rtl td {
  padding: 0.75rem;
}

.ui-jqgrid .ui-pg-button .ui-separator {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-color: #8d9fab;
}

.ui-jqgrid .ui-jqgrid-sortable {
  padding: 0.75rem 0;
}

.ui-jqgrid th[aria-selected=true] {
  background-color: #fbf0b2 !important;
  color: rgba(0, 0, 0, 0.73) !important;
}

.ui-jqgrid .s-ico {
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
  right: 0.125rem;
}

.ui-jqgrid .s-ico > .ui-grid-ico-sort {
  height: 0.45rem;
  line-height: 0.5;
  font-size: 1rem;
}

.ui-jqgrid .ui-jqgrid-bdiv {
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.075), -1px 0 0 0 rgba(0, 0, 0, 0.075);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #bbbdbf #e5e6e8;
  overflow: overlay;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (hover: hover) {
  .ui-jqgrid .ui-jqgrid-bdiv::-webkit-scrollbar {
    width: 7px;
  }
  .ui-jqgrid .ui-jqgrid-bdiv::-webkit-scrollbar-track {
    background-color: #e5e6e8;
  }
  .ui-jqgrid .ui-jqgrid-bdiv::-webkit-scrollbar-thumb {
    background-color: #bbbdbf;
  }
}

.ui-jqdialog .ui-jqdialog-titlebar {
  font-size: 1.25rem;
}

.ui-jqdialog .FormData {
  border-bottom: 1px dotted #d3dadf;
}

.ui-jqdialog .FormData:nth-last-child(2) {
  border-bottom: none;
}

.ui-jqdialog pre {
  font-size: 1rem;
}

.ui-jqdialog[id*="alertcnt_"] {
  padding: 0.5rem 0.75rem;
}

.ui-jqdialog .ui-resizable-handle {
  bottom: 1px;
  right: 3px;
}

.ui-jqdialog .searchFilter td {
  padding: 0.375rem 0;
}

.ui-jqdialog .searchFilter .queryresult {
  margin-bottom: 0.75rem;
}

.ui-jqdialog .searchFilter .queryresult td.query {
  padding: 0.5rem 0.75rem;
  border-bottom: 2px solid #d8d9da;
  background-color: #f3f3f3;
}

.ui-jqdialog .searchFilter .queryresult td.query:empty {
  display: none;
}

@media (max-width: 767.98px) {
  .ui-jqdialog {
    max-width: 90vw !important;
    max-height: 90vh !important;
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.ui-pg-table.navtable {
  float: none !important;
}

.ui-pager-control > .ui-pg-table > tbody > tr > td {
  width: auto !important;
}

@media (max-width: 991.98px) {
  .ui-pager-control > .ui-pg-table > tbody > tr > td {
    display: block;
    text-align: center;
    margin: 0.5rem;
    min-height: 1.75rem;
    scrollbar-width: none;
    overflow-y: auto;
  }
}

.ui-subgrid .ui-jqgrid-htable .ui-jqgrid-labels {
  background-color: transparent;
}

.ui-subgrid .ui-jqgrid-bdiv {
  max-height: 10rem;
}

.ui-subgrid .subgrid-data .ui-jqgrid {
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.ui-jqgrid .ui-jqgrid-hdiv .ui-search-toolbar {
  border-top: 1px solid #d8d9da;
}

.ui-jqgrid .ui-jqgrid-hdiv .ui-search-toolbar .ui-th-column {
  border-top-width: 0;
}

.ui-jqgrid .ui-jqgrid-hdiv .ui-search-toolbar .ui-search-input .form-control:not([size]):not([multiple]) {
  min-height: 1.75rem;
}

.fm-button.btn-default .fm-button-text, .fm-button.btn-secondary .fm-button-text {
  color: #fff;
}

.fm-button:only-of-type {
  margin: 0 0.25rem;
}

.ui-jqgrid .ui-jqgrid-bdiv .jqgroup {
  background-color: #fff !important;
}

.ui-jqgrid .ui-jqgrid-bdiv .jqgroup > td {
  color: #1172c1;
  border-left: 3px solid #74aaec !important;
}

*::-ms-backdrop,
.ui-jqgrid .jqgrow > td > .cbox {
  width: auto;
  height: auto;
}

.chosen-container {
  font-size: 1rem;
}

.chosen-container > .chosen-single, [class*="chosen-container"] > .chosen-single {
  font-size: 1rem;
  line-height: 2.25rem;
  height: 2.5rem;
  background: #f9fafb;
  box-shadow: none;
}

.chosen-choices {
  box-shadow: none !important;
}

.chosen-container-single .chosen-single .search-choice-close {
  background: none !important;
  color: inherit;
  height: 2.5rem;
  top: 0;
}

.chosen-container-single .chosen-single .search-choice-close::after {
  content: "\00D7";
  font-size: 1.25rem;
}

.chosen-container-single .chosen-single .search-choice-close:hover::after {
  color: #d52727;
}

.chosen-container-single .chosen-single div b {
  background: none !important;
}

.chosen-container-single .chosen-single div b::after {
  content: "";
  position: absolute;
  top: calc(50% - 0.25em);
  right: 0.5em;
  width: 0;
  height: 0;
  border-top: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-bottom: none;
  border-right: 0.425rem solid transparent;
  border-left: 0.425rem solid transparent;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background: none !important;
}

.chosen-container-active.chosen-with-drop .chosen-single div b::after {
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.chosen-container-single .chosen-search {
  position: relative;
}

.chosen-container-single .chosen-search input[type="text"] {
  background: none !important;
  border-radius: 0;
  line-height: 2.25rem;
  height: 2.5rem;
  font-size: 1rem;
}

.chosen-container-single .chosen-search::after {
  content: "\1F50D";
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-size: 1.25em;
}

.chosen-container .chosen-results li {
  line-height: 1.125rem;
  padding: 0.5rem;
}

.chosen-container-multi .chosen-choices {
  padding: 0.25rem 0.5rem;
  background: none !important;
  border-radius: 2px;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 1.5rem;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background: #eee;
  line-height: 1.5rem;
  font-size: 1rem;
  padding-bottom: 3px;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  background: none !important;
  color: inherit;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close::after {
  content: "\00D7";
  font-size: 1.125rem;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover::after {
  color: #d52727;
}

.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
  background: none !important;
}

.chosen-container .chosen-results-scroll-down span:before,
.chosen-container .chosen-results-scroll-up span:before {
  position: relative;
  top: -1px;
  left: 1px;
  width: 0;
  height: 0;
  border-top: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-bottom: none;
  border-right: 0.425rem solid transparent;
  border-left: 0.425rem solid transparent;
  content: "\f0d7";
  display: inline-block;
}

.chosen-container .chosen-results-scroll-up span:before {
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.chosen-container-active .chosen-single-with-drop div b:before {
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-top: none;
}

.chosen-container-single .chosen-single {
  border-radius: 2px;
}

.chosen-container .chosen-results li.highlighted {
  background: #0092ba;
  color: #fff;
}

.chosen-container .chosen-drop {
  border-radius: 0;
  border-color: #00addd;
  border-bottom-width: 2px;
}

.chosen-single.chosen-single-with-drop, .chosen-container-active .chosen-single {
  border-color: #00addd;
}

.chosen-container-multi .chosen-drop {
  border-top-width: 0;
}

.chosen-container-active .chosen-choices {
  border-color: #00addd;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border-color: #00addd;
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: auto;
  right: auto;
  display: block;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice {
  background: none !important;
  background-color: #5c94b6;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 0.375rem 1.375rem 0.375rem 0.5rem;
  position: relative;
  white-space: nowrap;
  border: none;
  box-shadow: none;
  border-radius: 2px;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1.125rem;
  height: auto;
  line-height: 1.5;
  text-align: center;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close::after {
  color: #fff;
  position: static;
  font-size: 0.9rem;
  line-height: 2.25;
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.tag-input-style + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover::after {
  color: #fff;
}

.select2-container .select2-selection {
  border-radius: 0.125rem;
  border-color: #c6c8cb;
}

.select2-container .select2-selection:hover {
  border-color: #abafb3;
}

.select2-container .select2-selection .select2-selection__rendered {
  line-height: inherit;
  height: 100%;
}

.select2-container.select2-container--focus .select2-selection, .select2-container .select2-selection[aria-expanded="true"] {
  border-color: #00addd;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0.25rem;
  position: relative;
  overflow: visible;
}

.select2-container .select2-selection__clear {
  position: absolute;
  left: auto;
  top: 0;
  height: 100%;
  font-size: 1.25em;
  color: #64666a;
  width: 1.5rem;
  text-align: center;
}

.select2-container .select2-selection__clear:hover {
  color: #d52727;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  right: -2.5rem;
  top: auto;
  height: auto;
}

.select2-container .select2-selection--multiple .select2-selection__clear:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.select2-container--open .select2-dropdown {
  border-radius: 0;
  border-color: #00addd;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.125);
}

.select2-results__option {
  padding: 0.5rem;
  margin-bottom: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0.25rem 0.375rem;
  border-radius: 0.125rem;
  background-color: #eee;
  border-color: #aaa;
}

.select2-container .select2-selection--single {
  height: 2.625rem;
  line-height: 2.3625rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-top: 0.425rem solid rgba(0, 0, 0, 0.5);
  border-bottom: none;
  border-right: 0.425rem solid transparent;
  border-left: 0.425rem solid transparent;
  margin-top: 0.14167rem;
  margin-left: -0.6375rem;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 0.425rem;
  border-bottom: 0.425rem solid rgba(0, 0, 0, 0.5);
}

.select2-container--default .select2-selection .select2-selection__choice__remove {
  font-size: 1.125em;
  margin-right: 0.25rem;
  color: #666;
}

.select2-container--default .select2-selection .select2-selection__choice__remove:hover {
  color: #d52727;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
}

.select2-container--default .select2-results__option[aria-selected="true"]:hover {
  background-color: #0092ba;
  color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected="false"] {
  background-color: #0092ba;
}

.select2-container .select2-search--inline {
  max-width: calc(100% - 1.5rem);
}

.select2-container .select2-search--inline .select2-search__field {
  max-width: 100%;
  margin: 0.25rem 0;
  padding-left: 0.25rem;
}

.select2-search {
  background: none;
}

.select2-search:before {
  content: "\1F50D";
  font-size: 1em;
  color: #888;
  position: absolute;
  right: 0.5rem;
  margin-top: 2px;
}

.select2-selection--multiple .select2-search:before {
  display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  margin-top: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 1px;
}

.tag-input-style .select2-selection .select2-selection__choice {
  position: relative;
  color: #fff;
  background-color: #5c94b6;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  margin: 2px;
  padding: 0.3125rem 1.5rem 0.3125rem 0.5rem;
  border: none;
  box-shadow: none;
  border-radius: 2px;
}

.tag-input-style .select2-selection .select2-selection__choice .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2px;
  left: auto;
  width: 1.125rem;
  height: auto;
  line-height: 2rem;
  text-align: center;
  color: #fff;
}

.tag-input-style .select2-selection .select2-selection__choice .select2-selection__choice__remove:hover {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.sw-main > ul.step-anchor > li.wizard-progressbar {
  position: absolute;
  z-index: 2;
  top: calc(50% - 0.125rem);
  bottom: 0;
  height: 0.25rem;
  max-width: 0;
  background-color: #2c87d3;
  transition: all 150ms;
}

@media (prefers-reduced-motion: reduce) {
  .sw-main > ul.step-anchor > li.wizard-progressbar {
    transition: none;
  }
}

.sw-main > ul.step-anchor > li.wizard-progressbar::before, .sw-main > ul.step-anchor > li.wizard-progressbar::after {
  display: none !important;
}

.sw-theme-circles > ul.step-anchor {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  width: 75%;
}

@media (max-width: 767.98px) {
  .sw-theme-circles > ul.step-anchor {
    width: 100%;
    margin-bottom: 3.5rem;
  }
}

.sw-theme-circles > ul.step-anchor::before {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li {
  display: inline-block;
  margin-left: 0;
  flex-grow: 1;
  position: relative;
  z-index: auto;
  border: 0px solid;
}

.sw-theme-circles > ul.step-anchor > li, .sw-theme-circles > ul.step-anchor > li.active ~ li.done {
  border-color: #c3ccd2;
}

.sw-theme-circles > ul.step-anchor > li.active, .sw-theme-circles > ul.step-anchor > li.done {
  border-color: #2c87d3;
}

.sw-theme-circles > ul.step-anchor > li:not(.active):not(.done), .sw-theme-circles > ul.step-anchor > li.active ~ li.done {
  border-color: #c3ccd2 !important;
}

.sw-theme-circles > ul.step-anchor > li:not(.active):not(.done) .step-title, .sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-title {
  color: #45464b !important;
}

.sw-theme-circles > ul.step-anchor > li:not(.active):not(.done) .step-description, .sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-description {
  color: #515259 !important;
}

.sw-theme-circles > ul.step-anchor > li::before {
  display: block;
  content: "";
  width: 100%;
  height: 0;
  overflow: hidden;
  border-top: 0.25rem solid;
  border-color: inherit;
  position: absolute;
  top: calc(50% - 0.125rem);
  z-index: 0;
}

.sw-theme-circles > ul.step-anchor > li:last-child::before {
  left: 0;
  width: 50%;
}

.sw-theme-circles > ul.step-anchor > li:first-child::before, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar + li::before {
  left: 50%;
  width: 50%;
}

.sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li::before {
  border-color: #c3ccd2;
}

.sw-theme-circles > ul.step-anchor > li.nav-item > a,
.sw-theme-circles > ul.step-anchor > li.nav-item.active ~ li.done > a {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  padding: 0;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #45464b;
  font-size: 1.125rem;
  background-color: #fff;
  border: 5px solid;
  border-color: inherit !important;
  border-radius: 50%;
  box-shadow: none !important;
}

@media (max-width: 575.98px) {
  .sw-theme-circles > ul.step-anchor > li.nav-item > a,
  .sw-theme-circles > ul.step-anchor > li.nav-item.active ~ li.done > a {
    width: 3.25rem;
    height: 3.25rem;
    border-width: 4px;
  }
}

.sw-theme-circles > ul.step-anchor > li.nav-item.active > a, .sw-theme-circles > ul.step-anchor > li.nav-item.done > a {
  background-color: #fff;
  color: #45464b;
  border-color: inherit !important;
}

.sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li > a, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar.active ~ li.done > a {
  transition: border-color 75ms, border-width 150ms, font-size 150ms;
  transition-delay: 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li > a, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar.active ~ li.done > a {
    transition: none;
  }
}

.sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li.active > a, .sw-theme-circles > ul.step-anchor > li.wizard-progressbar ~ li.done > a {
  transition-delay: 75ms;
  transition-duration: 150ms;
}

.sw-theme-circles > ul.step-anchor > li:not(.done) > a:hover {
  cursor: default;
}

.sw-theme-circles > ul.step-anchor > li > a:hover, .sw-theme-circles > ul.step-anchor > li > a:focus {
  border-width: 5px;
  background-color: #fff;
  color: #45464b;
  border-color: inherit;
}

.sw-theme-circles > ul.step-anchor > li.done > a:hover, .sw-theme-circles > ul.step-anchor > li.active ~ li.done > a:hover {
  border-width: 4px;
  font-size: 1.325rem;
}

.sw-theme-circles > ul.step-anchor > li .step-title-done {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li.done .step-title {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li.done .step-title-done {
  display: inline-block;
}

.sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-title {
  display: inline-block;
}

.sw-theme-circles > ul.step-anchor > li.active ~ li.done .step-title-done {
  display: none;
}

.sw-theme-circles > ul.step-anchor > li .step-description {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:first-child > a, .sw-theme-circles.wizard-steps-expand > ul.step-anchor > li.wizard-progressbar + li > a {
  margin: 0 !important;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:first-child::before, .sw-theme-circles.wizard-steps-expand > ul.step-anchor > li.wizard-progressbar + li::before {
  left: 0;
  width: 100%;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:first-child::after, .sw-theme-circles.wizard-steps-expand > ul.step-anchor > li.wizard-progressbar + li::after {
  display: none !important;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:last-child > a {
  margin: 0 0 0 auto !important;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:last-child::before {
  left: 0;
  width: 100%;
}

.sw-theme-circles.wizard-steps-expand > ul.step-anchor > li:last-child::after {
  display: none !important;
}

.sw-theme-circles .sw-toolbar {
  padding-left: 0;
  padding-right: 0;
}

.sw-theme-circles > .sw-container {
  padding: 1rem;
  border-width: 1px 0;
  border-style: dotted;
}

.sw-btn-finish, .sw-btn-next.sw-btn-hide.disabled {
  display: none;
}

.sw-btn-next.disabled ~ .sw-btn-finish {
  display: inline-block;
}

.fc-theme-bootstrap {
  --fc-list-event-hover-bg-color: rgba(0,0,0,0.08);
  --fc-today-bg-color: rgba(251, 241, 183, 0.5);
  --fc-event-border-color: #fff;
}

.fc-theme-bootstrap th, .fc-theme-bootstrap td, .fc-theme-bootstrap hr, .fc-theme-bootstrap thead, .fc-theme-bootstrap tbody, .fc-theme-bootstrap .fc-popover {
  border-color: #d8e7f0;
}

.fc-theme-bootstrap .fc-event {
  border-width: 0;
  border-radius: 0.125rem;
}

.fc-theme-bootstrap .fc-highlight {
  background: rgba(192, 219, 242, 0.4);
}

.fc-theme-bootstrap .fc-event[class*="text-"] .fc-event-main {
  color: inherit;
}

.fc-theme-bootstrap .fc-event[class*="brc-"] .fc-daygrid-event-dot,
.fc-theme-bootstrap .fc-event[class*="brc-"] .fc-list-event-dot,
.fc-theme-bootstrap .fc-event[class*="brc-"] .fc-list-event-graphic {
  border-color: inherit;
}

.fc-theme-bootstrap .fc-list-event:hover td {
  background-color: rgba(0, 0, 0, 0.08);
}

.fc-theme-bootstrap .fc-list-event-dot {
  border-color: #fff;
}

.fc-theme-bootstrap a {
  text-decoration: none;
}

.fc-theme-bootstrap a[data-goto]:hover {
  text-decoration: underline;
}

.fc-theme-bootstrap hr.fc-timegrid-divider {
  border-color: inherit;
}

.fc-theme-bootstrap a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}

.fc-scroller {
  scrollbar-width: thin;
}

.fc-scroller::-webkit-scrollbar {
  width: 8px;
  -webkit-appearance: none;
  appearance: none;
}

@media (max-width: 575.98px) {
  .fc-toolbar {
    flex-direction: column;
  }
  .fc-toolbar > .fc-toolbar-chunk:nth-child(2) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .fc-toolbar > .fc-toolbar-chunk:first-child {
    align-self: flex-end;
    order: 10;
  }
  .fc-toolbar > .fc-toolbar-chunk:last-child {
    align-self: flex-start;
    order: -1;
  }
}

.dp-modal {
  z-index: 1050;
}

.modal-backdrop ~ .dp-below .dp, .modal-backdrop ~ .dp-permanent .dp {
  z-index: 1051;
}

.dp {
  padding-top: 0;
  border-top: 0.25rem solid #4c99da;
  border-radius: 3px;
  font-size: 1rem;
}

.dp::before {
  display: none;
}

.dp .dp-col-header {
  font-size: 0.875em;
  color: #999;
}

.dp .dp-day {
  font-size: 0.925em;
}

.dp-cal-month, .dp-cal-year, .dp-day, .dp-month, .dp-year {
  border-radius: 0;
  font-size: 0.925em;
}

.dp-day:hover, .dp-month:hover, .dp-year:hover {
  background: #e1e3e9;
  color: #2b303d;
}

.dp-selected:hover, .dp-selected:focus, .dp-selected,
.dp-current:focus, .dp-current, .dp-day:focus, .dp-month:focus, .dp-year:focus {
  background: #3b82d9;
  color: #fff;
}

.dp-close:hover, .dp-close:focus,
.dp-clear:hover,
.dp-clear:focus,
.dp-today:hover,
.dp-today:focus {
  background: #00a4d2;
  color: #fff;
}

.dp-next,
.dp-prev {
  top: 0.75rem;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border: 2px solid #c3ccd2;
  border-radius: 1rem;
  color: #5f718b;
}

.dp-next:hover, .dp-next:focus,
.dp-prev:hover,
.dp-prev:focus {
  background-color: #fff;
  color: #1172c1;
  border-color: #5b9be8;
}

.dp-cal-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dp-cal-month,
.dp-cal-year {
  vertical-align: middle;
  padding: 0.425rem 0.75rem 0.5rem;
}

.dp-cal-month:hover, .dp-cal-month:focus,
.dp-cal-year:hover,
.dp-cal-year:focus {
  color: #57585c;
  background-color: #e4e4e4;
}

.dp-day-today::after {
  border-right-color: #ff9e29;
  border-top-color: #ff9e29;
  top: 1px;
  right: 1px;
}

.dp-day-today.dp-selected::after {
  border-right-color: #fff;
  border-top-color: #fff;
  top: 2px;
  right: 2px;
}

.dr-cals {
  display: flex;
  position: relative;
  z-index: 11;
  overflow: visible;
  box-shadow: none;
  padding-top: 0;
  border-top: 0.25rem solid #4c99da;
  border-radius: 3px;
  font-size: 1rem;
}

.dr-cals::before {
  display: none;
}

.dr-cals .dr-in-range, .dr-cals .dr-in-range:hover, .dr-cals .dr-in-range:focus {
  color: rgba(0, 0, 0, 0.8);
  background-color: #d2e6f6;
}

.dr-cals .dp-current:hover {
  color: rgba(0, 0, 0, 0.8);
  background-color: #d2e6f6;
  transform: scale(1.1);
}

.dr-cals .dr-selected, .dr-cals .dr-selected:hover, .dr-cals .dr-selected:focus {
  color: #fff;
  background: #3b82d9;
  border-radius: 0;
}

.dp-daterange-picker {
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: opacity 0.15s, visibility 0s 0.15s, z-index 0s 0.15s;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
}

@media (prefers-reduced-motion: reduce) {
  .dp-daterange-picker {
    transition: none;
  }
}

.dp-daterange-picker.visible {
  opacity: 1;
  z-index: 100;
  visibility: visible;
  transition: opacity 0.15s, visibility 0s 0s, z-index 0s 0s;
}

@media (prefers-reduced-motion: reduce) {
  .dp-daterange-picker.visible {
    transition: none;
  }
}

.dp-daterange-above {
  top: auto;
  bottom: 100%;
}

.dr-cal-end .dp {
  border-left-width: 0.25rem;
}

@media (max-width: 616px), (max-height: 480px) {
  .dr-cal-end {
    display: none;
  }
  .dr-cal-start .dp-next {
    visibility: visible;
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
  min-width: 20rem;
}

.bootstrap-datetimepicker-widget .collapse.in {
  display: list-item;
}

.bootstrap-datetimepicker-widget .timepicker .table th, .bootstrap-datetimepicker-widget .timepicker .table td, .bootstrap-datetimepicker-widget .datepicker .table th, .bootstrap-datetimepicker-widget .datepicker .table td, .bootstrap-datetimepicker-widget table td span {
  border-radius: 0;
}

.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
  background-color: #e0e5e8;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #3b82d9;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background-color: #e0e5e8;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #3b82d9;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background-color: #e0e5e8;
}

.bootstrap-datetimepicker-widget .picker-switch .table td span:hover {
  background-color: #eeeeee;
}

.bootstrap-touchspin .btn-group-vertical, .bootstrap-touchspin .input-group-btn-vertical {
  display: flex;
  flex-direction: column;
}

.bootstrap-touchspin .btn-group-vertical > .btn, .bootstrap-touchspin .input-group-btn-vertical > .btn {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0;
  height: 50%;
  border-radius: 0 3px 0 0;
}

.bootstrap-touchspin .btn-group-vertical > .btn:last-child, .bootstrap-touchspin .input-group-btn-vertical > .btn:last-child {
  margin-top: 1px;
  border-radius: 0 0 3px 0;
}

.bootstrap-touchspin .btn-group-inside-spinner {
  padding-right: 2.25rem;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2rem;
  text-align: center;
  line-height: 1;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 2px 0 0;
  top: 0;
}

.bootstrap-touchspin .btn-group-inside-spinner ~ .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 2px 0;
  bottom: 0;
}

.noUi-target {
  box-shadow: none;
  border-width: 0;
  border-radius: 0.125rem;
  background-color: #ddd;
}

.noUi-target[disabled] {
  opacity: 0.75;
  background-color: #e5e5e5;
}

.noUi-target:not([disabled]):not(.disabled) .noUi-handle {
  cursor: pointer;
}

.noUi-target .noUi-handle {
  border-radius: 2px;
  border-color: #bababa;
  box-shadow: 0 3px 6px -3px #bbb;
}

.noUi-target .noUi-handle.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 6px #d7d7d7, 0 3px 6px -3px #bbb;
}

.noUi-target .noUi-handle::before {
  display: none;
}

.noUi-target .noUi-handle::after {
  background-color: transparent;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: 45%;
  margin: auto;
  color: #999;
  background-image: linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor), linear-gradient(to right, currentColor, currentColor);
  background-position: calc(50% - 3px) center, center, calc(50% + 3px) center;
  background-size: 1px 100%;
  background-repeat: no-repeat;
}

.noUi-target .noUi-handle.slider-bars-h::after {
  height: auto;
  width: 45%;
  background-position: center calc(50% - 3px), center, center calc(50% + 3px);
  background-size: 100% 1px;
}

.noUi-target .noUi-handle.slider-bars-none::after {
  display: none;
}

.noUi-target.noUi-horizontal {
  height: 0.75rem;
}

.noUi-target.noUi-horizontal .noUi-handle {
  width: 2rem;
  height: 2rem;
  top: -0.625rem;
  right: -1rem;
}

.noUi-target.noUi-vertical {
  width: 0.75rem;
}

.noUi-target.noUi-vertical .noUi-handle {
  width: 2rem;
  height: 2rem;
  right: -0.625rem;
  top: -1rem;
}

.noUi-target.slider-sm.noUi-horizontal {
  height: 0.5rem;
}

.noUi-target.slider-sm.noUi-horizontal .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  top: -0.625rem;
  right: -0.875rem;
}

.noUi-target.slider-sm.noUi-vertical {
  width: 0.5rem;
}

.noUi-target.slider-sm.noUi-vertical .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  right: -0.625rem;
  top: -0.875rem;
}

.noUi-target.slider-thin.noUi-horizontal {
  height: 0.3125rem;
}

.noUi-target.slider-thin.noUi-horizontal .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  top: -0.71875rem;
  right: -0.875rem;
}

.noUi-target.slider-thin.noUi-vertical {
  width: 0.3125rem;
}

.noUi-target.slider-thin.noUi-vertical .noUi-handle {
  width: 1.75rem;
  height: 1.75rem;
  right: -0.71875rem;
  top: -0.875rem;
}

.noUi-target.slider-sm .noUi-handle::after, .noUi-target.slider-thin .noUi-handle::after {
  padding-top: 0.125rem;
}

.noUi-target.noUi-toggle-tooltip .noUi-tooltip {
  display: none;
}

.noUi-target.noUi-toggle-tooltip .noUi-active .noUi-tooltip {
  display: block;
}

.noUi-tooltip {
  padding: 0.25rem 0.325rem;
}

.slider-tooltip-right .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  bottom: auto;
  left: 120%;
  right: auto;
}

.slider-tooltip-left .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  bottom: auto;
  right: 120%;
  left: auto;
}

.slider-tooltip-top .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  right: auto;
  bottom: 120%;
  top: auto;
}

.slider-tooltip-bottom .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  right: auto;
  top: 120%;
  bottom: auto;
}

.slider-tooltip-caret .noUi-tooltip::before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.slider-tooltip-caret.slider-tooltip-bottom .noUi-tooltip {
  margin-top: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-bottom .noUi-tooltip::before {
  top: -0.5em;
  left: calc(50% - 0.5em);
  border-bottom: 0.5em solid;
  border-bottom-color: inherit;
  border-top: 0;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
}

.slider-tooltip-caret.slider-tooltip-top .noUi-tooltip {
  margin-bottom: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-top .noUi-tooltip::before {
  bottom: -0.5em;
  left: calc(50% - 0.5em);
  border-top: 0.5em solid;
  border-top-color: inherit;
  border-bottom: 0;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
}

.slider-tooltip-caret.slider-tooltip-left .noUi-tooltip {
  margin-right: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-left .noUi-tooltip::before {
  right: -0.5em;
  top: calc(50% - 0.5em);
  border-left: 0.5em solid;
  border-left-color: inherit;
  border-right: 0;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}

.slider-tooltip-caret.slider-tooltip-right .noUi-tooltip {
  margin-left: 0.25rem;
}

.slider-tooltip-caret.slider-tooltip-right .noUi-tooltip::before {
  left: -0.5em;
  top: calc(50% - 0.5em);
  border-right: 0.5em solid;
  border-right-color: inherit;
  border-left: 0;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}

.irs--flat .irs-line {
  height: 1rem;
  border-radius: 2px;
}

.irs--flat .irs-bar {
  height: 1rem;
  border-radius: 2px 0 0 2px;
}

.irs--flat .irs-handle {
  height: 1.325rem;
  z-index: auto;
}

.irs-from, .irs-to, .irs-single {
  top: -0.25rem;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  font-size: 0.875rem;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  font-size: 0.75rem;
}

.dropzone .dz-preview .dz-progress.progress {
  border-radius: 2px;
}

.dropzone .dz-preview .dz-progress.progress .dz-upload {
  background: none;
}

.dropzone .dz-preview.dz-image-preview {
  background-color: transparent;
}

.note-editor.note-frame, .note-editor.note-airframe {
  border-width: 0;
}

.note-toolbar {
  background-color: #eeeeee;
  border-color: #d8d9da;
}

.note-toolbar .note-btn {
  font-size: 1rem;
  color: #6e7074;
  padding: 0.375rem 0.75rem;
  border-width: 0 !important;
}

.note-toolbar .note-btn:hover, .note-toolbar .note-btn:focus, .note-toolbar .note-btn.focus {
  color: rgba(0, 0, 0, 0.6);
  background-color: #d2e6f6;
}

.note-toolbar .note-btn > .fa {
  color: inherit;
  font-size: 1rem;
}

.note-toolbar .note-btn > .note-current-fontname {
  color: inherit;
}

.note-modal-footer {
  min-height: 3rem;
}

.md-editor > textarea {
  background-color: #f4f4f4;
}

.card-body > .md-editor {
  border-width: 0;
}

.bootstrap-wysiwyg-editor {
  min-height: 200px;
  max-height: calc(100vh - 2rem);
  overflow: auto;
}

.bootstrap-wysiwyg-editor > img {
  margin: 0.25rem;
}

.card-body .bootstrap-wysiwyg-editor {
  border-radius: 0 0 0.25rem 0.25rem;
}

.bootstrap-wysiwyg-toolbar {
  background-color: #eeeeee;
  padding: 0.25rem 0.3125rem;
}

.bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 {
  border: 1px solid #dcddde;
}

.bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 .btn[data-edit], .bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 a[data-toggle] {
  border-radius: 0.25rem;
  border-width: 0;
}

.bootstrap-wysiwyg-toolbar.bsw-toolbar-style-2 .btn-group + .btn-group {
  margin-left: 2px;
}

.card-body .bsw-toolbar-style-2 {
  border-width: 0 0 1px 0;
}

.file-input-invisible {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.popover-wysiwyg-image {
  transform: none !important;
  left: 0 !important;
  top: 0 !important;
  width: 300px;
  max-width: calc(100vw - 2rem);
}

.popover-wysiwyg-image .arrow {
  display: none;
}

.rating-container .badge {
  font-size: 0.825rem;
  font-weight: 600;
}

.rating-container .filled-stars {
  text-shadow: none;
  color: inherit;
  -webkit-text-stroke: 1px currentColor;
}

input.typeahead ~ .dropdown-menu {
  width: 100%;
}

input.typeahead.scrollable ~ .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.bootstrap-tagsinput {
  color: #64666a;
  background-color: #fff;
  border: 1px solid #d3d5d7;
  display: block;
  padding: 0.5rem 0.375rem 0.25rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}

.bootstrap-tagsinput:hover {
  border-color: #b5b5b5;
}

.bootstrap-tagsinput.tagsinput-focus, .bootstrap-tagsinput.tagsinput-focus:hover {
  border-color: #f59942;
  outline: 0;
}

.bootstrap-tagsinput input {
  display: inline;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  color: inherit;
}

.bootstrap-tagsinput .tag {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline-block;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 0.25rem;
  margin-right: 1px;
  padding-right: 1.75rem !important;
  transition: all 0.15s;
}

.bootstrap-tagsinput .tag:not(.badge) {
  background-color: #5c94b6;
  color: #fff;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-tagsinput .tag {
    transition: none;
  }
}

.bootstrap-tagsinput .tag:empty {
  display: none;
}

.bootstrap-tagsinput .tag span[data-role="remove"] {
  position: absolute;
  right: 1px;
  top: 0;
  bottom: 0;
  width: 1.25rem;
  text-align: center;
  line-height: 1.8;
  cursor: pointer;
}

.bootstrap-tagsinput .tag span[data-role="remove"]:hover {
  background-color: rgba(0, 0, 0, 0.125);
}

.bootstrap-tagsinput .tag span[data-role="remove"]::after {
  content: "\00D7";
  font-size: 1.1em;
}

/*# sourceMappingURL=ace.css.map */