
@if $custom-file-input == true {

	input.ace-file-input[type=file], .ace-file-input [type=file] {
		position: absolute;
		z-index: -1;
		
		width: 1px;
		height: 1px;
		overflow: hidden;

		opacity :0;
		
		&:focus {
			outline: none;
		}
	}

	.ace-file-input {
		display: block;
		font-size: inherit;

		position: relative;
		height: 2.5rem;

		user-select: none;
		cursor: pointer;


		.ace-file-placeholder {
			display: flex;
		}

		.ace-file-container {
			display: block;
			height: 2.5rem;	
		
			@include transition(all $transition-general);	

			.ace-file-name {
				max-width: 100%;
				
				height: 2.5rem;
				line-height: 2.25;		
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				color: $file-input-name-color;
			}

			&.selected  {
				margin-right: 1rem;
				.ace-file-name {
					color: $file-input-selected-name-color;		
				}	
			}
		}
	

		.remove {
			display: none;
			text-decoration: none;
		}
		.ace-file-container.selected + .remove {
			display: block;
		}



		//disabled
		input[type=file] {
			&.disabled , &[disabled] , &[readonly] {
				+ .ace-file-container {
					cursor: default;//not-allowed;
					background-color: $file-input-disabled-bg;
					border-color: $file-input-disabled-border !important;
				}
			}
		}

		.ace-file-overlay {
			position: absolute;
			top: -0.25rem;
			bottom: -0.25rem;
			left: -0.25rem;
			right: -0.25rem;
			//z-index: 99;
			background-color: rgba(0,0,0,0.4);
			
			> .overlay-content {
				position: relative;
				top: calc(50% - 1rem);
			}
		}
	}


	.ace-file-multiple {
		height:auto;

		.ace-file-placeholder {
			flex-direction: column-reverse;
		}


		.ace-file-container {
			height:auto;
			border-radius: 0.25rem;

			.ace-file-name {
				height: auto;
				margin: 0 0.25rem;
			}

			&.selected  {
				margin-right: 0;
			}
		}

		.ace-file-placeholder .ace-file-name {
			margin: 0 1rem;

			text-align: center;

			text-overflow: unset;
			word-break: break-word;
			white-space: normal;
			line-height: normal;
		}

		////////
		.ace-file-item {
			border-bottom: 1px dotted $file-list-border;
			&:last-child {
				border-bottom-width: 0;
			}
		}

		.ace-file-icon.thumbnail-img {//small thumbnail
			width: 3.25rem;
			text-align: center;
		}

	}


}//@if $custom-file-input